import React from 'react';
import Loader from 'react-loaders';
import './DataLoader.scss';

interface DataLoaderProps {
    style?: React.CSSProperties;
}

const DataLoader = (props: DataLoaderProps) => {
    return (
        <div
            className="data-loader loader-container"
            style={props.style ?? { width: '100%', height: '10vw' }}>
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader active={true} type="ball-scale-multiple" />
                </div>
                <h6 className="mt-5">Loading your data...</h6>
            </div>
        </div>
    );
};

export default DataLoader;
