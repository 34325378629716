import { createAsyncThunk } from '@reduxjs/toolkit';

export interface LoadThreadMessagesArg {
    threadId: number;
    isAscending: boolean;
    pageSize?: number;
}

export const loadThreadMessages = createAsyncThunk(
    'supportMessages/loadThreadMessages',
    async (arg: LoadThreadMessagesArg, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const threadId = dispatch.getState().supportThreads.selectedThreadId;
        const messages = dispatch.getState().supportThreadsMessages.threads;

        const threadMessages = messages[threadId]?.messages;
        const message = arg.isAscending
            ? threadMessages?.at(-1)
            : threadMessages?.at(0);

        const response = await axios.get(
            `api/supportRequests/${arg.threadId}/messages`,
            {
                params: {
                    messageId: message?.id,
                    isAscending: arg.isAscending,
                    pageSize: arg.pageSize,
                },
            }
        );

        return {
            data: response.data.messages,
            threadId: arg.threadId,
            isAscending: arg.isAscending,
            lastMessageId: response.data.lastMessageId,
        };
    }
);
