import React, { useEffect, useState } from 'react';
import './QuestionnaireStagesList.scss';
import BootstrapTable, {
    SortOrder,
} from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import moment from 'moment';
import { DataForm } from '../../../models/DataRequestHub/DataForm';
import SortingOption from '../../../components/Table/models/bootstrap-table-sorting-option';
import { sortingFunction } from '../../../components/Table/models/table-helper';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../models/DataRequestHub/DataRequestProject';
import { DataFormType } from '../../../models/DataRequestHub/DataFormTypeEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Input } from 'reactstrap';
import CreateStagePopup from '../CreateStagePopup/CreateStagePopup';
import { DesignModeSwitcher } from '../DesignModeSwitcher/DesignModeSwitcher';
import { useNavigate } from 'react-router-dom';
import ReactSwitch from 'react-switch';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import QuestionSeenStatus from '../../../models/DataRequestHub/QuestionSeenStatus';
import { DataFormStageNameEditor } from './QuestionnaireStageNameEditor/DataFormStageNameEditor';
import { isDateInvalid } from '../../../components/EditableTable/EditableTableValidationHelper';
import EditableDateLabel from '../../../components/EditableDateLabel/EditableDateLabel';
import FormNotificationStatus from '../../../models/DataRequestHub/FormNotificationStatus';
import { EditorTypeEnum } from '../../../models/DataRequestHub/ProjectEditorEnum';

interface QuestionnaireStagesListProps {
    onStageNameClick?(formId: number): void;
    onEditIconClick?(formId: number): void;
    updateProjectsData(isInitialFetch?: boolean): void;
    projects: DataRequestProject[];
    isAdminView: boolean;
    isEditorMode: boolean;
    invalidStageIds: number[];
    openSettings?(): void;
    questionsSeenStatus: QuestionSeenStatus[];
}

interface TableSortingState {
    data: DataForm[];
    sortField: string;
    sortOrder: string;
}

interface QuestionnaireStage extends DataForm {
    practiceName: string;
}

const QuestionnaireStagesList = (props: QuestionnaireStagesListProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const formNotificationStatus = useStateSelector((s) =>
        s.dataRequestsNotifications.formsNotificationsStatus.filter(
            (f: FormNotificationStatus) =>
                f.formType === DataFormType.Questionnaire
        )
    );

    const targetFormType = DataFormType.Questionnaire;
    const [tableData, setTableData] = useState<DataForm[]>([]);
    const [sortingOption, setSortingOption] = useState<SortingOption>({
        dataField: 'displayOrder',
        order: 'asc',
    });
    const navigate = useNavigate();
    const [isCreateStagePopupVisible, setIsCreateStagePopupVisible] =
        useState(false);

    useEffect(() => {
        const forms = getFormsList();
        sortFunction(forms, sortingOption);
    }, [props.projects]);

    const updateStageVisibility = (isVisible: boolean, formId: number) => {
        const projectId = getProjectIdByFormId(formId);

        const params = {
            id: formId,
            templateId: 0,
            isVisible: isVisible,
        };

        axios
            .put(
                `/api/DataRequestProjects/${projectId}/dataFormVisibleStatuses`,
                [params]
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occured while updating stage',
                        'error'
                    );
                }
            });
    };

    const getFormsList = (): QuestionnaireStage[] =>
        props.projects
            .flatMap((project) => project.dataForms)
            .filter((form) => form.formType === targetFormType)
            .map((form) => {
                return {
                    ...form,
                    practiceName: getPracticeName(form.id),
                };
            });

    const getProjectIdByFormId = (formId: number) => {
        const projectId = props.projects.find((project) =>
            project.dataForms.some((form) => form.id === formId)
        ).id;
        return projectId;
    };

    const handleCompleteStatusChange = (
        isComplete: boolean,
        formId: number
    ) => {
        const requestObj = {
            isComplete: isComplete,
        };
        const projectId = getProjectIdByFormId(formId);
        axios
            .put(
                `/api/DataRequestProjects/${projectId}/dataForm/${formId}/completeStatus`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing marked as complete status',
                        'error'
                    );
                }
            });
    };

    const getStageNotifications = (formId: number): number => {
        const targetForm = formNotificationStatus.find(
            (f: FormNotificationStatus) => f.formId === formId
        );
        return targetForm?.notificationsCount ?? 0;
    };

    const getPracticeName = (formId: number): string =>
        props.projects?.find((p) => p.dataForms?.some((df) => df.id === formId))
            ?.practiceName;

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            hidden: props.projects?.length <= 1,
            sort: true,
            formatter: (cellContent: string) => cellContent,
        },
        {
            dataField: 'customName',
            text: 'Questionnaire',
            sort: true,
            headerClasses: 'stage-name-header',
            formatter: (cellContent: string, row: DataForm) => {
                const notificationsCount = getStageNotifications(row.id);
                const projectId = getProjectIdByFormId(row.id);

                return (
                    <div className="questionnaire-name-wrapper">
                        <DataFormStageNameEditor
                            isNameClickable={true}
                            updateUrl={`/api/DataRequestProjects/${projectId}/dataForms/${row.id}/customName`}
                            onSuccessfulUpdate={() => {
                                props.updateProjectsData();
                            }}
                            stageName={cellContent}
                            onStageNameClick={() =>
                                props.onStageNameClick(row.id)
                            }
                            isTemplateEditorView={props.isEditorMode}
                            isEditable={props.isAdminView && isProjectActive()}
                            enableLinkText={true}
                        />
                        {notificationsCount > 0 && !props.isEditorMode ? (
                            <Badge className="unread-comments-badge">
                                {notificationsCount > 9
                                    ? '9+'
                                    : notificationsCount}
                            </Badge>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: 'outstandingRequestsCount',
            text: '# of Outstanding Requests',
            hidden: props.isAdminView,
            sort: true,
        },
        {
            dataField: 'itemsToReviewCount',
            text: 'To Review',
            hidden: !props.isAdminView,
            sort: true,
        },
        {
            dataField: 'dateCreated',
            text: 'Date Created',
            sort: true,
            formatter: (cellContent: Date) => moment(cellContent).format('L'),
        },
        {
            dataField: 'dateDue',
            text: 'Date Due',
            sort: true,
            formatter: (cellContent: Date, row: DataForm) => {
                return (
                    <EditableDateLabel
                        value={cellContent}
                        isEditable={props.isAdminView}
                        onChange={(value) =>
                            handleDueDateChange(new Date(value), row.id)
                        }
                    />
                );
            },
        },
        {
            dataField: 'dateComplete',
            text: 'Date Complete',
            sort: true,
            formatter: (cellContent: Date) =>
                cellContent ? moment(cellContent).format('L') : 'NA',
        },
        {
            dataField: 'isComplete',
            text: 'Mark as complete',
            sort: false,
            hidden: !props.isAdminView,
            headerClasses: 'completness-checkbox-header',
            formatter: (cellContent: boolean, row: DataForm) => (
                <div className="checkbox-field">
                    <Input
                        checked={cellContent}
                        type="checkbox"
                        onChange={(event) => {
                            handleCompleteStatusChange(
                                event.target.checked,
                                row.id
                            );
                        }}
                        className="checkbox-input complete-checkbox"
                    />
                </div>
            ),
        },
        {
            dataField: 'isVisible',
            text: 'Visible',
            hidden: !props.isAdminView,
            sort: false,
            formatter: (cellContent: boolean, row: DataForm) => (
                <ReactSwitch
                    offColor="#d92550"
                    checked={cellContent}
                    onChange={(event) => updateStageVisibility(event, row.id)}
                    height={22}></ReactSwitch>
            ),
        },
    ];

    const handleDueDateChange = (date: Date, formId: number) => {
        const projectId = getProjectIdByFormId(formId);
        if (isDateInvalid(date)) {
            date = new Date('0001-01-01');
        }
        const params = {
            formId: formId,
            date: date,
        };

        axios
            .put(
                `/api/DataRequestProjects/${projectId}/dataFromDueDate`,
                params
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occured while updating stage',
                        'error'
                    );
                }
            });
    };

    const handleTableChange = (_props: any, state: TableSortingState) => {
        const newSortingOption: SortingOption = {
            dataField: state.sortField,
            order: state.sortOrder as SortOrder,
        };
        setSortingOption(newSortingOption);
        sortFunction(state.data, newSortingOption);
    };

    const sortFunction = (data: any[], sortingOption: SortingOption) => {
        const sortedQuestions = sortingFunction(data, sortingOption);
        setTableData(sortedQuestions);
    };

    const getProject = () => {
        return props.projects.find((f) => f);
    };

    const isProjectActive = () => {
        const targetProject = props.projects.find((project) => project);
        return targetProject.state === DataRequestProjectState.Active;
    };

    return (
        <div className="questionnaire-stages-list">
            <div className="editor-header">
                {props.isAdminView ? (
                    <div className="questionnaire-stages-host-header">
                        <Button
                            className="btn btn-primary"
                            onClick={() => setIsCreateStagePopupVisible(true)}>
                            Create Additional Questionnaire
                        </Button>
                        <div className="project-settings-section">
                            {props.isEditorMode && props.isAdminView ? (
                                <div
                                    className="clickable"
                                    onClick={() => {
                                        props.openSettings();
                                    }}>
                                    <FontAwesomeIcon icon={faWrench} />
                                    {'User View Settings'}
                                </div>
                            ) : (
                                <></>
                            )}
                            {isProjectActive() && (
                                <DesignModeSwitcher
                                    defaultValue={
                                        props.isEditorMode ? true : false
                                    }
                                    onEnable={props.onEditIconClick}
                                    onDisable={() => {
                                        navigate(
                                            `/data-request-dashboard?tab=${
                                                EditorTypeEnum.Questionnaire + 1
                                            }`,
                                            {
                                                state: {
                                                    projectId: getProject().id,
                                                    isAdmin: true,
                                                    projectName:
                                                        getProject().name,
                                                    practiceName:
                                                        getProject()
                                                            .practiceName,
                                                },
                                            }
                                        );
                                    }}
                                    formId={0}></DesignModeSwitcher>
                            )}
                        </div>
                    </div>
                ) : (
                    []
                )}
            </div>

            <BootstrapTable
                striped
                bordered
                bootstrap4
                remote
                keyField="id"
                defaultSorted={[sortingOption]}
                data={tableData}
                columns={getColumns()}
                onTableChange={handleTableChange}
                noDataIndication="No Data available"
                rowClasses={(row) => {
                    return props.invalidStageIds.includes(row.id)
                        ? 'invalid-stage'
                        : '';
                }}
            />

            {isCreateStagePopupVisible && props.isAdminView ? (
                <CreateStagePopup
                    project={getProject()}
                    onClose={() => setIsCreateStagePopupVisible(false)}
                    onAdd={props.updateProjectsData}
                />
            ) : (
                []
            )}
        </div>
    );
};

export default QuestionnaireStagesList;
