import React, { useEffect, useState } from 'react';
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap';
import './UpdatePaymentMethodModal.scss';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import PoweredByStripe from '../../../../assets/images/poweredByStripe.png';
import { PaymentMethod, PaymentMethodResult } from '@stripe/stripe-js';
import CardPaymentForm from '../../../../components/CardPaymentForm/CardPaymentForm';
import createNotification from '../../../../utils/createNotification';

import Rodal from '../../../../components/Rodal/Rodal';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../../store/selectors';

interface SavePaymentMethodModalProps {
    isVisible: boolean;
    onClose: () => void;
    updateCallBack: () => void;
    practiceId: number;
}

export const UpdatePaymentMethodModal = (
    props: SavePaymentMethodModalProps
) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isCardLoaded, setIsCardLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCardDataValid, setIsCardDataValid] = useState<boolean>(false);
    const axios = useStateSelector((state) => {
        return state.core.axios;
    });
    const [savedCard, setSavedCard] = useState(null);
    const [isCardExist, setIsCardExist] = useState(false);
    const [isUseDifferentMethodChecked, setIsUseDifferentMethodChecked] =
        useState(true);
    const [isCardSelected, setIsCardSelected] = useState(true);
    const [shouldSavePaymentMethod, setShouldSavePaymentMethod] =
        useState(false);

    useEffect(() => {
        GetSavedCard();
    }, []);

    const GetSavedCard = () => {
        axios
            .get('api/Wallet/PaymentMethod')
            .then((response: any) => {
                if (response.data && response.data.lastDigits) {
                    setSavedCard(response.data);
                    setIsCardExist(true);
                    setIsUseDifferentMethodChecked(false);
                    setIsCardSelected(true);
                }
            })
            .finally(() => setIsCardLoaded(true));
    };

    const savePaymentMethod = (paymentMethod: PaymentMethod) => {
        return axios.post<void>('api/Wallet/PaymentMethod', {
            paymentMethodId: paymentMethod.id,
            shouldUpdatePaymentMethod: savedCard ? true : false,
        });
    };

    const submit = () => {
        setIsLoading(true);
        if (isUseDifferentMethodChecked) {
            stripe
                .createPaymentMethod({
                    type: 'card',
                    card: elements.getElement('cardNumber'),
                })
                .then((result: PaymentMethodResult) => {
                    if (result?.paymentMethod) {
                        const id = result.paymentMethod.id;
                        let savePaymentPromise: Promise<any> =
                            Promise.resolve();
                        if (shouldSavePaymentMethod) {
                            savePaymentPromise = savePaymentMethod(
                                result.paymentMethod
                            );
                        }
                        savePaymentPromise
                            .then((response: any) => {
                                if (response && response.status != 200) {
                                    createNotification(
                                        response?.response?.data
                                            ? response.response.data
                                            : 'Something went wrong, could not save payment method.',
                                        'error'
                                    );
                                    setIsLoading(false);
                                } else {
                                    axios
                                        .put(
                                            `api/ServiceSubscriptions/UpdatePaymentMethod`,
                                            {
                                                practiceId: props.practiceId,
                                                stripePaymentMethodId: id,
                                                shouldUseSavedPaymentMethod:
                                                    shouldSavePaymentMethod,
                                            }
                                        )
                                        .then((response: any) => {
                                            if (
                                                response &&
                                                response.status == 200
                                            ) {
                                                props.updateCallBack();
                                                props.onClose();
                                                createNotification(
                                                    'Payment method saved successfully.',
                                                    'success'
                                                );
                                            } else {
                                                createNotification(
                                                    response?.response?.data
                                                        ? response.response.data
                                                        : 'Something went wrong, could not save payment method.',
                                                    'error'
                                                );
                                                setIsLoading(false);
                                            }
                                        });
                                }
                            })
                            .catch(() =>
                                createNotification(
                                    'Something went wrong, could not save payment method.',
                                    'error'
                                )
                            );
                    } else if (result.error && result.error.message) {
                        createNotification(result.error.message, 'error');
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    createNotification(
                        'Something went wrong during card validation.',
                        'error'
                    );
                    setIsLoading(false);
                });
        } else {
            axios
                .put(`api/ServiceSubscriptions/UpdatePaymentMethod`, {
                    practiceId: props.practiceId,
                    shouldUseSavedPaymentMethod: true,
                })
                .then((response: any) => {
                    if (response && response.status == 200) {
                        setIsLoading(false);
                        createNotification(
                            'Payment method saved successfully.',
                            'success'
                        );
                        props.updateCallBack();
                        props.onClose();
                    } else {
                        createNotification(
                            'Something went wrong, could not save payment method.',
                            'error'
                        );
                        setIsLoading(false);
                    }
                });
        }
    };

    return isCardLoaded ? (
        <div className="payment-modal-popup">
            <Rodal
                visible={props.isVisible}
                onClose={props.onClose}
                animation={'fade'}
                showMask={false}
                width={500}
                center>
                <ModalHeader className="popup-header">
                    Payment Method
                </ModalHeader>
                <CardPaymentForm
                    doesCardExist={isCardExist}
                    setIsUseDifferentMethodChecked={function (
                        isChecked: boolean
                    ): void {
                        setIsUseDifferentMethodChecked(isChecked);
                    }}
                    setIsCardSelected={function (isChecked: boolean): void {
                        setIsCardSelected(isChecked);
                    }}
                    isUseDifferentMethodChecked={isUseDifferentMethodChecked}
                    isCardSelected={isCardSelected}
                    savedCard={savedCard}
                    shouldSavePaymentMethod={shouldSavePaymentMethod}
                    setShouldSavePaymentMethod={function (
                        isChecked: boolean
                    ): void {
                        setShouldSavePaymentMethod(isChecked);
                    }}
                    setIsFormValid={function (isValid: boolean): void {
                        setIsCardDataValid(isValid);
                    }}></CardPaymentForm>
                <ModalFooter className="flex-footer">
                    <Row className="buttons-row">
                        <Button
                            className="btn close-button"
                            onClick={props.onClose}>
                            Cancel
                        </Button>
                        <ButtonLoader
                            className="btn btn-primary submit-button"
                            isLoading={isLoading}
                            loaderButtonText={''}
                            disabled={isLoading || !isCardDataValid}
                            buttonText={'Save Payment Method'}
                            onClick={submit}></ButtonLoader>
                    </Row>
                </ModalFooter>
                <div className="default-div-image">
                    <img
                        src={PoweredByStripe}
                        alt={'userImage'}
                        className="default-image"
                    />
                </div>
            </Rodal>
        </div>
    ) : (
        <div></div>
    );
};
