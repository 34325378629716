import React, { useState } from 'react';
import {
    Button,
    Col,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import Rodal from '../../../../components/Rodal/Rodal';
import createNotification from '../../../../utils/createNotification';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import './LeavingGroupModalPopup.scss';
import { useStateSelector } from '../../../../store/selectors';

interface LeavingGroupModalPopupProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isPoc: boolean;
    practiceId?: number;
    groupId?: number;
}

export const LeavingGroupModalPopup = (props: LeavingGroupModalPopupProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const axios = useStateSelector((state) => {
        return state.core.axios;
    });

    const name = props.isPoc ? 'IPOC' : 'PMG';

    const confirm = () => {
        setIsLoading(true);
        const action = props.isPoc
            ? axios.put('/api/clubhouses/pocprofile/ChangeProfileActiveStatus')
            : axios.delete(
                  `/api/group/${props.groupId}/practices/${props.practiceId}/leave`
              );
        action
            .then((response: any) => {
                if (response && response.status == 200) {
                    createNotification(
                        `Leaving ${name} was successful.`,
                        'success'
                    );
                    props.onConfirm();
                } else {
                    createNotification(
                        `Something went wrong during leaving ${name}.`,
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    `Something went wrong during leaving ${name}.`,
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
                props.onClose();
            });
    };

    return (
        <div className="leave-poc-modal-popup">
            <Rodal
                visible={props.isVisible}
                onClose={props.onClose}
                animation={'slideTop'}
                showMask={false}
                width={460}
                center>
                <ModalHeader className="popup-header">
                    Leave {name}?
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={11}>
                            {props.isPoc ? (
                                <div>
                                    After leaving the IPOC, your profile
                                    information will be limited to, and your
                                    posts identified with, ONLY your first and
                                    last name initials. All posts survive as
                                    long as the related thread exists.
                                </div>
                            ) : (
                                <div className="list-header-text">
                                    <div>After leaving the PMG:</div>
                                    <div>
                                        - You will remain bound by the
                                        restrictive covenants for the time
                                        period to which you agreed.
                                    </div>
                                    <div>
                                        - Your profile information in the PMG
                                        will be limited to your name and
                                        practice name, and your status will be
                                        "Former PMG Member".
                                    </div>
                                    <div>
                                        - Your Clubhouse posts will remain
                                        visible until the related thread is
                                        eliminated by the PMG, and they will be
                                        hidden for any period of time during
                                        which a practice is considered to be
                                        potentially competitive with your
                                        practice is a member of the PMG.
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={11}>
                            <div className="bold">
                                {props.isPoc
                                    ? 'Are you sure you want to leave the IPOC?'
                                    : 'Are you sure you want to leave this Practice Management Group?'}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn close-button"
                        onClick={props.onClose}>
                        No, do not leave
                    </Button>
                    <ButtonLoader
                        className="btn btn-primary confirm-button"
                        isLoading={isLoading}
                        loaderButtonText={''}
                        disabled={isLoading}
                        buttonText={'Leave'}
                        onClick={confirm}></ButtonLoader>
                </ModalFooter>
            </Rodal>
        </div>
    );
};
