import SortingOption from './bootstrap-table-sorting-option';

export const sortingFunction = (
    data: any[],
    sortingOption: SortingOption
) => {
    const sortedData = data.sort((a, b) => {
        const aValue = a[sortingOption.dataField];
        const bValue = b[sortingOption.dataField];

        const order = sortingOption.order === 'asc' ? 1 : -1;

        if (isValidDate(aValue) && isValidDate(bValue)) {
            return order * dateComparation(aValue, bValue);
        }

        if (isNumber(aValue) && isNumber(bValue)) {
            return order * numberComparation(aValue, bValue);
        }

        return order * stringComparation(aValue, bValue);
    });

    return sortedData;
};

const stringComparation = (a: string, b: string) => {
    if (a === null || a === undefined) return -1;
    if (b === null || b === undefined) return 1;
    return a.localeCompare(b);
};

const numberComparation = (a: number, b: number) => {
    if (a === null || a === undefined) return -1;
    if (b === null || b === undefined) return 1;
    return a - b;
};

const dateComparation = (a: string | null, b: string | null) => {
    if (a === null || a === undefined) return -1;
    if (b === null || b === undefined) return 1;
    return new Date(a).getTime() - new Date(b).getTime();
};

const isValidDate = (date: any) => {
    if (date === null || date === undefined) return false;
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate);
};

const isNumber = (value: any) => {
    return !isNaN(value) && typeof value === 'number';
};