export enum ProjectTypeEnum {
    AccountingStandardization,
    KpiBenchmarkingReport,
    MnaTransaction,
    Other,
}

export interface ProjectTypeEnumItem {
    id: number;
    name: string;
}

export const ProjectTypeEnumList = [
    {
        id: 0,
        name: 'Accounting Standardization',
    },
    {
        id: 1,
        name: 'KPI Benchmarking',
    },
    {
        id: 2,
        name: 'M&A Transaction',
    },
    {
        id: 3,
        name: 'Other',
    },
];
