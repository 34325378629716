import { createAsyncThunk } from '@reduxjs/toolkit';
import { CancelVoteArg } from './clubhouse-slice-helper';

export const CancelPosterVote = createAsyncThunk(
    'clubhouse/vote/cancel',
    async (arg: CancelVoteArg, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.delete(
            `api/clubhouses/${arg.clubhouseId}/discussions/${arg.discussionId}/removalVoting`
        );

        return response;
    }
);
