import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Hamburger from '../../Hamburger/hamburger';
import cx from 'classnames';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import {
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../../reducers/ThemeOptionsSlice';
import { DirectButton } from '../../../direct/direct-button/direct-button';
import ContactSupportButton from '../../../containers/ContactSupportButton/ContactSupportButton';
import { USER_ROLES } from '../../../utils/constants';
import { SupportContextEnum } from '../../../support-portal/models/SupportContext';

class AppMobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
        };
    }

    toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

    toggleMobileSmall = () => {
        let { enableMobileMenuSmall, setEnableMobileMenuSmall } = this.props;
        setEnableMobileMenuSmall(!enableMobileMenuSmall);
    };

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
        screenWidth: 0,
        isCharityAdmin: false,
        isInitialized: false,
    };

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.updateWindowDimensions);

        const summary = JSON.parse(localStorage.getItem('loggedInUserSummary'));
        this.setState({
            isCharityAdmin: summary.roleId === USER_ROLES.CHARITY_ADMIN,
        });
        this.setState({
            isAdmin: summary.roleId === USER_ROLES.ADMIN,
        });
        this.setState({
            isInitialized: true,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        let { enableMobileMenu } = this.props;

        return (
            <Fragment>
                <div className="app-header__mobile-menu">
                    <div onClick={this.toggleMobileSidebar}>
                        <Hamburger
                            active={enableMobileMenu}
                            type="elastic"
                            onClick={() =>
                                this.setState({
                                    activeMobile: !this.state.activeMobile,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="app-header__menu">
                    {this.state.isCharityAdmin ? (
                        <div className={'contact-support-button-wrapper'}>
                            <ContactSupportButton
                                buttonType="reference"
                                buttonText={
                                    this.state.screenWidth >= 610
                                        ? 'Contact Nonprofit Administrator'
                                        : ''
                                }
                                supportContext={SupportContextEnum.Charity}
                                fontSize="15px"
                                tooltipText="Contact the Nonprofit Administrator with any questions or support needs"
                                tooltipTargetId="app-header-mobile"
                            />
                        </div>
                    ) : (
                        []
                    )}
                    <div className="direct-host-wrapper-mobile">
                        {!this.state.isAdmin && this.state.isInitialized ? (
                            <DirectButton isMainButton={true}></DirectButton>
                        ) : (
                            []
                        )}
                    </div>
                    <span onClick={this.toggleMobileSmall}>
                        <Button
                            size="sm"
                            className={cx('btn-icon btn-icon-only', {
                                active: this.state.activeSecondaryMenuMobile,
                            })}
                            color="primary"
                            onClick={() =>
                                this.setState({
                                    activeSecondaryMenuMobile:
                                        !this.state.activeSecondaryMenuMobile,
                                })
                            }>
                            <div className="btn-icon-wrapper right-margin">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </Button>
                    </span>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: (enable) =>
        dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);
