import React, { useEffect } from 'react';
import '../KnowledgeBaseContentLibrary.scss';
import TitleArrow from '../../../../assets/images/TitleArrow.png';
import TypeArrow from '../../../../assets/images/TypeArrow.png';
import TypicalTagsArrow from '../../../../assets/images/TypicalTagsArrow.png';
import removeArrow from '../../../../assets/images/removeArrow.png';
import postingArrow from '../../../../assets/images/postingArrow.png';
import decArrow from '../../../../assets/images/decArrow.png';
import { Row, Col, Button, Badge, ModalHeader, ModalBody } from 'reactstrap';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createNotification from '../../../../utils/createNotification';
import Rodal from '../../../../components/Rodal/Rodal';
import { useStateSelector } from '../../../../store/selectors';

const KnowledgeBaseAboutModal = (props) => {
    const axios = useStateSelector((state) => state.core.axios);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    });

    const closeModal = () => {
        if (!props.isFirstVisit) {
            props.setIsVisible(false);
            return;
        }

        axios
            .post('/api/KnowledgeBase/add-to-viewed', { contentId: null })
            .then(() => {
                props.setIsFirstVisit(false);
                props.setIsVisible(false);
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    };

    return (
        <Rodal
            className="about-knowledge-popup"
            visible={props.isVisible}
            onClose={() => closeModal()}
            animation={'fade'}
            showMask={true}>
            <div className="pdetail-header">
                <ModalHeader> About The Knowledge Library </ModalHeader>
            </div>

            <ModalBody className="about-knowledge-body">
                <label>Purpose</label>
                <p className="about-knowledge-body-p">
                    VetValue's Knowledge Library is a reference knowledge base,
                    curated by VetValue, designed to help veterinary practice
                    owners and managers expand their knowledge of the{' '}
                    <i>business</i> aspects of owning and operating a veterinary
                    practice.
                </p>
                <label>Rules</label>
                <p className="about-knowledge-body-p">
                    Members are free to use the content for their own purposes
                    but you are not free to distribute this content to anyone
                    outside of the EcoSystem. A violation of these rules will
                    lead to suspension from the Knowledge Library.
                </p>
                <label>Navigating the Library</label>
                <p className="about-knowledge-body-p">
                    Members are able to sort, browse, search and download the
                    content in the library. They are also able to save any piece
                    of content to their own library shelf.{' '}
                </p>
                <div className="knowledge-library-records">
                    <Row className="gethelp-form">
                        <Col md={12} className="records-data">
                            <div className="product-name-image-wrapper modal-wrapper">
                                <div className="title-arrow">
                                    <label className="title-point-label">
                                        Title
                                    </label>
                                    <img
                                        src={TitleArrow}
                                        className="value-arrow"
                                    />
                                </div>

                                <h6>Valuation in a Sale Context</h6>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="pdf-image"
                                />
                                <div className="type-pdf-arrow">
                                    <label className="type-of-content">
                                        Type Of Content
                                    </label>
                                    <img
                                        src={TypeArrow}
                                        className="type-arrow"
                                    />
                                </div>
                            </div>
                            <p className="date-author">
                                Oct 15, 2021 | By Beth Williams
                            </p>
                            <div className="posting-arrow">
                                <label className="posting-arrow-content">
                                    Posting Date
                                </label>
                                <img
                                    src={postingArrow}
                                    className="posting-arrow-img"
                                />
                            </div>
                            <p className="text-desc">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor.
                            </p>
                            <div className="dec-arrow-wrapper">
                                <label className="dec-arrow-content">
                                    Description
                                </label>
                                <img src={decArrow} className="dec-arrow-img" />
                            </div>
                            <a className="open-valuation-product">Read More</a>
                            <div className="badge-button-wrapper">
                                <div className="badge-collections badge-wrapper">
                                    <Badge className="product-badge">
                                        Valuation
                                    </Badge>
                                    <Badge className="product-badge">
                                        Practice Sale
                                    </Badge>
                                    <Badge className="product-badge">
                                        EBITDA
                                    </Badge>
                                    <div className="tag-arrow-wrapper">
                                        <label className="tag-arrow-content">
                                            Topical Tags
                                        </label>
                                        <img
                                            src={TypicalTagsArrow}
                                            className="tag-arrow"
                                        />
                                    </div>
                                </div>
                                <div className="save-extra remove">
                                    <div className="btn-outer-line no-border">
                                        <Button className="save-my-shelf-btn">
                                            Remove from
                                            <span>My Shelf</span>
                                        </Button>
                                    </div>

                                    <img />
                                </div>
                                <div className="remove-arrow-wrapper">
                                    <label className="remove-arrow-content">
                                        Save to, or remove from, your personal
                                        shelf
                                    </label>
                                    <img
                                        src={removeArrow}
                                        className="remove-arrow"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <Button
                className="cancel-btn close-about-knowledge"
                onClick={() => closeModal()}>
                Close
            </Button>
        </Rodal>
    );
};
export default KnowledgeBaseAboutModal;
