import { ComparationType } from '../../../clubhouse/slices/messages/load-discussion-messages-by-likes.thunk';

export interface RetrieveMessageArg {
    roomId: number;
}

export interface RetrieveMessageByLikesArg {
    roomId: number;
    numberOfLikes: number;
    comparationType: ComparationType;
}

export const retrieveDirectRoomMessages = async (
    arg: RetrieveMessageArg,
    dispatch: any
) => {
    const axios = dispatch.getState().core.axios;
    const response = await axios.get(`api/direct/${arg.roomId}/messages`);

    return {
        data: response.data.messages,
        roomId: arg.roomId,
        lastMessageId: response.data.lastMessageId,
    };
};

export const retrieveDirectRoomMessagesByLikes = async (
    arg: RetrieveMessageByLikesArg,
    dispatch: any
) => {
    const axios = dispatch.getState().core.axios;
    const response = await axios.get(
        `api/direct/${arg.roomId}/messages/byLikes`,
        {
            params: {
                numberOfLikes: arg.numberOfLikes,
                comparationType: arg.comparationType,
            },
        }
    );

    return {
        data: response.data.messages,
        discussionId: arg.roomId,
        lastMessageId: response.data.lastMessageId,
    };
};
