import { createSlice } from '@reduxjs/toolkit';
import { AreYouStillHereState } from '../are-you-still-here.state';

const initialState: AreYouStillHereState = {
    isOpened: false,
    expirationDate: () => {
        const date = new Date(localStorage.getItem('expirationDate'));
        if (isNaN(date.getTime())) return undefined;
        return date;
    },
};

const areYouStillHere = createSlice({
    name: 'areYouStillHere',
    initialState,
    reducers: {
        setIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        setExpirationDate(state, action) {
            localStorage.setItem('expirationDate', action.payload);
        },
    },
});

export const { setIsOpened, setExpirationDate } = areYouStillHere.actions;
export default areYouStillHere.reducer;
