import React from 'react';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    Label,
    FormGroup,
} from 'reactstrap';
import ButtonLoader from '../Layout/Buttons/ButtonLoader';
import Rodal from '../Rodal/Rodal';

const DeleteConfirmationModal = ({
    isVisible,
    setIsVisible,
    title,
    mainText,
    onDelete,
    isDeleting,
}) => {
    return (
        isVisible && (
            <Rodal
                visible={true}
                animation="slideTop"
                width={498}
                showCloseButton={false}
                onClose={() => {
                    setIsVisible(false);
                }}
                className="delete-confirmation-modal">
                <ModalHeader>{title}</ModalHeader>
                <FormGroup>
                    <ModalBody>
                        <Row>
                            <Col sm={12}>
                                <Label className="label-textarea">
                                    {mainText}
                                </Label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            className="cancel"
                            onClick={() => {
                                setIsVisible(false);
                            }}>
                            Cancel
                        </Button>
                        <ButtonLoader
                            buttonText={'Delete'}
                            disabled={false}
                            isLoading={isDeleting}
                            onClick={onDelete}
                            className={
                                isDeleting
                                    ? 'btn btn-primary loader-submit btn-delete'
                                    : 'btn btn-primary btn-delete'
                            }
                            color="primary"></ButtonLoader>
                    </ModalFooter>
                </FormGroup>
            </Rodal>
        )
    );
};
export default DeleteConfirmationModal;
