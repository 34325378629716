import React, { useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import SizePerPageOption from './size-per-page-option';
import Tooltip from '../../../Tooltip/Tooltip';

const sizePerPageDefaultClass = 'react-bs-table-sizePerPage-dropdown';

const SizePerPageDropDown = (props) => {
    const {
        open = false,
        tableId = null,
        hidden = false,
        onClick,
        onBlur,
        options,
        className = '',
        variation = 'dropdown',
        bootstrap4 = false,
        btnContextual = 'btn-default btn-secondary',
        optionRenderer = null,
        currSizePerPage,
        onSizePerPageChange,
        tooltipTargetId,
    } = props;

    const dropDownStyle = { visibility: hidden ? 'hidden' : 'visible' };
    const openClass = open ? 'open show' : '';
    const dropdownClasses = cs(
        openClass,
        sizePerPageDefaultClass,
        variation,
        className
    );

    const id = tableId
        ? `${tooltipTargetId}-${tableId}-pageDropDown`
        : `${tooltipTargetId}-pageDropDown`;
    const key = tableId ? `${tableId}-pageDropDown` : 'pageDropDown';

    const [isOpen, setIsOpen] = useState(false);

    const showTooltip = () => {
        setIsOpen(true);
    };

    const hideTooltip = () => {
        setIsOpen(false);
    };

    return (
        <>
            <span style={dropDownStyle} className={dropdownClasses}>
                <button
                    id={id}
                    key={key}
                    type="button"
                    className={`btn ${btnContextual} dropdown-toggle`}
                    data-toggle="dropdown"
                    aria-expanded={open}
                    onClick={onClick}
                    onBlur={onBlur}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                >
                    {currSizePerPage}
                    {bootstrap4 ? null : (
                        <span>
                            <span className="caret" />
                        </span>
                    )}
                </button>
                <ul
                    className={`dropdown-menu ${openClass}`}
                    role="menu"
                    aria-labelledby={id}
                >
                    {options.map((option) => {
                        if (optionRenderer) {
                            return optionRenderer({
                                ...option,
                                onSizePerPageChange,
                            });
                        }
                        return (
                            <SizePerPageOption
                                {...option}
                                key={option.text}
                                bootstrap4={bootstrap4}
                                onSizePerPageChange={onSizePerPageChange}
                            />
                        );
                    })}
                </ul>
            </span>
            {tooltipTargetId ? (
                <Tooltip
                    className="custom-tooptip"
                    isOpen={isOpen}
                    placement="top"
                    target={id}
                >
                    Number of entries displayed per page
                </Tooltip>
            ) : null}
        </>
    );
};

SizePerPageDropDown.propTypes = {
    currSizePerPage: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onSizePerPageChange: PropTypes.func.isRequired,
    bootstrap4: PropTypes.bool,
    tableId: PropTypes.string,
    open: PropTypes.bool,
    hidden: PropTypes.bool,
    btnContextual: PropTypes.string,
    variation: PropTypes.oneOf(['dropdown', 'dropup']),
    className: PropTypes.string,
    optionRenderer: PropTypes.func,
};

export default SizePerPageDropDown;
