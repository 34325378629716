import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface UpdateMessageThunkArg {
    connection: HubConnection;
    clubhouseId: number;
    selectedDiscussionId: number;
    messageText: string;
    messageId: number;
}

export const updateMessage = createAsyncThunk(
    'clubhouseMessages/update',
    async (arg: UpdateMessageThunkArg) => {
        let chatMessage = {
            clubhouseId: arg.clubhouseId,
            discussionId: arg.selectedDiscussionId,
            htmlContent: arg.messageText,
            messageId: arg.messageId,
        };
        await arg.connection.send('UpdateMessage', chatMessage);
    }
);
