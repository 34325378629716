export enum EditorTypeEnum {
    Documents,
    FinancialRequest,
    Questionnaire,
    DataTable,
    Variable,
    DataTableView,
    DataTableData,
}

export const EditorTypeNameList = [
    {
        id: 0,
        name: 'Documents',
    },
    {
        id: 1,
        name: 'Financial Requests',
    },
    {
        id: 2,
        name: 'Questionnaire',
    },
    {
        id: 3,
        name: 'Data Tables',
    },
    {
        id: 4,
        name: 'Variables',
    },
    {
        id: 5,
        name: 'Data Table View',
    },
];
