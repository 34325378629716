import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import {
    IPOCRequestStatus,
    USER_POC_ACCESS_RIGHTS,
    USER_ROLES,
    PRACTICE_OWNERSHIP_TYPES,
} from '../../utils/constants';
import Helpers from '../../utils/helper';
import { WelcomeToPocModal } from '../welcome-to-poc-modal/welcome-to-poc-modal';
import ClubhouseArea from '../clubhouse-area/clubhouse-area';
import { GetPocId } from '../slices/clubhouse/get-poc-group.thunk';
import { DoesMemberAuthorizedInPoc } from '../slices/clubhouse/does-member-authorized-in-poc.thunk';
import { GetProfileRegistrationData } from '../slices/clubhouse/get-profile-registration-data.thunk';
import './poc-page.scss';
import { PocRegistrationData } from '../models/pocRegistrationData';
import { GroupType } from '../models/group-type';
import { Member } from '../models/member';
import PocProfileModal from '../poc-profile-modal/poc-profile-modal';
import { onPocSelected } from '../../utils/webViewHelper';
import { PageHeader } from '../page-header/page-header';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../utils/associateMembersConstants';
import TopLikedPostsModal from '../top-liked-posts-modal/top-liked-posts-modal';
import { MessageReference } from '../models/message-reference';
import PocAboutModal from '../poc-about-modal/poc-about-modal';
import { SetIfFirstVisit } from '../slices/clubhouse/add-to-viewed.thunk';
import { useStateSelector } from '../../store/selectors';
import AccessRestrictedModal from '../../components/Modal/AccessRestrictedModal';
import { SupportContextEnum } from '../../support-portal/models/SupportContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryParams } from '../../utils/queryParams';
import { CorporateGroup } from '../../containers/CorporateGroupManagement/Model/CorporateGroup';
import { PendingProfileModal } from '../poc-pending-profile-modal/poc-pending-profile-modal';
import { PRACTICE_OWNER_TYPES } from '../../utils/constants';

const PocPage = () => {
    const dispatch: any = useDispatch();
    const axios = useStateSelector((state: any) => state.core.axios);

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isForbiddenError, setIsForbiddenError] = useState(false);
    const [isUnexpectedError, setIsUnexpectedError] = useState(false);
    const [isPendingPopupVisible, setIsPendingPopupVisible] = useState(false);
    const [
        isAccessRestrictionPopupVisible,
        setIsAccessRestrictionPopupVisible,
    ] = useState(false);
    const [hasApprovedPractice, setHasApprovedPractice] = useState(false);

    const [isWelcomeVisible, setIsWelcomeVisible] = useState(true);
    const [isPocProfileModalVisible, setIsPocProfileModalVisible] =
        useState(false);
    const [isTopLikedPostsModalVisible, setIsTopLikedPostsModalVisible] =
        useState(false);
    const [isPocAboutModalVisible, setIsPocAboutModalVisible] = useState(false);

    const [pocId, setPocId] = useState(null);
    const [registrationData, setRegistrationData] =
        useState<PocRegistrationData>();
    const [corpGroupData, setCorpGroupData] = useState<CorporateGroup>();
    const [preselectedDiscussionId, setPreselectedDiscussionId] =
        useState(null);

    const [isDisabledMessageInput, setIsDisabledMessageInput] = useState(false);
    const [isDisableKbContent, setIsDisableKbContent] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const groupId = useParams().id;

    const userSummary = JSON.parse(localStorage.getItem('loggedInUserSummary'));
    const isUserAdmin = userSummary?.roleId === USER_ROLES.ADMIN;

    const userRole = userSummary?.roleId ?? null;
    const userCorporationId = userSummary?.corporateAffiliation?.id ?? null;
    const isCorporatePracticeOwner = userSummary.practiceOwnerType === PRACTICE_OWNERSHIP_TYPES.CORPORATE;

    onPocSelected();

    const [messageReference, setMessageReference] = useState<
        MessageReference | undefined
    >();

    const [pocTitle, setPocTitle] = useState('IPOC');

    useEffect(() => {
        if (!!groupId) {
            if (
                !isUserAdmin &&
                (!userCorporationId || userCorporationId != Number(groupId))
            ) {
                setIsForbiddenError(true);
                setIsPageLoading(false);
                return;
            } else {
                axios
                    .get(`api/CorporateGroups/${groupId}`)
                    .then((response: any) => {
                        if (response && response.data) {
                            setCorpGroupData(response.data);
                        }

                        getPocData(response.data);
                    });
            }
        } else if (isCorporatePracticeOwner) {
            setIsForbiddenError(true);
            setIsPageLoading(false);
            return;
        } else {
            getPocData();
        }
    }, []);

    useEffect(() => {
        const preselectedDiscussionId = Number(
            searchParams.get(QueryParams.DiscussionIdKey)
        );
        setPreselectedDiscussionId(preselectedDiscussionId);
    }, []);

    const currentMember = useStateSelector((state) => {
        const currentMemberId =
            state.clubhouse?.clubhouse?.currentMemberId ?? 0;
        return state.clubhouse?.clubhouse?.members.find(
            (member: Member) => member.id === currentMemberId
        );
    });

    const refreshPoc = () => {
        setIsDataLoading(true);
        setIsActive(false);
        setIsPageLoading(true);
        setIsForbiddenError(false);
        setIsPendingPopupVisible(false);
        setIsWelcomeVisible(true);
        setHasApprovedPractice(false);
        setIsPocProfileModalVisible(false);
        setIsUnexpectedError(false);
        getPocData();
    };

    const getPocData = (corpData: CorporateGroup = undefined) => {
        const corpDataLocal = corpData ?? corpGroupData;

        if (!!groupId) {
            if (corpDataLocal == undefined || corpDataLocal?.pocId == null) {
                setIsUnexpectedError(true);
                setIsPageLoading(false);
                return;
            } else {
                setPocTitle(`${corpDataLocal.name} Community`);
            }
        }

        dispatch(GetPocId())
            .then((response: any) => {
                if (response.payload.status === 200) {
                    if (
                        !isUserAdmin &&
                        !!groupId &&
                        corpDataLocal?.pocId != response.payload.data
                    ) {
                        setIsForbiddenError(true);
                        setIsPageLoading(false);
                        return;
                    }

                    setPocId(
                        isUserAdmin && !!groupId
                            ? corpDataLocal?.pocId
                            : response.payload.data
                    );
                    dispatch(DoesMemberAuthorizedInPoc())
                        .then((responseMemberAuthorized: any) => {
                            if (
                                responseMemberAuthorized.payload.status === 200
                            ) {
                                getUserAccessRights().then((response: any) => {
                                    if (
                                        response.data.userPocAccessRights ===
                                        USER_POC_ACCESS_RIGHTS.NO_ACCESS
                                    ) {
                                        setIsAccessRestrictionPopupVisible(
                                            true
                                        );
                                        return;
                                    }
                                    if (
                                        response.data.userPocAccessRights ===
                                        USER_POC_ACCESS_RIGHTS.VIEW_ONLY
                                    ) {
                                        setIsDisabledMessageInput(true);
                                    }
                                    setIsDisableKbContent(
                                        !response.data.doesHaveAccessToKb
                                    );
                                });
                                setIsPocAboutModalVisible(
                                    responseMemberAuthorized.payload.data
                                        .isFirstVisit
                                );
                                if (
                                    !responseMemberAuthorized.payload.data
                                        .doesMemberAuthorizedInPoc
                                ) {
                                    dispatch(GetProfileRegistrationData())
                                        .then(
                                            (responseRegistrationData: any) => {
                                                if (
                                                    responseRegistrationData
                                                        .payload.status === 200
                                                ) {
                                                    if (
                                                        responseRegistrationData
                                                            .payload.data
                                                            .status ==
                                                        IPOCRequestStatus.PENDING
                                                    ) {
                                                        setIsPendingPopupVisible(
                                                            true
                                                        );
                                                    } else {
                                                        setRegistrationData(
                                                            responseRegistrationData
                                                                .payload.data
                                                        );
                                                        setIsWelcomeVisible(
                                                            true
                                                        );
                                                        setHasApprovedPractice(
                                                            true
                                                        );
                                                    }
                                                } else if (
                                                    responseRegistrationData
                                                        .payload?.response
                                                        ?.status === 404
                                                ) {
                                                    setIsWelcomeVisible(true);
                                                    setHasApprovedPractice(
                                                        false
                                                    );
                                                } else {
                                                    if (
                                                        response.payload
                                                            ?.response
                                                            ?.status === 403
                                                    ) {
                                                        setIsForbiddenError(
                                                            true
                                                        );
                                                    }
                                                    if (
                                                        responseRegistrationData
                                                            .payload.response
                                                            ?.status == 409
                                                    ) {
                                                        setIsWelcomeVisible(
                                                            false
                                                        );
                                                    } else {
                                                        setIsUnexpectedError(
                                                            true
                                                        );
                                                    }
                                                }
                                                setIsPageLoading(false);
                                            }
                                        )
                                        .catch(() =>
                                            setIsUnexpectedError(true)
                                        );
                                } else {
                                    setIsWelcomeVisible(false);
                                    setIsPageLoading(false);
                                }
                            }
                        })
                        .catch(() => setIsUnexpectedError(true));
                } else if (response.payload?.response?.status === 403) {
                    setIsForbiddenError(true);
                    setIsPageLoading(false);
                } else {
                    setIsUnexpectedError(true);
                    setIsPageLoading(false);
                }
            })
            .catch(() => setIsUnexpectedError(true));
    };

    const openPocProfileModal = () => {
        setIsPocProfileModalVisible(true);
    };

    const setIfFirstVisit = () => {
        dispatch(SetIfFirstVisit());
    };

    const getHeaderButtons = () => {
        const buttons = [
            <a
                className={
                    'top-liked-posts' + (isUserAdmin ? ' no-margin' : '')
                }
                onClick={() => setIsTopLikedPostsModalVisible(true)}>
                Top Liked Posts
            </a>,
        ];
        if (!isUserAdmin && currentMember) {
            buttons.push(
                <Button
                    onClick={openPocProfileModal}
                    disabled={false}
                    type="button"
                    className="poc-profile"
                    color="primary">
                    {`My ${pocTitle == 'IPOC' ? 'IPOC' : 'PLC'} Profile`}
                </Button>
            );
        }

        return buttons;
    };

    const getUserAccessRights = async () => {
        const response = await axios.get(`api/users/AccessRights`);
        return response;
    };

    const getAboutThePocButton = () => {
        return (
            <div className="about-poc-title-wrapper">
                <div className="about-poc-title">
                    <div
                        onClick={() => {
                            setIsPocAboutModalVisible(true);
                        }}>
                        {`About the ${pocTitle}`}
                    </div>
                </div>
            </div>
        );
    };

    const isKbAvailable = () => {
        let loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        if (loggedInUserSummary) {
            const summary = JSON.parse(loggedInUserSummary);
            const isOwner = summary.roleId === USER_ROLES.PRACTICE_OWNER;
            const isAssociate =
                summary.roleId === USER_ROLES.PRACTICE_OWNER ||
                summary.roleId === USER_ROLES.USER ||
                (summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
                    summary.associateMember &&
                    summary.associateMember.some((x: any) =>
                        x.practices.some((p: any) =>
                            p.acceptedAccessRights.includes(
                                ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                            )
                        )
                    ));
            if (
                ((isOwner &&
                    summary.practices?.filter((x: any) => x.status == 1)
                        .length > 0) ||
                    isAssociate) &&
                !isDisableKbContent
            ) {
                return true;
            }
        }

        return false;
    };

    return (
        <Fragment>
            <div className="app-poc-page">
                <div className="flex-container">
                    {isPageLoading ? (
                        <div className="loader">
                            {Helpers.renderTableLoader()}
                        </div>
                    ) : isUnexpectedError ? (
                        <h2 className="error-message">
                            Unexpected error occurred while fetching Clubhouse
                        </h2>
                    ) : isAccessRestrictionPopupVisible ? (
                        <div>
                            <AccessRestrictedModal
                                text={
                                    'An administrator has revoked your access to the Independent Practice Owner Community. You may contact the administrator via: support@vetvalue.pet'
                                }
                                supportContext={
                                    SupportContextEnum.Poc
                                }></AccessRestrictedModal>
                        </div>
                    ) : isForbiddenError ? (
                        <h2 className="error-message">
                            You do not have access to view this page
                        </h2>
                    ) : isPendingPopupVisible ? (
                        <PendingProfileModal></PendingProfileModal>
                    ) : isWelcomeVisible ? (
                        <WelcomeToPocModal
                            setIsWelcomeToPocModalVisible={setIsWelcomeVisible}
                            registrationData={registrationData}
                            setIsUnexpectedError={setIsUnexpectedError}
                            hasApproveedPractice={hasApprovedPractice}
                            refreshPoc={() => refreshPoc()}
                            isCorporatePracticeOwner={isCorporatePracticeOwner}
                            corporationName={
                                userSummary.corporateAffiliation?.name
                            }></WelcomeToPocModal>
                    ) : (
                        <>
                            {' '}
                            {isDataLoading ? (
                                []
                            ) : (
                                <>
                                    <PageHeader
                                        roleId={userRole}
                                        areBreadcrumbsVisible={true}
                                        title={`The ${pocTitle}`}
                                        middleTools={getAboutThePocButton()}
                                        tools={getHeaderButtons()}
                                        clubhouseType={
                                            corpGroupData != undefined
                                                ? GroupType.CPOC
                                                : GroupType.IPOC
                                        }></PageHeader>
                                    {isPocProfileModalVisible &&
                                    currentMember ? (
                                        <PocProfileModal
                                            isVisible={isPocProfileModalVisible}
                                            setVisibility={
                                                setIsPocProfileModalVisible
                                            }
                                            currentMemberId={currentMember?.id}
                                            member={currentMember}
                                        />
                                    ) : (
                                        []
                                    )}
                                </>
                            )}
                            <ClubhouseArea
                                messageReference={messageReference}
                                resetMessageReference={() => {
                                    setMessageReference(undefined);
                                }}
                                clubhouseId={pocId}
                                isAdmin={isUserAdmin}
                                isDataLoading={isDataLoading}
                                setIsDataLoading={setIsDataLoading}
                                isActive={isActive}
                                setIsActive={setIsActive}
                                setIsForbiddenError={setIsForbiddenError}
                                refreshClubhouseData={refreshPoc}
                                setIsUnexpectedError={setIsUnexpectedError}
                                groupType={
                                    corpGroupData != undefined
                                        ? GroupType.CPOC
                                        : GroupType.IPOC
                                }
                                isDisabledMessageInput={isDisabledMessageInput}
                                preselectedDiscussionId={
                                    preselectedDiscussionId
                                }
                                isKbAvailable={isKbAvailable()}></ClubhouseArea>
                        </>
                    )}
                </div>
            </div>
            {isTopLikedPostsModalVisible ? (
                <TopLikedPostsModal
                    isVisible={isTopLikedPostsModalVisible}
                    setVisibility={(isVisible: boolean) =>
                        setIsTopLikedPostsModalVisible(isVisible)
                    }
                    openMessage={(reference: MessageReference) => {
                        setMessageReference(reference);
                        setIsTopLikedPostsModalVisible(false);
                    }}></TopLikedPostsModal>
            ) : (
                []
            )}
            {isPocAboutModalVisible && !isDataLoading && !isWelcomeVisible ? (
                <PocAboutModal
                    isVisible={isPocAboutModalVisible}
                    closeModal={() => {
                        setIsPocAboutModalVisible(false);
                        setIfFirstVisit();
                    }}
                    isCorporatePracticeOwner={isCorporatePracticeOwner}
                    corporationName={
                        userSummary.corporateAffiliation?.name
                    }></PocAboutModal>
            ) : (
                []
            )}
        </Fragment>
    );
};

export default PocPage;
