export enum PracticeOwnerType {
    Independent = 1,
    Former = 2,
    Aspiring = 3,
    Corporate = 4,
}

export const PRACTICE_OWNERSHIP_TYPES = {
    INDEPENDENT: PracticeOwnerType.Independent,
    FORMER: PracticeOwnerType.Former,
    ASPIRING: PracticeOwnerType.Aspiring,
    CORPORATE: PracticeOwnerType.Corporate,
};

export const PRACTICE_OWNERSHIP_TYPE_NAMES = {
    1: 'Current Independent Practice Owner',
    2: 'Former Practice',
    3: 'Future Practice',
    4: 'Current Corporate Practice Leader',
};
