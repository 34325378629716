import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import './ContactSupportButton.scss';
import Tooltip from '../../components/Tooltip/Tooltip';
import createNotification from '../../utils/createNotification';
import { useStateSelector } from '../../store/selectors';
import { SupportContextEnum } from '../../support-portal/models/SupportContext';
import {
    setIsNewRequestInitiated,
    setSelectedThreadId,
} from '../../support-portal/slices/threads/support-threads-slice';
import { useDispatch } from 'react-redux';
import { setIsOpened, setIsSelectedDirectRoom } from '../../direct/slices/rooms/direct-rooms-slice';

interface ContactSupportButtonProps {
    buttonType: 'button' | 'reference' | 'directButton';
    buttonText?: string;
    fontSize?: string;
    fontFamily?: string;
    tooltipTargetId?: string;
    tooltipText?: string;
    supportContext: SupportContextEnum;
    practiceId?: number;
    reportGenerationDate?: Date;
    reportId?: number;
    groupId?: number;
    onButtonClick?(initiateSupportThreadCallback: () => void): void;
}

const ContactSupportButton = (props: ContactSupportButtonProps) => {
    const buttonText = props.buttonText ?? 'Get Help';
    const fontSize = props.fontSize ?? '16px';
    const fontFamily = props.fontFamily ?? 'SegoeUI-Semibold';
    const dispatch: any = useDispatch();
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [isPopupSubmitButtonLoading, setIsPopupSubmitButtonLoading] =
        useState(false);
    const axios = useStateSelector((state) => state.core.axios);
    const contextsWithEmailIcon = [SupportContextEnum.KnowledgeLibrary]

    const handleButtonClick = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (props.onButtonClick) {
            props.onButtonClick(handleSupportSubmit);
            return;
        }

        if (!isPopupSubmitButtonLoading) {
            handleSupportSubmit();
        }

        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const handleSupportSubmit = () => {
        setIsPopupSubmitButtonLoading(true);
        dispatch(setIsSelectedDirectRoom(false))
        const requestObj = {
            supportContext: props.supportContext,
            practiceId: props.practiceId,
            reportGenerationDate: props.reportGenerationDate,
            reportId: props.reportId,
            groupId: props.groupId,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        axios
            .post(`api/SupportRequests`, requestObj)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setIsNewRequestInitiated(true));
                    dispatch(setSelectedThreadId(response.data));
                    dispatch(setIsOpened(true));
                } else {
                    createNotification(
                        'An unexpected error occured while sending support request.',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occured while sending support request.',
                    'error'
                );
            })
            .finally(() => {
                setIsPopupSubmitButtonLoading(false);
            });
    };

    const getButtonViewType = () => (
        <button style={{ fontFamily: fontFamily }} className="btn btn-primary">
            <FontAwesomeIcon icon={contextsWithEmailIcon.some(c => c == props.supportContext) ? faEnvelope as any : faUser as any} />
            {buttonText}
        </button>
    );

    const getReferenceViewType = () => (
        <>
            <div className="reference-icon">
                <FontAwesomeIcon icon={faUser as any} />
            </div>
            <div style={{ fontFamily: fontFamily }} className="reference-text">
                {buttonText}
            </div>
        </>
    );

    const getDirectButtonViewType = () => (
        <FontAwesomeIcon className="direct-icon" icon={faEnvelope as any} />
    );

    const renderButton = () => {
        switch (props.buttonType) {
            case 'button':
                return getButtonViewType();
            case 'reference':
                return getReferenceViewType();
            case 'directButton':
                return getDirectButtonViewType();
            default:
                return getButtonViewType();
        }
    };
    return (
        <>
            <div
                className="contact-support-button"
                id={props.tooltipTargetId}
                style={{ fontSize: fontSize }}
                onClick={handleButtonClick}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}>
                {renderButton()}
            </div>
            {props.tooltipText ? (
                <Tooltip
                    className="custom-tooltip"
                    isOpen={isTooltipOpen}
                    placement="auto"
                    target={props.tooltipTargetId}>
                    {props.tooltipText}
                </Tooltip>
            ) : (
                []
            )}
        </>
    );
};
export default ContactSupportButton;
