import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import { ProjectVariable } from '../../../../models/DataRequestHub/ProjectVariable';

export interface DropDownAnswerFieldProps {
    question: DataFormQuestion;
    isAdminView: boolean;
    isReadonly: boolean;
    updateBooleanAnswer(questionId: number, value: string): void;
    variable: ProjectVariable;
    updateNotification(questionId: number): void;
}

export const DropDownAnswerField = (props: DropDownAnswerFieldProps) => {
    const [answer, setAnswer] = useState<string>('');

    useEffect(() => {
        const data = props.question.answer?.answerText;
        setAnswer(data);
    }, [props]);

    const isAnswerChanged = (currentAnswer: string) => {
        return props.question.answer?.answerText != currentAnswer;
    };

    const renderAdminField = () => {
        return (
            <div className="select-wrapper-readonly">
                <Input
                    className={`text-size-field select-input`}
                    type="select"
                    value={answer}
                    defaultValue={''}
                    readOnly={true}>
                    {!answer && <option value=""></option>}
                    {props.variable?.options.map((x) => {
                        return <option value={x.option}>{x.option}</option>;
                    })}
                </Input>
            </div>
        );
    };

    const renderUserField = () => {
        return (
            <div
                className={
                    props.isReadonly
                        ? 'select-wrapper-readonly'
                        : 'select-wrapper'
                }>
                <Input
                    className={`text-size-field select-input`}
                    type="select"
                    defaultValue={''}
                    readOnly={props.isReadonly}
                    value={answer}
                    onBlur={() => {
                        props.updateNotification(props.question.id);
                    }}
                    onChange={(val) => {
                        props.updateNotification(props.question.id);
                        if (isAnswerChanged(val.target.value)) {
                            setAnswer(val.target.value);
                            props.updateBooleanAnswer(
                                props.question.id,
                                val.target.value
                            );
                        }
                    }}>
                    {!answer && <option value=""></option>}
                    {props.variable?.options.map((x) => {
                        return <option value={x.option}>{x.option}</option>;
                    })}
                </Input>
            </div>
        );
    };

    return props.isAdminView ? renderAdminField() : renderUserField();
};
