import { createAsyncThunk } from '@reduxjs/toolkit';

export const RefreshNotificationFlags = createAsyncThunk(
    'notificationFlags/refresh',
    async (_, dispatch: any) => {
        const token = localStorage.getItem('token');
        if (token) {
            const axios = dispatch.getState().core.axios;
            const response = await axios.get(`api/notificationflags`);
            return response;
        }
        return { data: [] };
    }
);
