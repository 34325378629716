import React, { useState, Fragment } from 'react';
import { Row, Col, FormGroup, Label, InputGroup } from 'reactstrap';
import createNotification from '../../utils/createNotification';
import { COMMON_REGEX } from '../../utils/constants';
import InputMask from 'react-input-mask';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../utils/helper';
import { useStateSelector } from '../../store/selectors';

const ChangeContactDetails = (props) => {
    const { summaryData } = props;
    const [phoneNumber, setPhoneNumber] = useState(
        Helpers.phoneNumberWithoutExtension(summaryData.phoneNumber)
    );
    const [email, setEmail] = useState(summaryData.email);
    const axios = useStateSelector((state) => state.core.axios);
    const [showEmailVerifyCode, setShowEmailVerifyCode] = useState(false);
    const [showPhoneVerifyCode, setShowPhoneVerifyCode] = useState(false);
    const [emailVerifyCode, setEmailVerifyCode] = useState('');
    const [phoneVerifyCode, setPhoneVerifyCode] = useState('');

    const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
    const invalidStyle = {
        border: '1px solid red',
        borderRadius: '5px',
    };

    const validStyle = {
        border: '',
    };

    const phoneNumberOnBlur = (event) => {
        setPhoneNumberInvalid(
            event.target.value &&
                event.target.value.replace(/[-() ]/g, '').length === 10
                ? false
                : true
        );
    };

    const updateDetails = (updateField) => {
        if (!phoneNumber || !email || phoneNumberInvalid) {
            return;
        }

        const requestObj = {
            phoneNumber:
                updateField === 1
                    ? Helpers.trimPhoneNumberFromMask(phoneNumber)
                    : '',
            email: updateField === 2 ? email : '',
        };

        axios
            .put('/api/Users', requestObj)
            .then((response) => {
                if (response.data) {
                    if (!response.data.isError) {
                        createNotification(
                            'Contact details update request initiated. Please refer your email or phone for the verification code.',
                            'success'
                        );
                        updateField === 1
                            ? setShowPhoneVerifyCode(true)
                            : setShowEmailVerifyCode(true);
                    } else {
                        createNotification(response.data.message, 'error');
                    }
                } else {
                    const message = response.response.data.message;
                    createNotification(message, 'error');
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    };

    const verifyUserDetails = (updateField) => {
        if (updateField === 1) {
            if (!phoneVerifyCode || !phoneNumber) {
                return;
            }
        }

        if (updateField === 2) {
            if (!emailVerifyCode || !email) {
                return;
            }
        }
        const requestObj = {
            phone:
                updateField === 1
                    ? Helpers.trimPhoneNumberFromMask(phoneNumber)
                    : '',
            email: updateField === 2 ? email : '',
            verificationCode:
                updateField == 1 ? phoneVerifyCode : emailVerifyCode,
        };
        axios
            .post('/api/Users/UpdateUserVerify', requestObj)
            .then((response) => {
                if (response.data) {
                    if (!response.data.isError) {
                        createNotification(
                            'Contact details updated successfully.',
                            'success'
                        );
                        props.setshowChangeContactDetails(false);
                        props.getSummaryData();
                        updateField === 1
                            ? setShowPhoneVerifyCode(false)
                            : setShowEmailVerifyCode(false);
                    } else {
                        createNotification(response.data.message, 'error');
                    }
                } else {
                    const message = response.response.data.message;
                    createNotification(message, 'error');
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    };

    return (
        <div class="main-card mb-3">
            <Form
                onSubmit={() => {}}
                initialValues={{}}
                validationSchema={yup.object().shape({})}>
                <div class="card-header">Change Contact Details</div>
                <div class="card-body">
                    <Fragment>
                        <div className="form-wizard-content">
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="txtphoneNumber">
                                            Phone Number
                                        </Label>
                                        <InputGroup
                                            className="mb-3"
                                            style={
                                                phoneNumberInvalid
                                                    ? invalidStyle
                                                    : validStyle
                                            }>
                                            <div className="input-group-text prepend-addon">
                                                <FontAwesomeIcon
                                                    icon={faPhone}
                                                />
                                            </div>
                                            <InputMask
                                                required
                                                className="form-control"
                                                placeholder="(999) 999-9999"
                                                mask="(999) 999-9999"
                                                maskChar={null}
                                                name="phoneNumber"
                                                id="txtphoneNumber"
                                                value={phoneNumber}
                                                onChange={(event) => {
                                                    setPhoneNumberInvalid(
                                                        event.target.value &&
                                                            event.target.value.replace(
                                                                /[-() ]/g,
                                                                ''
                                                            ).length === 10
                                                            ? false
                                                            : true
                                                    );
                                                    setPhoneNumber(
                                                        event.target.value
                                                    );
                                                }}
                                                onBlur={(event) =>
                                                    phoneNumberOnBlur(event)
                                                }
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    {showPhoneVerifyCode ? (
                                        <FormGroup>
                                            <Label for="txtVerifyPhone">
                                                Verify New Phone
                                            </Label>
                                            <Field
                                                type="text"
                                                placeholder="Enter verification code received on text"
                                                name="phoneVerify"
                                                id="txtVerifyPhone"
                                                onChange={(event) =>
                                                    setPhoneVerifyCode(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    ) : (
                                        ''
                                    )}
                                    {showPhoneVerifyCode ? (
                                        <button
                                            class="btn btn-success btn-lg"
                                            onClick={() =>
                                                verifyUserDetails(1)
                                            }>
                                            Verify
                                        </button>
                                    ) : (
                                        <button
                                            class="btn btn-success btn-lg"
                                            onClick={() => updateDetails(1)}>
                                            Update
                                        </button>
                                    )}
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="txtEmail">Email</Label>
                                        <Field
                                            type="text"
                                            value={email}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        'Email is required.',
                                                },
                                                pattern: {
                                                    value: COMMON_REGEX.EMAIL,
                                                    errorMessage:
                                                        'Please enter a valid Email Id',
                                                },
                                            }}
                                            placeholder="Enter email"
                                            name="emailVerify"
                                            id="txtEmail"
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                        />
                                    </FormGroup>

                                    {showEmailVerifyCode ? (
                                        <FormGroup>
                                            <Label for="txtVerifyEmail">
                                                Verify New Email
                                            </Label>
                                            <Field
                                                type="text"
                                                placeholder="Enter verification code received on email"
                                                name="email"
                                                id="txtVerifyEmail"
                                                onChange={(event) =>
                                                    setEmailVerifyCode(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    ) : (
                                        ''
                                    )}
                                    {showEmailVerifyCode ? (
                                        <button
                                            class="btn btn-success btn-lg"
                                            onClick={() =>
                                                verifyUserDetails(2)
                                            }>
                                            Verify
                                        </button>
                                    ) : (
                                        <button
                                            class="btn btn-success btn-lg"
                                            onClick={() => updateDetails(2)}>
                                            Update
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Fragment>
                </div>
                <div class="d-block text-right card-footer">
                    <button
                        class="mr-2 btn btn-primary btn-lg"
                        onClick={() =>
                            props.setshowChangeContactDetails(false)
                        }>
                        Cancel
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default ChangeContactDetails;
