import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ModalHeader, ModalBody } from 'reactstrap';
import BootstrapTable from '../react-bootstrap-table-next/react-bootstrap-table2';
import Rodal from '../Rodal/Rodal';
import { W9Document } from '../../models/W9Document/W9Document';
import {
    W9DocumentStatus,
    w9DocuemntStatusNamesList,
} from '../../models/W9Document/W9DocumentStatus';
import createNotification from '../../utils/createNotification';
import './W9HistoryModal.scss';
import { useStateSelector } from '../../store/selectors';

const customStyles = {
    marginTop: '50px',
};

interface W9HistoryModalProps {
    isVisisble: boolean;
    onClose(): void;
    userId?: string;
    name: string;
    isTableMode: boolean;
    payeeId?: string;
}

const W9HistoryModal = (props: W9HistoryModalProps) => {
    const axios = useStateSelector((state) => state.core.axios);

    useEffect(() => {
        fetchHistory();
    }, []);

    const fetchHistory = () => {
        const request = props.payeeId
            ? axios.get(
                  `/api/Documents/w9-documents/payee/${props.payeeId}/history`
              )
            : axios.get(`/api/Documents/w9-documents/${props.userId}/history`);

        request
            .then((response: any) => {
                if (response?.response?.data.isError) {
                    createNotification(
                        'An error occured while saving the details',
                        'error'
                    );
                    props.onClose();
                } else {
                    setDocList(response.data.data);
                }
            })
            .catch((error: any) => {
                createNotification(error, 'error');
            });
    };

    const [docList, setDocList] = useState<W9Document[]>([]);
    const getDocListColumns = () => [
        {
            dataField: 'dateCreated',
            text: 'Upload Date',
            formatter: (cellContent: Date) =>
                cellContent
                    ? new Date(cellContent).toLocaleDateString('en-US')
                    : '',
        },
        {
            dataField: 'taxYear',
            text: 'Tax year',
            formatter: (cellContent: string) =>
                cellContent === '0' ? '-' : cellContent,
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cellContent: W9DocumentStatus) =>
                w9DocuemntStatusNamesList.find((f) => f.id === cellContent)
                    ?.name ?? '-',
        },
        {
            dataField: 'approver',
            text: 'Approver',
            formatter: (cellContent: string) =>
                cellContent ? cellContent : '-',
        },
        {
            dataField: 'fileName',
            text: 'PDF Copy',
            formatter: (_cellContent: any, row: W9Document) => {
                return (
                    <>
                        <Button
                            className="btn btn-secondary"
                            color="secondary"
                            onClick={() => getDocument(row.id)}>
                            PDF
                        </Button>
                    </>
                );
            },
        },
    ];

    const getDocument = (documentId: number) => {
        let postObj = {
            DocTypeId: 4,
            Id: documentId,
        };
        axios
            .post('/api/Documents/GetDocumentUrl', postObj)
            .then((response: any) => {
                if (response.isError) {
                    createNotification(
                        'An error occured while fetching file source',
                        'error'
                    );
                } else {
                    let link = response.data.data;
                    window.open(link, '_blank');
                }
            })
            .catch((error: any) => {
                createNotification(error, 'error');
            });
    };

    const w9HistoryTable = (
        <div className="font-14p">
            {docList ? (
                <BootstrapTable
                    striped
                    bordered
                    bootstrap4
                    remote
                    keyField="id"
                    data={docList}
                    columns={getDocListColumns()}
                    noDataIndication="No Data available"
                />
            ) : (
                ''
            )}
        </div>
    );

    const popupFormat = (
        <Rodal
            width={700}
            height={500}
            visible={props.isVisisble}
            animation={'slideRight'}
            showCloseButton={true}
            customStyles={customStyles}
            className={''}
            onClose={props.onClose}
            showMask={false}>
            <ModalHeader>{props.name} W-9 history</ModalHeader>
            <ModalBody>
                {w9HistoryTable}
                <div className="action-buttons-wrapper">
                    <Button onClick={props.onClose} color="primary">
                        Ok
                    </Button>
                </div>
            </ModalBody>
        </Rodal>
    );

    if (props.isTableMode) {
        return <div className="w9-history-modal">{w9HistoryTable}</div>;
    } else {
        return <div className="w9-history-modal">{popupFormat}</div>;
    }
};
export default W9HistoryModal;
