import { createAsyncThunk } from '@reduxjs/toolkit';

export const GetPocId = createAsyncThunk(
    'clubhouse/getPocId',
    async (_args, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/clubhouses/poc`);

        return response;
    }
);
