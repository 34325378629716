import React, { useEffect, useState } from 'react';
import './ProjectVariableHost.scss';
import { useStateSelector } from '../../../../../store/selectors';
import DataLoader from '../../../../../components/DataLoader/DataLoader';
import { Button } from 'reactstrap';
import BootstrapTable, {
    TableChangeState,
    TableChangeType,
} from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import createNotification from '../../../../../utils/createNotification';
import { objectToQueryString } from '../../../../../utils/queryHelper';
import { TableFilter } from '../../../../../components/Table/models/table-filter';
import {
    ProjectVariable,
    ProjectVariableOption,
    SystemVariableNamesList,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import { AddProjectVariablePopup } from '../../Popups/AddProjectVariablePopup/AddProjectVariablePopup';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { EditProjectVariablePopup } from '../../Popups/EditProjectVariablePopup/EditProjectVariablePopup';

export interface ProjectVariableHostProps {
    projectId: number;
    onUpdate(): void;
}

export const ProjectVariableHost = (props: ProjectVariableHostProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [tableData, setTableData] = useState<ProjectVariable[]>([]);
    const defaultSortDirection: { dataField: string; order: 'asc' | 'desc' } = {
        dataField: 'dateCreated',
        order: 'desc',
    };
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [selectedVariableId, setSelectedVariableId] = useState(0);
    const tableTooltipTargetId = 'data-and-document-requests-dashboard';
    const [sortingState, setSortingState] = useState<any>({
        data: [],
        filters: {},
        page: page,
        searchText: '',
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });
    const [variableNameToEdit, setVariableNameToEdit] = useState('');
    const [varialbeIdToEdit, setVarialbeIdToEdit] = useState(0);
    const [projectVariableOptionsToEdit, setProjectVariableOptionsToEdit] =
        useState<Array<ProjectVariableOption>>([]);
    const [
        isProjectVariableEditPopupVisible,
        setIsProjectVariableEditPopupVisible,
    ] = useState(false);
    const [isAddNewVariablePopupVisible, setIsAddNewVariablePopupVisible] =
        useState(false);

    useEffect(() => {
        if (sortingState) {
            fetchProjectVariable(sortingState);
        }
    }, [sortingState]);

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'name',
            text: 'Variable Name',
            sort: true,
        },
        {
            dataField: 'dateCreated',
            text: 'Added On',
            sort: true,
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
        },

        {
            dataField: 'id',
            text: 'Action',
            formatter: (cellContent: number, row: ProjectVariable) => (
                <div className="action-icons">
                    <FontAwesomeIcon
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsProjectVariableEditPopupVisible(true);
                            setVarialbeIdToEdit(row.id);
                            setVariableNameToEdit(row.name);
                            setProjectVariableOptionsToEdit(row.options);
                        }}
                        icon={faEdit}
                    />
                    {!SystemVariableNamesList.find(
                        (x) => x.name === row.name
                    ) && (
                        <FontAwesomeIcon
                            className="delete-button"
                            onClick={() => {
                                setSelectedVariableId(cellContent);
                                setIsConfirmationVisible(true);
                            }}
                            icon={faTrashCan}
                        />
                    )}
                </div>
            ),
        },
    ];

    const removeVariable = (variableId: number) => {
        axios
            .delete(
                `/api/DataRequestProject/${props.projectId}/ProjectVariables/${variableId}`
            )
            .then((response) => {
                if (response.status === 200) {
                    fetchProjectVariable(sortingState);
                } else {
                    createNotification(
                        'An error occured while removing variable',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An error occured while removing variable',
                    'error'
                );
            });
    };

    const fetchProjectVariable = (sortingState: any) => {
        let tableFilter = new TableFilter(
            {
                filters: [],
                logic: '',
            },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder ?? defaultSortDirection.order,
                field: sortingState.sortField ?? defaultSortDirection.dataField,
            }
        );
        axios
            .get(
                `/api/DataRequestProject/${
                    props.projectId
                }/ProjectVariables/Paginated?${objectToQueryString(
                    tableFilter
                )}`
            )
            .then((response) => {
                if (response.status === 200) {
                    props.onUpdate();
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleTableChange = (
        _type: TableChangeType,
        newState: TableChangeState<any>
    ) => {
        setSortingState(newState);
    };

    return (
        <div className="project-variable-host">
            {isLoading ? (
                <DataLoader />
            ) : (
                <>
                    <div className="mb-3">
                        <strong>Current Variables</strong>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3"
                            onClick={() => {
                                setIsAddNewVariablePopupVisible(true);
                            }}>
                            Add New Variable
                        </Button>
                    </div>
                    <BootstrapTable
                        striped
                        bordered
                        bootstrap4
                        remote
                        keyField="id"
                        data={tableData}
                        columns={getColumns()}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            tooltipTargetId: tableTooltipTargetId,
                        })}
                        defaultSorted={[defaultSortDirection]}
                        onTableChange={handleTableChange}
                        noDataIndication="No Data available"
                    />
                </>
            )}

            {isAddNewVariablePopupVisible && (
                <AddProjectVariablePopup
                    projectId={props.projectId}
                    close={() => {
                        setIsAddNewVariablePopupVisible(false);
                    }}
                    onSubmit={() => {
                        setIsAddNewVariablePopupVisible(false);
                        fetchProjectVariable(sortingState);
                    }}></AddProjectVariablePopup>
            )}

            {isConfirmationVisible ? (
                <ConfirmationModal
                    isVisible={isConfirmationVisible}
                    setIsVisible={setIsConfirmationVisible}
                    header="Confirmation"
                    confirmationText={`Are you sure you want to remove this variable?`}
                    onConfirm={removeVariable}
                    params={selectedVariableId}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"></ConfirmationModal>
            ) : (
                []
            )}

            {isProjectVariableEditPopupVisible && (
                <EditProjectVariablePopup
                    projectId={props.projectId}
                    options={projectVariableOptionsToEdit}
                    variableId={varialbeIdToEdit}
                    onSubmit={() => {
                        fetchProjectVariable(sortingState);
                        setIsProjectVariableEditPopupVisible(false);
                    }}
                    variableName={variableNameToEdit}
                    close={() =>
                        setIsProjectVariableEditPopupVisible(false)
                    }></EditProjectVariablePopup>
            )}
        </div>
    );
};
