import React, { useEffect, useState } from 'react';
import './SnapshotsPopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import { Input, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useStateSelector } from '../../../store/selectors';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import createNotification from '../../../utils/createNotification';
import DataLoader from '../../../components/DataLoader/DataLoader';
import BootstrapTable, {
    SortOrder,
} from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import moment from 'moment';
import { DataTable } from '../../../models/DataRequestHub/DataTable';
import SortingOption from '../../../components/Table/models/bootstrap-table-sorting-option';
import { sortingFunction } from '../../../components/Table/models/table-helper';

interface SnapshotsPopupProps {
    projectId: number;
    tableId: number;
    viewId: number;
    header: string;
    onClose(): void;
    triggerNext?: () => void;
    triggerBack?: () => void;
    setSelectedTableViewId: (id: number) => void;
    setSelectedTable: (table: DataTable) => void;
    isArchiveMode: boolean;
    snapshotFileName: string;
    isAdminView: boolean;
}

export const SnapshotsPopup = (props: SnapshotsPopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [snapshotName, setSnapshotName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [sortingOption, setSortingOption] = useState<SortingOption>({
        dataField: 'dateCreated',
        order: 'desc',
    });
    const addNewSnapshot = () => {
        setIsLoading(true);

        const params = {
            snapshotComment: snapshotName.trim(),
        };
        axios
            .post(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.tableId}/dataTableSnapshots`,
                params
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while creating snapshot',
                        'error'
                    );
                } else {
                    setSnapshotName('');
                    getSnapshotsData();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getSnapshotsData = () => {
        setIsLoading(true);

        axios
            .get(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.tableId}/dataTableSnapshots`
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while fetching snapshots',
                        'error'
                    );
                } else {
                    sortFunction(response.data, sortingOption);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getSnapshotFile = (
        tableId: number,
        viewId: number,
        snapshotDate: Date
    ) => {
        setIsLoading(true);
        const requestParamsObj = {
            params: { snapshotTableId: tableId, viewId: viewId },
            responseType: 'blob' as const,
        };
        axios
            .get(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.tableId}/dataTableSnapshots/file`,
                requestParamsObj
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while downloading snapshot',
                        'error'
                    );
                } else {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    const snapshoDateStringFormat =
                        moment(snapshotDate).format('MM-DD-YYYY');
                    const fileName = `${props.snapshotFileName} ${snapshoDateStringFormat}.xlsx`;
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getTableColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'dateCreated',
            text: 'Created Date',
            sort: true,
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
        },
        {
            dataField: 'snapshotComment',
            text: 'Comment',
            sort: true,
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: (_: number, row: DataTable) => (
                <div className="buttons-wrapper">
                    <div
                        className="link-text"
                        onClick={() => {
                            if (props.viewId) {
                                const view = row.views.find(
                                    (x) =>
                                        x.originalTableViewId === props.viewId
                                );
                                props.setSelectedTableViewId(view.id);
                            }
                            props.setSelectedTable(row);
                            handleNextStep();
                        }}>
                        {' '}
                        View
                    </div>
                    <div
                        className="link-text"
                        onClick={() => {
                            let viewId = 0;
                            if (props.viewId) {
                                const view = row.views.find(
                                    (x) =>
                                        x.originalTableViewId === props.viewId
                                );
                                viewId = view.id;
                            }
                            getSnapshotFile(row.id, viewId, row.dateCreated);
                        }}>
                        {'Download'}
                    </div>
                </div>
            ),
        },
    ];

    const handleNextStep = () => {
        if (props.triggerNext) {
            props.triggerNext();
        }
    };

    useEffect(() => {
        getSnapshotsData();
        props.setSelectedTable(null);
        props.setSelectedTableViewId(null);
    }, []);

    const handleTableChange = (_props: any, state: any) => {
        const newSortingOption: SortingOption = {
            dataField: state.sortField,
            order: state.sortOrder as SortOrder,
        };
        setSortingOption(newSortingOption);
        sortFunction(state.data, newSortingOption);
    };

    const sortFunction = (data: any[], sortingOption: SortingOption) => {
        const sortedQuestions = sortingFunction(data, sortingOption);
        setTableData(sortedQuestions);
    };

    return (
        <Rodal
            visible={true}
            onClose={props.onClose}
            className="snapshot-popup"
            width={500}
            animation="fade"
            closeMaskOnClick={false}
            centered>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalBody>
                {props.isArchiveMode || !props.isAdminView ? (
                    <></>
                ) : (
                    <Row>
                        <div className="comment-input">
                            <Input
                                placeholder="Comment..."
                                value={snapshotName}
                                onChange={(event) =>
                                    setSnapshotName(event.target.value)
                                }
                            />
                            <ButtonLoader
                                onClick={() => {
                                    addNewSnapshot();
                                }}
                                className={'btn btn-primary'}
                                buttonText={'Add New Snapshot'}
                                loaderButtonText={''}
                                isLoading={isLoading}
                                disabled={!snapshotName}></ButtonLoader>
                        </div>
                    </Row>
                )}
                <Row>
                    {isLoading ? (
                        <DataLoader />
                    ) : (
                        <BootstrapTable
                            striped
                            bordered
                            bootstrap4
                            remote
                            keyField="id"
                            defaultSorted={[sortingOption]}
                            onTableChange={handleTableChange}
                            data={tableData}
                            columns={getTableColumns()}
                            noDataIndication="No Data available"
                        />
                    )}
                </Row>
            </ModalBody>
        </Rodal>
    );
};
