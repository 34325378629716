import {
    faCheckCircle,
    faTimes,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    GridColDef,
    GridActionsCellItem,
    GridRowId,
    GridValidRowModel,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';

interface DataTableFormActionColumnProps {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    getRowId(row: GridValidRowModel): any;
    projectId: number;
    dataTableId: number;
    updateProjectsData(): void;
    isAdmin: boolean;
}

const DataTableFormActionColumn = (
    props: DataTableFormActionColumnProps
): GridColDef => {
    const axios = useStateSelector((s) => s.core.axios);

    const handleDeleteClick = (gridId: GridRowId) => () => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());

        let removedRowCustomId = latestStateArray.find((question) => {
            const id = props.getRowId(question);
            return id === gridId;
        }).id;
        props.isAdmin
            ? removeRow(removedRowCustomId)
            : updateRemovalRequest(removedRowCustomId, true);
    };

    const handleRejectClick = (gridId: GridRowId) => () => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());

        let removedRowCustomId = latestStateArray.find((question) => {
            const id = props.getRowId(question);
            return id === gridId;
        }).id;
        updateRemovalRequest(removedRowCustomId, false);
    };

    const updateRemovalRequest = (
        dataTableRowId: number,
        isRemovalRequested: boolean
    ) => {
        axios
            .put(
                `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTableId}/dataTableRows/RemovalRequested`,
                {
                    isRemovalRequested: isRemovalRequested,
                    dataTableRowId: dataTableRowId,
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occured while deliting row',
                        'error'
                    );
                }
            });
    };

    const removeRow = (dataTableRowId: number) => {
        axios
            .delete(
                `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTableId}/dataTableRows/${dataTableRowId}`
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occured while deliting row',
                        'error'
                    );
                }
            });
    };

    const isRemovalRequested = (gridId: GridRowId) => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());
        let isRemovalRequested = latestStateArray.find((question) => {
            const id = props.getRowId(question);
            return id === gridId;
        })?.isRemovalRequested;

        return isRemovalRequested;
    };

    const column: GridColDef = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        minWidth: 90,
        maxWidth: 100,
        headerAlign: 'left',
        getActions: ({ id }) =>
            isRemovalRequested(id) && props.isAdmin
                ? [
                      <GridActionsCellItem
                          icon={
                              <FontAwesomeIcon
                                  size="1x"
                                  icon={faCheckCircle as any}
                                  title="Approve"
                              />
                          }
                          label="Approve"
                          onClick={handleDeleteClick(id)}
                          color="success"
                      />,
                      <GridActionsCellItem
                          icon={
                              <FontAwesomeIcon
                                  size="1x"
                                  icon={faTimes as any}
                                  title="Reject"
                              />
                          }
                          label="Reject"
                          onClick={handleRejectClick(id)}
                          color="error"
                      />,
                  ]
                : [
                      <GridActionsCellItem
                          icon={
                              <FontAwesomeIcon
                                  size="1x"
                                  icon={faTrashAlt as any}
                                  title="Delete"
                              />
                          }
                          label="Delete"
                          onClick={handleDeleteClick(id)}
                          color="error"
                      />,
                  ],
    };

    return column;
};

export default DataTableFormActionColumn;
