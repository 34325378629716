import { createAsyncThunk } from '@reduxjs/toolkit';
import { PostDiscussion } from './clubhouse-slice-helper';

export const createDiscussion = createAsyncThunk(
    'clubhouse/createDiscussion',
    async (discussion: PostDiscussion, dispatch: any) => {
        const axios = dispatch.getState().core.axios;

        return await axios.post(
            `api/clubhouses/${discussion.clubhouseId}/discussions`,
            {
                name: discussion.name,
                description: discussion.description,
            }
        );
    }
);
