import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';



/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

export const initGTM = (trackingID) => {
    const tagManagerArgs = { gtmId: 'GTM-MQH64LB' };
    TagManager.initialize(tagManagerArgs);
};

export const gtmEvent = (category) => {
    window.dataLayer.push({ event: category });
};
