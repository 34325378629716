import { createAsyncThunk } from '@reduxjs/toolkit';

export const GetVeterinarySchools = createAsyncThunk(
    'clubhouse/getVetSchools',
    async (_args, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/veterinarySchools`);

        return response;
    }
);
