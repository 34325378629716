import React from 'react';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    Label,
    FormGroup,
} from 'reactstrap';
import Rodal from '../Rodal/Rodal';
import ButtonLoader from '../Layout/Buttons/ButtonLoader';
import './ConfirmationModal.scss';

const ConfirmationModal = ({
    isVisible,
    setIsVisible,
    header,
    confirmationText,
    onConfirm,
    params,
    nextButtonText,
    cancelButtonText,
    isLoading = false,
    closeOnConfirm = true,
    isConfirmationDanger = false,
}) => {
    const animation = 'slideTop';

    const onConfirmClick = () => {
        onConfirm(params);
        if (closeOnConfirm) {
            setIsVisible(false);
        }
    };

    return (
        isVisible && (
            <Rodal
                visible={true}
                animation={animation}
                width={498}
                showCloseButton={false}
                onClose={() => {
                    setIsVisible(false);
                }}
                className="confirmation-modal">
                <ModalHeader>{header ? header : 'Confirmation'}</ModalHeader>
                <FormGroup>
                    <ModalBody>
                        <Row>
                            <Col sm={12}>
                                <Label className="label-textarea">
                                    {confirmationText}
                                </Label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            className={
                                isConfirmationDanger
                                    ? 'btn btn-primary'
                                    : 'btn-cancel'
                            }
                            onClick={() => {
                                setIsVisible(false);
                            }}>
                            {cancelButtonText ? cancelButtonText : 'Cancel'}
                        </Button>
                        <ButtonLoader
                            className={
                                isConfirmationDanger
                                    ? 'btn btn-danger'
                                    : 'btn btn-primary'
                            }
                            buttonText={
                                nextButtonText ? nextButtonText : 'Confirm'
                            }
                            isLoading={isLoading}
                            onClick={() => {
                                onConfirmClick();
                            }}></ButtonLoader>
                    </ModalFooter>
                </FormGroup>
            </Rodal>
        )
    );
};
export default ConfirmationModal;
