import React, { useEffect, useState } from 'react';
import {
    faThumbsUp,
    faThumbsDown,
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import './RequestedContentTable.scss';
import { REQUESTED_CONTENT_STATUS } from '../../../../utils/knowledgeBaseConstants';
import createNotification from '../../../../utils/createNotification';
import { USER_ROLES } from '../../../../utils/constants';
import editAction from '../../../../assets/knowledgecontent/editAction.png';
import KnowledgeBaseContentViewModal from '../KnowledgeBaseContentViewModal';
import SuggestionModal from './SuggestionModal';
import KnowledgeBaseRequestContentPreviewModal from '../KnowledgeBaseRequestContentPreviewModal';
import InfoTooltip from '../../../../components/info-tooltip/info-tooltip';
import { useStateSelector } from '../../../../store/selectors';

export const RequestedContentTable = (props) => {
    const getLikeIconClasseName = (isLiked) =>
        isLiked ? 'like-icon active' : 'like-icon inactive';
    const isAdmin =
        JSON.parse(localStorage.getItem('loggedInUserSummary')).roleId ===
        USER_ROLES.ADMIN;
    const axios = useStateSelector((state) => state.core.axios);
    const [tableContent, setTableContent] = useState([]);
    const [isContentModalVisible, setIsContentModalVisible] = useState(false);
    const [isSuggestionModalVisible, setIsSuggestionModalVisible] =
        useState(false);
    const [suggestionContent, setSuggestionContent] = useState(null);
    const [isAdminPreviewVisible, setIsAdminPreviewVisible] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);

    useEffect(() => {
        getRequestedContent();
    }, [props.contentChangesFlag]);

    const openContent = (selectedContent) => {
        setSelectedContent(selectedContent);
        setIsContentModalVisible(true);
    };

    const openAdminPreview = (selectedContent) => {
        setSelectedContent(selectedContent);
        setIsAdminPreviewVisible(true);
    };

    const approveContent = (requestedContentId) => {
        axios
            .post(
                `/api/knowledgeBaseRequestedContent/${requestedContentId}/approve`
            )
            .then((response) => {
                if (
                    response &&
                    response.request &&
                    response.request.status === 200
                ) {
                    getRequestedContent();
                    props.onContentApproved();
                }
            });
    };

    const getRequestedContent = () => {
        axios.get('/api/knowledgeBaseRequestedContent').then((response) => {
            if (
                response &&
                response.request &&
                response.request.status === 200
            ) {
                setTableContent(response.data);
            } else {
                createNotification(
                    'Error occurred while fetching requested content list. Please try again.',
                    'error'
                );
            }
        });
    };

    const addLike = (contentId) => {
        axios
            .post('/api/RequestedContentLike', null, {
                params: {
                    contentId: contentId,
                },
            })
            .then((response) => {
                if (
                    response &&
                    response.request &&
                    response.request.status === 200
                ) {
                    getRequestedContent();
                }
            });
    };

    const deleteLike = (contentId) => {
        axios
            .delete('/api/RequestedContentLike', {
                params: {
                    contentId: contentId,
                },
            })
            .then((response) => {
                if (
                    response &&
                    response.request &&
                    response.request.status === 200
                ) {
                    getRequestedContent();
                }
            });
    };

    const columns = [
        {
            dataField: 'id',
            hidden: true,
        },
        {
            dataField: 'topic',
            text: 'Topic',
            formatter: (cellContent, row) =>
                row.status === REQUESTED_CONTENT_STATUS[2].id &&
                row.isUserCreator &&
                row.knowledgeBase ? (
                    <a
                        className="content-topic"
                        onClick={() => {
                            openContent(row.knowledgeBase);
                        }}>
                        {cellContent}
                    </a>
                ) : isAdmin ? (
                    <a
                        className="content-topic"
                        onClick={() => {
                            openAdminPreview(row);
                        }}>
                        {cellContent}
                    </a>
                ) : (
                    cellContent
                ),
        },
        {
            dataField: 'createdDate',
            text: 'Request Date',
            formatter: (cellContent) => moment(cellContent).format('l'),
        },
        {
            dataField: 'numberLikes',
            text: 'Likes',
            formatter: (cellContent, row) => (
                <div className="likes-column">
                    <label>{cellContent}</label>
                    {row.status === REQUESTED_CONTENT_STATUS[2].id &&
                    row.isUserCreator &&
                    row.knowledgeBase ? (
                        <div className="approve-actions">
                            <FontAwesomeIcon
                                className="approve-icon"
                                onClick={() => approveContent(row.id)}
                                icon={faThumbsUp}
                            />
                            <FontAwesomeIcon
                                className="reject-icon"
                                onClick={() => {
                                    setIsSuggestionModalVisible(true);
                                    setSuggestionContent(row.id);
                                }}
                                icon={faThumbsDown}
                            />
                        </div>
                    ) : (
                        <FontAwesomeIcon
                            className={getLikeIconClasseName(row.isLiked)}
                            onClick={() => {
                                if (!isAdmin) {
                                    row.isLiked
                                        ? deleteLike(row.id)
                                        : addLike(row.id);
                                }
                            }}
                            icon={faThumbsUp}
                        />
                    )}
                </div>
            ),
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cellContent, row) => {
                return isAdmin && row?.rejectedReason ? (
                    <div style={{ display: 'flex' }}>
                        {REQUESTED_CONTENT_STATUS[cellContent].name}{' '}
                        <InfoTooltip
                            text={row.rejectedReason}
                            idText={`kb-table-rejected-reason-${row.id}`}
                            place="top"
                        />
                    </div>
                ) : (
                    REQUESTED_CONTENT_STATUS[cellContent].name
                );
            },
        },
        {
            dataField: 'knowledgeBase',
            text: 'Action',
            formatter: (cellContent, row) =>
                cellContent ? (
                    <img
                        onClick={() =>
                            props.openViewEditPopup(cellContent, row)
                        }
                        src={editAction}
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                ) : (
                    <FontAwesomeIcon
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => props.openCreatePopup(row)}
                        size="lg"
                        icon={faPlusCircle}></FontAwesomeIcon>
                ),
            hidden: !isAdmin,
        },
    ];

    return (
        <>
            <div className="requested-content-table">
                <BootstrapTable
                    keyField="topic"
                    data={tableContent}
                    columns={columns}
                    noDataIndication="No Data available"></BootstrapTable>
            </div>
            {isSuggestionModalVisible ? (
                <SuggestionModal
                    data={suggestionContent}
                    isVisible={isSuggestionModalVisible}
                    getRequestedContent={getRequestedContent}
                    setIsVisible={
                        setIsSuggestionModalVisible
                    }></SuggestionModal>
            ) : (
                []
            )}
            {isAdminPreviewVisible ? (
                <KnowledgeBaseRequestContentPreviewModal
                    data={selectedContent}
                    isVisible={isAdminPreviewVisible}
                    setIsVisible={
                        setIsAdminPreviewVisible
                    }></KnowledgeBaseRequestContentPreviewModal>
            ) : (
                []
            )}
            {isContentModalVisible ? (
                <KnowledgeBaseContentViewModal
                    data={selectedContent}
                    isVisible={isContentModalVisible}
                    setIsVisible={
                        setIsContentModalVisible
                    }></KnowledgeBaseContentViewModal>
            ) : (
                []
            )}
        </>
    );
};
export default RequestedContentTable;
