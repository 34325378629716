import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCurrentMember = createAsyncThunk(
    'directMembers/fetchCurrentMember',
    async (_arg: undefined, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/direct`);
        return response;
    }
);
