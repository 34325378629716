export enum StandardizationStatusEnum {
    InProcess,
    Completed,
    Failed,
    UnableToComplete,
    Processing,
    FinalPaymentRequested,
}

export const StandardizationStatusList = [
    {
        id: 0,
        name: 'Deposit Received - In Process',
    },
    {
        id: 1,
        name: 'Final Payment Confirmed',
    },
    {
        id: 2,
        name: 'Payment Error',
    },
    {
        id: 3,
        name: 'Unable to Complete - Refund Granted',
    },
    {
        id: 4,
        name: 'Deposit Received - In Process',
    },
    {
        id: 5,
        name: 'Final Payment Requested',
    },
];
