import React, { useEffect, useState } from 'react';
import './SubscriptionsHost.scss';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ActiveSubscriptionsPaginatedTable from '../../components/ActiveSubscriptionsPaginatedTable/ActiveSubscriptionsPaginatedTable';
import SubscriptionsPaymentHistoryPaginatedTable from '../../components/PaymentHistoryPaginatedTable/SubscriptionsPaymentHistoryPaginatedTable';
import { useStateSelector } from '../../store/selectors';
import Helpers from '../../utils/helper';
import { PmgSubscriptionsTab } from './PmgSubscriptionsTab/PmgSubscriptionsTab';
import { UsersSubscriptionsTab } from './UsersSubscriptionsTab/UsersSubscriptionsTab';

interface SubscriptionsHostProps {
    preselectedMemberId?: number;
}

const SubscriptionsHost = (props: SubscriptionsHostProps) => {
    const [typeOptions, setTypeOptions] = useState([]);
    const [memberNameOptions, setMemberNameOptions] = useState([]);
    const [practiceNameOptions, setPracticeNameOptions] = useState([]);
    const tabs = [
        { id: 0, name: 'PMG Subscriptions' },
        { id: 1, name: 'User Subscriptions' },
    ];
    const [activeTab, setActiveTab] = useState(0);
    const axios = useStateSelector((state) => state.core.axios);

    useEffect(() => {
        getMemberNameOptions();
        getPracticeNameOptions();
        getTypeOptions();
    }, []);

    const getPracticeNameOptions = () => {
        if (props.preselectedMemberId) {
            axios
                .get('/api/Practice/GetUserPractices', {
                    params: { userId: props.preselectedMemberId },
                })
                .then((response: any) => {
                    const list = response.data.data.sort((a: any, b: any) =>
                        Helpers.stringSortFunction(a.name, b.name)
                    );
                    list.unshift({ id: 0, name: 'None' });
                    setPracticeNameOptions(list);
                });
        } else {
            axios.get('/api/Practice').then((response: any) => {
                const list = response.data.data.sort((a: any, b: any) =>
                    Helpers.stringSortFunction(a.name, b.name)
                );
                list.unshift({ id: 0, name: 'None' });
                setPracticeNameOptions(list);
            });
        }
    };

    const getTypeOptions = () => {
        axios.get('/api/Group/GetPMGGroups').then((response: any) => {
            const list = response.data.data;
            if (props.preselectedMemberId) {
                const groupList = list.filter(
                    (f: any) =>
                        f.practices?.some((p: any) => {
                            return p.applicantId == props.preselectedMemberId;
                        }) ?? false
                );
                groupList.unshift({ id: 0, name: 'None' });
                setTypeOptions(groupList);
            } else {
                const userGroupList = list.sort(function (a: any, b: any) {
                    if (a.id > b.id) {
                        return 1;
                    } else if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                });
                userGroupList.unshift({ id: 0, name: 'None' });
                setTypeOptions(userGroupList);
            }
        });
    };

    const getMemberNameOptions = () => {
        axios.get('/api/Users/GetUsersCount').then((count: any) => {
            axios
                .post('/api/Users/GetUsers', {
                    Filter: {
                        Filters: [
                            { Field: 'roleId', Operator: 'neq', Value: '2' },
                        ],
                        Logic: '',
                    },
                    PageNumber: 1,
                    PageSize: count.data,
                    Sort: {},
                })
                .then((response: any) => {
                    const list = response.data.list;
                    if (props.preselectedMemberId) {
                        setMemberNameOptions([
                            list.find(
                                (f: any) => f.id === props.preselectedMemberId
                            ),
                        ]);
                    } else {
                        const memberList = list.sort((a: any, b: any) =>
                            Helpers.stringSortFunction(a.name, b.name)
                        );
                        memberList.unshift({ id: 0, name: 'None' });
                        setMemberNameOptions(memberList);
                    }
                });
        });
    };

    const tabContent = () => {
        switch (activeTab) {
            case 0:
                return (
                    <PmgSubscriptionsTab
                        typeOptions={typeOptions}
                        memberNameOptions={memberNameOptions}
                        practiceNameOptions={practiceNameOptions}
                        preselectedMemberId={
                            props.preselectedMemberId
                        }></PmgSubscriptionsTab>
                );
            case 1:
                return (
                    <UsersSubscriptionsTab
                        memberNameOptions={memberNameOptions}
                        preselectedMemberId={
                            props.preselectedMemberId
                        }></UsersSubscriptionsTab>
                );
            default:
                return <></>;
        }
    };

    const changeTab = (tabId: number) => {
        setActiveTab(tabId);
    };

    return (
        <div className="subscriptions-host practice-page-tabs">
            <div className="body_inner_content">
                <div className="RRT__container">
                    <div className="RRT__tabs body-tabs practice-reports-tabs">
                        {tabs?.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className={
                                        activeTab == item.id
                                            ? 'RRT__tab RRT__tab--first RRT__tab--selected'
                                            : 'RRT__tab'
                                    }
                                    onClick={() => {
                                        changeTab(item.id);
                                    }}
                                    id={'tab-info-' + item.id}>
                                    {item.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {tabContent()}
            </div>
        </div>
    );
};

export default SubscriptionsHost;
