import { createBaseAxiosInstance } from '../axios-base';

import { SET_AXIOS } from '../actions/actions';
import { CoreState } from './core-state';
const initialState: CoreState = {
    axios: createBaseAxiosInstance(),
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_AXIOS:
            return {
                ...state,
                axios: action.payload,
            };
        default:
            return state;
    }
}
