import React, { useState } from 'react';
import { MODAL_SIZE } from '../../../components/Modal/Modal';
import createNotification from '../../../utils/createNotification';
import {
    Col,
    Label,
    FormGroup,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import moment from 'moment';
import { ANNOUNCEMENT_STATUSES } from '../../../utils/announcementsConstants';
import DeleteConfirmationModal from '../../../components/Modal/DeleteConfirmationModal';
import Helpers from '../../../utils/helper';
import { Field, Form, Input } from '@availity/form';
import * as yup from 'yup';
import Rodal from '../../../components/Rodal/Rodal';
import { useStateSelector } from '../../../store/selectors';

const AnnouncementModal = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [
        isDeleteConfirmationModalVisible,
        setIsDeleteConfirmationModalVisible,
    ] = useState(false);
    let modalForm = {};
    const axios = useStateSelector((state) => state.core.axios);
    const hideModal = () => {
        props.resetDialog();
        if (modalForm) {
            modalForm.resetForm();
        }
        props.refreshTable();
    };

    const initSubmit = () => {
        modalForm.handleSubmit();
    };

    const saveAnnouncement = () => {
        setIsSaving(true);
        axios
            .post('/api/Announcements', props.data)
            .then((response) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error occured while saving the Announcement.'
                    )
                ) {
                    createNotification(
                        'Announcement has been successfully saved!',
                        'success'
                    );
                    hideModal();
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const deleteAnnouncement = () => {
        setIsDeleting(true);
        axios
            .delete(`/api/Announcements/${props.data.id}`)
            .then((response) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error occured while deleting the Announcement.'
                    )
                ) {
                    createNotification(
                        'Announcement has been successfully deleted!',
                        'success'
                    );
                    hideModal();
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    return (
        <>
            {props.data && (
                <Rodal
                    visible={props.isVisible}
                    onClose={() => hideModal()}
                    animation={'fade'}
                    showMask={true}
                    width={MODAL_SIZE.MEDIUM}
                    className="modal-style announcement-modal">
                    <ModalHeader>
                        {props.dialogMode == 'create'
                            ? 'Create an Announcement'
                            : props.dialogMode == 'edit'
                            ? `Edit an Announcement`
                            : `Announcement Details`}
                    </ModalHeader>

                    <ModalBody>
                        <Form
                            innerRef={(c) => {
                                modalForm = c;
                            }}
                            onSubmit={() => {
                                saveAnnouncement();
                            }}
                            initialValues={{
                                title: props.data.title,
                                updatedOn: props.data.modifiedOn
                                    ? moment(props.data.modifiedOn).format(
                                          'MM/D/YYYY h:mma'
                                      )
                                    : '',
                                postedOn: props.data.postedOn
                                    ? moment(props.data.postedOn).format(
                                          'MM/D/YYYY h:mma'
                                      )
                                    : '',
                                status: props.data.isActive,
                                description: props.data.description,
                            }}
                            validationSchema={yup.object().shape({
                                title: yup.string().required(),
                                postedOn: yup
                                    .string()
                                    .required()
                                    .test(
                                        'checkDate',
                                        'Invalid Date',
                                        (value) => {
                                            const selectedDate = moment(
                                                value,
                                                'MM/D/YYYY h:mma',
                                                true
                                            ).toDate();
                                            return (
                                                selectedDate !=
                                                    'Invalid Date' &&
                                                selectedDate.getTime() <
                                                    Date.now()
                                            );
                                        }
                                    ),
                                description: yup.string().required(),
                            })}>
                            <div
                                className="associate_form announcement_form"
                                style={{ borderBottom: 0 }}>
                                <div className="row">
                                    <Col md={5}>
                                        <FormGroup>
                                            {props.dialogMode == 'view' && (
                                                <div>
                                                    <Label for="title">
                                                        Title
                                                    </Label>
                                                    <p className="disabled-paragraph">
                                                        {props.data.title}
                                                    </p>
                                                </div>
                                            )}
                                            {(props.dialogMode == 'edit' ||
                                                props.dialogMode ==
                                                    'create') && (
                                                <Field
                                                    type="text"
                                                    label="Title"
                                                    name="title"
                                                    onChange={(event) => {
                                                        props.data.title =
                                                            event.target.value;
                                                        props.setData({
                                                            ...props.data,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            {props.dialogMode == 'view' && (
                                                <div>
                                                    <Label for="updatedOn">
                                                        Last Update
                                                    </Label>
                                                    <p className="disabled-paragraph">
                                                        {moment(
                                                            props.data
                                                                .modifiedOn
                                                        ).format(
                                                            'MM/D/YYYY h:mma'
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            {(props.dialogMode == 'edit' ||
                                                props.dialogMode ==
                                                    'create') && (
                                                <Field
                                                    type="text"
                                                    label="Last Update"
                                                    name="updatedOn"
                                                    disabled={true}
                                                />
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            {props.dialogMode == 'view' && (
                                                <div>
                                                    <Label for="postedOn">
                                                        Last Post
                                                    </Label>
                                                    <p className="disabled-paragraph">
                                                        {props.data.postedOn
                                                            ? moment(
                                                                  props.data
                                                                      .postedOn
                                                              ).format(
                                                                  'MM/D/YYYY h:mma'
                                                              )
                                                            : ''}
                                                    </p>
                                                </div>
                                            )}
                                            {(props.dialogMode == 'edit' ||
                                                props.dialogMode ==
                                                    'create') && (
                                                <div>
                                                    <Field
                                                        name="postedOn"
                                                        label="Posted On"
                                                        type="text"
                                                        onChange={(event) => {
                                                            props.data.postedOn =
                                                                moment(
                                                                    event.target
                                                                        .value,
                                                                    'MM/D/YYYY h:mma',
                                                                    false
                                                                ).toDate();
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            {props.dialogMode == 'view' && (
                                                <div>
                                                    <Label for="status">
                                                        Status
                                                    </Label>
                                                    <p className="disabled-paragraph">
                                                        {props.data.status}
                                                    </p>
                                                </div>
                                            )}
                                            {(props.dialogMode == 'edit' ||
                                                props.dialogMode ==
                                                    'create') && (
                                                <>
                                                    <Label for="status">
                                                        Status
                                                    </Label>
                                                    <div className="customerStyle">
                                                        <Input
                                                            type="select"
                                                            name="status"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                props.data.isActive =
                                                                    event?.target?.value;
                                                            }}>
                                                            {[
                                                                ...ANNOUNCEMENT_STATUSES,
                                                            ].map((item) => (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                    key={
                                                                        'status ' +
                                                                        item.id
                                                                    }>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </div>
                                                </>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </div>

                                <div className="row">
                                    <Col>
                                        <FormGroup>
                                            {props.dialogMode == 'view' && (
                                                <div>
                                                    <Label for="description">
                                                        Body Text
                                                    </Label>
                                                    <p className="disabled-paragraph">
                                                        {props.data.description}
                                                    </p>
                                                </div>
                                            )}

                                            {(props.dialogMode == 'edit' ||
                                                props.dialogMode ==
                                                    'create') && (
                                                <Field
                                                    type="textarea"
                                                    label="Body Text"
                                                    name="description"
                                                    onChange={(event) => {
                                                        props.data.description =
                                                            event.target.value;
                                                        props.setData({
                                                            ...props.data,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </FormGroup>
                                    </Col>
                                </div>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="footer-wrapper">
                            <div></div>
                            <div className="remove-cancel-btn member-button-panel">
                                {props.dialogMode == 'view' && (
                                    <Button
                                        className="cancel"
                                        onClick={() => hideModal()}>
                                        Close
                                    </Button>
                                )}
                                <div className="edit-btn">
                                    {props.dialogMode == 'view' && (
                                        <Button
                                            className="edit"
                                            color="primary"
                                            onClick={() =>
                                                props.setDialogMode('edit')
                                            }>
                                            Edit
                                        </Button>
                                    )}
                                </div>

                                {props.dialogMode == 'edit' && (
                                    <Button
                                        color="primary"
                                        className="delete-announcement-btn"
                                        onClick={() =>
                                            setIsDeleteConfirmationModalVisible(
                                                true
                                            )
                                        }>
                                        Delete Announcement
                                    </Button>
                                )}
                                {(props.dialogMode == 'edit' ||
                                    props.dialogMode == 'create') && (
                                    <Button
                                        className="cancel"
                                        onClick={() => hideModal()}>
                                        Cancel
                                    </Button>
                                )}
                                {(props.dialogMode == 'edit' ||
                                    props.dialogMode == 'create') && (
                                    <ButtonLoader
                                        buttonText={'Save'}
                                        disabled={false}
                                        isLoading={isSaving}
                                        onClick={initSubmit}
                                        className={
                                            isSaving
                                                ? 'btn btn-primary loader-submit'
                                                : 'btn btn-primary loader-submit save'
                                        }
                                        color="primary"></ButtonLoader>
                                )}
                            </div>
                        </div>
                    </ModalFooter>
                </Rodal>
            )}

            {isDeleteConfirmationModalVisible ? (
                <DeleteConfirmationModal
                    isVisible={isDeleteConfirmationModalVisible}
                    setIsVisible={setIsDeleteConfirmationModalVisible}
                    title="Delete Announcement"
                    mainText="Are you sure you want to delete this announcement?"
                    onDelete={deleteAnnouncement}
                    isDeleting={isDeleting}></DeleteConfirmationModal>
            ) : (
                []
            )}
        </>
    );
};
export default AnnouncementModal;
