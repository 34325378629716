import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notification } from '../../models/notification';

export const updateMessageInList = createAsyncThunk(
    'clubhouseMessages/update-in-list',
    (notification: Notification) => {
        return {
            message: notification.message,
            discussionId: notification.chatId,
        };
    }
);
