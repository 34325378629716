import { GridColDef, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import React from 'react';
import ReactSwitch from 'react-switch';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
} from '../../../models/DataRequestHub/ProjectVariable';
import { ViewColumnFilterData } from '../../../models/DataRequestHub/DataTable';

interface FilterSwitcherColumnProps {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    getRowId(row: GridValidRowModel): any;
}

const FilterSwitcherColumn = (props: FilterSwitcherColumnProps): GridColDef => {
    const handleChange = (gridId: GridRowId, isFilterEnabled: boolean) => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());
        const updatedStateArray = latestStateArray.map((row) => {
            const updatedRow = {
                ...row,
                isFilterEnabled: isFilterEnabled,
                filterData: [] as ViewColumnFilterData[],
            };
            const id = props.getRowId(row);
            if (id === gridId) {
                return updatedRow;
            }
            return row;
        });

        props.setRows(() => updatedStateArray);
    };

    const column: GridColDef = {
        field: 'isFilterEnabled',
        headerName: 'Filter',
        type: 'actions',
        minWidth: 70,
        maxWidth: 100,
        cellClassName: (params) => {
            let cellClass = 'switcher-cell is-filter-enabled';

            const isFilterEnabled =
                params.row.isIncluded &&
                params.row.answerType ===
                    AnswerTypeVariableNamesList.find(
                        (x) => x.id === AnswerTypeVariable.PickFromList
                    ).name;
            if (!isFilterEnabled) {
                cellClass = `${cellClass} disabled`;
            }

            return cellClass;
        },
        headerAlign: 'left',
        getActions: (value: any) => {
            const id = value.id as number;

            return [
                <ReactSwitch
                    offColor="#d92550"
                    checked={value.row?.isFilterEnabled ?? false}
                    onChange={(event) => {
                        handleChange(id, event);
                    }}
                    height={22}
                />,
            ];
        },
    };

    return column;
};

export default FilterSwitcherColumn;
