import adminDefaultIcon from '../assets/images/vet-value-paw.png';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const PHONENO_PREFIX = process.env.REACT_APP_PHONENO_PREFIX;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const CHART_URL = process.env.REACT_APP_CHART_URL;
export const GA_ID = process.env.REACT_APP_GA_ID;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const REFERRAL_FEE_SCHEDULE_LINK =
    process.env.REACT_APP_REFERRAL_FEE_SCHEDULE_LINK;
export const REFERRAL_FEE_SCHEDULE_DATE =
    process.env.REACT_APP_REFERRAL_FEE_SCHEDULE_DATE;
export const DEV_EMAIL_DOMAIN = process.env.REACT_APP_DEV_EMAIL_DOMAIN;
export const WHEREBY_ORIGIN = process.env.REACT_APP_WHEREBY_ORIGIN;
export const ADMIN_PROFILE_ICON_DEFAULT = adminDefaultIcon;
export const ABOUT_PMGS_VIDEO_URL = process.env.REACT_APP_ABOUT_PMGS_VIDEO_URL;
export const ENCODING_KEY = process.env.REACT_APP_ENCODING_KEY;
