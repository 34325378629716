import { SupportContextEnum } from './SupportContext';
import { SupportThreadInitiatorInfo as SupportThreadInitiatorInfo } from './SupportThreadMemberInfo';

export interface SupportThread {
    id: number;
    initiator: SupportThreadInitiatorInfo;
    totalMessages: number;
    lastMessageDate: Date;
    supportContext: SupportContextEnum;
    numberOfViewedMessages: number;
    status: SupportThreadStatus;
    dateCreated: Date;
    dateInitiated: Date;
}

export enum SupportThreadStatus {
    Initiated = 1,
    InProcess,
    Stopped,
}
