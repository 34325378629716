import React, { useEffect, useState } from 'react';
import './GiftCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../utils/helper';
import moment from 'moment';
import cashImg from '../../assets/images/cash.jpg';
import { GiftCardDetails } from './GiftCardDetails';
import { useStateSelector } from '../../store/selectors';
import Loader from 'react-loaders';
import { GiftCardServiceNameMapping } from '../../containers/UserPages/Profile/CreateGiftCardModal/GiftCardModels';
interface GiftCardProps {
    giftCardTransactionId?: string;
    giftCardDetails?: GiftCardDetails;
    walletId?: number;
}
const GiftCard = (props: GiftCardProps) => {
    const axios = useStateSelector((c) => c.core.axios);
    const [giftCard, setGiftCard] = useState<GiftCardDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.giftCardDetails) {
            setGiftCard(props.giftCardDetails);
            setIsLoading(false);
        }
        if (props.giftCardTransactionId && !giftCard) {
            axios
                .get(
                    props.walletId
                        ? `api/giftcards/${props.giftCardTransactionId}?walletId=${props.walletId}`
                        : `api/giftcards/${props.giftCardTransactionId}`
                )
                .then((response: any) => {
                    if (response.data) {
                        setGiftCard(response.data);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [props]);

    return isLoading || !giftCard ? (
        <div className="gift-card-loader loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-scale-multiple" active={true} />
                </div>
                <h6 className="mt-5">Loading the Gift Card...</h6>
            </div>
        </div>
    ) : (
        <div className="gift-card">
            <FontAwesomeIcon
                icon={faDollar}
                className="top-left-dollar-icon"></FontAwesomeIcon>
            <FontAwesomeIcon
                icon={faDollar}
                className="bottom-left-dollar-icon"></FontAwesomeIcon>
            <FontAwesomeIcon
                icon={faDollar}
                className="top-right-dollar-icon"></FontAwesomeIcon>
            <FontAwesomeIcon
                icon={faDollar}
                className="bottom-right-dollar-icon"></FontAwesomeIcon>
            <div className="gift-card-content-container">
                <div className="gift-card-row-container">
                    <div className="gift-card-header">
                        <h2>VetValue Ecosystem</h2>
                        <h2>Gift Card</h2>
                    </div>
                </div>
                <div className="gift-card-details-container">
                    <div>
                        <p>
                            <strong>To:</strong> {giftCard.to}
                        </p>
                        <p>
                            <strong>From:</strong> {giftCard.from}
                        </p>
                        <p>
                            <strong>Purpose:</strong>{' '}
                            {GiftCardServiceNameMapping.find(
                                (g) => g.id == giftCard.purpose
                            )?.name ?? ''}
                        </p>
                        <p>
                            <strong>Value:</strong>{' '}
                            {Helpers.GetCurrencyFormat(giftCard.value, 2, true)}
                        </p>
                        <p>
                            <strong>Date:</strong>{' '}
                            {moment(giftCard.date).format('MM/DD/YYYY')}
                        </p>
                        <p className="footnote">
                            * This amount has been added to your wallet
                        </p>
                    </div>
                    <img src={cashImg} />
                </div>
            </div>
        </div>
    );
};

export default GiftCard;
