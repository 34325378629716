export enum SupportContextEnum {
    Poc = 1,
    Pmg,
    Capvar,
    Charity,
    Referral,
    Analytics,
    MyAccount,
    Brokerage,
    KnowledgeLibrary,
    CapvarInterpretationSupport,
}

export interface SupportContextItem {
    id: number;
    name: string;
}

export const SupportContextItemList: SupportContextItem[] = [
    {
        id: SupportContextEnum.MyAccount,
        name: 'MEMBER ACCOUNTS',
    },
    {
        id: SupportContextEnum.Referral,
        name: 'REFERRAL',
    },
    {
        id: SupportContextEnum.Poc,
        name: 'IPOC',
    },
    {
        id: SupportContextEnum.Capvar,
        name: 'CAPVAR',
    },
    {
        id: SupportContextEnum.CapvarInterpretationSupport,
        name: 'CAPVAR INTERPRETATION SUPPORT',
    },
    {
        id: SupportContextEnum.Analytics,
        name: 'ANALYTICS',
    },
    {
        id: SupportContextEnum.Pmg,
        name: 'PMG',
    },
    {
        id: SupportContextEnum.Brokerage,
        name: 'BROKERAGE',
    },
    {
        id: SupportContextEnum.Charity,
        name: 'NONPROFIT',
    },
    {
        id: SupportContextEnum.KnowledgeLibrary,
        name: 'KNOWLEDGE LIBRARY',
    },
];

export const SupportContextItemPrefixToAdmin: SupportContextItem[] = [
    {
        id: SupportContextEnum.MyAccount,
        name: 'Member Account',
    },
    {
        id: SupportContextEnum.Referral,
        name: 'Referral',
    },
    {
        id: SupportContextEnum.Poc,
        name: 'IPOC',
    },
    {
        id: SupportContextEnum.Capvar,
        name: 'CAPVAR',
    },
    {
        id: SupportContextEnum.CapvarInterpretationSupport,
        name: 'CAPVAR',
    },
    {
        id: SupportContextEnum.Analytics,
        name: 'Analytics',
    },
    {
        id: SupportContextEnum.Pmg,
        name: 'PMG',
    },
    {
        id: SupportContextEnum.Brokerage,
        name: 'Brokerage',
    },
    {
        id: SupportContextEnum.Charity,
        name: 'Nonprofit',
    },
    {
        id: SupportContextEnum.KnowledgeLibrary,
        name: 'Knowledge Library',
    },
];

export const SupportSubContexts: SupportContextItem[] = [
    {
        id: SupportContextEnum.CapvarInterpretationSupport,
        name: 'Interpretation Support'
    },
    {
        id: SupportContextEnum.Capvar,
        name: 'Data Input'
    }
]