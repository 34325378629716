import { createAsyncThunk } from '@reduxjs/toolkit';

interface UpdateContentStatusParams {
    discussionId: number;
    messageId: number;
    contentId: number;
    isAddedToShelf: boolean;
}

export const UpdateContentStatus = createAsyncThunk(
    'message/content/updateStatus',
    (params: UpdateContentStatusParams) => {
        return {
            discussionId: params.discussionId,
            messageId: params.messageId,
            contentId: params.contentId,
            isAddedToShelf: params.isAddedToShelf,
        };
    }
);
