import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Row, Col, InputGroup, Input } from 'reactstrap';
import { ModalHeader, ModalBody } from 'reactstrap';
import { CurrencyInput, Form } from '@availity/form';
import Rodal from '../../../../../components/Rodal/Rodal';
import './WalletModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDollarSign,
    faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import * as yup from 'yup';
import Helpers from '../../../../../utils/helper';
import RichTextInput from '../../../../../components/RichTextInput/RichTextInput';
import { RewardItemTypeList } from '../../../../../models/Referral/RewardHistory/RewardItemType';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import { WithdrawalStatus } from '../../../../Referal/Model/Referral/WithdrawalStatus';

const creditsOptions = [
    {
        id: 1,
        name: 'Reward Credits',
        statuses: [WithdrawalStatus.Nonprofit, WithdrawalStatus.YearEndSweep],
    },
    {
        id: 2,
        name: 'Store Credits',
        statuses: [WithdrawalStatus.YearEndSweep, WithdrawalStatus.NoSweep],
    },
    {
        id: 3,
        name: 'Flex Credits',
        statuses: [WithdrawalStatus.YearEndSweep, WithdrawalStatus.NoSweep],
    },
];

const sourceOptions = [
    { id: 1, name: 'Admin Entry' },
    { id: 2, name: 'Direct Referral' },
    { id: 3, name: 'Indirect Referral' },
];

export const adminEntryReasonOptions = [
    { id: 1, name: 'APVAR' },
    { id: 2, name: 'CAPVAR' },
    { id: 3, name: 'KPI Benchmarking' },
    { id: 4, name: 'Practice Valuation Comparison' },
    { id: 5, name: 'Donation' },
    { id: 6, name: 'Rebate' },
    { id: 7, name: 'Refund' },
    { id: 8, name: 'Reward' },
    { id: 9, name: 'Withdrawal' },
    { id: 10, name: 'Gift Card' },
];

const WalletModal = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [adjustmentAmount, setAdjustmentAmount] = useState(0);
    const [adjustmentComment, setAdjustmentComment] = useState('');
    const [selectedCreditTypeOption, setSelectedCreditTypeOption] = useState(1);
    const [selectedSourceOption, setSelectedSourceOption] = useState(1);
    const [selectedRewardItemOption, setSelectedRewardItemOption] = useState(0);
    const [selectedAdminEntryReasonOption, setSelectedAdminEntryReasonOption] =
        useState(1);
    const [selectedReferredPartyIdOption, setSelectedReferredPartyIdOption] =
        useState(0);
    const [referralOptions, setReferralOptions] = useState([]);
    const [indirectReferralOptions, setIndirectReferralOptions] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isToolbarActive, setToolbarActive] = useState(false);
    const [isValidAdjustmentCommentInput, setIsValidAdjustmentCommentInput] =
        useState(true);
    const [
        isEnteredFocusOnAdjustmentCommentField,
        setIsEnteredFocusOnAdjustmentCommentField,
    ] = useState(false);
    const [focusAdjustmentAmountField, setFocusAdjustmentAmountField] =
        useState(null);
    const [availableCreditTypes, setAvailableCreditTypes] = useState([]);
    let modalForm = {};

    useEffect(() => {
        fetchReferralData();
        let availableCrTypes = creditsOptions.filter((c) =>
            c.statuses.includes(props.editedUser.withdrawalStatus)
        );
        setAvailableCreditTypes(availableCrTypes);
        setSelectedCreditTypeOption(availableCrTypes[0].id);
    }, []);

    const isReferralDropDownsVisible = () => {
        return selectedSourceOption === 2 || selectedSourceOption === 3;
    };

    const isIndirectReferralSelected = () => {
        return selectedSourceOption === 3;
    };

    const isNoSweep = () =>
        props.editedUser.withdrawalStatus == WithdrawalStatus.NoSweep;

    const isNonprofit = () =>
        props.editedUser.withdrawalStatus == WithdrawalStatus.Nonprofit;

    const changeAdjustmentComment = (comment) => {
        if (
            !isEnteredFocusOnAdjustmentCommentField &&
            comment.trim().length === 0
        ) {
            setIsEnteredFocusOnAdjustmentCommentField(true);
            setIsValidAdjustmentCommentInput(true);
            setAdjustmentComment(comment);
            return;
        }
        setIsEnteredFocusOnAdjustmentCommentField(true);
        setIsValidAdjustmentCommentInput(comment && comment.trim().length > 0);
        setAdjustmentComment(comment);
    };

    const submitRequest = () => {
        if (
            isEnteredFocusOnAdjustmentCommentField &&
            !isValidAdjustmentCommentInput
        ) {
            setFocusAdjustmentAmountField(
                focusAdjustmentAmountField == null
                    ? true
                    : !focusAdjustmentAmountField
            );
            return;
        }
        setLoader(true);
        isReferralDropDownsVisible()
            ? props
                  .addReferralReward({
                      amount: adjustmentAmount,
                      referredPartyId: selectedReferredPartyIdOption,
                      rewardItem: selectedRewardItemOption,
                      isIndirect: isIndirectReferralSelected(),
                  })
                  .finally(() => setLoader(false))
            : props
                  .addAdminEntry({
                      type: selectedCreditTypeOption,
                      amount: adjustmentAmount,
                      comment: adjustmentComment,
                      walletId: props.editedUser.id,
                      reason: selectedAdminEntryReasonOption,
                  })
                  .finally(() => setLoader(false));
    };

    const mapReferrals = (referrals) =>
        referrals.map((obj) => ({
            ...obj,
            dropDownItem: `${obj.fullName}, ${obj.email}`,
        }));

    const useNull = () => null;

    const fetchReferralData = () => {
        const userId = props.editedUser.id;
        axios
            .all([
                axios.get(`/api/referral/${userId}`).catch(useNull),
                axios
                    .get(`/api/referral/${userId}?indirect=true`)
                    .catch(useNull),
            ])
            .then(
                axios.spread((...responses) => {
                    let refList = mapReferrals(responses[0]?.data);
                    setReferralOptions(refList);

                    let indirectRefList = mapReferrals(responses[1]?.data);
                    setIndirectReferralOptions(indirectRefList);
                })
            )
            .catch(() => {
                showErrorNotification();
            });
    };

    const showErrorNotification = () =>
        createNotification(
            'There was some error while getting referrals and invitees information. Please come back later',
            'error'
        );

    const handleCreditTypeOptionChange = (selectedOption) => {
        setSelectedCreditTypeOption(selectedOption);
    };

    const handleSourceChange = (option) => {
        setSelectedSourceOption(option);
    };

    const handleReferredPartyIdChange = (option) => {
        setSelectedReferredPartyIdOption(option);
    };

    const handleRewardItemChange = (option) => {
        setSelectedRewardItemOption(option);
    };

    const handleAdminEntryItemChange = (option) => {
        setSelectedAdminEntryReasonOption(option);
    };

    const currentDate = () => {
        return new Date()
            .toLocaleString('en-GB', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
            .replace(',', '')
            .replace('am', 'AM')
            .replace('pm', 'PM');
    };

    const memberData = (
        <div className="member-data">
            <Row>
                <Col>
                    <div className="text-style">Member Name:</div>
                    <div className="subtext-style">{props.editedUser.name}</div>
                </Col>
                <Col>
                    <div className="text-style">Current Wallet Balance:</div>
                    <div className="subtext-style">
                        {Helpers.GetCurrencyFormat(
                            props.editedUser.rewardBalance +
                                props.editedUser.storeBalance +
                                props.editedUser.flexBalance,
                            2
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-style">Reward Balance:</div>
                    <div className="subtext-style">
                        {Helpers.GetCurrencyFormat(
                            props.editedUser.rewardBalance,
                            2
                        )}
                    </div>
                </Col>
                <Col>
                    <div className="text-style">Flex Balance:</div>
                    <div className="subtext-style">
                        {Helpers.GetCurrencyFormat(
                            props.editedUser.flexBalance,
                            2
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-style">Store Balance:</div>
                    <div className="subtext-style">
                        {Helpers.GetCurrencyFormat(
                            props.editedUser.storeBalance,
                            2
                        )}
                    </div>
                </Col>
            </Row>
            <br></br>
        </div>
    );

    return (
        <div className="wallet-modal-popup">
            <Rodal
                width={500}
                visible={props.isVisisble}
                animation={'slideRight'}
                onClose={props.onClose}
                showMask={false}
                center>
                <ModalHeader>Manual Wallet Entry</ModalHeader>
                <ModalBody>
                    {memberData}
                    <Form
                        enableReinitialize
                        className="user-form"
                        initialValues={{
                            adjustmentAmount: adjustmentAmount,
                        }}
                        onSubmit={() => {
                            if (
                                isReferralDropDownsVisible() &&
                                !selectedReferredPartyIdOption
                            ) {
                                return;
                            }
                            submitRequest();
                        }}
                        validationSchema={yup.object().shape({
                            adjustmentAmount: yup
                                .number()
                                .required()
                                .notOneOf([0]),
                        })}
                        innerRef={(c) => (modalForm = c)}>
                        <FormGroup>
                            <label>Date:</label>
                            <InputGroup>
                                <div className="input-group-text prepend-addon">
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                </div>
                                <Input
                                    className="input-with-icon"
                                    type="text"
                                    value={currentDate()}
                                    readOnly
                                    validate={false}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <label>Transaction Number:</label>
                            <Input type="text" value="Pending" readOnly />
                        </FormGroup>
                        <FormGroup>
                            <label>Source/Use of Credits:</label>
                            <DropdownList
                                className="credit-type-select"
                                selectIcon={<ArrowDown />}
                                data={sourceOptions}
                                defaultValue={sourceOptions[0]}
                                onChange={(value) => {
                                    handleSourceChange(value.id);
                                }}
                                dataKey="id"
                                textField="name"
                            />
                        </FormGroup>
                        {isReferralDropDownsVisible() && (
                            <>
                                <FormGroup>
                                    <label>{`${
                                        isIndirectReferralSelected()
                                            ? 'Indirect '
                                            : ''
                                    }Referred Party:`}</label>
                                    <DropdownList
                                        className="credit-type-select"
                                        selectIcon={<ArrowDown />}
                                        data={
                                            isIndirectReferralSelected()
                                                ? indirectReferralOptions
                                                : referralOptions
                                        }
                                        onChange={(value) => {
                                            handleReferredPartyIdChange(
                                                value.walletId
                                            );
                                        }}
                                        dataKey="id"
                                        containerClassName={
                                            !selectedReferredPartyIdOption
                                                ? 'error-input'
                                                : ''
                                        }
                                        invalid={!selectedReferredPartyIdOption}
                                        textField="dropDownItem"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Item:</label>
                                    <DropdownList
                                        className="credit-type-select"
                                        selectIcon={<ArrowDown />}
                                        data={RewardItemTypeList}
                                        defaultValue={RewardItemTypeList[0]}
                                        onChange={(value) => {
                                            handleRewardItemChange(value.id);
                                        }}
                                        dataKey="id"
                                        textField="name"
                                    />
                                </FormGroup>
                            </>
                        )}
                        {!isReferralDropDownsVisible() && (
                            <>
                                <FormGroup>
                                    <label>Item:</label>
                                    <DropdownList
                                        className="credit-type-select"
                                        selectIcon={<ArrowDown />}
                                        data={adminEntryReasonOptions}
                                        defaultValue={
                                            adminEntryReasonOptions[0]
                                        }
                                        onChange={(value) => {
                                            handleAdminEntryItemChange(
                                                value.id
                                            );
                                        }}
                                        dataKey="id"
                                        textField="name"
                                    />
                                </FormGroup>
                            </>
                        )}
                        <FormGroup>
                            <label htmlFor="selectedOption">Credit type:</label>
                            <DropdownList
                                className="credit-type-select"
                                selectIcon={<ArrowDown />}
                                data={availableCreditTypes}
                                readOnly={
                                    isReferralDropDownsVisible() ||
                                    isNonprofit()
                                }
                                defaultValue={
                                    availableCreditTypes.filter((c) =>
                                        c.statuses.includes(
                                            props.editedUser.WithdrawalStatus
                                        )
                                    )[0]
                                }
                                onChange={(value) => {
                                    handleCreditTypeOptionChange(value.id);
                                }}
                                dataKey="id"
                                value={
                                    isReferralDropDownsVisible()
                                        ? isNoSweep()
                                            ? creditsOptions.find(
                                                  (o) =>
                                                      o.name == 'Flex Credits'
                                              ).id
                                            : availableCreditTypes[0]
                                        : selectedCreditTypeOption
                                }
                                textField="name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="adjustmentAmount">
                                Adjustment Amount:
                            </label>
                            <InputGroup>
                                <div className="input-group-text prepend-addon">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </div>
                                <CurrencyInput
                                    name="adjustmentAmount"
                                    allowDecimals={false}
                                    allowNegativeValue={true}
                                    prefix=""
                                    className="input-with-icon min-width form-control currency-input"
                                    value={adjustmentAmount}
                                    onValueChanged={(value) => {
                                        setAdjustmentAmount(
                                            value && value !== 'NaN'
                                                ? Number(value)
                                                : 0
                                        );
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="adjustment-comment">
                            <label htmlFor="adjustmentComment">
                                Adjustment Comments:
                            </label>
                            <div
                                className={
                                    'input-group rich-text-input-wrapper' +
                                    (!isValidAdjustmentCommentInput
                                        ? ' adjustment-comment-input-invalid'
                                        : '')
                                }>
                                <RichTextInput
                                    toggleToolbar={() => {
                                        setToolbarActive(!isToolbarActive);
                                    }}
                                    focus={focusAdjustmentAmountField}
                                    setMessageText={
                                        changeAdjustmentComment
                                    }></RichTextInput>
                            </div>
                        </FormGroup>
                    </Form>
                    <div className="btn-footer">
                        <Button
                            className="btn close-button"
                            onClick={props.onClose}>
                            Cancel
                        </Button>
                        <ButtonLoader
                            buttonText={
                                !loader ? 'Confirm Wallet Entry' : 'Loading...'
                            }
                            onClick={() => {
                                if (
                                    !isEnteredFocusOnAdjustmentCommentField &&
                                    adjustmentComment.trim().length === 0 &&
                                    adjustmentAmount === 0
                                ) {
                                    setIsEnteredFocusOnAdjustmentCommentField(
                                        true
                                    );
                                    setIsValidAdjustmentCommentInput(false);
                                    setAdjustmentComment(adjustmentComment);
                                    modalForm.handleSubmit();
                                    return;
                                }
                                if (
                                    !isEnteredFocusOnAdjustmentCommentField &&
                                    adjustmentComment.trim().length === 0 &&
                                    adjustmentAmount !== 0
                                ) {
                                    setIsEnteredFocusOnAdjustmentCommentField(
                                        true
                                    );
                                    setIsValidAdjustmentCommentInput(false);
                                    setAdjustmentComment(adjustmentComment);
                                    setFocusAdjustmentAmountField(
                                        focusAdjustmentAmountField == null
                                            ? true
                                            : !focusAdjustmentAmountField
                                    );
                                    modalForm.handleSubmit();
                                    return;
                                }
                                changeAdjustmentComment(adjustmentComment);
                                modalForm.handleSubmit();
                            }}
                            disabled={false}
                            className="btn btn-primary"
                            isLoading={loader}></ButtonLoader>
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};
export default WalletModal;
