import React from 'react';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
} from 'reactstrap';

const EditProfileDropdown = props => {
    return (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret className="" color="primary">
                Edit Profile
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right">
                <div className="dropdown-menu-header"></div>
                <DropdownItem
                    onClick={() => props.setshowChangeContactDetails(true)}>
                    Change contact details
                </DropdownItem>
                <DropdownItem onClick={() => props.setshowResetPassword(true)}>
                    Reset Password
                </DropdownItem>
                <DropdownItem
                    onClick={() => props.setshowConfirmMFAUpdateModal(true)}>
                    {props.isMFAEnabled ? 'Disable' : 'Enable'} SMS
                    Authentication
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    onClick={() => props.setshowConfirmDeleteModal(true)}
                    style={{ color: 'red' }}>
                    Delete Account
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

export default EditProfileDropdown;
