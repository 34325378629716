import React, { useEffect, useState } from 'react';
import KnowledgeBaseContentTile from '../KnowledgeBaseContentLibrary/KnowledgeBaseContentTile';
import './LibraryItemsWrapperComponent.scss';
import { USER_ROLES } from '../../../utils/constants';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../../utils/associateMembersConstants';

interface LibraryItemsWrapperComponentProps {
    contentList: any[];
    setInitialData(): void;
    isSelectable?: boolean;
    selectItem?(contentId: number): void;
    deselectItem?(contentId: number): void;
    isPremiumContent: boolean;
}

const LibraryItemsWrapperComponent = (
    props: LibraryItemsWrapperComponentProps
) => {
    const [isSaveToShelfAvailable, setIsSaveToShelfAvailable] = useState(
        calculateAccessToShelfIfPremiumContent()
    );

    useEffect(() => {
        setIsSaveToShelfAvailable(calculateAccessToShelfIfPremiumContent());
    }, []);

    function calculateAccessToShelfIfPremiumContent(): boolean {
        if (!props.isPremiumContent) {
            return true;
        }

        var userSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        var roleId = userSummary.roleId;

        if (
            roleId === USER_ROLES.ADMIN ||
            roleId === USER_ROLES.PRACTICE_OWNER
        ) {
            return true;
        }

        if (roleId == USER_ROLES.PRACTICE_SECONDARY_USER) {
            return userSummary.associateMember?.some((x: any) =>
                x.practices.some((p: any) =>
                    p.acceptedAccessRights.includes(
                        ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                    )
                )
            );
        }

        return false;
    }

    return (
        <>
            <div className="library-items-wrapper">
                {props.contentList.map((cont, index) =>
                    index % 2 == 0 ? (
                        <KnowledgeBaseContentTile
                            isSelectable={props.isSelectable}
                            updateTables={() => props.setInitialData()}
                            data={cont}
                            selectItem={(id: any) => props.selectItem(id)}
                            deselectItem={props.deselectItem}
                            isSaveToShelfVisible={isSaveToShelfAvailable}
                            showNotificationFlag={
                                true
                            }></KnowledgeBaseContentTile>
                    ) : (
                        []
                    )
                )}
            </div>
            <div className="library-items-wrapper">
                {props.contentList.map((cont, index) =>
                    index % 2 == 1 ? (
                        <KnowledgeBaseContentTile
                            isSelectable={props.isSelectable}
                            updateTables={() => props.setInitialData()}
                            data={cont}
                            selectItem={props.selectItem}
                            deselectItem={props.deselectItem}
                            isSaveToShelfVisible={isSaveToShelfAvailable}
                            showNotificationFlag={
                                true
                            }></KnowledgeBaseContentTile>
                    ) : (
                        []
                    )
                )}
            </div>
        </>
    );
};
export default LibraryItemsWrapperComponent;
