import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface SendSupportThreadMessageThunkArg {
    connection: HubConnection;
    threadId: number;
    htmlContent: string;
    attachmentsIds: number[];
}

export const sendSupportThreadMessage = createAsyncThunk(
    'supportMessages/send',
    async (arg: SendSupportThreadMessageThunkArg) => {
        let newMessage = {
            threadId: arg.threadId,
            htmlContent: arg.htmlContent,
            attachmentsIds: arg.attachmentsIds,
        };
        await arg.connection.send('SendMessage', newMessage);
    }
);
