import React, { useRef, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { Form, Field } from '@availity/form';
import '@availity/yup';
import * as yup from 'yup';
import ButtonLoader from '../../components/Layout/Buttons/ButtonLoader';
import './edit-discussion.scss';
import dateLogo from '../../assets/knowledgecontent/dateLogo.png';
import { Discussion } from '../models/discussion';
import moment from 'moment';
import createNotification from '../../utils/createNotification';

interface DiscussionEditProps {
    discussion: Discussion;
    hide(): void;
    updateDiscussion(
        discussionId: number,
        name: string,
        description: string
    ): Promise<any>;
}

const EditDiscussionModal = (props: DiscussionEditProps) => {
    const ResourceAlreadyExistStatusCode = 409;
    const originalName = props.discussion.name;
    const originalDescription = props.discussion.description;
    const [name, setName] = useState(props.discussion.name);
    const [description, setDescription] = useState(
        props.discussion.description
    );
    const [isLoading, setIsLoading] = useState(false);
    const modalForm = useRef(null);

    const isDiscussionChanged = (): boolean => {
        return originalDescription !== description || originalName !== name;
    };

    const initSubmit = () => {
        modalForm.current.handleSubmit();
    };

    const saveDiscussion = () => {
        setIsLoading(true);
        props
            .updateDiscussion(props.discussion.id, name, description)
            .then((response: any) => {
                if (
                    response.payload.response?.status ===
                    ResourceAlreadyExistStatusCode
                ) {
                    createNotification(
                        `The discussion ${name} already exists`,
                        'error'
                    );
                } else {
                    props.hide();
                }

                setIsLoading(false);
            });
    };

    return (
        <div className="edit-discussion">
            <Form
                innerRef={modalForm}
                initialValues={{
                    discussionName: name,
                    description: description,
                }}
                validationSchema={yup.object().shape({
                    discussionName: yup
                        .string()
                        .max(30)
                        .required(),
                    description: yup
                        .string()
                        .max(140)
                        .required(),
                })}
                onSubmit={() => {
                    saveDiscussion();
                }}>
                <div className="knowledge-contetnt-popup-header">
                    <div className="tile-edit-btn-wrapper">
                        <h5>Discussion Name: </h5>
                        <div className="input-count-wrapper">
                            <Field
                                type="text"
                                name="discussionName"
                                value={name}
                                maxLength={30}
                                onChange={(event: any) =>
                                    setName(event.target.value)
                                }
                            />
                            <div className="text-count">
                                <span>{name.length}/30</span>
                            </div>
                        </div>
                        <ButtonLoader
                            buttonText={'Save'}
                            loaderButtonText={'Save'}
                            disabled={!isDiscussionChanged()}
                            isLoading={isLoading}
                            onClick={initSubmit}
                            className={
                                isLoading
                                    ? 'btn discussion-btn save-btn loader-submit'
                                    : 'btn discussion-btn save-btn'
                            }></ButtonLoader>
                    </div>
                </div>
                <ModalBody className="edit-title-body edit-detail">
                    <div className="intro-text-wrapper">
                        <label>Description</label>
                        <div className="intro-text-area">
                            <Field
                                type="textarea"
                                name="description"
                                maxLength={140}
                                onChange={(event: any) =>
                                    setDescription(event.target.value)
                                }
                            />
                        </div>
                        <div className="text-count">
                            <span>{description.length}/140</span>
                        </div>
                    </div>
                    <div className="title-wrapper">
                        <div className="title">
                            <label>Created By</label>
                            <div className="input-title">
                                <span>{props.discussion.createdBy}</span>
                            </div>
                        </div>
                        <div className="author-input">
                            <div className="originally-based-col">
                                <label>Created Date</label>
                                <div className="flex-container">
                                    <div className="input-group-text">
                                        <img src={dateLogo} />
                                    </div>
                                    <input
                                        disabled
                                        type="text"
                                        placeholder={moment(
                                            props.discussion.dateCreated
                                        ).format('l')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="edited-post-wrapper">
                        <div className="title">
                            <label>Number of Posts</label>
                            <div className="input-title">
                                <span>{props.discussion.totalMessages}</span>
                            </div>
                        </div>
                        <div className="author-input">
                            <label>Number of Posters</label>
                            <div className="author-input">
                                <span>{props.discussion.posters?.length}</span>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </ModalBody>
            </Form>
        </div>
    );
};

export default EditDiscussionModal;
