import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    DropdownItem,
    Nav,
    NavItem,
    UncontrolledDropdown,
} from 'reactstrap';
import './direct-rooms-list.scss';
import searchLogo from '../../assets/images/searchLogo.png';
import CreateRoomModal from '../chat/create-room-modal/create-room-modal';
import { useDispatch } from 'react-redux';
import { createRoom } from '../slices/rooms/create-room.thunk';
import { setIsCreateRoomModalVisible } from '../slices/rooms/direct-rooms-slice';
import moment from 'moment';
import { useStateSelector } from '../../store/selectors';
import { RoomItem } from '../models/room-item';
import { RoomMember } from '../models/room-member';
import { RoomType } from '../models/room-type-enum';
import { SelectedRoomState } from '../models/selected-room-state';
import {
    SupportContextEnum,
    SupportContextItemPrefixToAdmin,
    SupportSubContexts,
} from '../../support-portal/models/SupportContext';
import { DirectRoom } from '../models/direct-room';
import { DirectMember } from '../models/direct-member';

enum RoomSortModes {
    MostPosts,
    MostRecentlyPostedTo,
}

export interface DirectRoomsListProps {
    selectedRoomState: SelectedRoomState | undefined;
    setSelectedRoomState: (roomId: number, roomType: RoomType) => void;
    roomItems: RoomItem[];
    members: RoomMember[];
    currentUserId: number;
    openMemberProfileModal: (id: number) => void;
    refresh: () => void;
    isNewChannelsLinkVisible: boolean;
    isBlockingRoom: (roomId: number, roomType: RoomType) => boolean;
}

const DirectRoomsList = (props: DirectRoomsListProps) => {
    const [searchText, setSearchText] = useState('');
    const [sortMode, setSortMode] = useState<RoomSortModes>(
        RoomSortModes.MostRecentlyPostedTo
    );

    const supportThreads = useStateSelector(
        (state) => state.supportThreads.threads
    );

    const currentMember = useStateSelector(
        (s) => s.directMembers.currentMember
    );

    const [sortedRooms, setSortedRooms] = useState<RoomItem[]>([]);

    const isCreateRoomModalVisible: boolean = useStateSelector(
        (state) => state.directRooms.isCreateRoomModalVisible
    );
    const [isInitialized, setIsInitialized] = useState(false);
    const dispatch: any = useDispatch();
    const createNewRoom = (userId: number): Promise<any> => {
        const existingRoom = props.roomItems.find((r) =>
            r.room.members.some((m) => m.id == userId)
        );
        if (existingRoom) {
            props.setSelectedRoomState(existingRoom.room.id, RoomType.Direct);
            return Promise.resolve();
        } else {
            return dispatch(createRoom(userId));
        }
    };

    useEffect(() => {
        setSortedRooms(sortRooms(props.roomItems ?? [], sortMode));
        if (
            isInitialized &&
            props.roomItems.length == 1 &&
            sortedRooms.length === 0
        ) {
            props.refresh();
        }
        setIsInitialized(true);
    }, [props.roomItems]);

    const getRoomProperties = (room: RoomItem): RoomMember => {
        switch (room.roomType) {
            case RoomType.Direct:
                let opponentMember = room.room?.members?.find(
                    (m) => m.id !== props.currentUserId
                );
                let member = structuredClone(
                    props.members?.find(
                        (m) => m.member.userId === opponentMember?.id
                    )
                );

                if (member) {
                    const anonymousMember = room.room.members.find(
                        (m) => m.isAnonymousUser && m.id == member.member.id
                    );
                    if (anonymousMember) {
                        member.member.fullName = mapAnonymousName(
                            anonymousMember.anonymousMemberUniqueCode
                        );
                        member.member.firstName = mapAnonymousName(
                            anonymousMember.anonymousMemberUniqueCode
                        );
                        member.member.lastName = '';
                        member.member.initials = '?';
                    }
                    const isCurrentMemberAnonymous =
                        room.room.members.find(
                            (m) => m.id != member.member.userId
                        ).isAnonymousUser && member.member.isCharityAdmin;
                    if (isCurrentMemberAnonymous) {
                        member.member.initials = '?';
                    }
                }
                return member;
            case RoomType.SupportThread:
                const roomMember: RoomMember = JSON.parse(
                    JSON.stringify(props.members)
                ).find(
                    (f: RoomMember) =>
                        f.member.userId !== props.currentUserId &&
                        f.roomType === RoomType.SupportThread
                );
                const relatedSupportThread = supportThreads.find(
                    (f) => f.id === room.room.id
                );
                roomMember.member.fullName = mapSupportMemberName(
                    roomMember?.member?.lastName,
                    roomMember?.member?.fullName,
                    relatedSupportThread?.supportContext
                );
                return roomMember;
            default:
                break;
        }
    };

    const mapAnonymousName = (uniqueCode: number) => {
        return `Anonymous ${uniqueCode ?? ''}`;
    };

    const mapSupportMemberName = (
        lastName: string,
        fullName: string,
        context: SupportContextEnum
    ) =>
        `${
            SupportContextItemPrefixToAdmin.find((f) => f.id === context)?.name
        } ${lastName}` +
        `${
            SupportSubContexts.find((f) => f.id == context)
                ? ' | ' + SupportSubContexts.find((f) => f.id == context).name
                : ''
        }`;

    const doesMatchFilter = (text: string) => {
        return (
            !searchText?.trim() ||
            text.toLowerCase().includes(searchText.toLowerCase())
        );
    };

    const selectSortMode = (mode: RoomSortModes) => {
        if (sortMode === mode) {
            return;
        }
        setSortMode(mode);
        setSortedRooms(sortRooms(props.roomItems, mode));
    };

    const sortRooms = (rooms: RoomItem[], mode: RoomSortModes) => {
        if (mode === RoomSortModes.MostPosts) {
            return [...rooms].sort(
                (a, b) => b.room.totalMessages - a.room.totalMessages
            );
        } else if (mode === RoomSortModes.MostRecentlyPostedTo) {
            return [...rooms].sort((a, b) => {
                const dateA = new Date(
                    a.room.lastMessageDate.toString().split('.')[0]
                ).getTime();
                const dateB = new Date(
                    b.room.lastMessageDate.toString().split('.')[0]
                ).getTime();
                return dateA < dateB && b.room.totalMessages > 0
                    ? 1
                    : dateA > dateB && a.room.totalMessages > 0
                    ? -1
                    : 0;
            });
        } else {
            return [...rooms];
        }
    };

    const getOpponentName = (
        room: DirectRoom,
        roomProps: DirectMember
    ): string => {
        return `${
            roomProps?.isCharityAdmin &&
            room.members.find((m) => m.id != roomProps.userId).isAnonymousUser
                ? ''
                : `${roomProps?.fullName}`
        }${
            roomProps?.isCharityAdmin &&
            roomProps?.charityName &&
            !room.members.find((m) => m.id != roomProps.userId).isAnonymousUser
                ? ' - '
                : ''
        }${roomProps?.charityName ? `${roomProps?.charityName}` : ''}${
            roomProps?.isCharityAdmin &&
            room.members.find((m) => m.id != roomProps.userId).isAnonymousUser
                ? ' (A)'
                : ''
        }`;
    };

    return (
        <>
            <div className="direct-rooms-list">
                <Nav vertical>
                    <NavItem className="search-navbar">
                        <div className="search-btn-wrapper">
                            <div className="search-box">
                                <img src={searchLogo} />
                                <input
                                    type="text"
                                    className="search-icon"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </NavItem>
                </Nav>
                <h5>Direct Messaging Channels</h5>
                <ButtonGroup className="sort-buttons" size="sm">
                    <Button
                        color={
                            sortMode === RoomSortModes.MostPosts
                                ? 'primary'
                                : 'white'
                        }
                        onClick={() => selectSortMode(RoomSortModes.MostPosts)}>
                        Most Frequent
                    </Button>
                    <Button
                        color={
                            sortMode === RoomSortModes.MostRecentlyPostedTo
                                ? 'primary'
                                : 'white'
                        }
                        onClick={() =>
                            selectSortMode(RoomSortModes.MostRecentlyPostedTo)
                        }>
                        Most Recent
                    </Button>
                </ButtonGroup>
                {props.isNewChannelsLinkVisible &&
                currentMember.hasFullAccess ? (
                    <label
                        className="nav-item-header add-room"
                        onClick={() =>
                            dispatch(setIsCreateRoomModalVisible(true))
                        }>
                        New Personal Channel
                    </label>
                ) : (
                    <div className="mb-2" />
                )}
                <div className="navbar-wrapper">
                    <Nav vertical className="mobile-nav-bar">
                        {props.members && sortedRooms
                            ? sortedRooms
                                  .filter((room) =>
                                      doesMatchFilter(
                                          getRoomProperties(room)?.member
                                              .fullName
                                      )
                                  )
                                  .map((roomItem: RoomItem, k: number) => {
                                      const room = roomItem.room;
                                      const roomProps =
                                          getRoomProperties(roomItem)?.member;
                                      const numberOfNewMessages =
                                          room.totalMessages -
                                          room.members.find(
                                              (m) =>
                                                  m.id === props.currentUserId
                                          )?.numberOfViewedMessages;

                                      return roomProps ? (
                                          <NavItem key={'room' + k}>
                                              <UncontrolledDropdown>
                                                  <DropdownItem
                                                      onClick={() => {
                                                          props.setSelectedRoomState(
                                                              room.id,
                                                              roomItem.roomType
                                                          );
                                                      }}
                                                      className={
                                                          room.id ==
                                                              props
                                                                  .selectedRoomState
                                                                  .id &&
                                                          roomItem.roomType ===
                                                              props
                                                                  .selectedRoomState
                                                                  .type
                                                              ? 'not-active'
                                                              : props.isBlockingRoom(
                                                                    room.id,
                                                                    roomItem.roomType
                                                                )
                                                              ? 'blocked-chat'
                                                              : ''
                                                      }>
                                                      <div className="widget-content p-0 let-chat-data">
                                                          <div className="widget-content-wrapper chat-icon-text-wrapper">
                                                              <div className="widget-content-left full-width">
                                                                  <div className="widget-heading">
                                                                      {roomProps
                                                                          .profile
                                                                          ?.avatarPath ? (
                                                                          <div
                                                                              onClick={(
                                                                                  e
                                                                              ) => {
                                                                                  props.openMemberProfileModal(
                                                                                      roomProps.userId
                                                                                  );
                                                                                  e.stopPropagation();
                                                                              }}
                                                                              className="table-avatar-icon">
                                                                              <span className="avatar-icon-wrapper">
                                                                                  <img
                                                                                      src={
                                                                                          roomProps
                                                                                              .profile
                                                                                              .avatarPath
                                                                                      }
                                                                                      className="poster-member-icon"
                                                                                  />
                                                                                  {roomProps.onlineState ? (
                                                                                      <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                                                                                  ) : (
                                                                                      <div className="badge badge-bottom btn-shine badge-secondary badge-dot badge-dot-lg" />
                                                                                  )}
                                                                              </span>
                                                                          </div>
                                                                      ) : (
                                                                          <div
                                                                              onClick={(
                                                                                  e
                                                                              ) => {
                                                                                  props.openMemberProfileModal(
                                                                                      roomProps.userId
                                                                                  );
                                                                                  e.stopPropagation();
                                                                              }}
                                                                              className="poster-member-icon avatar-icon-wrapper initials"
                                                                              style={{
                                                                                  background:
                                                                                      roomProps.backgroundColorCode,
                                                                              }}>
                                                                              <div
                                                                                  className="poster-member-initials"
                                                                                  style={{
                                                                                      color: roomProps.foregroundColorCode,
                                                                                  }}>
                                                                                  {
                                                                                      roomProps.initials
                                                                                  }
                                                                              </div>
                                                                              {roomProps.onlineState ? (
                                                                                  <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                                                                              ) : (
                                                                                  <div className="badge badge-bottom btn-shine badge-secondary badge-dot badge-dot-lg" />
                                                                              )}
                                                                          </div>
                                                                      )}

                                                                      <div className="room-name-wrapper">
                                                                          <div
                                                                              style={{
                                                                                  width: '100%',
                                                                              }}>
                                                                              <div
                                                                                  style={{
                                                                                      display:
                                                                                          'flex',
                                                                                  }}>
                                                                                  <span className="room-name">
                                                                                      {getOpponentName(
                                                                                          room,
                                                                                          roomProps
                                                                                      )}
                                                                                  </span>
                                                                              </div>
                                                                              {room.totalMessages ? (
                                                                                  <div className="room-info">
                                                                                      <span>
                                                                                          {`#
                                                                                      of
                                                                                      Messages:
                                                                                      ${room.totalMessages}`}
                                                                                      </span>
                                                                                      <span>
                                                                                          {`Last
                                                                                      Message:
                                                                                      ${moment(
                                                                                          room.lastMessageDate
                                                                                      ).format(
                                                                                          'M.DD.YYYY'
                                                                                      )}`}
                                                                                      </span>
                                                                                  </div>
                                                                              ) : (
                                                                                  []
                                                                              )}
                                                                          </div>
                                                                      </div>
                                                                      {numberOfNewMessages >
                                                                      0 ? (
                                                                          <div className="badge-wrapper">
                                                                              <Badge
                                                                                  className={
                                                                                      props
                                                                                          .selectedRoomState
                                                                                          .id ===
                                                                                          room.id &&
                                                                                      roomItem.roomType ===
                                                                                          props
                                                                                              .selectedRoomState
                                                                                              .type
                                                                                          ? 'active-new-messages-badge'
                                                                                          : 'inactive-new-messages-badge'
                                                                                  }>
                                                                                  {
                                                                                      numberOfNewMessages
                                                                                  }
                                                                              </Badge>
                                                                          </div>
                                                                      ) : (
                                                                          []
                                                                      )}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </DropdownItem>
                                              </UncontrolledDropdown>
                                          </NavItem>
                                      ) : (
                                          []
                                      );
                                  })
                            : []}
                    </Nav>
                </div>
            </div>
            {isCreateRoomModalVisible ? (
                <CreateRoomModal
                    isVisible={isCreateRoomModalVisible}
                    hide={() => dispatch(setIsCreateRoomModalVisible(false))}
                    createRoom={(userId) => createNewRoom(userId)}
                    members={props.members.map((m) => m.member)}
                    rooms={sortedRooms}
                    currentUserId={props.currentUserId}
                    openMemberProfileModal={(id) =>
                        props.openMemberProfileModal(id)
                    }></CreateRoomModal>
            ) : (
                []
            )}
        </>
    );
};

export default DirectRoomsList;
