import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import React from 'react';
import PortalTooltip from '../../PortalTooltip/PortalTooltip';

interface CustomRenderCell
    extends GridRenderCellParams<any, any, any, GridTreeNodeWithRender> {
    anchorTooltipId?: string;
    renderValue: string;
}

const CustomRenderCell = (props: CustomRenderCell) => {
    return (
        <>
            {props.value ? (
                <PortalTooltip
                    anchorId={props.anchorTooltipId}
                    delayShow={1000}
                    place="bottom"
                    content={props.renderValue}
                />
            ) : (
                []
            )}
            <div id={props.anchorTooltipId} className="MuiDataGrid-cellContent">
                {props.renderValue}
            </div>
        </>
    );
};
export default CustomRenderCell;
