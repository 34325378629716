import React, { useState, useEffect } from 'react';
import createNotification from '../../utils/createNotification';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import BootstrapTable from '../../components/react-bootstrap-table-next/react-bootstrap-table2';
import { TableHeader } from '../../components/TableHeader/TableHeader';
import { Col, Row, Card, CardBody } from 'reactstrap';
import paginationFactory from '../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import filterFactory from '../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import Loader from 'react-loaders';
import Layout from '../../components/Layout/index';
import moment from 'moment';
import AnnouncementModal from './Modal/AnnouncementModal';
import './AnnouncementsTable.scss';
import { HomeButton } from '../../components/HomeButton/home-button';
import { useStateSelector } from '../../store/selectors';

const AnnouncementsTable = () => {
    const [announcements, setAnnouncements] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tableSettings, setTableSettings] = useState({
        Filter: {
            Filters: [],
            Logic: '',
        },
        PageNumber: 1,
        PageSize: 10,
        Sort: {
            Dir: 'desc',
            Field: 'postedOn',
        },
    });
    const [page, setPage] = useState(0);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const axios = useStateSelector((state) => state.core.axios);
    const token = localStorage.getItem('token');
    const [isViewEditPopupVisible, setIsViewEditPopupVisible] = useState(false);
    const [popupMode, setPopupMode] = useState(null);
    const defaultAnnouncement = {
        title: '',
        description: '',
        isActive: false,
        modifiedOn: '',
        postedOn: moment(new Date()).toDate(),
    };
    const [selectedAnnouncement, setSelectedAnnouncement] =
        useState(defaultAnnouncement);
    const getColumns = () => {
        return [
            {
                dataField: 'title',
                text: 'Header',
                formatter: (cellContent) =>
                    cellContent == '' ? '-' : cellContent,
                sort: true,
            },
            {
                dataField: 'modifiedOn',
                text: 'Last Edit',
                sort: true,
                formatter: (cellContent) =>
                    !cellContent || cellContent == ''
                        ? ''
                        : moment(cellContent).format('MM/D/YYYY h:mma'),
            },
            {
                dataField: 'postedOn',
                text: 'Posted On',
                sort: true,
                formatter: (cellContent) =>
                    !cellContent || cellContent == ''
                        ? ''
                        : moment(cellContent).format('MM/D/YYYY h:mma'),
            },
            {
                dataField: 'isActive',
                text: 'Status',
                sort: true,
                formatter: (cellContent) => (cellContent ? 'Active' : 'Draft'),
            },
            {
                dataField: 'action',
                text: 'Action',
                style: {
                    width: '10em',
                },
                formatter: (_cellContent, row) => {
                    return (
                        <button
                            type="button"
                            className="btn btn-primary action_btn"
                            onClick={() => openViewEditPopup(row)}>
                            View / Edit
                        </button>
                    );
                },
            },
        ];
    };

    const defaultSorted = [
        {
            dataField: 'postedOn',
            order: 'desc',
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        fetchAnnouncements(tableSettings);
    }, []);

    const handleTableChange = (_type, newState) => {
        let sortField = newState.sortField;
        let sortOrder = newState.sortOrder;
        let page = newState.page;
        let sizePerPage = newState.sizePerPage;

        let fetchObj = {
            Filter: {
                Filters: [],
                Logic: '',
            },
            PageNumber: page,
            PageSize: sizePerPage,
            Sort: {
                Dir: sortOrder,
                Field: sortField,
            },
        };

        setTableSettings(fetchObj);
        fetchAnnouncements(fetchObj);
    };

    const fetchAnnouncements = (fetchObj) => {
        return axios
            .post('api/Announcements/admin', fetchObj, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.isError || !response.data || !response.data.data) {
                    createNotification(
                        'Something went wrong, could not load your Dashboard. Try signing in again.',
                        'error'
                    );
                    return;
                }
                const announcementsData = response.data.data;
                setAnnouncements(announcementsData.list);
                setPage(announcementsData.pageNumber);
                setTotalSize(announcementsData.totalItems);
                setSizePerPage(announcementsData.pageSize);
            })
            .catch(() => {
                createNotification(
                    'Something went wrong, could not load your Dashboard. Try signing in again.',
                    'error'
                );
            })
            .finally(() => setIsLoading(false));
    };

    const openViewEditPopup = (row) => {
        setSelectedAnnouncement(row);
        setPopupMode('view');
        setIsViewEditPopupVisible(true);
    };

    const createAnnouncement = () => {
        setPopupMode('create');
        setIsViewEditPopupVisible(true);
    };

    const buttons = [
        {
            buttonText: 'Create New Announcement',
            onClick: createAnnouncement,
        },
    ];

    const renderLoader = () => {
        return (
            <div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading your request...</h6>
                </div>
            </div>
        );
    };

    const resetDialog = () => {
        setIsViewEditPopupVisible(false);
        setSelectedAnnouncement(defaultAnnouncement);
        setPopupMode('');
    };

    return (
        <>
            {isLoading ? (
                renderLoader()
            ) : (
                <Layout>
                    <div className="associate-members-table">
                        <div className="page-title">
                            <h3>Announcements Manager</h3>
                        </div>

                        <Breadcrumb>
                            <BreadcrumbItem>
                                <HomeButton></HomeButton>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Announcements Manager
                            </BreadcrumbItem>
                        </Breadcrumb>

                        <div className="body_white_box associate_member">
                            <div className="body_header_title">
                                <h5>
                                    Announcements
                                    <TableHeader
                                        hasToolbar={true}
                                        buttons={buttons}></TableHeader>
                                </h5>
                            </div>
                            <Row className="associate_data">
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <div>
                                                <BootstrapTable
                                                    bootstrap4
                                                    remote
                                                    keyField="id"
                                                    data={announcements}
                                                    columns={getColumns()}
                                                    defaultSorted={
                                                        defaultSorted
                                                    }
                                                    filter={filterFactory()}
                                                    pagination={paginationFactory(
                                                        {
                                                            page,
                                                            sizePerPage,
                                                            totalSize,
                                                        }
                                                    )}
                                                    onTableChange={
                                                        handleTableChange
                                                    }
                                                    noDataIndication="No Announcements available"
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {isViewEditPopupVisible && (
                                <AnnouncementModal
                                    isVisible={isViewEditPopupVisible}
                                    setIsVisible={setIsViewEditPopupVisible}
                                    dialogMode={popupMode}
                                    setDialogMode={setPopupMode}
                                    refreshTable={() => {
                                        fetchAnnouncements(tableSettings);
                                    }}
                                    resetDialog={resetDialog}
                                    setData={setSelectedAnnouncement}
                                    data={selectedAnnouncement}
                                    token={token}></AnnouncementModal>
                            )}
                        </div>
                    </div>
                </Layout>
            )}
        </>
    );
};
export default AnnouncementsTable;
