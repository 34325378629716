import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Label, Form, FormGroup, Col } from 'reactstrap';
import Helpers from '../../../../../utils/helper';
import { CorporateGroup } from '../../../../CorporateGroupManagement/Model/CorporateGroup';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import './UserDetailsModal.scss';
import { CorporateGroupMember } from '../../../../CorporateGroupManagement/Model/CorporateGroupMember';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { PRACTICE_OWNERSHIP_TYPES } from '../../../../../utils/constants/PracticeOwnerType.enum';

interface UserBasicDetailsProps {
    userData: UserBasicDetailsData;
    onMemberAfiliationUpdate?: () => void;
    renderLoader: () => void;
}

export interface UserBasicDetailsData {
    id: number;
    name: string;
    invitedByUsers: string[];
    email: string;
    phone: string;
    accountCreationDate: Date;
    lastLoginDate: Date;
    rolesList: string[];
    practiceOwnerType?: number;
    corporateAffiliation?: number;
}

const UserBasicDetails = (props: UserBasicDetailsProps) => {
    const { userData } = props;
    const axios = useStateSelector((state) => state.core.axios);
    const [isUserDataLoading, setIsUserDataLoading] = useState(true);
    const [groups, setGroups] = useState<CorporateGroup[]>([]);
    const [currentGroupName, setCurrentGroupName] = useState('');
    const [changedCorpGroupId, setChangedCorpGroupId] = useState(0);
    const [memberData, setMemberData] = useState<CorporateGroupMember>();
    const [confirmationHeader, setConfirmationHeader] = useState('');
    const [confirmationText, setConfirmationText] = useState('');
    const [action, setAction] = useState<(() => void) | null>(null);
    const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
        useState(false);
        
    const newGroup: CorporateGroup = {
        id: 0,
        name: 'NA',
        membersCount: 0,
    };

    useEffect(() => {
        getMemberByUserId(userData.id);
        fetchGroups(() => {
            const currentGroupId = userData.corporateAffiliation ?? 0;
            setChangedCorpGroupId(currentGroupId);
            setIsUserDataLoading(false);
        });
    }, []);

    const fetchGroups = (finaly: () => void = undefined) => {
        axios
            .get('/api/CorporateGroups/groups')
            .then((response: any) => {
                if (response && response.data) {
                    setGroups([newGroup, ...response.data]);
                } else {
                    createNotification(
                        'An unexpected error occurred while loading Corporate Groups.',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while loading Corporate Groups.',
                    'error'
                );
            })
            .finally(() => {
                if (finaly) finaly();
            });
    };

    const getMemberByUserId = (userId: number) => {
        axios.get(`/api/CorporateGroups/member/${userId}`).then((response) => {
            if (response.data) {
                setMemberData(response.data);
            }
        });
    };

    const addMember = (newGroupId: number) => {
        axios
            .post('api/CorporateGroups/member', {
                userId: userData.id,
                FullName: userData.name,
                groupId: newGroupId,
            })
            .then(() => {
                setChangedCorpGroupId(newGroupId);
                props.onMemberAfiliationUpdate?.();
                createNotification(
                    'User added to the group successfully',
                    'success'
                );
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while adding user to the group',
                    'error'
                );
            });
    };

    const updateMember = (newGroupId: number) => {
        axios
            .put('api/CorporateGroups/member/group', {
                id: memberData.id,
                userId: userData.id,
                FullName: userData.name,
                groupId: newGroupId,
            })
            .then(() => {
                if (!newGroupId) {
                    setChangedCorpGroupId(0);
                } else {
                    setChangedCorpGroupId(newGroupId);
                }
                createNotification(
                    'Corporate group changed successfully',
                    'success'
                );
                props.onMemberAfiliationUpdate?.();
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while changing groups.',
                    'error'
                );
            });
    };

    const handleGroupChange = (
        newGroupId: number,
        newGroupName: string,
        currentGroupName: string
    ) => {
        if (newGroupId !== changedCorpGroupId) {
            if (userData.corporateAffiliation) {
                if (newGroupId == 0) {
                    setConfirmationHeader('Delete member');
                    setConfirmationText(
                        `Are you sure you want to delete ${userData.name} from ${currentGroupName}?`
                    );
                    setAction(() => () => updateMember(null));
                } else {
                    setConfirmationHeader('Change corporate group');
                    setConfirmationText(
                        `Are you sure you want to change ${currentGroupName} to ${newGroupName} for ${userData.name}?`
                    );
                    setAction(() => () => updateMember(newGroupId));
                }
            } else {
                setConfirmationHeader('Add member');
                setConfirmationText(
                    `Are you sure you want to add ${userData.name} to ${newGroupName} ?`
                );
                setAction(() => () => addMember(newGroupId));
            }
            setIsConfirmationDialogVisible(true);
        }
    };

    return isUserDataLoading ? (
        props.renderLoader
    ) : (
        <Form>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Name:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={8}>
                        {userData.name}
                    </Label>
                </Col>
            </FormGroup>
            {userData.invitedByUsers && userData.invitedByUsers.length > 0 ? (
                <FormGroup row>
                    <Label for="name" sm={4}>
                        Invited by:
                    </Label>
                    <Col sm={8}>
                        <Label for="invitedByUser" sm={8}>
                            <Label>{userData.invitedByUsers.join(', ')}</Label>
                        </Label>
                    </Col>
                </FormGroup>
            ) : (
                ''
            )}
            <FormGroup row>
                <Label for="name" sm={4}>
                    Email:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {userData.email}
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Phone:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {Helpers.getFormattedPhoneNumberWithoutPrefix(
                            userData.phone
                        )}
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Registered date:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {userData.accountCreationDate
                            ? moment(userData.accountCreationDate).format(
                                  'MM/D/YYYY h:mma'
                              )
                            : ''}
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Last login date:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {userData.lastLoginDate
                            ? moment(userData.lastLoginDate).format('L')
                            : ''}
                    </Label>
                </Col>
            </FormGroup>
            {userData.practiceOwnerType ? (
                <FormGroup row>
                    <Label for="name" sm={4}>
                        Corporate Affiliation:
                    </Label>
                    {userData.practiceOwnerType !=
                    PRACTICE_OWNERSHIP_TYPES.INDEPENDENT ? (
                        <Col sm={8}>
                            <div className="customerStyle">
                                <select
                                    className="selectCustomer"
                                    value={changedCorpGroupId}
                                    onChange={(e) => {
                                        const selectedId = Number(
                                            e.target.value
                                        );
                                        const selectedGroup = groups.find(
                                            (opt) => opt.id === selectedId
                                        );
                                        if (selectedGroup) {
                                            handleGroupChange(
                                                selectedId,
                                                selectedGroup.name,
                                                currentGroupName
                                            );
                                        }
                                    }}
                                    onFocus={() => {
                                        const currentGroup = groups.find(
                                            (opt) =>
                                                opt.id === changedCorpGroupId
                                        );
                                        if (currentGroup) {
                                            setCurrentGroupName(
                                                currentGroup.name
                                            );
                                        }
                                    }}>
                                    {groups.map((opt) => (
                                        <option value={opt.id}>
                                            {opt.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                    ) : (
                        <Col sm={8}>
                            <Label for="name" sm={2}>
                                NA
                            </Label>
                        </Col>
                    )}
                </FormGroup>
            ) : (
                <></>
            )}
            {userData?.rolesList?.length > 1 ? (
                <FormGroup row>
                    <Label for="name" sm={4}>
                        {'Role(s):'}
                    </Label>
                    <Col sm={8}>
                        <Label for="name" sm={2}>
                            {userData.rolesList.join(', ')}
                        </Label>
                    </Col>
                </FormGroup>
            ) : (
                []
            )}
            {isConfirmationDialogVisible ? (
                <ConfirmationModal
                    isVisible={true}
                    setIsVisible={setIsConfirmationDialogVisible}
                    header={confirmationHeader}
                    confirmationText={confirmationText}
                    onConfirm={() => {
                        action();
                    }}
                    params={''}
                    nextButtonText={'Yes'}
                    cancelButtonText={'Cancel'}
                    closeOnConfirm={true}
                />
            ) : (
                []
            )}
        </Form>
    );
};

export default UserBasicDetails;
