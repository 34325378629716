import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface SendMessageThunkArg {
    connection: HubConnection;
    clubhouseId: number;
    messageText: string;
    selectedDiscussionId: number;
    attachmentsIds: Array<number>;
    sharedContentIds: Array<number>;
}

export const sendMessage = createAsyncThunk(
    'clubhouseMessages/send',
    async (arg: SendMessageThunkArg) => {
        let chatMessage = {
            clubhouseId: arg.clubhouseId,
            discussionId: arg.selectedDiscussionId,
            htmlContent: arg.messageText,
            attachmentsIds: arg.attachmentsIds,
            sharedContentIds: arg.sharedContentIds,
        };
        await arg.connection.send('SendMessage', chatMessage);
    }
);
