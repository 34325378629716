import React, { useState } from 'react';
import './DataFormStageNameEditor.scss';
import { Input } from 'reactstrap';
import { useStateSelector } from '../../../../store/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk,
    faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import createNotification from '../../../../utils/createNotification';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface DataFormStageNameEditorProps {
    updateUrl: string;
    stageName: string;
    isEditable: boolean;
    onStageNameClick(): void;
    isTemplateEditorView: boolean;
    onSuccessfulUpdate(): void;
    enableLinkText: boolean;
    isNameClickable: boolean;
}

export const DataFormStageNameEditor = (
    props: DataFormStageNameEditorProps
) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [editorState, setEditorState] = useState<'view' | 'edit'>('view');
    const [stageName, setStageName] = useState(props.stageName);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const updateStageName = () => {
        setIsLoading(true);
        const newStageName = stageName.trim();
        const params = {
            name: newStageName,
        };
        axios
            .put(props.updateUrl, params)
            .then((response) => {
                if (response.status === 200) {
                    setStageName(newStageName);
                    props.onSuccessfulUpdate();
                    setEditorState('view');
                } else {
                    createNotification(
                        'An error occured while updating Project Name',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const validateStageName = (name: string) => {
        const hasError = name.trim().length <= 0;
        setHasError(hasError);
    };

    const renderEditor = () => (
        <div className="data-form-stage-name-editor">
            <Input
                invalid={hasError}
                disabled={isLoading}
                value={stageName}
                onChange={(event) => {
                    setStageName(event.target.value);
                    validateStageName(event.target.value);
                }}></Input>
            <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                onClick={() => {
                    if (!isLoading) {
                        setStageName(props.stageName);
                        setHasError(false);
                        setEditorState('view');
                    }
                }}
            />

            <FontAwesomeIcon
                icon={faFloppyDisk as any}
                size="lg"
                onClick={() => {
                    if (!isLoading && !hasError) {
                        updateStageName();
                    }
                }}
            />
        </div>
    );

    const render = () => {
        switch (editorState) {
            case 'edit':
                return renderEditor();
            default:
                return (
                    <div className="data-form-stage-name-view">
                        {props.isNameClickable ? (
                            <div
                                className={'link-button'}
                                onClick={() => props.onStageNameClick()}>
                                {props.stageName}
                            </div>
                        ) : (
                            <div>{props.stageName}</div>
                        )}

                        {props.isEditable && (
                            <FontAwesomeIcon
                                size="lg"
                                icon={faPenToSquare as any}
                                onClick={() => setEditorState('edit')}
                            />
                        )}
                    </div>
                );
        }
    };

    return <div className="data-form-stage-name-editor">{render()}</div>;
};
