import React, { useEffect, useRef, useState } from 'react';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import './DateAnswerField.scss';
import { TimeZone, TimeZoneHelper } from '../../../../utils/timeZoneHelper';
import ReactDatePicker from 'react-datepicker';
import { InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

export interface DateAnswerFieldProps {
    question: DataFormQuestion;
    isAdminView: boolean;
    isReadonly: boolean;
    updateDateAnswer(questionId: number, value: string): void;
    updateNotification(questionId: number): void;
}

export const DateAnswerField = (props: DateAnswerFieldProps) => {
    const [answer, setAnswer] = useState<Date>(null);
    useEffect(() => {
        const answerText = props.question.answer?.answerText;
        const utcDate = new Date(answerText);
        const date = new Date(
            utcDate.getUTCFullYear(),
            utcDate.getUTCMonth(),
            utcDate.getUTCDate()
        );

        TimeZoneHelper.isDateValid(date) ? setAnswer(date) : setAnswer(null);
    }, [props.question.answer?.answerText]);
    const datePickerRef = useRef(null);
    const [timeZone] = useState<TimeZone>(
        TimeZoneHelper.getTimeZoneByDate(new Date())
    );
    const isAnswerChanged = (currentAnswer: string) => {
        return props.question.answer?.answerText != currentAnswer;
    };

    const onChange = (date: Date) => {
        props.updateNotification(props.question.id);
        if (isAnswerChanged(date.toString())) {
            const valueWithTImeZoneOffset = TimeZoneHelper.parseUtcDate(
                new Date(date),
                timeZone
            );

            setAnswer(valueWithTImeZoneOffset);
            props.updateDateAnswer(
                props.question.id,
                valueWithTImeZoneOffset.toISOString()
            );
        }
    };

    return (
        <div className="date-answer-field date-field">
            <div
                className="date-picker-container"
                onClick={() => {
                    if (!props.isAdminView && !props.isReadonly) {
                        datePickerRef.current.setOpen(true);
                    }
                }}
                aria-disabled={props.isAdminView || props.isReadonly}>
                <div className="d-flex align-items-center small-border">
                    <ReactDatePicker
                        ref={datePickerRef}
                        selected={answer}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        portalId="questionnaire-picker-root-portal"
                        showYearDropdown
                        showMonthDropdown
                        todayButton={<>Today</>}
                        dateFormat="MM/dd/yyyy"
                        onChange={(date: any) => {
                            onChange(date);
                        }}
                        disabled={props.isAdminView || props.isReadonly}
                    />
                    <InputGroupAddon addonType="prepend">
                        <div className="input-group-text">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                    </InputGroupAddon>
                </div>
            </div>
        </div>
    );
};
