import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { initializaeSignalRConnection } from './initializaeSignalRConnection';

const useSignalR = (targetHubUrl: string) => {
    const [connection, setConnection] = useState<signalR.HubConnection>(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const newConnection = initializaeSignalRConnection(targetHubUrl);

        setConnection(newConnection);

        const startConnection = async () => {
            try {
                await newConnection.start();
                console.log(`SignalR Connected. Target url: ${targetHubUrl}`);
                setIsConnected(true);
            } catch (err) {
                console.error('Error establishing SignalR connection:', err);
            }
        };

        startConnection();

        return () => {
            newConnection?.stop();
        };
    }, [targetHubUrl]);

    return { connection, isConnected };
};

export default useSignalR;
