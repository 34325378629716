import React, { useEffect, useState } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Currency from '../Currency/Currency';
import Rodal from '../Rodal/Rodal';
import Loader from 'react-loaders';
import './PaymentDialog.scss';
import createNotification from '../../utils/createNotification';
import {
    PaymentMethod,
    PaymentMethodResult,
    Stripe,
    StripeElements,
} from '@stripe/stripe-js';
import InfoTooltip from '../info-tooltip/info-tooltip';
import { CurrencyInput, Form } from '@availity/form';
import Helpers from '../../utils/helper';
import CheckoutInfo from './CheckoutForm/CheckoutInfo';
import PoweredByStripe from '../../assets/images/poweredByStripe.png';
import GroupRebateInfo from './GroupRebateInfo/GroupRebateInfo';
import CardPaymentForm from '../CardPaymentForm/CardPaymentForm';
import { Discount } from '../../models/Discount/Discount';
import { useStateSelector } from '../../store/selectors';
import { USER_ROLES } from '../../utils/constants';

export interface PriceItem {
    priceType: 'regular' | 'summary' | 'spacer';
    label: string;
    tooltipText?: string;
    value?: number;
    valueType?: 'currency' | 'percent';
}
export interface PaymentDialogProps {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    reloadData?: () => void;
    stripe: Stripe;
    elements: StripeElements;
    priceList: PriceItem[];
    originalServicePrice: number;
    servicePrice: number;
    totalProductPrice: number;
    apliedCredits: number;
    setAppliedCredits: (appliedCredits: number) => void;
    directPayment?: () => void;
    chargeRequest: (
        shouldUseSavedPaymentMethod: boolean,
        source: string
    ) => void;
    isParrentLoading: boolean;
    isPaymentInProcess: boolean;
    groupOrderRebatePercent?: number;
    discountList?: Discount[];
    isAuthorizationRequired: boolean;
    checkoutHeaderTooltipText?: string;
    netPaymentLable?: string;
    disableStoreCredits?: boolean;
    disableFlexCredits?: boolean;
    disableRewardCredits?: boolean;
}

export const PaymentDialog = (props: PaymentDialogProps) => {
    const [isCardExist, setIsCardExist] = useState(false);
    const [isUseDifferentMethodChecked, setIsUseDifferentMethodChecked] =
        useState(true);
    const [isLoading, setIsLoading] = useState(null);
    const [amountFromUserWallet, setAmountFromUserWallet] = useState('');
    const [shouldSavePaymentMethod, setShouldSavePaymentMethod] =
        useState(false);
    const [storeCredits, setStoreCredits] = useState(0);
    const [flexCredits, setFlexCredits] = useState(0);
    const [rewardCredits, setRewardCredits] = useState(0);
    const [vvCretids, setVvCredits] = useState(0);
    const [savedCard, setSavedCard] = useState(null);
    const axios = useStateSelector((state) => {
        return state.core.axios;
    });
    const [isCardSelected, setIsCardSelected] = useState(true);

    const walletTooltipText =
        'Store Credits will always be applied before Reward Credits for VV Purchases.';

    const [isFormValid, setIsFormValid] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [modalType, setModalType] = useState<'ref' | 'card'>('ref');
    const summary = useStateSelector((s) => s.userSummary.summary);

    useEffect(() => {
        setIsLoading(false);
        setAmountFromUserWallet('0');

        GetSavedCard();
    }, []);

    useEffect(() => {
        if (props.isVisible) {
            setIsDataLoading(true);
            axios
                .get('/api/Wallet')
                .then((response: any) => {
                    const walletResponse = response.data;

                    if (response.status === 200) {
                        setStoreCredits(walletResponse.storeCredits);
                        setFlexCredits(walletResponse.flexCredits);
                        setRewardCredits(walletResponse.rewardCredits);
                        setVvCredits(
                            (!!props.disableRewardCredits
                                ? 0
                                : walletResponse.rewardCredits) +
                                (!!props.disableFlexCredits
                                    ? 0
                                    : walletResponse.flexCredits) +
                                (!!props.disableStoreCredits
                                    ? 0
                                    : walletResponse.storeCredits)
                        );
                    } else {
                        createNotification(
                            'Could not fetch Wallet balance at this time. Please try again later',
                            'error'
                        );
                    }
                })
                .finally(() => {
                    setIsDataLoading(false);
                });
        }
    }, []);

    const onAmountChanged = (value: string) => {
        const vvCretidsLimit = Math.min(props.servicePrice, vvCretids);
        const valueNumber = Number(value);
        if (valueNumber > vvCretidsLimit) {
            setAmountFromUserWallet(vvCretidsLimit.toFixed(2));
            props.setAppliedCredits(vvCretidsLimit);
        } else {
            setAmountFromUserWallet(value ? value : '0');
            props.setAppliedCredits(
                Number.isNaN(valueNumber) ? 0 : valueNumber
            );
        }
    };

    const GetSavedCard = () => {
        axios.get('api/Wallet/PaymentMethod').then((response: any) => {
            if (response.data && response.data.lastDigits) {
                setSavedCard(response.data);
                setIsCardExist(true);
                setIsUseDifferentMethodChecked(false);
                setIsCardSelected(true);
            }
        });
    };

    const proceedToPayment = () => {
        setModalType('card');
    };

    const createStripePaymentMethod = () =>
        props.stripe.createPaymentMethod({
            type: 'card',
            card: props.elements.getElement('cardNumber'),
        });

    const proccedChargeWithPaymentMethod = () => {
        createStripePaymentMethod()
            .then((result: PaymentMethodResult) => {
                if (result?.paymentMethod) {
                    if (shouldSavePaymentMethod) {
                        savePaymentMethod(
                            result.paymentMethod,
                            shouldSavePaymentMethod && !!savedCard,
                            () =>
                                props.chargeRequest(
                                    shouldSavePaymentMethod,
                                    result.paymentMethod.id
                                )
                        );
                    } else {
                        props.chargeRequest(false, result.paymentMethod.id);
                    }
                } else if (result.error && result.error.message) {
                    createNotification(result.error.message, 'error');
                    setIsLoading(false);
                }
            })
            .catch(() => {
                createNotification(
                    'Something went wrong during card validation.',
                    'error'
                );
                setIsLoading(false);
            });
    };

    const proceedChargeWithPaymentToken = () => {
        props.stripe
            .createToken(props.elements.getElement('cardNumber'))
            .then((tokenObj: any) => {
                if (shouldSavePaymentMethod) {
                    createStripePaymentMethod().then((result) => {
                        if (result?.paymentMethod) {
                            savePaymentMethod(
                                result.paymentMethod,
                                shouldSavePaymentMethod && !!savedCard,
                                () =>
                                    props.chargeRequest(
                                        shouldSavePaymentMethod,
                                        tokenObj.token.id
                                    )
                            );
                        } else if (result.error && result.error.message) {
                            createNotification(result.error.message, 'error');
                            setIsLoading(false);
                        }
                    });
                } else {
                    props.chargeRequest(false, tokenObj.token.id);
                }
            })
            .catch(() => {
                setIsLoading(false);
                createNotification(
                    'Something went wrong during validation.',
                    'error'
                );
            });
    };

    const savePaymentMethod = (
        paymentMethod: PaymentMethod,
        shouldUpdatePaymentMethod: boolean,
        onSuccess: () => void
    ) => {
        return axios
            .post('api/Wallet/PaymentMethod', {
                paymentMethodId: paymentMethod.id,
                shouldUpdatePaymentMethod: shouldUpdatePaymentMethod,
            })
            .then((response: any) => {
                if (response?.status != 200) {
                    createNotification(
                        response?.response?.data
                            ? response.response.data
                            : 'Something went wrong, could not save payment method.',
                        'error'
                    );
                    setIsLoading(false);
                } else {
                    onSuccess();
                }
            })
            .catch(() => {
                setIsLoading(false);
                createNotification(
                    'Something went wrong, could not save payment method.',
                    'error'
                );
            });
    };

    const charge = () => {
        setIsLoading(true);
        if (isUseDifferentMethodChecked) {
            if (props.isAuthorizationRequired) {
                proccedChargeWithPaymentMethod();
            } else {
                proceedChargeWithPaymentToken();
            }
        } else {
            props.chargeRequest(true, '');
        }
    };

    const submit = () => {
        charge();
    };

    return modalType === 'ref' ? (
        <div className="payment-dialog">
            <Rodal
                visible={props.isVisible}
                onClose={() => {
                    props.setIsVisible(false);
                }}
                className="checkout-modal"
                width={600}
                showMask={false}>
                <ModalHeader>
                    <div className="payment-dialog-header">
                        Checkout{' '}
                        {props.checkoutHeaderTooltipText ? (
                            <InfoTooltip
                                place="bottom"
                                text={props.checkoutHeaderTooltipText}
                                idText="checkout-header-tooltip"
                            />
                        ) : (
                            []
                        )}
                    </div>
                </ModalHeader>
                {isDataLoading || props.isParrentLoading ? (
                    Helpers.renderTableLoader()
                ) : (
                    <>
                        <ModalBody>
                            {summary.roleId !==
                            USER_ROLES.PRACTICE_SECONDARY_USER ? (
                                <>
                                    <CheckoutInfo
                                        discountList={props.discountList}
                                        priceList={props.priceList}
                                        storeCredits={
                                            !!props.disableStoreCredits
                                                ? undefined
                                                : storeCredits
                                        }
                                        flexCredits={
                                            !!props.disableFlexCredits
                                                ? undefined
                                                : flexCredits
                                        }
                                        rewardCredits={
                                            !!props.disableRewardCredits
                                                ? undefined
                                                : rewardCredits
                                        }></CheckoutInfo>

                                    <Row className="highlight-row">
                                        <div
                                            style={{
                                                width: '100%',
                                                padding: '16px',
                                            }}>
                                            <div className="amount-input-row">
                                                <span className="wallet-text-ref">
                                                    <div>
                                                        Apply VV Credits:{' '}
                                                    </div>
                                                    <InfoTooltip
                                                        text={walletTooltipText}
                                                        idText="want-use-credits"></InfoTooltip>
                                                </span>
                                                <Form
                                                    onSubmit={() => {}}
                                                    initialValues={{
                                                        applyCredits: '0',
                                                    }}>
                                                    <CurrencyInput
                                                        name="applyCredits"
                                                        value={
                                                            amountFromUserWallet
                                                        }
                                                        onValueChanged={(
                                                            value
                                                        ) =>
                                                            onAmountChanged(
                                                                value
                                                            )
                                                        }
                                                        allowDecimals={true}
                                                        allowNegativeValue={
                                                            false
                                                        }
                                                        decimalsLimit={2}
                                                        min={0}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                    </Row>
                                </>
                            ) : (
                                []
                            )}
                            <Row>
                                <div className="payment-footer">
                                    <h6>
                                        {props.netPaymentLable
                                            ? props.netPaymentLable
                                            : 'Net payment due:'}{' '}
                                        <span style={{ color: '#535bd6' }}>
                                            <Currency
                                                quantity={
                                                    props.totalProductPrice
                                                }
                                                currency="USD"
                                            />
                                        </span>
                                    </h6>

                                    <div className="checkbox-buttons">
                                        <Button
                                            className="mb-2 mr-2 close-button btn-secondary"
                                            onClick={() => {
                                                setAmountFromUserWallet('0');
                                                props.setIsVisible(false);
                                                setModalType('ref');
                                            }}
                                            disabled={
                                                isLoading ||
                                                props.isPaymentInProcess
                                            }>
                                            Cancel
                                        </Button>
                                        <Button
                                            className="mb-2 mr-2 btn-primary"
                                            color="info"
                                            onClick={() =>
                                                props.totalProductPrice === 0 &&
                                                props.directPayment &&
                                                !props.isAuthorizationRequired
                                                    ? props.directPayment()
                                                    : proceedToPayment()
                                            }
                                            disabled={
                                                isLoading ||
                                                props.totalProductPrice < 0 ||
                                                props.isPaymentInProcess
                                            }>
                                            <i className="pe-7s-lock btn-icon-wrapper">
                                                {' '}
                                            </i>
                                            {props.isAuthorizationRequired ? (
                                                <>Authorize Payment Method</>
                                            ) : props.totalProductPrice == 0 ? (
                                                <> Place your order</>
                                            ) : (
                                                <>
                                                    Pay{' '}
                                                    <b>
                                                        <Currency
                                                            quantity={
                                                                props.totalProductPrice
                                                            }
                                                            currency="USD"
                                                        />
                                                    </b>
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                            <div className="default-div-image">
                                <img
                                    src={PoweredByStripe}
                                    alt={'userImage'}
                                    className="default-image"
                                />
                            </div>
                            {props.groupOrderRebatePercent &&
                            summary.roleId !==
                                USER_ROLES.PRACTICE_SECONDARY_USER ? (
                                <GroupRebateInfo
                                    groupRebatePercent={
                                        props.groupOrderRebatePercent
                                    }
                                    price={
                                        props.originalServicePrice
                                    }></GroupRebateInfo>
                            ) : (
                                []
                            )}
                        </ModalBody>
                        {isLoading || props.isPaymentInProcess ? (
                            <div
                                className="loader-container"
                                style={{ height: '100%', width: '100%' }}>
                                <div className="loader-container-inner">
                                    <br />
                                    <div className="text-center">
                                        <Loader
                                            active={isLoading}
                                            type="ball-scale-multiple"
                                        />
                                    </div>
                                    <h6 className="mt-5">
                                        Processing your payment...
                                    </h6>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                )}
            </Rodal>
        </div>
    ) : (
        <div className="payment-dialog">
            <Rodal
                visible={props.isVisible}
                onClose={() => {
                    setAmountFromUserWallet('0');
                    props.setIsVisible(false);
                }}
                className="checkout-modal"
                width={500}
                showMask={false}
                showCloseButton={!isLoading}>
                <ModalHeader>
                    <div className="payment-dialog-header">
                        {false ? 'Estimated Cost:' : 'Payment Due:'}
                    </div>
                    <b>
                        <Currency
                            quantity={props.totalProductPrice}
                            currency="USD"
                        />
                    </b>
                </ModalHeader>
                <CardPaymentForm
                    doesCardExist={isCardExist}
                    setIsUseDifferentMethodChecked={
                        setIsUseDifferentMethodChecked
                    }
                    setIsCardSelected={setIsCardSelected}
                    isUseDifferentMethodChecked={isUseDifferentMethodChecked}
                    isCardSelected={isCardSelected}
                    savedCard={savedCard}
                    shouldSavePaymentMethod={shouldSavePaymentMethod}
                    setShouldSavePaymentMethod={setShouldSavePaymentMethod}
                    setIsFormValid={setIsFormValid}></CardPaymentForm>
                {isLoading ? (
                    <div
                        className="loader-container"
                        style={{ height: '100%', width: '100%' }}>
                        <div className="loader-container-inner">
                            <br />
                            <div className="text-center">
                                <Loader
                                    active={isLoading}
                                    type="ball-scale-multiple"
                                />
                            </div>
                            <h6 className="mt-5">Processing your payment...</h6>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <ModalFooter>
                    <Button
                        className="mb-2 mr-2 btn-icon close-button btn-secondary"
                        onClick={() => {
                            setAmountFromUserWallet('0');
                            props.setIsVisible(false);
                            setModalType('ref');
                        }}
                        disabled={isLoading || props.isPaymentInProcess}>
                        Cancel
                    </Button>
                    <Button
                        className="mb-2 btn-primary"
                        color="info"
                        onClick={submit}
                        disabled={
                            isLoading ||
                            !isFormValid ||
                            props.isPaymentInProcess
                        }>
                        <i className="pe-7s-lock btn-icon-wrapper"> </i>
                        {props.isAuthorizationRequired ? (
                            <>Authorize Payment Method</>
                        ) : (
                            <span>Place your order</span>
                        )}
                    </Button>
                </ModalFooter>
            </Rodal>
        </div>
    );
};
