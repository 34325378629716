import React, { Fragment } from 'react';
import { jwtDecode } from 'jwt-decode';
import { connect } from 'react-redux';
import { SET_AXIOS } from '../../../../actions/actions';
import {
    DropdownToggle,
    DropdownMenu,
    Button,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import { toast, Bounce } from 'react-toastify';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import city3 from '../../../../assets/utils/images/dropdown-header/city3.jpg';
import AuthContext from '../../../../context/index';
import Avatar, { ConfigProvider } from 'react-avatar';
import { PracticeSwitcher } from './PracticeSwitcher';
import { USER_ROLE_NAMES } from '../../../../utils/constants';
import Helpers from '../../../../utils/helper';
import { gtmEvent } from '../../../Tracking/index';
import { TokenHelper } from '../../../../utils/tokenHelper';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            isAdmin: true,
        };
    }
    static contextType = AuthContext;
    userGroupPropertyName = 'custom:user_group';

    logout = () => {
        gtmEvent('click');
        this.props.router.navigate('/login');
        TokenHelper.clearLocalStorage(true);
        this.context.setToken('');
        this.context.setRefreshToken('');
        this.context.setExpiresIn('');
    };

    notify2 = () =>
        (this.toastId = toast(
            "You don't have any new items in your calendar for today! Go out and play!",
            {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success',
                icon: false,
            }
        ));

    componentDidMount() {
        let token = localStorage.getItem('token');
        if (token) {
            let decodedToken = jwtDecode(token);
            if (decodedToken) {
                if (
                    decodedToken[this.userGroupPropertyName] &&
                    decodedToken[this.userGroupPropertyName] === '1'
                ) {
                    this.setState({ isAdmin: true });
                } else {
                    this.setState({ isAdmin: false });
                }
            }
        }
    }

    render() {
        const firstName = localStorage.getItem('name');
        const loggedInUserSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        const roleName = USER_ROLE_NAMES[loggedInUserSummary.roleId];
        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                        color="link"
                                        className="p-0">
                                        <ConfigProvider
                                            colors={[
                                                '#d73d32',
                                                '#7e3794',
                                                '#4285f4',
                                                '#67ae3f',
                                                '#d61a7f',
                                                '#ff4080',
                                            ]}>
                                            <Avatar
                                                size={42}
                                                name={
                                                    firstName ? firstName : ''
                                                }
                                                round={true}
                                            />
                                        </ConfigProvider>
                                        <FontAwesomeIcon
                                            className="ml-2 opacity-8"
                                            icon={faAngleDown}
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu
                                        right
                                        className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-info">
                                                <div
                                                    className="menu-header-image opacity-2"
                                                    style={{
                                                        backgroundImage:
                                                            'url(' +
                                                            city3 +
                                                            ')',
                                                    }}
                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <Avatar
                                                                    color={Avatar.getRandomColor(
                                                                        'sitebase',
                                                                        [
                                                                            'red',
                                                                            'green',
                                                                            'blue',
                                                                        ]
                                                                    )}
                                                                    size={40}
                                                                    name={
                                                                        firstName
                                                                            ? firstName
                                                                            : ''
                                                                    }
                                                                    round={true}
                                                                />
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">
                                                                    {firstName
                                                                        ? firstName
                                                                        : ''}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginTop:
                                                                            '20px',
                                                                    }}
                                                                    className="widget-heading">
                                                                    {roleName}
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .logout
                                                                    }
                                                                    className="btn-pill btn-shadow btn-shine"
                                                                    color="focus">
                                                                    Logout
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!this.state.isAdmin && (
                                            <PracticeSwitcher></PracticeSwitcher>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetAxios: () => dispatch({ type: SET_AXIOS, payload: {} }),
});

export default Helpers.withRouter(connect(null, mapDispatchToProps)(UserBox));
