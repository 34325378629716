import React from 'react';
import './LinkComponentWithNotificationFlag.scss';
import { Link, useLocation } from 'react-router-dom';
import { NotificationFlag } from '../NotificationFlag/NotificationFlag';

interface LinkComponentWithNotificationFlagProps {
    to: string;
    label: string;
    active: boolean;
    icon: string;
    flagCode: string;
}

const LinkComponentWithNotificationFlag = (
    props: LinkComponentWithNotificationFlagProps
) => {
    const location = useLocation();
    return (
        <Link
            className={`link-component-with-notification-flag metismenu-link ${
                props.to === location.pathname ? 'active' : ''
            }`}
            onClick={() => {
                window.location.href = props.to;
            }}
            to={props.to}>
            <i className={`metismenu-icon ${props.icon}`}></i>
            {props.label}{' '}
            <NotificationFlag
                eventCode={props.flagCode}
                includePrefix={true}></NotificationFlag>
        </Link>
    );
};

export default LinkComponentWithNotificationFlag;
