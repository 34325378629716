import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Hamburger from '../../Hamburger/hamburger';
import AppMobileMenu from '../AppMobileMenu';
import logo from '../../../assets/demo-ui/images/vetValue.png';

import {
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../../reducers/ThemeOptionsSlice';
class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
        };
    }

    toggleEnableClosedSidebar = () => {
        let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    };

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        let { enableClosedSidebar, enableMobileMenu } = this.props;

        const {} = this.state;

        return (
            <Fragment>
                <div className="app-header__logo">
                    <div />
                    {enableClosedSidebar && !enableMobileMenu ? null : (
                        <img
                            width="149"
                            height="60"
                            alt="logo"
                            className="logo-image_2l9 image-logo_1Ok"
                            src={logo}></img>
                    )}
                    <div className="header__pane ml-auto">
                        <div onClick={this.toggleEnableClosedSidebar}>
                            <Hamburger
                                active={enableClosedSidebar}
                                type="elastic"
                                onClick={() =>
                                    this.setState({
                                        active: !this.state.active,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <AppMobileMenu />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
    setEnableClosedSidebar: (enable) =>
        dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: (enable) =>
        dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
