import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateMember = createAsyncThunk(
    'directMembers/updateMember',
    async (arg: number, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/direct/members/${arg}`);
        return response;
    }
);
