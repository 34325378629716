import { createSlice } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from './data-request-notifications.state';
import { DataRequestProject } from '../DataRequestProject';
import { DataFormType } from '../DataFormTypeEnum';
import TabNotificationStatus from '../TabNotificationStatus';
import { QuestionProgressStatus } from '../QuestionProgressStatusEnum';
import QuestionSeenStatus, {
    calculateQuestionUserNotifications,
} from '../QuestionSeenStatus';
import Answer from '../Answer';
import FormNotificationStatus from '../FormNotificationStatus';
import { TableNotificationStatus } from '../TableNotificationStatus';
import {
    mapCellsSeenStatus,
    mapTablesNotifications,
    resetCellsNotifications,
    resetTableNotifications,
    updateCellsStauts,
    updateTablesNotifications,
    updateTablesSeenStatus,
} from './data-tables-status-mapper';
import {
    mapQuestionsStatus,
    resetFormTypeQuestionsNotifications,
    resetQuestionsNotificationsByFormId,
    resetQuestionsNotifications,
    updateQuestionsStatus,
    mapNotificationsForAppearedQuestions,
    mapFormsAdminNotifications,
    mapFormsUserNotifications,
    updateQuestionAnswer,
} from './data-forms-status-mapper';
import { TableCellSeenStatus } from '../TableCellSeenStatus';

const initialState: DataRequestNotificationsState = {
    isAdminView: undefined,
    questionsSeenStatus: [],
    formsNotificationsStatus: [],
    projectTabsNotificationsStatus: [],
    moduleTabsNotificationsStatus: [],
    tablesNotificationsStatus: [],
    tableCellsSeenStatus: [],
};

const defaultTabsStatus: TabNotificationStatus[] = [
    {
        formType: DataFormType.Documents,
        displayName: 'Documents',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.FinancialRequest,
        displayName: 'Financial Requests',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.Questionnaire,
        displayName: 'Questionnaire',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.DataTable,
        displayName: 'Data Tables',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
];

export interface ResetQuestionInfo {
    questionId: number;
    isSubquestion: boolean;
}

export interface ResetCellInfo {
    customRowId: string;
    customColumnId: string;
}

const dataRequestNotificationsSlice = createSlice({
    name: 'DataRequestNotifications',
    initialState,
    reducers: {
        initializeDataRequestNotifications(state, action) {
            const projects = action.payload.projects as DataRequestProject[];
            const isAdminView = action.payload.isAdminView as boolean;

            const quesiotnsStatus = mapQuestionsStatus(projects);
            const formsNotifications = isAdminView
                ? mapFormsAdminNotifications(quesiotnsStatus)
                : mapFormsUserNotifications(quesiotnsStatus);

            const cellsSeenStatus = mapCellsSeenStatus(projects);

            const tablesNotifications = mapTablesNotifications(
                cellsSeenStatus,
                projects,
                isAdminView
            );

            const updateFormTabsStatus = mapProjectTabsStatuses(
                formsNotifications,
                tablesNotifications,
                isAdminView
            );

            const updateModuleTabsStatus = isAdminView
                ? mapModuleTabsAdminStatuses(quesiotnsStatus)
                : mapModuleTabsUserStatuses(quesiotnsStatus);

            state.isAdminView = isAdminView;
            state.questionsSeenStatus = quesiotnsStatus;
            state.formsNotificationsStatus = formsNotifications;
            state.projectTabsNotificationsStatus = updateFormTabsStatus;
            state.moduleTabsNotificationsStatus = updateModuleTabsStatus;
            state.tableCellsSeenStatus = cellsSeenStatus;
            state.tablesNotificationsStatus = tablesNotifications;
        },
        updateQuestionsAndCells(state, action) {
            const projects = action.payload.projects as DataRequestProject[];
            const updatedQuestionsStatus = updateQuestionsStatus(
                state.questionsSeenStatus,
                projects
            );

            const updatedCellsStatus = updateCellsStauts(
                state.tableCellsSeenStatus,
                projects
            );

            const tablesNotifications = updateTablesSeenStatus(
                state.tablesNotificationsStatus,
                projects
            );

            updateTabsState(
                state,
                updatedQuestionsStatus,
                updatedCellsStatus,
                tablesNotifications
            );
            state.questionsSeenStatus = updatedQuestionsStatus;
            state.tableCellsSeenStatus = updatedCellsStatus;
            state.tablesNotificationsStatus = tablesNotifications;
        },
        resetQuestionsSeenStatusByFormType(state, action) {
            const targetFormType = action.payload.formType as DataFormType;

            const updatedQuestionsStatus = resetFormTypeQuestionsNotifications(
                state.questionsSeenStatus,
                targetFormType
            );
            updateTabsState(
                state,
                updatedQuestionsStatus,
                state.tableCellsSeenStatus,
                state.tablesNotificationsStatus
            );

            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        resetQuestionsSeenStatusByFormId(state, action) {
            const targetFormId = action.payload.formId as number;

            const updatedQuestionsStatus = resetQuestionsNotificationsByFormId(
                state.questionsSeenStatus,
                targetFormId
            );
            updateTabsState(
                state,
                updatedQuestionsStatus,
                state.tableCellsSeenStatus,
                state.tablesNotificationsStatus
            );

            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        resetQuestionsSeenStatusByIds(state, action) {
            const questionsInfoToReset = action.payload
                .resetQuestionInfo as ResetQuestionInfo[];
            const formId = action.payload.formId as number;

            const updatedQuestionsStatus = resetQuestionsNotifications(
                state.questionsSeenStatus,
                questionsInfoToReset,
                formId
            );
            updateTabsState(
                state,
                updatedQuestionsStatus,
                state.tableCellsSeenStatus,
                state.tablesNotificationsStatus
            );

            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        addQuestionAnswer(state, action) {
            const answer = action.payload.answer as Answer;
            const resetQuestionInfo = action.payload
                .questionInfo as ResetQuestionInfo;

            let updatedQuestions = updateQuestionAnswer(
                state.questionsSeenStatus,
                answer,
                resetQuestionInfo
            );
            const changedQuestion = updatedQuestions.find(
                (question) =>
                    question.id === resetQuestionInfo.questionId &&
                    question.isSubQuestion === resetQuestionInfo.isSubquestion
            );
            updatedQuestions = mapNotificationsForAppearedQuestions(
                updatedQuestions,
                changedQuestion
            );

            state.questionsSeenStatus = updatedQuestions;
            updateTabsState(
                state,
                updatedQuestions,
                state.tableCellsSeenStatus,
                state.tablesNotificationsStatus
            );
        },
        resetCellSeenStatusByIds(state, action) {
            if (state.isAdminView) return;
            const cellIdsToReset = action.payload
                .cellsInfoToReset as ResetCellInfo[];
            const tableId = action.payload.tableId as number;
            const viewId = action.payload.viewId as number;

            const targetView = state.tablesNotificationsStatus
                .flatMap((table) => table.viewsNotificationsStatus)
                .find((view) => view.viewId === viewId);
            const updatedQuestionsStatus = resetCellsNotifications(
                state.tableCellsSeenStatus,
                cellIdsToReset,
                tableId,
                targetView
            );

            const updatedTableStatus = state.tablesNotificationsStatus.map(
                (tableStatus) =>
                    tableStatus.tableId === tableId
                        ? resetTableNotifications(
                              tableStatus,
                              cellIdsToReset,
                              viewId
                          )
                        : tableStatus
            );

            const updatedTablesNotifications = updateTablesNotifications(
                updatedQuestionsStatus,
                updatedTableStatus,
                state.isAdminView
            );

            const updatedTabsStatus = mapProjectTabsStatuses(
                state.formsNotificationsStatus,
                updatedTablesNotifications,
                state.isAdminView
            );

            state.tableCellsSeenStatus = updatedQuestionsStatus;
            state.tablesNotificationsStatus = updatedTablesNotifications;
            state.projectTabsNotificationsStatus = updatedTabsStatus;
        },
    },
});

const updateTabsState = (
    state: DataRequestNotificationsState,
    updatedQuestionsStatus: QuestionSeenStatus[],
    cellsStatus: TableCellSeenStatus[],
    tablesNotificationState: TableNotificationStatus[]
) => {
    const formsNotifications = state.isAdminView
        ? mapFormsAdminNotifications(updatedQuestionsStatus)
        : mapFormsUserNotifications(updatedQuestionsStatus);

    const updatedTablesNotifications = updateTablesNotifications(
        cellsStatus,
        tablesNotificationState,
        state.isAdminView
    );

    const updateFormTabsStatus = mapProjectTabsStatuses(
        formsNotifications,
        updatedTablesNotifications,
        state.isAdminView
    );
    const updateModuleTabsStatus = state.isAdminView
        ? mapModuleTabsAdminStatuses(updatedQuestionsStatus)
        : mapModuleTabsUserStatuses(updatedQuestionsStatus);

    state.formsNotificationsStatus = formsNotifications;
    state.projectTabsNotificationsStatus = updateFormTabsStatus;
    state.moduleTabsNotificationsStatus = updateModuleTabsStatus;
    state.projectTabsNotificationsStatus = updateFormTabsStatus;
};

const mapProjectTabsStatuses = (
    formNotificationsStatus: FormNotificationStatus[],
    tablesNotifications: TableNotificationStatus[],
    isAdminView: boolean
) => {
    const projectTabs = defaultTabsStatus;
    const result = projectTabs.map((tab): TabNotificationStatus => {
        if (tab.formType === DataFormType.DataTable) {
            return updateTablesTabStatus(tab, tablesNotifications, isAdminView);
        } else {
            return updateFormTabStatus(
                tab,
                formNotificationsStatus,
                isAdminView
            );
        }
    });

    return result;
};

const updateFormTabStatus = (
    tab: TabNotificationStatus,
    formNotificationsStatus: FormNotificationStatus[],
    isAdminView: boolean
) => {
    const formTypeStatuse = formNotificationsStatus.filter(
        (status) => status.formType === tab.formType
    );

    const notificationsCount = formTypeStatuse
        .map((f) => f.notificationsCount)
        .reduce((sum, value) => sum + value, 0);

    const isFormNew = formTypeStatuse.every((e) => e.isNew);
    const isNewProject = formTypeStatuse.every((e) => e.isNewProject);

    return {
        ...tab,
        formType: tab.formType,
        isNewForm: isFormNew,
        countOfNotifications: isAdminView && isFormNew ? 0 : notificationsCount,
        isNewProject: isNewProject,
    };
};

const updateTablesTabStatus = (
    tab: TabNotificationStatus,
    tablesNotifications: TableNotificationStatus[],
    isAdminView: boolean
): TabNotificationStatus => {
    const notificationsCount = tablesNotifications
        .map((f) => f.notificationsCount)
        .reduce((sum, value) => sum + value, 0);

    const isNewProject = tablesNotifications.every((e) => e.isNewProject);

    return {
        ...tab,
        formType: tab.formType,
        isNewForm: false,
        countOfNotifications: isAdminView ? 0 : notificationsCount,
        isNewProject: isNewProject,
    };
};

const mapModuleTabsUserStatuses = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const moduleTabs = mapModuleTabItems(questionsSeenStatus);

    const result = moduleTabs.map((moduleTab): TabNotificationStatus => {
        const formItems = questionsSeenStatus.filter(
            (question) => question.formId === moduleTab.formId
        );

        const questionsStatus = formItems.filter(
            (question) =>
                !question.isSubQuestion &&
                question.formId === moduleTab.formId &&
                question.displayModule === moduleTab.displayName
        );

        const subQquestionsStatus = formItems.filter(
            (question) =>
                question.isSubQuestion && question.formId === moduleTab.formId
        );

        const moduleNotificationsCount = questionsStatus
            .map((questionStatus) => {
                let count = calculateQuestionUserNotifications(
                    questionStatus,
                    formItems
                );

                if (
                    questionStatus.isSeenByUser &&
                    !questionStatus.isSubQuestion
                ) {
                    const subQuestionsNotifications = subQquestionsStatus
                        .filter(
                            (subQuestionStatus) =>
                                subQuestionStatus.originQuestionId ===
                                questionStatus.id
                        )
                        .reduce(
                            (sum, value) =>
                                sum +
                                calculateQuestionUserNotifications(
                                    value,
                                    formItems
                                ),
                            0
                        );

                    count += subQuestionsNotifications;
                }

                return count;
            })
            .reduce((sum, value) => sum + value, 0);

        return { ...moduleTab, countOfNotifications: moduleNotificationsCount };
    });

    return result;
};

const mapModuleTabsAdminStatuses = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const moduleTabs = mapModuleTabItems(questionsSeenStatus);

    const result = moduleTabs.map((moduleTab): TabNotificationStatus => {
        const moduleQuestions = questionsSeenStatus.filter(
            (question) =>
                !question.isSubQuestion &&
                question.formId === moduleTab.formId &&
                question.displayModule === moduleTab.displayName
        );

        const moduleNotificationsmoduleNotificationsCount = moduleQuestions
            .map((questionStatus) => {
                let count = 0;

                if (questionStatus.isUserQuestionHighlighted) {
                    count += 1;
                }

                if (
                    !questionStatus.isSeenByAdmin &&
                    questionStatus.isMarkedAsComplete &&
                    questionStatus.status === QuestionProgressStatus.InReview
                ) {
                    count += 1;
                }
                return count;
            })
            .reduce((sum, value) => sum + value, 0);

        return {
            ...moduleTab,
            countOfNotifications: moduleNotificationsmoduleNotificationsCount,
        };
    });

    return result;
};

const mapModuleTabItems = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const displayModules = questionsSeenStatus.map(
        (questionStatus): TabNotificationStatus | null =>
            questionStatus.displayModule
                ? {
                      formType: questionStatus.dataFormType,
                      displayName: questionStatus.displayModule,
                      isNewProject: true,
                      isNewForm: true,
                      countOfNotifications: 0,
                      formId: questionStatus.formId,
                  }
                : null
    );
    const notEmptyDisplayModules = displayModules.filter((x) => x);

    const uniqueDisplayModules = notEmptyDisplayModules.filter(
        (moduleState, index) => {
            const duplicateIndex = notEmptyDisplayModules.findIndex(
                (duplicateModuleState) =>
                    duplicateModuleState.displayName ===
                        moduleState.displayName &&
                    duplicateModuleState.formId === moduleState.formId &&
                    duplicateModuleState.formType === moduleState.formType
            );
            return index === duplicateIndex;
        }
    );

    return uniqueDisplayModules;
};

export const {
    initializeDataRequestNotifications,
    updateQuestionsAndCells,
    resetQuestionsSeenStatusByFormType,
    resetQuestionsSeenStatusByIds,
    addQuestionAnswer,
    resetQuestionsSeenStatusByFormId,
    resetCellSeenStatusByIds,
} = dataRequestNotificationsSlice.actions;
export default dataRequestNotificationsSlice.reducer;
