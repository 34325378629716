import { random } from 'lodash';
import { ObjectHelper } from './ObjectHelper';

export class DataGenerator {
    private objectHelper: ObjectHelper;
    constructor() {
        this.objectHelper = new ObjectHelper();
    }
    generateData() {
        let data: { id: any }[] = [];
        let links: any[] = [];

        let test = new Date();
        test.setDate(test.getDate() + 3);
        test.setHours(0, 0, 0, 0);

        for (let i = 1; i < 10; i++) {
            const today = new Date();
            today.setHours(0)
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + i* 2);
            this.addRecord(tomorrow, i, data);
        }
        // let start = 0;
        // let end = 0;
        // for (let i = 1; i < 100; i++) {
        //     start = Math.trunc(Math.random() * 1000);
        //     end = Math.trunc(Math.random() * 1000);

        //     this.addLink(data[start].id, data[end].id, links);
        // }

        return { data: data, links: links };
    }

    addRecord(starDate: Date, i: number, result: { id: any }[]) {
        let endDate = new Date(starDate.getTime());
        endDate.setDate(starDate.getDate() + random(1, 5));
        let id = this.objectHelper.genID();
        let record = {
            id: id,
            name: `Task ${i}`,
            start: starDate,
            end: endDate,
            color: this.getRandomColor(),
        };
        result.push(record);
        return id;
    }

    addLink(startId: any, endId: any, list: any[]) {
        let id = this.objectHelper.genID();
        let record = {
            id: id,
            start: startId,
            startPosition: 1,
            end: endId,
            endPosition: 0,
        };
        list.push(record);
        return id;
    }

    randomDate(start: Date, end: Date) {
        return new Date(
            start.getTime() + Math.random() * (end.getTime() - start.getTime())
        );
    }

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    setRandomColor() {
        const colorPadElement = document.getElementById('colorpad');
        if (colorPadElement) {
            colorPadElement.style.backgroundColor = this.getRandomColor();
        }
    }
}
