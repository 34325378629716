import DataFormQuestion from './DataFormQuestion';
import { DataFormType } from './DataFormTypeEnum';

export interface DataForm {
    id: number;
    originalTemplateName: string;
    customName: string;
    formType: DataFormType;
    questions: DataFormQuestion[];
    dateComplete: Date;
    dateCreated: Date;
    dateDue: Date;
    itemsToReviewCount: number;
    newQuestionsCount: number;
    outstandingRequestsCount: number;
    isVisible: boolean;
    isComplete: boolean;
    isNew: boolean;
}

export const emptyDataForm: DataForm = {
    id: 0,
    originalTemplateName: '',
    customName: '',
    formType: undefined,
    questions: [] as DataFormQuestion[],
    dateComplete: new Date(),
    dateCreated: new Date(),
    dateDue: new Date(),
    itemsToReviewCount: 0,
    newQuestionsCount: 0,
    outstandingRequestsCount: 0,
    isVisible: true,
    isComplete: false,
    isNew: true,
};
