import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import BootstrapTable from '../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableHeader } from '../../components/TableHeader/TableHeader';
import createNotification from '../../utils/createNotification';
import './AssociateMembersTable.scss';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import AssociateMemberModal from './Modal/AssociateMemberModal';
import { useLocation } from 'react-router-dom';
import { HomeButton } from '../../components/HomeButton/home-button';
import { useStateSelector } from '../../store/selectors';
import {
    getDefaultAssosiateMemberRights,
    getMappedAssosiateMemberRights,
} from '../../utils/associateMembersConstants';
import { PRACTICE_STATUS_ENUM, USER_ROLES } from '../../utils/constants';
import { PRACTICE_OWNERSHIP_TYPES } from '../../utils/constants/PracticeOwnerType.enum';

const DefaultAssociateMemberObject = {
    name: '',
    email: '',
    status: '',
    practices: [],
    nonprofitMapping: {
        accessRights: getDefaultAssosiateMemberRights(),
    },
};

export const AssociateMembersTable = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const search = useLocation().search;
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [associateMemberDialogVisible, setAssociateMemberDialogVisible] =
        useState(false);
    const [associateMemberDialogMode, setAssociateMemberDialogMode] =
        useState('');
    const [associateMemberData, setAssociateMemberData] = useState(
        DefaultAssociateMemberObject
    );
    const [isCurrentUserNonprofitAdmin, setIsCurrentUserNonprofitAdmin] =
        useState(false);

    const [tableSettings, setTableSettings] = useState(null);
    const [selectedAssociateMember, setSelectedAssociateMember] =
        useState(undefined);

    const limits = useStateSelector((state) => state.currentUser.limits);
    const summary = useStateSelector((state) => state.userSummary.summary);

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc',
        },
    ];
    const [loadWithParameter, setLoadWithParameter] = useState(true);

    const handleTableChange = (type, newState) => {
        let sortField = newState.sortField;
        let sortOrder = newState.sortOrder;
        let page = newState.page;
        let sizePerPage = newState.sizePerPage;

        let fetchObj = {
            Filter: {
                Filters: [],
                Logic: '',
            },
            PageNumber: page,
            PageSize: sizePerPage,
            Sort: {
                Dir: sortOrder,
                Field: sortField,
            },
        };

        const arrayOfObj = Object.entries(newState.filters).map((e) => ({
            [e[0]]: e[1],
        }));

        if (arrayOfObj.length > 0) {
            arrayOfObj.forEach((element) => {
                let key = Object.keys(element);

                let values = newState.filters[key];

                let obj = {
                    Field: key[0],
                    Operator: 'contains',
                    Value: values.filterVal,
                };

                fetchObj.Filter.Filters.push(obj);
            });
        }

        setTableSettings(fetchObj);
        fetchUsersData(fetchObj);
    };

    useEffect(() => {
        setIsCurrentUserNonprofitAdmin(
            props.summaryData.roleId === USER_ROLES.CHARITY_ADMIN
        );
    }, []);

    useEffect(() => {
        if (selectedAssociateMember) {
            viewAssociate(selectedAssociateMember);
        }
    }, [selectedAssociateMember]);

    useEffect(() => {
        const id = new URLSearchParams(search).get('id');
        if (id && loadWithParameter && tableData.length > 0) {
            setSelectedAssociateMember(id);
            setLoadWithParameter(false);
        }
    }, [tableData]);

    const fetchUsersData = (postObj) => {
        const id = new URLSearchParams(search).get('id');
        if (id && postObj && loadWithParameter) {
            postObj = {
                ...postObj,
                Filter: {
                    ...postObj.Filter,
                    Filters: [{ Field: 'id', Operator: 'eq', Value: id }],
                },
            };
        }
        axios
            .post('/api/Associate/get', {
                requestModel: postObj,
                userID: props.currentUserId,
            })
            .then((response) => {
                if (response && response.data && response.data.isError) {
                    createNotification(
                        'Could not fetch Users at the moment. Please try again later or contact the Administrator',
                        'error'
                    );
                } else {
                    if (response.data.message == 'No data found.') {
                        setTableData([]);
                    } else {
                        let userData = response.data.data;
                        if (props.currentUserId) {
                            // remove current user from list of users
                            let res = userData.list.filter(
                                (x) => x.id != props.currentUserId
                            );
                            setTableData(res);
                        } else {
                            setTableData(userData.list);
                        }
                        setPage(userData.pageNumber);
                        setTotalSize(userData.totalItems);
                        setSizePerPage(userData.pageSize);
                        localStorage['filter'] = JSON.stringify(postObj);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setTableData([]);
            });
    };

    const viewAssociate = (cellContent) => {
        const user = tableData.find((u) => u.id == cellContent);
        if (isCurrentUserNonprofitAdmin) {
            const nonprofitMapping = user.nonprofitMapping;
            nonprofitMapping.accessRights = getMappedAssosiateMemberRights(
                nonprofitMapping?.associateMemberAccessRights
            );
            setAssociateMemberData({
                id: user.id,
                name: user.name,
                email: user.email,
                status: user.status,
                practices: [],
                nonprofitMapping: nonprofitMapping,
            });
        } else {
            const practices = props.summaryData.practices
                .filter((p) => p.status == 1)
                .map((p) => {
                    const practice = user.practices.find(
                        (pr) => pr.practiceId == p.id
                    );
                    const pr = {
                        practiceId: p.id,
                        name: p.name,
                        isSelected: !!practice,
                        roleId: practice ? practice.roleId : '',
                        accessRights: getMappedAssosiateMemberRights(
                            practice?.accessRights
                        ),
                        cannotBePmgRepresentative: tableData.some(
                            (td) =>
                                !getMappedAssosiateMemberRights(
                                    practice?.accessRights
                                ).pmgRepresentativeAccessRights &&
                                td.practices.some(
                                    (prac) =>
                                        prac.practiceId === p.id &&
                                        getMappedAssosiateMemberRights(
                                            prac.accessRights
                                        ).pmgRepresentativeAccessRights
                                )
                        ),
                    };
                    pr.accessRights.referralPartnerExtension =
                        user.practices.some(
                            (p) =>
                                pr.isSelected &&
                                getMappedAssosiateMemberRights(p.accessRights)
                                    .referralPartnerExtension
                        );
                    return pr;
                });
            setAssociateMemberData({
                id: user.id,
                name: user.name,
                email: user.email,
                status: user.status,
                practices: practices,
                nonprofitMapping: null,
            });
        }
        setAssociateMemberDialogMode('view');
        setAssociateMemberDialogVisible(true);
    };

    const addAssociate = () => {
        const defaultAssociate = {
            name: '',
            email: '',
            status: undefined,
            practices: isCurrentUserNonprofitAdmin
                ? []
                : props.summaryData.practices
                      .filter(
                          (p) =>
                              p.status == PRACTICE_STATUS_ENUM.Approved &&
                              p.practiceOwnershipType !=
                                  PRACTICE_OWNERSHIP_TYPES.FORMER
                      )
                      .map((practice) => ({
                          practiceId: practice.id,
                          name: practice.name,
                          isSelected: false,
                          roleId: 0,
                          accessRights: getDefaultAssosiateMemberRights(),
                          cannotBePmgRepresentative: tableData.some(
                              (td) =>
                                  !getMappedAssosiateMemberRights(
                                      practice?.accessRights
                                  ).pmgRepresentativeAccessRights &&
                                  td.practices.some(
                                      (prac) =>
                                          prac.practiceId === practice.id &&
                                          getMappedAssosiateMemberRights(
                                              prac.accessRights
                                          ).pmgRepresentativeAccessRights
                                  )
                          ),
                      })),
        };
        if (isCurrentUserNonprofitAdmin) {
            defaultAssociate.nonprofitMapping = {
                accessRights: getDefaultAssosiateMemberRights(),
            };
        }
        setAssociateMemberData(defaultAssociate);
        setAssociateMemberDialogMode('create');
        setAssociateMemberDialogVisible(true);
    };

    const buttons = [
        {
            buttonText: '+ Add New Affiliated Member',
            onClick: addAssociate,
        },
    ];

    const resetAssociateDialog = () => {
        setSelectedAssociateMember(undefined);
        setAssociateMemberDialogVisible(false);
        setAssociateMemberData(DefaultAssociateMemberObject);
        setAssociateMemberDialogMode('');
    };

    const getColumns = () => [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: 'createdOn',
            text: 'Registered Date',
            sort: true,
            formatter: (cellContent) =>
                cellContent
                    ? new Date(cellContent).toLocaleString('en-US')
                    : '',
        },
        {
            dataField: 'role',
            text: 'Role',
            sort: true,
        },
        {
            dataField: 'id',
            text: 'Action',
            sort: false,
            formatter: (cellContent) => {
                return (
                    <>
                        <div
                            style={{ textAlign: 'center' }}
                            className="d-block w-100 edit-col">
                            <span
                                className="icon-hover-style"
                                onClick={() =>
                                    setSelectedAssociateMember(cellContent)
                                }>
                                <FontAwesomeIcon icon={faWrench} />
                            </span>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <Fragment>
            {(summary.roleId !== USER_ROLES.PRACTICE_OWNER || 
                (limits.doesSupportSubscriptions && limits.hasPaidSubscription)) ? (
                <div className="associate-members-table">
                    {!props.isShortView && (
                        <>
                            <div className="page-title">
                                <h3>My Affiliated Members</h3>
                            </div>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <HomeButton></HomeButton>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    My Affiliated Members
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </>
                    )}
                    <div className="body_white_box associate_member">
                        <div className="body_header_title">
                            <h5>
                                Affiliated Member
                                <TableHeader
                                    hasToolbar={true}
                                    buttons={buttons}></TableHeader>
                            </h5>
                        </div>
                        {tableData ? (
                            <Row className="associate_data">
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <div className="associate-member-list-table">
                                                <BootstrapTable
                                                    bootstrap4
                                                    remote
                                                    keyField="id"
                                                    data={tableData}
                                                    columns={getColumns()}
                                                    defaultSorted={
                                                        defaultSorted
                                                    }
                                                    filter={filterFactory()}
                                                    pagination={paginationFactory(
                                                        {
                                                            page,
                                                            sizePerPage,
                                                            totalSize,
                                                        }
                                                    )}
                                                    onTableChange={
                                                        handleTableChange
                                                    }
                                                    noDataIndication="No Users available"
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        {associateMemberDialogVisible ? (
                            <AssociateMemberModal
                                isVisible={associateMemberDialogVisible}
                                setIsVisible={() => {
                                    setAssociateMemberDialogVisible();
                                    setSelectedAssociateMember(undefined);
                                }}
                                dialogMode={associateMemberDialogMode}
                                setDialogMode={setAssociateMemberDialogMode}
                                resetDialog={resetAssociateDialog}
                                data={associateMemberData}
                                getSummaryData={props.getSummaryData}
                                currentUserId={props.currentUserId}
                                summaryData={props.summaryData}
                                setData={(p) => {
                                    setAssociateMemberData(p);
                                }}
                                refreshTable={() => {
                                    fetchUsersData(tableSettings);
                                }}
                            />
                        ) : (
                            []
                        )}
                    </div>
                </div>
            ) : (
                <div className="text-center mt-5">
                    <h3>Access restricted</h3>
                </div>
            )}
        </Fragment>
    );
};
export default AssociateMembersTable;
