import { createAsyncThunk } from '@reduxjs/toolkit';
import { StartVotingArgs } from './clubhouse-slice-helper';

export const StartVoting = createAsyncThunk(
    'clubhouse/start-voting',
    async (args: StartVotingArgs, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.post(
            `api/clubhouses/${args.clubhouseId}/discussions/${args.discussionId}/removalVoting`
        );
        return response;
    }
);
