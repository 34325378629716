import React, { useEffect } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Rodal from '../Rodal/Rodal';

export const MODAL_SIZE = {
    LARGE: 1,
    MEDIUM: 2,
    SMALL: 3,
};

const CustomModal = ({
    title = 'My Modal',
    visible = false,
    setVisible = () => {},
    modalSize = MODAL_SIZE.SMALL,
    children,
    buttons,
}) => {
    useEffect(() => {}, []);

    return (
        <Rodal
            visible={visible}
            onClose={() => setVisible(false)}
            animation={'fade'}
            showMask={false}
            width={
                modalSize === MODAL_SIZE.LARGE
                    ? 900
                    : modalSize === MODAL_SIZE.MEDIUM
                    ? 600
                    : modalSize === MODAL_SIZE.SMALL
                    ? 400
                    : 400
            }
            className="modal-style">
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            {buttons && buttons.length > 0 && (
                <ModalFooter>
                    {buttons.map((btn, index) => (
                        <Button
                            key={index}
                            disabled={!btn.isEnabled}
                            color={btn.color}
                            onClick={btn.onClick}>
                            {btn.title}
                        </Button>
                    ))}
                </ModalFooter>
            )}
        </Rodal>
    );
};

export default CustomModal;
