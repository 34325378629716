import { AxiosInstance } from 'axios';
import { PaymentMaxPollCount } from './constants';
import { TransactionStatusesEnum } from '../wallet/models/TransactionStatusesEnum';
import createNotification from './createNotification';

export class PaymentsHelper {
    public static async waitForPaymentConfirmation(
        axios: AxiosInstance,
        updateVisibleState: (isVisible: boolean) => void,
        onSuccess: () => void,
        successMessage: string,
        failureMessage: string,
        transactionId: string,
        pollCount = 1
    ) {
        if (pollCount <= PaymentMaxPollCount) {
            await axios
                .get(`api/wallet/transactions/${transactionId}/status`)
                .then((response: any) => {
                    if (response?.status === 200) {
                        if (
                            response?.data === TransactionStatusesEnum.Confirmed
                        ) {
                            updateVisibleState(false);
                            createNotification(successMessage, 'success');
                            onSuccess();
                        } else {
                            if (PaymentMaxPollCount === pollCount) {
                                createNotification(
                                    'Could not verify the payment, please be patient. We will send you an email once the payment has been verified. For any assistance, you may contact us directly.',
                                    'error'
                                );
                                updateVisibleState(false);
                            } else {
                                setTimeout(() => {
                                    this.waitForPaymentConfirmation(
                                        axios,
                                        updateVisibleState,
                                        onSuccess,
                                        successMessage,
                                        failureMessage,
                                        transactionId,
                                        pollCount + 1
                                    );
                                }, 2000);
                            }
                        }
                    } else {
                        createNotification(failureMessage, 'error');
                    }
                })
                .catch(() => {
                    createNotification(failureMessage, 'error');
                });
        }
    }
}
