export enum WithdrawalStatus {
    YearEndSweep = 1,
    NoSweep = 2,
    Nonprofit = 3,
}

export const WithdrawalStatuses = [
    { status: WithdrawalStatus.NoSweep, label: 'No Sweep' },
    { status: WithdrawalStatus.YearEndSweep, label: 'Year End Sweep' },
    { status: WithdrawalStatus.Nonprofit, label: 'Nonprofit' },
];
