import React, { useState, useEffect } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Nav, NavItem, NavLink } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { SET_AXIOS } from '../../../../actions/actions';
import { createBaseAxiosInstance } from '../../../../axios-base';

export const PracticeSwitcher = props => {
    const [practices, setPractices] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        let loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        if (loggedInUserSummary) {
            loggedInUserSummary = JSON.parse(loggedInUserSummary);
            if (
                loggedInUserSummary &&
                loggedInUserSummary.practices &&
                loggedInUserSummary.practices.length > 0
            ) {
                setPractices(loggedInUserSummary.practices);

                let currentPracticeId = localStorage.getItem(
                    'currentPracticeId'
                );
                let currentPracticeRegId = localStorage.getItem(
                    'currentPracticeRegId'
                );

                if (currentPracticeId) {
                    let practice = loggedInUserSummary.practices.find(
                        x => x.id == currentPracticeId
                    );
                    if (practice) {
                        moveSelectedPracticeToTop(
                            practice,
                            loggedInUserSummary.practices
                        );
                    } else {
                        if (loggedInUserSummary.practices[0].id) {
                            localStorage.setItem(
                                'currentPracticeId',
                                loggedInUserSummary.practices[0].id
                            );
                        }
                    }
                } else if (currentPracticeRegId) {
                    let practice = loggedInUserSummary.practices.find(
                        x => x.practiceRegId == currentPracticeRegId
                    );
                    if (practice) {
                        moveSelectedPracticeToTop(
                            practice,
                            loggedInUserSummary.practices
                        );
                    } else {
                        if (loggedInUserSummary.practices[0].id) {
                            localStorage.setItem(
                                'currentPracticeId',
                                loggedInUserSummary.practices[0].id
                            );
                        }
                    }
                } else {
                    if (loggedInUserSummary.practices[0].id) {
                        localStorage.setItem(
                            'currentPracticeId',
                            loggedInUserSummary.practices[0].id
                        );
                    }
                    if (loggedInUserSummary.practices[0].practiceRegId) {
                        localStorage.setItem(
                            'currentPracticeRegId',
                            loggedInUserSummary.practices[0].practiceRegId
                        );
                    }
                }
                dispatch({
                    type: SET_AXIOS,
                    payload: createBaseAxiosInstance(),
                });
            }
        }
    }, []);

    const switchPractice = practice => {
        moveSelectedPracticeToTop(practice, practices);
        localStorage.setItem(
            'currentPracticeId',
            practice.id ? practice.id : ''
        );
        localStorage.setItem(
            'currentPracticeRegId',
            practice.practiceRegId ? practice.practiceRegId : ''
        );
        dispatch({ type: SET_AXIOS, payload: createBaseAxiosInstance() });
        window.location.reload();
    };

    const moveSelectedPracticeToTop = (practice, practiceArr) => {
        if (practiceArr && practiceArr.length > 0) {
            let updatedPractices = [...practiceArr];
            let selectedPractice = updatedPractices.splice(
                updatedPractices.findIndex(x =>
                    practice.id
                        ? x.id == practice.id
                        : x.practiceRegId == practice.practiceRegId
                ),
                1
            );
            updatedPractices.unshift(selectedPractice[0]);
            setPractices(updatedPractices);
        }
    };

    return (
        <div
            className="scroll-area-xs"
            style={{
                height: '150px',
            }}>
            <PerfectScrollbar>
                <Nav vertical>
                    <NavItem className="nav-item-header">My Practices</NavItem>
                    {practices.map((practice, index) => (
                        <NavItem key={index}>
                            <NavLink onClick={() => switchPractice(practice)}>
                                {practice.name}
                                {index > 0 && (
                                    <div className="ml-auto badge badge-pill badge-info">
                                        Switch
                                    </div>
                                )}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </PerfectScrollbar>
        </div>
    );
};
