import React, { useEffect } from 'react';
import Loader from 'react-loaders';
import { ModalBody, Button } from 'reactstrap';
import Rodal from '../../../components/Rodal/Rodal';

const NdaSuccess = () => {
    useEffect(() => {
        setNdaSignedSuccess();
    }, []);

    const setNdaSignedSuccess = () => {
        window.top.postMessage('true', '*');
    };

    return (
        <Rodal
            visible={true}
            showMask={false}
            width="400"
            showCloseButton={false}>
            <ModalBody height="425px">
                <div
                    className="text-center"
                    style={{ marginTop: '30px', marginLeft: '50%' }}>
                    <Loader
                        style={{ height: '50px' }}
                        type="ball-scale-multiple"
                    />
                </div>
                <h3 className="text-center">On Board!</h3>
                <span className="text-center">
                    Thank you for signing up our NDA. We will present you with
                    the Detailed Valuation form shortly once we receive your
                    signed NDA. If this takes too long, kindly refresh the page
                </span>
                <div className="text-center" style={{ marginTop: '10px' }}>
                    <Button
                        type="button"
                        color="success"
                        onClick={setNdaSignedSuccess}>
                        OK
                    </Button>
                </div>
            </ModalBody>
        </Rodal>
    );
};
export default NdaSuccess;
