import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';

export interface TextAnswerFieldProps {
    question: DataFormQuestion;
    isAdminView: boolean;
    isReadonly: boolean;
    getTextAreaHeightByTextSizeOption(textSize: string): number;
    extendTextAreaHeight(element: any, content: string, heightPx: number): void;
    decreaseTextAreaHeight(element: any, heightPx: number): void;
    updateTextAnswer(questionId: number, value: string): void;
    updateNotification(questionId: number): void;
}

export const TextAnswerField = (props: TextAnswerFieldProps) => {
    const [answer, setAnswer] = useState<string>('');

    useEffect(() => {
        const data = props.question.answer?.answerText;
        setAnswer(data);
    }, [props]);

    const isAnswerChanged = (currentAnswer: string) => {
        return props.question.answer?.answerText != currentAnswer;
    };

    const renderAdminField = () => {
        return (
            <div className="expanded-text-with-badge">
                <Input
                    readOnly
                    onFocusCapture={(element: any) => {
                        const height = props.getTextAreaHeightByTextSizeOption(
                            props.question.displayTextSize
                        );
                        props.extendTextAreaHeight(
                            element,
                            props.question.answer?.answerText,
                            height
                        );
                    }}
                    className={`text-size-${props.question.displayTextSize.toLocaleLowerCase()} text-size-field`}
                    type="textarea"
                    defaultValue={answer}
                    onBlur={(element) => {
                        const height = props.getTextAreaHeightByTextSizeOption(
                            props.question.displayTextSize
                        );
                        props.decreaseTextAreaHeight(element, height);
                    }}></Input>
            </div>
        );
    };

    const renderUserField = () => {
        return (
            <div className="expanded-text-with-badge">
                <Input
                    maxLength={Number(props.question.maxTextSize)}
                    readOnly={props.isReadonly}
                    className={`text-size-${props.question.displayTextSize.toLocaleLowerCase()} text-size-field`}
                    onKeyUp={(element: any) => {
                        const height = props.getTextAreaHeightByTextSizeOption(
                            props.question.displayTextSize
                        );
                        props.extendTextAreaHeight(
                            element,
                            props.question.answer?.answerText,
                            height
                        );
                    }}
                    onFocusCapture={(element: any) => {
                        const height = props.getTextAreaHeightByTextSizeOption(
                            props.question.displayTextSize
                        );
                        props.extendTextAreaHeight(
                            element,
                            props.question.answer?.answerText,
                            height
                        );
                    }}
                    type="textarea"
                    defaultValue={answer}
                    onBlur={(element) => {
                        props.updateNotification(props.question.id);
                        if (isAnswerChanged(element.target.value)) {
                            props.updateTextAnswer(
                                props.question.id,
                                element.target.value
                            );
                        }
                        const height = props.getTextAreaHeightByTextSizeOption(
                            props.question.displayTextSize
                        );
                        props.decreaseTextAreaHeight(element, height);
                    }}></Input>
            </div>
        );
    };

    return props.isAdminView ? renderAdminField() : renderUserField();
};
