import React from 'react';
import { Label } from 'reactstrap';

interface RadioInputProps {
    label: string;
    value: number | string | boolean;
    checked: boolean;
    setter: (value: number | string | boolean) => void;
    key: string;
    disabled: boolean;
}
export const RadioInput = (props: RadioInputProps) => {
    return (
        <label className="flex-container">
            <input
                id={props.key}
                type="radio"
                checked={props.checked}
                onChange={() => props.setter(props.value)}
                className="radio-margin-right"
                disabled={props.disabled}
            />
            <Label
                className="radio-label"
                onClick={() => props.setter(props.value)}>
                {props.label}
            </Label>
        </label>
    );
};
