import React, { useState } from 'react';
import Rodal from '../../../../../components/Rodal/Rodal';
import { ModalBody, ModalHeader } from 'reactstrap';
import './EditProjectVariablePopup.scss';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import {
    ProjectVariableOption,
    SystemVariableNames,
    SystemVariableNamesList,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import AddNewVariableOptionForm from '../AddNewVariableOptionForm/AddNewVariableOptionForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export interface EditProjectVariablePopupProps {
    close(): void;
    options: Array<ProjectVariableOption>;
    onSubmit(): void;
    projectId: number;
    variableId: number;
    variableName: string;
}

export const EditProjectVariablePopup = (
    props: EditProjectVariablePopupProps
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Array<ProjectVariableOption>>(
        props.options
    );

    const [newOptionName, setNewOptionName] = useState<string>('');
    const [newOptionValue, setNewOptionValue] = useState<string>('');
    const axios = useStateSelector((s) => s.core.axios);
    const [currentVariable] = useState(
        SystemVariableNamesList.find((f) => f.name === props.variableName)
    );

    const updateVariables = () => {
        setIsLoading(true);
        axios
            .put(
                `/api/DataRequestProject/${props.projectId}/ProjectVariables/${props.variableId}`,
                {
                    name: currentVariable
                        ? currentVariable.name.trim()
                        : props.variableName,
                    options: options,
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    props.onSubmit();
                } else {
                    createNotification(
                        'An error occured while saving project variables',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getVariableOptionRenderName = (option: ProjectVariableOption) => {
        switch (currentVariable?.id) {
            case SystemVariableNames.DisplayFormat:
                return `${option.name}: ${option.option}`;
            default:
                return option.option;
        }
    };

    const renderOptionList = (option: ProjectVariableOption, index: number) => (
        <div
            className="project-variable-tile"
            key={`${option}-${option.id}-${index}`}>
            <span className="project-variable-info">
                <span className="project-variable-name">
                    {getVariableOptionRenderName(option)}
                </span>
            </span>

            <span
                className="delete-button"
                onClick={() => {
                    const newOptions = [...options];
                    newOptions.splice(index, 1);
                    setOptions(newOptions);
                }}>
                <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
            </span>
        </div>
    );

    return (
        <div className="edit-project-variable-popup">
            <Rodal
                visible={true}
                onClose={props.close}
                className="checkout-modal create-event-modal"
                width={600}
                animation={'fade'}
                showMask={true}
                centered>
                <ModalHeader>{props.variableName}</ModalHeader>
                <ModalBody>
                    <div className="options-list">
                        {options.map(
                            (option: ProjectVariableOption, index: number) =>
                                renderOptionList(option, index)
                        )}
                    </div>
                    <AddNewVariableOptionForm
                        optionName={newOptionName}
                        setOptionName={setNewOptionName}
                        optionValue={newOptionValue}
                        setOptionValue={setNewOptionValue}
                        isLoading={isLoading}
                        add={(value) => {
                            const newOptions = [...options, value];
                            setOptions(newOptions);
                        }}
                        isNestedVariable={
                            currentVariable?.id ===
                            SystemVariableNames.DisplayFormat
                        }></AddNewVariableOptionForm>
                    <div className="buttons-wrapper">
                        <button
                            disabled={isLoading}
                            className="btn btn-cancel"
                            onClick={props.close}>
                            Cancel
                        </button>
                        <ButtonLoader
                            buttonText={'Save & Close'}
                            loaderButtonText={''}
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                                updateVariables();
                            }}
                            className={'btn btn-primary'}
                        />
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};
