import { createSlice } from '@reduxjs/toolkit';
import { Clubhouse } from '../../models/clubhouse';
import { Discussion } from '../../models/discussion';
import { Member } from '../../models/member';
import { OnlineState } from '../../models/online-state';
import { Poster } from '../../models/poster';
import { fetchClubhouseData } from './fetch-clubhouse-data.thunk';
import { StartVoting } from './start-voting.thunk';
import { AddPosterVote } from './add-poster-vote.thunk';
import { CancelPosterVote } from './cancel-poster-vote.thunk';
import { ClubhouseState } from './clubhouse.state';

let clubhouse: Clubhouse;

const initialState: ClubhouseState = {
    clubhouse: clubhouse,
    isLoading: false,
    status: '',
    error: '',
};

const clubhouseSlice = createSlice({
    name: 'clubhouse',
    initialState,
    reducers: {
        onClubhouseUpdate(state, action) {
            state.clubhouse = mapClubhouseState(state, action.payload);
        },
        selectDiscussion(state, action) {
            state.clubhouse.selectedDiscussionId = action.payload;
        },
        updateOnlineMembers(state, action) {
            const entities = (action.payload ?? []) as Array<number>;

            state.clubhouse.members.forEach((member) => {
                if (entities.includes(member.id))
                    member.onlineState = OnlineState.Online;
                else member.onlineState = OnlineState.Offline;
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchClubhouseData.pending, (state: any) => {
                setPendingState(state);
            })
            .addCase(
                fetchClubhouseData.fulfilled,
                (state: any, action: any) => {
                    if (action.payload.status == 200) {
                        state.clubhouse = mapClubhouseState(
                            state,
                            action.payload.data
                        );

                        setSucceededState(state);
                    } else {
                        setRejectedState(state);
                    }
                }
            )
            .addCase(fetchClubhouseData.rejected, (state: any) => {
                setRejectedState(state);
            })
            .addCase(StartVoting.pending, (state: any, action: any) => {
                const discussion: Discussion = state.clubhouse.discussions.find(
                    (d: Discussion) => d.id === action.meta.arg.discussionId
                );
                discussion.isVotingInitializing = true;
            })
            .addCase(AddPosterVote.pending, (state: any, action: any) => {
                const discussion: Discussion = state.clubhouse.discussions.find(
                    (d: Discussion) => d.id === action.meta.arg.discussionId
                );
                discussion.isVoteSaving = true;
            })
            .addCase(CancelPosterVote.pending, (state: any, action: any) => {
                const discussion: Discussion = state.clubhouse.discussions.find(
                    (d: Discussion) => d.id === action.meta.arg.discussionId
                );
                discussion.isVoteSaving = true;
            });
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

const mapClubhouseState = (state: any, newState: any) => {
    const selectedDiscussionId = state.clubhouse?.selectedDiscussionId;
    const currentMemberId =
        state.clubhouse?.currentMemberId ?? newState.currentMemberId;

    newState.members.forEach((member: Member) => {
        const memberState = state.clubhouse?.members?.find(
            (m: Member) => m.id === member.id
        )?.onlineState;
        member.onlineState = memberState ?? OnlineState.Offline;
        member.fullName = member.firstName.concat(' ', member.lastName);
        member.initials = (
            member.firstName.charAt(0) + member.lastName.charAt(0)
        )?.toUpperCase();
    });

    state.clubhouse = newState;
    state.clubhouse.selectedDiscussionId = selectedDiscussionId;
    state.clubhouse.currentMemberId = currentMemberId;
    state.clubhouse.discussions.forEach((d: Discussion) => {
        const member = state.clubhouse.members.find(
            (m: Member) => m.id === d.createdById
        );
        d.createdBy = member ? member.firstName + ' ' + member.lastName : '';
    });

    state.clubhouse.discussions.forEach((d: Discussion) => {
        d.posters.forEach((p: Poster) => {
            const member = state.clubhouse.members.find(
                (m: Member) => m.id === p.id
            );
            const fullName = member
                ? member.firstName + ' ' + member.lastName
                : '';
            p.fullName = fullName;
            p.isActive = member.isActive;
        });
    });

    return state.clubhouse;
};

export const { selectDiscussion, updateOnlineMembers, onClubhouseUpdate } =
    clubhouseSlice.actions;
export default clubhouseSlice.reducer;
