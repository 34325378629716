import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Modal, ModalHeader, Row } from 'reactstrap';
import Switch from 'react-switch';
import './UserAccessRightsModal.scss'
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { UserAccessRights } from '../../../../../clubhouse/models/userAccessRights';
import { USER_POC_ACCESS_RIGHTS } from '../../../../../utils/constants';

interface PocAccessManagingToolsProps {
    userId: number,
    onClose: () => void,
    updateUserPocAccessRights: (userPocAccessRights: number) => void,
    userAccessRights: UserAccessRights
}

const PocAccessManagingToolsModal = (props: PocAccessManagingToolsProps) => {
    const [loader, setLoader] = useState(false)
    const [userPocAccessRights, setUserPocAccessRights] = useState(props.userAccessRights.userPocAccessRights);

    const changeSwitcher = (value: number, eventValue: boolean) => {
        if (!eventValue) {
            return
        }
        setUserPocAccessRights(value)
    }

    return (
        <div>
            <Modal onClosed={props.onClose}
                   className="poc-access-rights-popup"
                   isOpen={true}>
                <ModalHeader className="poc-access-rights-popup-header" toggle={props.onClose}>Configure Member Access Rights</ModalHeader>
                <div className="user-access-rights-popup-content">
                    <div className="member-data">
                        <Row>
                            <Col>
                                <div className="text-style">Member: <span className="subtext-style">{props.userAccessRights.firstName} {props.userAccessRights.lastName}</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-style">Email: <span className="subtext-style">{props.userAccessRights.email}</span></div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <label className="main-label">Independent Practice Owner Community (IPOC)</label>
                            <div className="switches-container">
                                <FormGroup>
                                    <label className="text-style">Unrestricted Access</label>
                                    <Switch
                                        offColor="#d92550"
                                        checked={userPocAccessRights === USER_POC_ACCESS_RIGHTS.UNRESTRICTED_ACCESS}
                                        onChange={(event) => {
                                            changeSwitcher(USER_POC_ACCESS_RIGHTS.UNRESTRICTED_ACCESS, event)
                                        }
                                        }
                                        height={22}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="text-style">View Only</label>
                                    <Switch
                                        offColor="#d92550"
                                        checked={userPocAccessRights === USER_POC_ACCESS_RIGHTS.VIEW_ONLY}
                                        onChange={(event) => {
                                            changeSwitcher(USER_POC_ACCESS_RIGHTS.VIEW_ONLY, event)
                                        }
                                        }
                                        height={22}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="text-style">No Access</label>
                                    <Switch
                                        offColor="#d92550"
                                        checked={userPocAccessRights === USER_POC_ACCESS_RIGHTS.NO_ACCESS}
                                        onChange={(event) => {
                                            changeSwitcher(USER_POC_ACCESS_RIGHTS.NO_ACCESS, event)
                                        }
                                        }
                                        height={22}
                                    />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <div className="btn-footer">
                        <Button
                            className="btn close-button"
                            onClick={props.onClose}>
                            Cancel
                        </Button>
                        <ButtonLoader
                            buttonText={!loader ? 'Save' : 'Loading...'}
                            onClick={() => {
                                props.updateUserPocAccessRights(userPocAccessRights)
                            }}
                            disabled={false}
                            className='btn btn-primary'
                            isLoading={loader} loaderButtonText={undefined}></ButtonLoader>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default PocAccessManagingToolsModal;
