import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import './../../KnowledgeBaseContentManager/KnowledgeBaseContent.scss';
import {
    Label,
    FormGroup,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupText,
} from 'reactstrap';
import {
    KNOWLEDGE_BASE_TYPES,
    KNOWLEDGE_BASE_TYPES_ENUM,
} from '../../../../utils/knowledgeBaseConstants';
import createNotification from '../../../../utils/createNotification';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { Field, Form, Input } from '@availity/form';
import * as yup from 'yup';
import Rodal from '../../../../components/Rodal/Rodal';
import Helpers from '../../../../utils/helper';
import InfoTooltip from '../../../../components/info-tooltip/info-tooltip';
import { NOT_EMPTY_OR_WHITESPACE_REGEX } from '../../../../utils/constants';
import { useStateSelector } from '../../../../store/selectors';

const KnowledgeBaseOfferContentModal = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [isSaving, setIsSaving] = useState(false);
    const [file, setFile] = useState(null);
    const [content, setContent] = useState(emptyContent);

    const emptyContent = {
        title: '',
        introText: '',
        author: '',
        type: KNOWLEDGE_BASE_TYPES_ENUM.Other,
    };
    let modalForm = {};
    const resetModal = () => {
        document.body.style.overflow = 'unset';
        setFile(null);
        setIsSaving(false);
        props.setIsVisible(false);
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        let loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        if (loggedInUserSummary) {
            loggedInUserSummary = JSON.parse(loggedInUserSummary);
            emptyContent.author = loggedInUserSummary.name;
        }
        setContent({ ...emptyContent });
    }, []);

    const saveContent = () => {
        setIsSaving(true);
        const formData = new FormData();
        formData.append('File', file);
        formData.append('Data', JSON.stringify(content));
        axios
            .post('/api/KnowledgeBase/offer', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error occured while sending offered Knowledge Library Content.'
                    )
                ) {
                    createNotification(
                        'Knowledge Library Content has been successfully offered!',
                        'success'
                    );
                    resetModal();
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const initSubmit = () => {
        modalForm.handleSubmit();
    };

    const changeFileHandler = (event) => {
        setFile(event.target.files[0]);
        content.fileName = event.target.files[0]?.name;
        modalForm.setFieldValue(
            'sourceFile',
            event.target.files[0]?.name ?? '',
            true
        );
        const extension = content.fileName?.split('.').pop();
        if (extension) {
            const typeId =
                KNOWLEDGE_BASE_TYPES.find((e) =>
                    e.extensions?.includes('.' + extension)
                )?.id ?? 8;
            if (typeId) {
                content.type = typeId;
                modalForm.setFieldValue('type', typeId, false);
            }
        } else {
            content.type = KNOWLEDGE_BASE_TYPES_ENUM.Other;
        }
        setContent({ ...content });
    };

    return content ? (
        <div className="knowledge-base-content offer-content-popup">
            <Rodal
                visible={props.isVisible}
                onClose={() => resetModal()}
                animation={'fade'}
                showMask={true}
                className="modal-style kb-modal-content announcement-modal">
                <div className="body_white_box associate_member">
                    <ModalHeader>Offer Content</ModalHeader>
                    <ModalBody>
                        <Form
                            innerRef={(c) => {
                                modalForm = c;
                            }}
                            onSubmit={() => {
                                saveContent();
                            }}
                            initialValues={{
                                title: content.title,
                                author: content.author,
                                introText: content.introText,
                                sourceFile: content.fileName
                                    ? content.fileName
                                    : '',
                            }}
                            validationSchema={yup.object().shape({
                                title: yup
                                    .string()
                                    .required()
                                    .matches(
                                        NOT_EMPTY_OR_WHITESPACE_REGEX,
                                        ' '
                                    ),
                                author: yup
                                    .string()
                                    .required()
                                    .matches(
                                        NOT_EMPTY_OR_WHITESPACE_REGEX,
                                        ' '
                                    ),
                                introText: yup
                                    .string()
                                    .required()
                                    .matches(
                                        NOT_EMPTY_OR_WHITESPACE_REGEX,
                                        ' '
                                    ),
                            })}>
                            <div
                                className="associate_form announcement_form"
                                style={{ borderBottom: 0 }}>
                                <p>
                                    Offer here any high quality content you have
                                    for the Knowledge Library. If VetValue
                                    accepts and publishes your proposed content,
                                    VetValue reserves the right to provide you
                                    with a modest honorarium denominated in VV
                                    Reward Credits which will be deposited into
                                    your wallet.
                                </p>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Field
                                                type="text"
                                                label="Title"
                                                name="title"
                                                maxLength={200}
                                                onChange={(event) => {
                                                    content.title =
                                                        event.target.value;
                                                    setContent({
                                                        ...content,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Field
                                                type="text"
                                                label="Author"
                                                name="author"
                                                maxLength={200}
                                                onChange={(event) => {
                                                    content.author =
                                                        event.target.value;
                                                    setContent({
                                                        ...content,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="introText">
                                                Intro Text
                                            </Label>
                                            <InfoTooltip text="You may want to include in your introduction,<br/> a brief description of your background and professional qualifications,<br/> for context."></InfoTooltip>
                                            <Field
                                                type="textarea"
                                                name="introText"
                                                onChange={(event) => {
                                                    content.introText =
                                                        event.target.value;
                                                    setContent({
                                                        ...content,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="sourceFile">
                                                Source File
                                            </Label>
                                            <Label
                                                for="upload-file"
                                                className="upload-now">
                                                Upload New
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText className="upload-file-group prepend-addon">
                                                    <label
                                                        for="upload-file"
                                                        className="choose-file-label">
                                                        Choose File
                                                    </label>
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        id="upload-file"
                                                        className="upload-file-input"
                                                        onChange={
                                                            changeFileHandler
                                                        }
                                                    />
                                                </InputGroupText>
                                                <Input
                                                    type="text"
                                                    name="sourceFile"
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="footer-wrapper-container">
                            <Button
                                className="cancel"
                                onClick={() => resetModal()}>
                                Cancel
                            </Button>
                            <ButtonLoader
                                buttonText={'Offer Content'}
                                disabled={false}
                                isLoading={isSaving}
                                onClick={initSubmit}
                                className="btn btn-primary loader-submit"
                                color="primary"></ButtonLoader>
                        </div>
                    </ModalFooter>
                </div>
            </Rodal>
        </div>
    ) : (
        []
    );
};

export default KnowledgeBaseOfferContentModal;
