import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface LikeMessageThunkArg {
    connection: HubConnection;
    messageId: number;
    chatId: number;
}

export const likeSupportMessage = createAsyncThunk(
    'supportMessage/sendLike',
    async (arg: LikeMessageThunkArg) => {
        let like = {
            messageId: arg.messageId,
            threadId: arg.chatId,
        };
        await arg.connection.send('LikeMessage', like);
    }
);
