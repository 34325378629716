export class TableFilter {
    constructor(
        public filter: { filters: Array<any>; logic: string },
        public pageNumber: number,
        public pageSize: number,
        public sort: { dir: string; field: string }
    ) {}
}

export class TransactionTableFilter extends TableFilter {
    constructor(
        public filter: { filters: Array<any>; logic: string },
        public pageNumber: number,
        public pageSize: number,
        public sort: { dir: string; field: string },
        public selectedTransactionId: string | null
    ) {
        super(filter, pageNumber, pageSize, sort)
    }
}
