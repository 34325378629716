import moment from 'moment';
import React, { useState, useEffect } from 'react';

export interface RealTimeDifferenceTrackerProps {
    getDeadlineToTrack(): Date;
    callBackOnDeadlineReached(): void;
    maxDiffMs: number;
    callBackOnMaxDiffExceeded(): void;
}

export const RealTimeDifferenceTracker = (
    props: RealTimeDifferenceTrackerProps
) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        var timer = setInterval(() => {
            const currentDate = new Date();
            const startDate = moment(currentDate);
            const timeEnd = moment(props.getDeadlineToTrack());
            const diff = timeEnd.diff(startDate);
            const diffDuration = moment.duration(diff);
            if (diffDuration.asMilliseconds() > props.maxDiffMs) {
                props.callBackOnMaxDiffExceeded();
            }
            setHours(diffDuration.hours());
            setMinutes(diffDuration.minutes());
            setSeconds(diffDuration.seconds());
            if (diff <= 0) {
                props.callBackOnDeadlineReached();
            }
        }, 1000);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    return hours || minutes || seconds ? (
        <div>
            {hours ? <>{hours}:</> : <></>}
            {minutes}:{(seconds < 10 ? '0' : '') + seconds}
        </div>
    ) : (
        <div></div>
    );
};

export default RealTimeDifferenceTracker;
