import React, { useState, useEffect } from 'react';
import './ActiveSubscriptionsPaginatedTable.scss';
import BootstrapTable from '../react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../react-bootstrap-table-next/react-bootstrap-table2-paginator';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TableFilter } from '../Table/models/table-filter';
import { objectToQueryString } from '../../utils/queryHelper';
import createNotification from '../../utils/createNotification';
import Helpers from '../../utils/helper';
import UserDetailsModal from '../../containers/Admin/Permissions/Users/Modal/UserDetailsModal';
import { DropdownList } from 'react-widgets/cjs';
import { FormGroup } from 'reactstrap';
import { ArrowDown } from '../arrow-down/arrow-down';
import SubscriptionPaymentInfoPopup from '../SubscriptionPaymentInfoPopup/SubscriptionPaymentInfoPopup';
import Loader from 'react-loaders';
import { useStateSelector } from '../../store/selectors';

enum PaymentStatus {
    Pending = 1,
    Paid = 2,
    Failed = 3,
}

interface SubscriptionInfo {
    id: number;
    groupName: string;
    practiceName: string;
    ownerId: number;
    ownerName: string;
    serviceType: string;
    dateSubscriptionStart: Date;
    dateNextSubscriptionPayment: Date;
    subscriptionFee: number;
    subscriptionPaymentStatus: PaymentStatus;
}

interface ActiveSubscriptionsPaginatedTableProps {
    typeOptions: Array<any>;
    memberNameOptions: Array<any>;
    practiceNameOptions: Array<any>;
    isUserDetailsAvailable?: boolean;
    onTryPaymentAgainFinished(): void;
}

const ActiveSubscriptionsPaginatedTable = (
    props: ActiveSubscriptionsPaginatedTableProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
        useState(false);
    const [isPaymentInfoVisible, setIsPaymentInfoVisible] = useState(false);
    const [userId, setUserID] = useState(0);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
    const tableTooltipTargetId = 'active-subscriptions-paginated-table';
    const defaultSortDirection: { dataField: any; order: any } = {
        dataField: 'dateSubscriptionStart',
        order: 'desc',
    };

    const [sortingState, setSortingState] = useState<any>({
        data: [],
        filters: {},
        page: page,
        searchText: '',
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });

    const [tableFilters, setTalbeFilters] = useState<
        { field: string; value: string; operator: string }[]
    >([]);

    const PaymentStatusList = [
        { id: 0, name: 'None' },
        { id: 1, name: 'Pending' },
        { id: 2, name: 'Paid' },
        { id: 3, name: 'Failed' },
    ];

    useEffect(() => {
        if (
            props.memberNameOptions.length === 1 &&
            props.memberNameOptions[0]?.id
        ) {
            handleMemberNameChange(props.memberNameOptions[0].id);
        }
    }, [props]);

    useEffect(() => {
        if (sortingState) {
            fetchSubscriptionsData(sortingState);
        }
    }, [sortingState, tableFilters]);

    const handleTypeNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'groupId');
        if (changedProperty) {
            newFilters.push({
                field: 'groupId',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handlePracticeNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'practice.id');
        if (changedProperty) {
            newFilters.push({
                field: 'practice.id',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handleMemberNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'ownerId');
        if (changedProperty) {
            newFilters.push({
                field: 'ownerId',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handlePaymentStatusChange = (changedProperty: number) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter(
            (f) => f.field !== 'subscriptionPaymentStatus'
        );
        if (changedProperty) {
            newFilters.push({
                field: 'subscriptionPaymentStatus',
                value: changedProperty.toString(),
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const showUserDetailPopup = (userId: number) => {
        setIsUserDetailsModalVisible(true);
        setUserID(userId);
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'group.Name',
            text: 'Type',
            sort: true,
            formatter: (_cellContent: any, row: SubscriptionInfo) =>
                row.groupName,
        },
        {
            dataField: 'practice.Name',
            text: 'Practice Name',
            sort: true,
            formatter: (_cellContent: any, row: SubscriptionInfo) =>
                row.practiceName,
        },
        {
            dataField: 'ownerName',
            text: 'Member Name',
            sort: true,
            formatter: (_cellContent: any, row: SubscriptionInfo) => (
                <div
                    className={
                        props.isUserDetailsAvailable ? 'referenced-text' : ''
                    }
                    onClick={() =>
                        props.isUserDetailsAvailable
                            ? showUserDetailPopup(row.ownerId)
                            : []
                    }>
                    {row.ownerName}
                </div>
            ),
        },
        {
            dataField: 'serviceType',
            text: 'Service / Subscription',
            formatter: () => 'PMG Subscription',
        },
        {
            dataField: 'dateSubscriptionStart',
            text: 'Starting Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'dateNextSubscriptionPayment',
            text: 'Next Payment Due',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'subscriptionFee',
            text: 'Subscription Amount',
            formatter: (cellContent: number) =>
                Helpers.currencyFormatWithBrackets(cellContent, true, true),
            sort: true,
        },
        {
            dataField: 'subscriptionPaymentStatus',
            text: 'Payment Status',
            formatter: (cellContent: PaymentStatus) =>
                cellContent === PaymentStatus.Failed
                    ? PaymentStatusList[cellContent]?.name
                    : PaymentStatusList[PaymentStatus.Pending].name,
            sort: true,
        },
        {
            dataField: 'id',
            text: 'Details / Actions',
            formatter: (_cellContent: any, row: SubscriptionInfo) =>
                row.subscriptionPaymentStatus == PaymentStatus.Failed ? (
                    <button
                        type="button"
                        className="btn btn-primary info-button"
                        onClick={() => {
                            setSelectedSubscriptionId(row.id);
                            setIsPaymentInfoVisible(true);
                        }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                ) : (
                    <></>
                ),
        },
    ];

    const handleTableChange = (_type: any, newState: any) => {
        setSortingState(newState);
    };

    const fetchSubscriptionsData = (sortingState: any) => {
        let tableFilter = new TableFilter(
            { filters: tableFilters, logic: '' },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder,
                field: sortingState.sortField,
            }
        );

        axios
            .get(`api/serviceSubscriptions?${objectToQueryString(tableFilter)}`)
            .then((response: any) => {
                if (response?.status === 200) {
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'Error occured while fetching active subscriptions',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while fetching active subscriptions',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <div className="active-subscriptions-paginated-table">
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '200px',
                        }}>
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                ) : (
                    <>
                        <div className="filters-bar">
                            <FormGroup className="filter-scroller">
                                <div className="filter-item member-input">
                                    <label>Type</label>
                                    <DropdownList
                                        className="credit-type-select filter-enabled"
                                        selectIcon={<ArrowDown />}
                                        data={props.typeOptions}
                                        onChange={(value) => {
                                            handleTypeNameChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                </div>
                                <div className="line-separator"></div>
                                <div className="filter-item member-input">
                                    <label>Practice Name</label>
                                    <DropdownList
                                        className="credit-type-select filter-enabled"
                                        selectIcon={<ArrowDown />}
                                        data={props.practiceNameOptions}
                                        onChange={(value) => {
                                            handlePracticeNameChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                </div>
                                <div className="line-separator"></div>
                                <div className="filter-item member-input">
                                    <label>Member Name</label>
                                    <DropdownList
                                        className="credit-type-select filter-enabled"
                                        selectIcon={<ArrowDown />}
                                        data={props.memberNameOptions}
                                        value={
                                            props.memberNameOptions.length ===
                                                1 &&
                                            props.memberNameOptions[0]?.id
                                                ? {
                                                      ...props
                                                          .memberNameOptions[0],
                                                  }
                                                : undefined
                                        }
                                        disabled={
                                            props.memberNameOptions.length === 1
                                        }
                                        onChange={(value) => {
                                            handleMemberNameChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                </div>
                                <div className="line-separator"></div>
                                <div className="filter-item member-input">
                                    <label>Status</label>
                                    <DropdownList
                                        className="credit-type-select"
                                        selectIcon={<ArrowDown />}
                                        data={PaymentStatusList.filter(
                                            (f) => f.id != PaymentStatus.Paid
                                        )}
                                        onChange={(value) => {
                                            handlePaymentStatusChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                </div>
                            </FormGroup>
                        </div>
                        <BootstrapTable
                            remote
                            bootstrap4
                            keyField="id"
                            data={tableData}
                            columns={getColumns()}
                            defaultSorted={[defaultSortDirection]}
                            filter={filterFactory()}
                            pagination={paginationFactory({
                                page,
                                sizePerPage,
                                totalSize,
                                tooltipTargetId: tableTooltipTargetId,
                            })}
                            onTableChange={handleTableChange}
                            noDataIndication={'No Data available'}
                            rowClasses={(row: SubscriptionInfo) => {
                                var today = new Date(Date.now());
                                var dateNextSubscriptionPayment = new Date(
                                    row.dateNextSubscriptionPayment
                                );
                                return dateNextSubscriptionPayment < today
                                    ? 'overdue-payment'
                                    : '';
                            }}></BootstrapTable>
                    </>
                )}
            </div>
            {isUserDetailsModalVisible && (
                <UserDetailsModal
                    onClose={() => {
                        setIsUserDetailsModalVisible(false);
                    }}
                    isVisisble={isUserDetailsModalVisible}
                    userID={userId}></UserDetailsModal>
            )}
            {isPaymentInfoVisible ? (
                <SubscriptionPaymentInfoPopup
                    subscriptionId={selectedSubscriptionId}
                    close={() => setIsPaymentInfoVisible(false)}
                    chargingFinished={() => {
                        fetchSubscriptionsData(sortingState);
                        setIsPaymentInfoVisible(false);
                        props.onTryPaymentAgainFinished();
                    }}
                />
            ) : (
                []
            )}
        </div>
    );
};

export default ActiveSubscriptionsPaginatedTable;
