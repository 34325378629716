import {
    faFile,
    faFileImage,
    faFileVideo,
    faFilePdf,
    faFileAudio,
    faFileExcel,
    faFileWord,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

export class FileType {
    constructor(
        public id: number,
        public name: string,
        public extensions: string[],
        public icon: IconDefinition
    ) {}
}

export const FILE_EXTENSIONS = [
    new FileType(1, 'PDF', ['.pdf'], faFilePdf),
    new FileType(
        2,
        'Excel',
        ['.xls', '.xlsx', '.xlsm', '.xlsb', '.xltx'],
        faFileExcel
    ),
    new FileType(3, 'Word', ['.doc', '.docx'], faFileWord),
    new FileType(
        4,
        'Audio',
        [
            '.mp3',
            '.wma',
            '.aac',
            '.wav',
            '.flac',
            '.aif',
            '.aiff',
            '.iff',
            '.m3u',
            '.m4a',
            '.mid',
            '.mpa',
            '.oga',
            '.ra',
        ],
        faFileAudio
    ),
    new FileType(
        5,
        'Video',
        [
            '.mp4',
            '.mov',
            '.wmv',
            '.avi',
            '.avchd',
            '.flv',
            '.f4v',
            '.swf',
            '.webm',
            '.mkv',
        ],
        faFileVideo
    ),
    new FileType(
        6,
        'Image',
        [
            '.jpg',
            '.jpeg',
            '.gif',
            '.png',
            '.jfif',
            '.pjpeg',
            '.pjp',
            '.svg',
            '.webp',
            '.bmp',
            '.ico',
            '.cur',
            '.tif',
            '.tiff',
            '.apng',
            '.avif',
        ],
        faFileImage
    ),
    new FileType(8, 'Other', null, faFile),
];
