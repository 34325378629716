export const YEARS_OF_PRACTICE_OWNERSHIP_DROPDOWN_ITEMS = [
    { id: 0, name: 'Less than a year' },
    { id: 1, name: '1 year' },
    { id: 2, name: '2 years' },
    { id: 3, name: '3 years' },
    { id: 4, name: '4 years' },
    { id: 5, name: '5 years' },
    { id: 6, name: '6 years' },
    { id: 7, name: '7 years' },
    { id: 8, name: '8 years' },
    { id: 9, name: '9 years' },
    { id: 10, name: '10 years' },
    { id: 11, name: '11 years' },
    { id: 12, name: '12 years' },
    { id: 13, name: '13 years' },
    { id: 14, name: '14 years' },
    { id: 15, name: '15 years' },
    { id: 16, name: '16 years' },
    { id: 17, name: '17 years' },
    { id: 18, name: '18 years' },
    { id: 19, name: '19 years' },
    { id: 20, name: '20 years' },
    { id: 21, name: '21 years' },
    { id: 22, name: '22 years' },
    { id: 23, name: '23 years' },
    { id: 24, name: '24 years' },
    { id: 25, name: '25 years' },
    { id: 26, name: '26 years' },
    { id: 27, name: '27 years' },
    { id: 28, name: '28 years' },
    { id: 29, name: '29 years' },
    { id: 30, name: '30 years' },
    { id: 999, name: 'More than 30 years' },
];

export const NUMBER_OF_OWNERS_REGISTRATION_DROPDOWN_ITEMS = [
    { id: 1, name: `I'm the sole owner` },
    { id: 2, name: `2` },
    { id: 3, name: `3` },
    { id: 4, name: `4` },
    { id: 5, name: `5` },
    { id: 6, name: `6` },
    { id: 7, name: `7` },
    { id: 8, name: `8` },
    { id: 9, name: `9` },
    { id: 10, name: `10` },
    { id: 999, name: 'More than 10' },
];

export const NUMBER_OF_OWNERS_DROPDOWN_ITEMS = [
    { id: 1, name: `1` },
    { id: 2, name: `2` },
    { id: 3, name: `3` },
    { id: 4, name: `4` },
    { id: 5, name: `5` },
    { id: 6, name: `6` },
    { id: 7, name: `7` },
    { id: 8, name: `8` },
    { id: 9, name: `9` },
    { id: 10, name: `10` },
    { id: 999, name: 'More than 10' },
];

export const NUMBER_OF_ADDITIONAL_OWNERS_DROPDOWN_ITEMS = [
    { id: 0, name: `No additional owners` },
    { id: 1, name: `1` },
    { id: 2, name: `2` },
    { id: 3, name: `3` },
    { id: 4, name: `4` },
    { id: 5, name: `5` },
    { id: 6, name: `6` },
    { id: 7, name: `7` },
    { id: 8, name: `8` },
    { id: 9, name: `9` },
    { id: 10, name: `10` },
    { id: 999, name: 'More than 10' },
];

export const PRACTICE_FOCUS_DROPDOWN_ITEMS = [
    { id: 1, name: 'General Practice' },
    { id: 2, name: 'Referral Specialty / Emergency' },
    { id: 3, name: 'Equine / Large Animal' },
    { id: 4, name: 'Other' },
];

export const OWNERS_PRACTICE = [
    { key: 'ft', name: 'Full-time' },
    { key: 'pt', name: 'Part-time' },
    { key: 'no', name: 'No' },
];

export const OWNERS_PRACTICE_NUMBERS = [
    { id: '1', name: 'Full Time' },
    { id: '2', name: 'Part Time' },
    { id: '0', name: 'Owner does not Practice' },
];

export const OWNERS_PLANS_IN_MEDICINE = [
    {
        name: 'Continue to own/practice for no more than 2 years',
        id: 1,
    },
    {
        name: 'Continue to own/practice for 3-5 years',
        id: 2,
    },
    {
        name: 'Continue to own/practice for 6-10 years',
        id: 4,
    },
    {
        name: 'Continue to own/practice for 11+ years',
        id: 3,
    },
];
