export enum NotificationFlagsCategories {
    // Categories
    PracticesCode = 'PRACTICES',
    PmgCode = 'PMG',
    PocCode = 'POC',
    ReferralHubCode = 'RP',
    KnowledgeLibraryCode = 'KL',
    AccountCode = 'ACCOUNT',
    OtherDataRequests = 'OTHER_DR',

    // Sub-categories
    FirstTimeVisitCode = 'FIRST_TIME_VISIT',
    CalendarCode = 'CALENDAR',
    ServicesPurchasingCode = 'SP',
    ClubBoardCode = 'CLUBBOARD',
    ClubRoomCode = 'CLUBROOM',
    VirtualMeetingCode = 'VM',
    ClipCode = 'SHARED.CLIP',
    ReferredPartiesCode = 'REFERRED_PARTIES',
    ReferralRewardsCode = 'REFERRAL_REWARDS',
    WalletCode = 'WALLET',
    RewardsHistoryCode = 'REWARDS_HISTORY',
    PurchaseHistoryCode = 'PURCHASE_HISTORY',
}
