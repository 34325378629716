import {
    faFile,
    faFileImage,
    faFileVideo,
    faFilePdf,
    faFileAudio,
    faFileExcel,
    faFileWord,
    faLink,
} from '@fortawesome/free-solid-svg-icons';

export const KNOWLEDGE_BASE_TYPES_ENUM = {
    Pdf: 1,
    Excel: 2,
    Word: 3,
    Audio: 4,
    Video: 5,
    Image: 6,
    Link: 7,
    Other: 8,
};
export const KNOWLEDGE_BASE_TYPES = [
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Pdf,
        name: 'PDF',
        extensions: ['.pdf'],
        icon: faFilePdf,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Excel,
        name: 'Excel',
        extensions: ['.xls', '.xlsx', '.xlsm', '.xlsb', '.xltx'],
        icon: faFileExcel,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Word,
        name: 'Word',
        extensions: ['.doc', '.docx'],
        icon: faFileWord,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Audio,
        name: 'Audio',
        extensions: [
            '.mp3',
            '.wma',
            '.aac',
            '.wav',
            '.flac',
            '.aif',
            '.aiff',
            '.iff',
            '.m3u',
            '.m4a',
            '.mid',
            '.mpa',
            '.oga',
            '.ra',
        ],
        icon: faFileAudio,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Video,
        name: 'Video',
        extensions: [
            '.mp4',
            '.mov',
            '.wmv',
            '.avi',
            '.avchd',
            '.flv',
            '.f4v',
            '.swf',
            '.webm',
            '.mkv',
        ],
        icon: faFileVideo,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Image,
        name: 'Image',
        extensions: [
            '.jpg',
            '.jpeg',
            '.gif',
            '.png',
            '.jfif',
            '.pjpeg',
            '.pjp',
            '.svg',
            '.webp',
            '.bmp',
            '.ico',
            '.cur',
            '.tif',
            '.tiff',
            '.apng',
            '.avif',
        ],
        icon: faFileImage,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Link,
        name: 'Link',
        extensions: null,
        icon: faLink,
    },
    {
        id: KNOWLEDGE_BASE_TYPES_ENUM.Other,
        name: 'Other',
        extensions: null,
        icon: faFile,
    },
];

export const KNOWLEDGE_BASE_SORT_OPTIONS = [
    {
        id: '',
        name: '',
    },
    {
        id: 'asc',
        name: 'Ascending',
    },
    {
        id: 'desc',
        name: 'Descending',
    },
];
export const KNOWLEDGE_CONTENT_STATUSES = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Offered' },
    { id: 3, name: 'In Progress' },
    { id: 4, name: 'Ready For Approval' },
];

export const KNOWLEDGE_CONTENT_STATUSES_ENUM = {
    Draft: 0,
    Active: 1,
    Offered: 2,
};

export const CONTENT_LEVEL = [
    { id: -1, name: 'Free' },
    { id: 0, name: 'General' },
    { id: 1, name: 'P' },
    { id: 2, name: 'P1' },
    { id: 3, name: 'P2' },
    { id: 4, name: 'P3' },
];

export const REQUESTED_CONTENT_STATUS = [
    { id: 0, name: 'Submitted' },
    { id: 1, name: 'Reviewed' },
    { id: 2, name: 'Ready for Approval' },
    { id: 3, name: 'In Progress' },
];

export const KNOWLEDGE_CONTENT_ALLOCATION_ENUM = {
    General: 1,
    Individual: 2,
    Corporate: 3,
};

export const KNOWLEDGE_CONTENT_ALLOCATION = [
    { id: 2, name: 'Individual' },
    { id: 1, name: 'General' },
    { id: 3, name: 'Corporate' },
];
