import { GroupType } from '../../clubhouse/models/group-type';
import { NotificationFlagsCategories } from '../../models/NotificationFlags/NotificationFlagsCategories';

export class PmgPocChatNotificationFlagsHelper {
    public static generateNotificationFlag = (
        groupType: GroupType,
        groupId: number,
        discussionId: number
    ) =>
        groupType === GroupType.PMG
            ? `${NotificationFlagsCategories.PmgCode}.${groupId}.${NotificationFlagsCategories.ClubBoardCode}.${discussionId}`
            : `${NotificationFlagsCategories.PocCode}.${discussionId}`;
}
