import { loadRoomMessages } from './load-room-messages.thunk';
import { createSlice } from '@reduxjs/toolkit';
import { addToMessageList } from './add-to-message-list.thunk';
import { refreshDirectRoomMessageList } from './refresh-direct-room-message-list.thunk';
import { checkForChanges } from './check-for-changes.thunk';
import { addLikeToMessage } from './add-like-to-message.thunk';
import { MessagesGroup } from '../../../clubhouse/models/messages-group';
import { Message } from '../../../clubhouse/models/message';
import { Attachment } from '../../../clubhouse/models/attachment';
import { DirectMessagesState } from './direct-messages.state';

const initialState: DirectMessagesState = {
    rooms: [] as Array<MessagesGroup>,
    isPageLoading: false,
    isMessageLoading: false,
    status: '',
    error: '',
    maxMessagesCount: 600,
    isNewMessageReceived: false,
    numberOfUnreadMessages: 0,
};

const directMessagesSlice = createSlice({
    name: 'directMessages',
    initialState,
    reducers: {
        setIsNewDirectMessageReceived(state, action) {
            state.isNewMessageReceived = action.payload;
        },
        setNumberOfDirectUnreadMessages(state, action) {
            state.numberOfUnreadMessages = action.payload;
        },
        UpdateState(state, action) {
            Object.keys(state.rooms).forEach((id: any) => {
                const roomIds = action.payload.rooms.map(
                    (room: any) => room.id
                );
                if (!roomIds.includes(Number(id))) {
                    delete state.rooms[id];
                }
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadRoomMessages.pending, (state) =>
                setPendingState(state)
            )
            .addCase(loadRoomMessages.fulfilled, (state, action) => {
                const room = state.rooms[action.payload.roomId];
                if (room) {
                    if (!action.payload.isAscending) {
                        if (action.payload.data.length === 0) {
                            room.hasLastPage = true;
                        } else {
                            room.hasLastPage = false;
                        }
                    }
                    room.messages = mergeMessagesAndTrim(
                        room.messages,
                        action.payload.data,
                        state.maxMessagesCount
                    );
                    room.lastMessageId = action.payload.lastMessageId;
                    room.hasFirstPage = hasMessageWithId(
                        room.messages,
                        action.payload.lastMessageId
                    );
                    state.rooms = {
                        ...state.rooms,
                        [action.payload.roomId]: { ...room },
                    };
                } else {
                    state.rooms = mapRoom(state, action.payload);
                }

                setSucceededState(state);
            })
            .addCase(loadRoomMessages.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(addToMessageList.pending, (state) =>
                setPendingState(state)
            )
            .addCase(addToMessageList.fulfilled, (state, action) => {
                const room = state.rooms[action.payload.roomId];

                if (room && (room.hasFirstPage || room.messages.length === 0)) {
                    if (room.messages.length === 0) {
                        room.hasFirstPage = true;
                    }
                    mapAttachments(action.payload.message);
                    room.messages.push(action.payload.message);
                    room.lastMessageId = action.payload.message.id;
                    state.rooms = {
                        ...state.rooms,
                        [action.payload.roomId]: {
                            ...room,
                        },
                    };
                }

                setSucceededState(state);
            })
            .addCase(addLikeToMessage.fulfilled, (state, action) => {
                if (action && action.payload && action.payload.roomId) {
                    const room = state.rooms[action.payload.roomId];
                    if (room) {
                        if (room.messages.length === 0) {
                            room.hasFirstPage = true;
                        }
                        let messageToUpdate = room.messages.find(
                            (x) => x.id === action.payload.message.id
                        );
                        if (messageToUpdate) {
                            var index = room.messages.indexOf(messageToUpdate);
                            messageToUpdate.likerIds =
                                action.payload.message.likerIds;
                            if (index !== -1) {
                                room.messages[index] = messageToUpdate;
                            }
                        } else {
                            room.messages.push(action.payload.message);
                            room.messages.sort((a, b) => a.id - b.id);
                        }
                        state.rooms = {
                            ...state.rooms,
                            [action.payload.roomId]: {
                                ...room,
                            },
                        };
                    }
                }
                setSucceededState(state);
            })
            .addCase(addToMessageList.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(refreshDirectRoomMessageList.pending, (state) =>
                setPendingState(state)
            )
            .addCase(
                refreshDirectRoomMessageList.fulfilled,
                (state, action) => {
                    state.rooms = mapRoom(state, action.payload);
                    state.rooms[action.payload.roomId].hasFirstPage = true;
                    setSucceededState(state);
                }
            )
            .addCase(refreshDirectRoomMessageList.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(checkForChanges.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(checkForChanges.pending, (state) => setPendingState(state))
            .addCase(checkForChanges.fulfilled, (state, action) => {
                const room = state.rooms[action.payload.roomId];
                if (
                    room &&
                    action.payload.lastMessageId !== room.lastMessageId
                ) {
                    if (room.hasFirstPage) {
                        state.rooms = mapRoom(state, action.payload);
                    }
                }

                setSucceededState(state);
            });
    },
});

const setRejectedState = (state: any) => {
    state.isPageLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isPageLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isPageLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

const mergeMessagesAndTrim = (
    storedMessages: Array<Message>,
    newMessages: Array<Message>,
    frameSize: number
) => {
    let filteredNewMessages = newMessages.filter(
        (m: Message) => !storedMessages.find((s: Message) => s.id === m.id)
    );
    filteredNewMessages = newMessages.map((m: Message) => {
        mapAttachments(m);
        return m;
    });
    const elementsToCut =
        storedMessages.length + filteredNewMessages.length - frameSize;
    if (filteredNewMessages.length && storedMessages.length) {
        const isPrepending =
            new Date(filteredNewMessages[0].dateCreated) <
            new Date(storedMessages[0].dateCreated);
        if (isPrepending) {
            const prependedUntrimmedResult = [
                ...filteredNewMessages,
                ...storedMessages,
            ];

            return trimFromRight(prependedUntrimmedResult, elementsToCut);
        }
    }
    const appendedUntrimmedResult = [...storedMessages, ...filteredNewMessages];
    return trimFromLeft(appendedUntrimmedResult, elementsToCut);
};

const trimFromLeft = (array: Array<Message>, elementsToCut: number) => {
    if (elementsToCut > 0) {
        return array.slice(elementsToCut);
    }
    return array;
};

const trimFromRight = (array: Array<Message>, elementsToCut: number) => {
    if (elementsToCut > 0) {
        return array.slice(0, array.length - elementsToCut);
    }
    return array;
};

const mapRoom = (state: any, response: any) => {
    const messages = response.data.map((m: Message) => {
        mapAttachments(m);
        return m;
    });
    return {
        ...state.rooms,
        [response.roomId]: {
            messages: messages,
            lastMessageId: response.lastMessageId,
            hasFirstPage: hasMessageWithId(
                response.data,
                response.lastMessageId
            ),
        },
    };
};

const mapAttachments = (message: Message) => {
    message.attachments = message.attachments.map(
        (a: Attachment) => new Attachment(a.id, a.name, a.size)
    );
};

const hasMessageWithId = (messages: Array<Message>, messageId: number) =>
    messages.some((m: Message) => m.id === messageId);

export const {
    setIsNewDirectMessageReceived,
    UpdateState,
    setNumberOfDirectUnreadMessages,
} = directMessagesSlice.actions;
export default directMessagesSlice.reducer;
