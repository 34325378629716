import { loadDiscussionMessages } from './load-discussion-messages.thunk';
import { createSlice } from '@reduxjs/toolkit';
import { addToMessageList } from './add-to-message-list.thunk';
import { refreshMessageList } from './refresh-message-list.thunk';
import { checkForChanges } from './check-for-changes.thunk';
import { Message } from '../../models/message';
import { MessagesGroup } from '../../models/messages-group';
import { Attachment } from '../../models/attachment';
import { addLikeToMessage } from './add-like-to-message.thunk';
import { loadDiscussionMessagesByLikes } from './load-discussion-messages-by-likes.thunk';
import { UpdateContentStatus } from './update-content-status.thunk';
import { updateMessageInList } from './update-message-in-list.thunk';
import { MessagesSliceState } from './messages-slice.state';

const initialState: MessagesSliceState = {
    discussions: [] as Array<MessagesGroup>,
    isPageLoading: false,
    isMessageLoading: false,
    status: '',
    error: '',
    maxMessagesCount: 600,
    isNewMessageReceived: false,
};

const clubhouseMessagesSlice = createSlice({
    name: 'clubhouseMessages',
    initialState,
    reducers: {
        setIsNewMessageReceived(state, action) {
            state.isNewMessageReceived = action.payload;
        },
        UpdateState(state, action) {
            Object.keys(state.discussions).forEach((id: any) => {
                const discussionIds = action.payload.discussions.map(
                    (discussion: any) => discussion.id
                );
                if (!discussionIds.includes(Number(id))) {
                    delete state.discussions[id];
                }
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadDiscussionMessages.pending, (state) =>
                setPendingState(state)
            )
            .addCase(loadDiscussionMessages.fulfilled, (state, action) => {
                if (action.payload.replaceList) {
                    state.discussions[action.payload.discussionId] = undefined;
                }
                const discussion =
                    state.discussions[action.payload.discussionId];
                if (discussion) {
                    if (!action.payload.isAscending) {
                        if (action.payload.data.length === 0) {
                            discussion.hasLastPage = true;
                        } else {
                            discussion.hasLastPage = false;
                        }
                    } else {
                        discussion.hasLastPage = false;
                    }
                    discussion.messages = mergeMessagesAndTrim(
                        discussion.messages,
                        action.payload.data,
                        state.maxMessagesCount,
                        false
                    );
                    discussion.lastMessageId = action.payload.lastMessageId;
                    discussion.hasFirstPage = hasMessageWithId(
                        discussion.messages,
                        action.payload.lastMessageId
                    );
                    state.discussions = {
                        ...state.discussions,
                        [action.payload.discussionId]: { ...discussion },
                    };
                } else {
                    state.discussions = mapDiscussion(state, action.payload);
                }

                setSucceededState(state);
            })
            .addCase(loadDiscussionMessages.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(loadDiscussionMessagesByLikes.pending, (state) =>
                setPendingState(state)
            )
            .addCase(
                loadDiscussionMessagesByLikes.fulfilled,
                (state, action) => {
                    const discussion =
                        state.discussions[action.payload.discussionId];
                    if (discussion) {
                        if (!action.payload.isAscending) {
                            if (action.payload.data.length === 0) {
                                discussion.hasLastPage = true;
                            } else {
                                discussion.hasLastPage = false;
                            }
                        } else {
                            discussion.hasLastPage = false;
                        }
                        discussion.messages = mergeMessagesAndTrim(
                            discussion.messages,
                            action.payload.data,
                            state.maxMessagesCount,
                            true
                        );
                        discussion.lastMessageId = action.payload.lastMessageId;
                        discussion.hasFirstPage = hasMessageWithId(
                            discussion.messages,
                            action.payload.lastMessageId
                        );
                        state.discussions = {
                            ...state.discussions,
                            [action.payload.discussionId]: { ...discussion },
                        };
                    } else {
                        state.discussions = mapDiscussion(
                            state,
                            action.payload
                        );
                    }

                    setSucceededState(state);
                }
            )
            .addCase(loadDiscussionMessagesByLikes.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(addToMessageList.pending, (state) =>
                setPendingState(state)
            )
            .addCase(addToMessageList.fulfilled, (state, action) => {
                const discussion =
                    state.discussions[action.payload.discussionId];

                if (
                    discussion &&
                    (discussion.hasFirstPage ||
                        discussion.messages.length === 0)
                ) {
                    if (discussion.messages.length === 0) {
                        discussion.hasFirstPage = true;
                    }
                    mapAttachments(action.payload.message);
                    discussion.messages.push(action.payload.message);
                    discussion.lastMessageId = action.payload.message.id;
                    state.discussions = {
                        ...state.discussions,
                        [action.payload.discussionId]: {
                            ...discussion,
                        },
                    };
                }

                setSucceededState(state);
            })
            .addCase(updateMessageInList.pending, (state) =>
                setPendingState(state)
            )
            .addCase(updateMessageInList.fulfilled, (state, action) => {
                const discussion =
                    state.discussions[action.payload.discussionId];

                if (discussion) {
                    const message = discussion.messages.find(
                        (m) => m.id === action.payload.message.id
                    );
                    if (!message) {
                        setSucceededState(state);
                        return;
                    }
                    message.htmlContent = action.payload.message.htmlContent;
                    message.initialHtmlContent =
                        action.payload.message.initialHtmlContent;
                    state.discussions = {
                        ...state.discussions,
                        [action.payload.discussionId]: {
                            ...discussion,
                        },
                    };
                }

                setSucceededState(state);
            })
            .addCase(addLikeToMessage.fulfilled, (state, action) => {
                if (action && action.payload && action.payload.discussionId) {
                    const discussion =
                        state.discussions[action.payload.discussionId];
                    if (discussion) {
                        if (discussion.messages.length === 0) {
                            discussion.hasFirstPage = true;
                        }
                        let messageToUpdate = discussion.messages.find(
                            (x) => x.id === action.payload.message.id
                        );
                        if (messageToUpdate) {
                            var index =
                                discussion.messages.indexOf(messageToUpdate);
                            messageToUpdate.likerIds =
                                action.payload.message.likerIds;
                            if (index !== -1) {
                                discussion.messages[index] = messageToUpdate;
                            }
                        } else {
                            discussion.messages.push(action.payload.message);
                            discussion.messages.sort((a, b) => a.id - b.id);
                        }
                        state.discussions = {
                            ...state.discussions,
                            [action.payload.discussionId]: {
                                ...discussion,
                            },
                        };
                    }
                }
                setSucceededState(state);
            })
            .addCase(UpdateContentStatus.fulfilled, (state, action) => {
                const discussion =
                    state.discussions[action.payload.discussionId];
                const content = discussion.messages
                    .find((f) => f.id === action.payload.messageId)
                    .sharedContents.find(
                        (f) => f.id === action.payload.contentId
                    );
                content.shouldBeUpdated = false;
                content.isAddedToShelf = action.payload.isAddedToShelf;

                state.discussions = {
                    ...state.discussions,
                    [action.payload.discussionId]: {
                        ...discussion,
                    },
                };
            })
            .addCase(addToMessageList.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(refreshMessageList.pending, (state) =>
                setPendingState(state)
            )
            .addCase(refreshMessageList.fulfilled, (state, action) => {
                state.discussions = mapDiscussion(state, action.payload);
                state.discussions[action.payload.discussionId].hasFirstPage =
                    true;
                setSucceededState(state);
            })
            .addCase(refreshMessageList.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(checkForChanges.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(checkForChanges.pending, (state) => setPendingState(state))
            .addCase(checkForChanges.fulfilled, (state, action) => {
                const discussion =
                    state.discussions[action.payload.discussionId];
                if (
                    discussion &&
                    action.payload.lastMessageId !== discussion.lastMessageId
                ) {
                    if (discussion.hasFirstPage) {
                        state.discussions = mapDiscussion(
                            state,
                            action.payload
                        );
                    }
                }

                setSucceededState(state);
            });
    },
});

const setRejectedState = (state: any) => {
    state.isPageLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isPageLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isPageLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

const mergeMessagesAndTrim = (
    storedMessages: Array<Message>,
    newMessages: Array<Message>,
    frameSize: number,
    countLikes: boolean
) => {
    let filteredNewMessages = newMessages.filter(
        (m: Message) => !storedMessages.find((s: Message) => s.id === m.id)
    );
    filteredNewMessages = filteredNewMessages.map((m: Message) => {
        mapAttachments(m);
        return m;
    });
    const elementsToCut =
        storedMessages.length + filteredNewMessages.length - frameSize;
    if (filteredNewMessages.length && storedMessages.length) {
        let isPrepending =
            new Date(filteredNewMessages[0].dateCreated) <
            new Date(storedMessages[0].dateCreated);
        isPrepending = countLikes
            ? filteredNewMessages[0].likerIds.length <
                  storedMessages[0].likerIds.length ||
              (filteredNewMessages[0].likerIds.length ===
                  storedMessages[0].likerIds.length &&
                  isPrepending)
            : isPrepending;
        if (isPrepending) {
            const prependedUntrimmedResult = [
                ...filteredNewMessages,
                ...storedMessages,
            ];

            return trimFromRight(prependedUntrimmedResult, elementsToCut);
        }
    }
    const appendedUntrimmedResult = [...storedMessages, ...filteredNewMessages];
    return trimFromLeft(appendedUntrimmedResult, elementsToCut);
};

const trimFromLeft = (array: Array<Message>, elementsToCut: number) => {
    if (elementsToCut > 0) {
        return array.slice(elementsToCut);
    }
    return array;
};

const trimFromRight = (array: Array<Message>, elementsToCut: number) => {
    if (elementsToCut > 0) {
        return array.slice(0, array.length - elementsToCut);
    }
    return array;
};

const mapDiscussion = (state: any, response: any) => {
    const messages = response.data.map((m: Message) => {
        mapAttachments(m);
        return m;
    });
    return {
        ...state.discussions,
        [response.discussionId]: {
            messages: messages,
            lastMessageId: response.lastMessageId,
            hasFirstPage: hasMessageWithId(
                response.data,
                response.lastMessageId
            ),
        },
    };
};

const mapAttachments = (message: Message) => {
    message.attachments = message.attachments.map(
        (a: Attachment) => new Attachment(a.id, a.name, a.size)
    );
};

const hasMessageWithId = (messages: Array<Message>, messageId: number) =>
    messages.some((m: Message) => m.id === messageId);

export const { setIsNewMessageReceived, UpdateState } =
    clubhouseMessagesSlice.actions;
export default clubhouseMessagesSlice.reducer;
