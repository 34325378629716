import Answer from '../Answer';
import { isQuestionVisibleByLogic } from '../DataFormQuestion';
import { DataFormType } from '../DataFormTypeEnum';
import { DataRequestProject } from '../DataRequestProject';
import FormNotificationStatus from '../FormNotificationStatus';
import { QuestionProgressStatus } from '../QuestionProgressStatusEnum';
import QuestionSeenStatus, {
    calculateQuestionUserNotifications,
} from '../QuestionSeenStatus';
import { ResetQuestionInfo } from './data-request-notifications.slice';

export const mapQuestionsStatus = (
    projects: DataRequestProject[]
): QuestionSeenStatus[] => {
    const forms = projects.flatMap((project) => project.dataForms);

    if (forms.length) {
        const questionsSeenStatus = forms.flatMap((m) =>
            m.questions.map((q): QuestionSeenStatus => {
                const questionProject = projects.find((project) =>
                    project.dataForms.some((dataForm) =>
                        dataForm.questions.some(
                            (question) => question.id === q.id
                        )
                    )
                );
                const isNewProject = !questionProject.isSeenByUser;

                return {
                    ...q,
                    isSeenByUser:
                        isNewProject || m.isNew ? true : q.isSeenByUser,
                    isSeenByAdmin: q.isSeenByAdmin,
                    isUserQuestionHighlighted: q.isUserQuestionHighlighted,
                    isAdminFeedbackHighlighted:
                        isNewProject || m.isNew
                            ? false
                            : q.isAdminFeedbackHighlighted,
                    dataFormType: m.formType,
                    isMarkedAsComplete: q.isMarkedAsComplete,
                    isNewProject: isNewProject,
                    isNewForm: m.isNew,
                    formId: m.id,
                    projectId: questionProject.id,
                };
            })
        );

        return questionsSeenStatus;
    }

    return [];
};

export const resetFormTypeQuestionsNotifications = (
    questionsStatus: QuestionSeenStatus[],
    formType: DataFormType
): QuestionSeenStatus[] => {
    if (formType === DataFormType.Questionnaire) {
        return questionsStatus;
    }

    const updatedQuestionsStatus = questionsStatus.map((questionStatus) => {
        if (questionStatus.dataFormType === formType) {
            const updatedQuestionStatus = {
                ...questionStatus,
                isSeenByUser: true,
                isSeenByAdmin: true,
                isUserQuestionHighlighted: false,
                isAdminFeedbackHighlighted: false,
                isNewForm: false,
                isNewProject: false,
            };

            return updatedQuestionStatus;
        }

        return questionStatus;
    });

    return updatedQuestionsStatus;
};

export const resetQuestionsNotificationsByFormId = (
    questionsStatus: QuestionSeenStatus[],
    formId: number
): QuestionSeenStatus[] => {
    const updatedQuestionsStatus = questionsStatus.map((questionStatus) => {
        if (questionStatus.formId === formId) {
            const updatedQuestionStatus = {
                ...questionStatus,
                isSeenByUser: true,
                isSeenByAdmin: true,
                isUserQuestionHighlighted: false,
                isAdminFeedbackHighlighted: false,
                isNewForm: false,
                isNewProject: false,
            };

            return updatedQuestionStatus;
        }

        return questionStatus;
    });

    return updatedQuestionsStatus;
};

export const resetQuestionsNotifications = (
    questionsStatus: QuestionSeenStatus[],
    questionIdsToReset: ResetQuestionInfo[],
    formId: number
): QuestionSeenStatus[] => {
    if (!questionIdsToReset.length || !questionsStatus.length) {
        return questionsStatus;
    }

    const isNewForm = questionsStatus
        .filter((f) => f.formId === formId)
        .every((e) => e.isNewForm);

    if (isNewForm) {
        return resetQuestionsNotificationsByFormId(questionsStatus, formId);
    }

    const updatedQuestionsStatus = questionsStatus.map((status) => {
        const isTargetQuestion = !!questionIdsToReset?.find(
            (questionInfo) =>
                questionInfo.questionId === status.id &&
                status.isSubQuestion === questionInfo.isSubquestion
        );

        const isSubquestionOfTarget = !!questionIdsToReset?.find(
            (questionInfo) =>
                questionInfo.questionId === status.originQuestionId
        );

        if (isTargetQuestion || isSubquestionOfTarget) {
            return {
                ...status,
                isSeenByUser: true,
                isSeenByAdmin: true,
                isUserQuestionHighlighted: false,
                isAdminFeedbackHighlighted: false,
                isNewForm: false,
                isNewProject: false,
            };
        }

        return status;
    });

    return updatedQuestionsStatus;
};

export const mapNotificationsForAppearedQuestions = (
    questionsSeenStatus: QuestionSeenStatus[],
    changedQuestion: QuestionSeenStatus
): QuestionSeenStatus[] => {
    const dependentQuestions = questionsSeenStatus.filter(
        (question) =>
            question.logicParentCustomId === changedQuestion.customQuestionId &&
            question.formId === changedQuestion.formId &&
            question.dataFormType === changedQuestion.dataFormType
    );

    const result = questionsSeenStatus.map((questionStatus) => {
        const currentDependentQuestion = dependentQuestions.find(
            (question) =>
                question.id === questionStatus.id &&
                question.dataFormType === questionStatus.dataFormType &&
                question.formId === questionStatus.formId &&
                question.isSubQuestion &&
                questionStatus.isSubQuestion
        );

        if (currentDependentQuestion) {
            const formQuestionsStatus = questionsSeenStatus.filter(
                (q) => q.formId === currentDependentQuestion.formId
            );
            const isVisibleByLogic = isQuestionVisibleByLogic(
                formQuestionsStatus,
                currentDependentQuestion
            );

            if (isVisibleByLogic && questionStatus.isSeenByUser) {
                return { ...questionStatus, isSeenByUser: false };
            }
        }

        return questionStatus;
    });
    return result;
};

export const updateQuestionsStatus = (
    questionsStatus: QuestionSeenStatus[],
    projects: DataRequestProject[]
): QuestionSeenStatus[] => {
    const dataForms = projects.flatMap((project) => project.dataForms);

    if (dataForms.length) {
        const updatedQuestionsStatus = dataForms.flatMap(
            (form): QuestionSeenStatus[] =>
                form.questions.map((question): QuestionSeenStatus => {
                    const existenQuestionStatus = questionsStatus.find(
                        (questionStatus) =>
                            questionStatus.id === question.id &&
                            questionStatus.isSubQuestion ===
                                question.isSubQuestion
                    );

                    const questionProject = projects.find((project) =>
                        project.dataForms.some((dataForm) =>
                            dataForm.questions.some(
                                (formQuestion) =>
                                    formQuestion.id === question.id
                            )
                        )
                    );
                    const isNewProject = !questionProject.isSeenByUser;

                    if (existenQuestionStatus) {
                        return {
                            ...existenQuestionStatus,
                            isSeenByUser: existenQuestionStatus?.isSeenByUser,
                            isAdminFeedbackHighlighted:
                                existenQuestionStatus?.isAdminFeedbackHighlighted
                                    ? true
                                    : question.isAdminFeedbackHighlighted,
                            isSeenByAdmin: existenQuestionStatus?.isSeenByAdmin
                                ? question.isSeenByAdmin
                                : existenQuestionStatus?.isSeenByAdmin,
                            isUserQuestionHighlighted:
                                existenQuestionStatus?.isUserQuestionHighlighted
                                    ? true
                                    : question.isUserQuestionHighlighted,
                            dataFormType: form.formType,
                        };
                    }

                    return {
                        ...question,
                        isSeenByUser:
                            isNewProject || form.isNew
                                ? true
                                : question.isSeenByUser,
                        isSeenByAdmin: question.isSeenByAdmin,
                        isUserQuestionHighlighted:
                            question.isUserQuestionHighlighted,
                        isAdminFeedbackHighlighted:
                            isNewProject || form.isNew
                                ? false
                                : question.isAdminFeedbackHighlighted,
                        dataFormType: form.formType,
                        isMarkedAsComplete: question.isMarkedAsComplete,
                        isNewProject: isNewProject,
                        isNewForm: form.isNew,
                        formId: form.id,
                        projectId: questionProject.id,
                    };
                })
        );

        return updatedQuestionsStatus;
    }

    return questionsStatus;
};

export const updateQuestionAnswer = (
    questionsSeenStatus: QuestionSeenStatus[],
    answer: Answer,
    resetQuestionInfo: ResetQuestionInfo
): QuestionSeenStatus[] => {
    const targetQuestion = questionsSeenStatus.find(
        (f) =>
            f.id === resetQuestionInfo.questionId &&
            f.isSubQuestion === resetQuestionInfo.isSubquestion
    );
    let updatedQuestions = resetQuestionsNotifications(
        questionsSeenStatus,
        [resetQuestionInfo],
        targetQuestion.formId
    );

    updatedQuestions = updatedQuestions.map(
        (updatedQuestion): QuestionSeenStatus => {
            if (
                updatedQuestion.id === resetQuestionInfo.questionId &&
                updatedQuestion.isSubQuestion ===
                    resetQuestionInfo.isSubquestion
            ) {
                return {
                    ...updatedQuestion,
                    answer: answer,
                };
            }

            return updatedQuestion;
        }
    );

    return updatedQuestions;
};

export const mapFormsAdminNotifications = (
    questionsSeenStatus: QuestionSeenStatus[]
): FormNotificationStatus[] => {
    const formsStatus = initializeFormsStatus(questionsSeenStatus);
    const result = formsStatus.map((formStatus): FormNotificationStatus => {
        const tabQuestionStatuses = questionsSeenStatus.filter(
            (status) => status.formId === formStatus.formId
        );
        const notificationsCount = tabQuestionStatuses
            .map((questionStatus) => {
                let count = 0;
                if (questionStatus.isUserQuestionHighlighted) {
                    count += 1;
                }
                if (
                    !questionStatus.isSeenByAdmin &&
                    questionStatus.isMarkedAsComplete &&
                    questionStatus.status === QuestionProgressStatus.InReview
                ) {
                    count += 1;
                }
                return count;
            })
            .reduce((sum, value) => sum + value, 0);
        return {
            ...formStatus,
            formType: formStatus.formType,
            notificationsCount: notificationsCount,
            isNewProject: false,
        };
    });

    return result;
};

export const mapFormsUserNotifications = (
    questionsSeenStatus: QuestionSeenStatus[]
): FormNotificationStatus[] => {
    const formsStatus = initializeFormsStatus(questionsSeenStatus);
    const result = formsStatus.map((formStatus): FormNotificationStatus => {
        const tabQuestionStatuses = questionsSeenStatus.filter(
            (status) => status.formId === formStatus.formId
        );

        let notificationsCount = 0;

        const questionsStatus = tabQuestionStatuses.filter(
            (question) =>
                question.dataFormType === formStatus.formType &&
                !question.isSubQuestion
        );

        const subQuestionsStatus = tabQuestionStatuses.filter(
            (question) =>
                question.dataFormType === formStatus.formType &&
                question.isSubQuestion
        );

        const isNewProject = tabQuestionStatuses.every((q) => q.isNewProject);
        if (isNewProject) {
            return {
                ...formStatus,
                formType: formStatus.formType,
                notificationsCount: 0,
                isNewProject: isNewProject,
            };
        }

        if (formStatus.isNew) {
            notificationsCount = 1;
        } else {
            notificationsCount += questionsStatus
                .map((questionStatus) => {
                    let count = calculateQuestionUserNotifications(
                        questionStatus,
                        tabQuestionStatuses
                    );

                    if (
                        questionStatus.isSeenByUser &&
                        !questionStatus.isSubQuestion
                    ) {
                        const subQuestionsNotifications = subQuestionsStatus
                            .filter(
                                (subQuestionStatus) =>
                                    subQuestionStatus.originQuestionId ===
                                    questionStatus.id
                            )
                            .reduce(
                                (sum, value) =>
                                    sum +
                                    calculateQuestionUserNotifications(
                                        value,
                                        tabQuestionStatuses
                                    ),
                                0
                            );

                        count += subQuestionsNotifications;
                    }

                    return count;
                })
                .reduce((sum, value) => sum + value, 0);
        }

        return {
            ...formStatus,
            formType: formStatus.formType,
            notificationsCount: notificationsCount,
            isNewProject: isNewProject,
        };
    });
    return result;
};

export const initializeFormsStatus = (
    questionsSeenStatus: QuestionSeenStatus[]
) => {
    return questionsSeenStatus
        .filter((question, index) => {
            const duplicateIndex = questionsSeenStatus.findIndex(
                (duplicateModuleState) =>
                    duplicateModuleState.formId === question.formId &&
                    duplicateModuleState.dataFormType === question.dataFormType
            );
            return index === duplicateIndex;
        })
        .map(
            (question): FormNotificationStatus => ({
                formId: question.formId,
                formType: question.dataFormType,
                notificationsCount: 0,
                isNew: question.isNewForm,
                isNewProject: question.isNewProject,
            })
        );
};
