import { createAsyncThunk } from '@reduxjs/toolkit';
import createNotification from '../../utils/createNotification';

export const FetchSummary = createAsyncThunk(
    'userSummary/fetchSummary',
    async (_, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const accessToken = localStorage.getItem('accessToken');
        return await axios
            .post('api/Users/GetUserSummary', { accessToken })
            .catch(() => {
                createNotification(
                    'Something went wrong, could not load your Dashboard. Try signing in again.',
                    'error'
                );
            });
    }
);
