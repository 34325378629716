import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Input, InputGroup } from 'reactstrap';
import SimpleNumberLocalizer from 'react-widgets-simple-number';
import { DropdownList } from 'react-widgets';
import createNotification from '../../utils/createNotification';
import _ from 'lodash';
import Loader from 'react-loaders';
import { Loader as LoaderAnim } from 'react-loaders';
import InfoTooltip from '../../components/info-tooltip/info-tooltip';
import { ArrowDown } from '../../components/arrow-down/arrow-down';
import { useStateSelector } from '../../store/selectors';

new SimpleNumberLocalizer();

const Affiliation = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [localAffiliates, setLocalAffiliates] = useState([]);
    const [currentAffiliateIndex, setcurrentAffiliateIndex] = useState(-1);
    const [currentAffiliate, setCurrentAffiliate] = useState({});
    const [newAffiliate, setNewAffiliate] = useState({});
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [loader, setLoader] = useState(true);
    const [loaderMessage, setLoaderMessage] = useState('');

    const [errors, setErrors] = useState({
        city: { isValid: true, isRequired: { message: 'City is required.' } },
        name: { isValid: true, isRequired: { message: 'Name is required.' } },
        practiceName: {
            isValid: true,
            isRequired: { message: 'Practice Name is required.' },
        },
        state: { isValid: true, isRequired: { message: 'State is required.' } },
        type: { isValid: true, isRequired: { message: 'Type is required.' } },
    });
    const spinner = <LoaderAnim color="#12345" type="ball-scale-multiple" />;

    useEffect(() => {
        (async () => {
            getConsolidator();
            if (props.viewApplication) {
                setIsReadOnly(true);
            }
        })();
    }, []);

    useEffect(() => {
        setCurrentAffiliateValue(currentAffiliateIndex);
    }, [currentAffiliateIndex]);

    useEffect(() => {
        if (props.readOnly) {
            props.readOnly(isReadOnly);
        }
    }, [isReadOnly]);

    const getConsolidator = () => {
        setLoaderData(true, 'Loading Affiliates...');
        axios
            .get(`/api/practice/${props.data.id}/affiliations`)
            .then((response) => {
                if (response.isError) {
                    createNotification(
                        'An error occured while fetching the details',
                        'error'
                    );
                } else {
                    if (response.data.data.length > 0) {
                        setAllAffiliates(response.data.data);
                        setcurrentAffiliateIndex(0);
                    }
                }
            })
            .catch(function (error) {
                createNotification(error, 'error');
            })
            .finally(function () {
                setLoaderData(false, '');
            });
    };

    const setAllAffiliates = (affilateList) => {
        setLocalAffiliates(affilateList);
        props.data.setAffiliates(affilateList);
    };

    const setCurrentAffiliateValue = (index) => {
        if (index >= 0) {
            const affiliate = Object.assign({}, localAffiliates[index]);
            setCurrentAffiliate(affiliate);
        } else {
            setCurrentAffiliate({});
        }
    };

    const next = () => {
        if (localAffiliates && localAffiliates.length > 0) {
            var index = currentAffiliateIndex + 1;
            if (index < localAffiliates.length) {
                setcurrentAffiliateIndex(index);
            } else {
                setcurrentAffiliateIndex(0);
            }
        }
    };

    const previous = () => {
        if (localAffiliates && localAffiliates.length > 0) {
            var index = currentAffiliateIndex - 1;
            if (index >= 0) {
                setcurrentAffiliateIndex(index);
            } else {
                setcurrentAffiliateIndex(localAffiliates.length - 1);
            }
        }
    };

    const addAffiliate = () => {
        setNewAffiliate({
            name: '',
            practiceName: '',
            city: '',
            state: '',
            type: 0,
            practiceID: props.data.id,
        });
        setIsReadOnly(false);
    };

    const editAffiliation = () => {
        setNewAffiliate(currentAffiliate);
        setIsReadOnly(false);
    };

    const setLoaderData = (loader, msg) => {
        setLoader(loader);
        setLoaderMessage(msg);
    };

    const submitAffiliate = () => {
        var isValid = validateAffiliate(newAffiliate);
        if (!isValid) {
            return;
        }
        if (newAffiliate && newAffiliate.id) {
            //Edit affiliate
            updateAffiliate();
        } else {
            //add affiliate
            saveAffiliate();
        }
    };

    const saveAffiliate = () => {
        setLoaderData(true, 'Saving Affiliate...');
        axios
            .post('/api/Affiliations', newAffiliate)
            .then((response) => {
                if (response.data.isError) {
                    createNotification(
                        'An error occured while adding affiliate',
                        'error'
                    );
                } else {
                    var affiliateList = localAffiliates;
                    affiliateList.push(response.data.data);
                    setAllAffiliates(affiliateList);
                    setIsReadOnly(true);
                    setcurrentAffiliateIndex(affiliateList.length - 1);
                    createNotification(
                        'Affiliation added successfully',
                        'success'
                    );
                }
            })
            .catch(function (error) {
                createNotification(
                    'An error ocurred while processing request.',
                    'error'
                );
            })
            .finally(function () {
                setLoaderData(false, '');
            });
    };

    const updateAffiliate = () => {
        setLoaderData(true, 'Updating Affiliate...');
        axios
            .put(`/api/Affiliations/${currentAffiliate.id}`, newAffiliate)
            .then((response) => {
                if (response.data.isError) {
                    createNotification(
                        'An error occured while updating affiliate',
                        'error'
                    );
                } else {
                    var affiliateList = localAffiliates;
                    affiliateList[currentAffiliateIndex] = newAffiliate;
                    setAllAffiliates(affiliateList);
                    setIsReadOnly(true);
                    setCurrentAffiliate(newAffiliate);
                    createNotification(
                        'Affiliation updated successfully',
                        'success'
                    );
                }
            })
            .catch(function (error) {
                createNotification(
                    'An error ocurred while processing request.',
                    'error'
                );
            })
            .finally(function () {
                setLoaderData(false, '');
            });
    };

    const validateAffiliate = (affiliate) => {
        var err = Object.assign({}, errors);
        var keys = Object.keys(err);
        var isValid = true;
        _.each(keys, function (key) {
            var value = affiliate[key];
            var errObj = errors[key];
            if (errObj.isRequired && !value) {
                errObj.isValid = false;
                isValid = false;
            }
        });
        setErrors(err);
        return isValid;
    };

    const cancel = () => {
        //setcurrentAffiliateIndex(0);
        setIsReadOnly(true);
        var Err = { ...errors };
        for (var prop in Err) {
            Err[prop].isValid = true;
        }
        setErrors(Err);
    };

    const onAffiliateNameChange = (e) => {
        setNewAffiliate({ ...newAffiliate, name: e.target.value });
        var Err = { ...errors };
        if (!Err.name.isValid) {
            Err.name.isValid = true;
            setErrors(Err);
        }
    };

    const onAffiliatePracticeNameChange = (e) => {
        setNewAffiliate({ ...newAffiliate, practiceName: e.target.value });
        var Err = { ...errors };
        if (!Err.practiceName.isValid) {
            Err.practiceName.isValid = true;
            setErrors(Err);
        }
    };

    const OnCitySelect = (e) => {
        setNewAffiliate({ ...newAffiliate, city: e.target.value });
        var Err = { ...errors };
        if (!Err.city.isValid) {
            Err.city.isValid = true;
            setErrors(Err);
        }
    };

    const OnStateSelect = (e) => {
        setNewAffiliate({ ...newAffiliate, state: e.target.value });
        var Err = { ...errors };
        if (!Err.state.isValid) {
            Err.state.isValid = true;
            setErrors(Err);
        }
    };

    const setPracticeFocus = (id) => {
        setNewAffiliate({ ...newAffiliate, type: id });
        var Err = { ...errors };
        if (!Err.type.isValid) {
            Err.type.isValid = true;
            setErrors(Err);
        }
    };

    function getLoaderHtml() {
        return (
            <div
                className="loader-container"
                style={{ height: '100%', width: '100%' }}>
                <div className="loader-container-inner">
                    <br />
                    <div className="text-center">
                        <Loader show={loader} message={spinner} />
                    </div>
                    <h6 className="mt-5">{loaderMessage ?? 'Loading...'}</h6>
                </div>
            </div>
        );
    }

    function getAffiliteFormHtml() {
        if ((localAffiliates && localAffiliates.length > 0) || !isReadOnly) {
            return (
                <Row form className="">
                    <Col lg={12} md={12} sm={12}>
                        <Label for="PracticeName">Practice Name</Label>
                        <InputGroup className="mb-3">
                            {isReadOnly ? (
                                <Input
                                    type="text"
                                    name="PracticeName"
                                    id="PracticeName"
                                    placeholder="Practice Name"
                                    value={currentAffiliate.practiceName ?? ''}
                                    disabled
                                />
                            ) : (
                                <Input
                                    type="text"
                                    name="PracticeName"
                                    id="PracticeName"
                                    placeholder="Practice Name"
                                    value={newAffiliate.practiceName}
                                    onChange={(e) => {
                                        onAffiliatePracticeNameChange(e);
                                    }}
                                    style={{ width: '100%' }}
                                />
                            )}
                            {!errors.practiceName.isValid ? (
                                <span style={{ color: 'red' }}>
                                    {errors.practiceName.isRequired.message}
                                </span>
                            ) : (
                                ''
                            )}
                        </InputGroup>
                    </Col>

                    <Col lg={4} md={4} sm={4}>
                        <FormGroup>
                            <Label for="txtGrowPractice">City</Label>
                            <div className="mb-3">
                                {isReadOnly ? (
                                    <Input
                                        type="text"
                                        name="city"
                                        id="city"
                                        value={
                                            currentAffiliate.city
                                                ? currentAffiliate.city
                                                : ''
                                        }
                                        disabled
                                    />
                                ) : (
                                    <Input
                                        type="text"
                                        name="city"
                                        id="city"
                                        onChange={(e) => {
                                            OnCitySelect(e);
                                        }}
                                        value={newAffiliate.city}
                                    />
                                )}
                                {!errors.city.isValid ? (
                                    <span style={{ color: 'red' }}>
                                        {errors.city.isRequired.message}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg={4} md={4} sm={4}>
                        <FormGroup>
                            <Label for="txtGrowPractice">State</Label>
                            <div className="mb-3">
                                {isReadOnly ? (
                                    <Input
                                        type="text"
                                        name="city"
                                        id="city"
                                        value={
                                            currentAffiliate.state
                                                ? currentAffiliate.state
                                                : ''
                                        }
                                        disabled
                                    />
                                ) : (
                                    <Input
                                        type="text"
                                        name="city"
                                        id="city"
                                        onChange={(e) => {
                                            OnStateSelect(e);
                                        }}
                                        value={newAffiliate.state}
                                    />
                                )}
                                {!errors.state.isValid ? (
                                    <span style={{ color: 'red' }}>
                                        {errors.state.isRequired.message}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg={4} md={4} sm={4}>
                        <FormGroup>
                            <Label for="txtGrowPractice">Type</Label>
                            <div className="mb-3">
                                {
                                    <DropdownList
                                        selectIcon={<ArrowDown />}
                                        data={[
                                            { name: 'Please Select', id: 0 },
                                            { name: 'General Practice', id: 1 },
                                            {
                                                name: 'Referral Specialty / Emergency',
                                                id: 2,
                                            },
                                            {
                                                name: 'Equine / Large Animal',
                                                id: 3,
                                            },
                                            { name: 'Other', id: 5 },
                                        ]}
                                        value={
                                            isReadOnly
                                                ? currentAffiliate.type
                                                : newAffiliate.type
                                        }
                                        invalid={!props.data.practiseTypeID}
                                        allowCreate="onFilter"
                                        onChange={(value) =>
                                            setPracticeFocus(value.id)
                                        }
                                        dataKey="id"
                                        textField="name"
                                        style={{ width: '100%' }}
                                        disabled={isReadOnly}
                                    />
                                }
                                {!errors.type.isValid ? (
                                    <span style={{ color: 'red' }}>
                                        {errors.type.isRequired.message}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                        <Label for="affiliated">
                            Affiliated Party Name & Nature of Affiliation
                            <InfoTooltip
                                text="Please provide the name of the<br/> party
                                    associated with the<br/> member practice that has
                                    an<br/> affiliation with the affiliated<br/> practice
                                    and please explain the<br/> nature of that
                                    affiliation."
                                idText="affiliation"
                            />
                        </Label>
                        <InputGroup className="mb-3">
                            {isReadOnly ? (
                                <Input
                                    type="text"
                                    name="affiliated1"
                                    id="affiliated"
                                    value={currentAffiliate.name}
                                    disabled
                                />
                            ) : (
                                <Input
                                    type="text"
                                    style={{ width: '100%' }}
                                    // className = {!errors.name.isValid ? "invalid" : "" }
                                    name="affiliated-name-new"
                                    id="affiliated-new"
                                    value={newAffiliate.name}
                                    onChange={(e) => {
                                        onAffiliateNameChange(e);
                                    }}
                                />
                            )}
                            {!errors.name.isValid ? (
                                <span style={{ color: 'red' }}>
                                    {errors.name.isRequired.message}
                                </span>
                            ) : (
                                ''
                            )}
                        </InputGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                        {!isReadOnly && !props.viewApplication ? (
                            <InputGroup className="mb-3 form-submit-btn">
                                <button
                                    type="button"
                                    className="btn mr-2"
                                    onClick={cancel}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-submit"
                                    onClick={submitAffiliate}>
                                    {newAffiliate && newAffiliate.id
                                        ? 'Save Changes'
                                        : 'Submit Affiliation'}{' '}
                                </button>
                            </InputGroup>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
            );
        }
    }

    return (
        <Fragment>
            <div className="form-wizard-content affiliate-div">
                <div className="">
                    <span className="results-title  mb-3">
                        <b>
                            Please disclose any practice affiliations of this
                            practice’s owners and designated representative. An
                            affiliation includes any ownership, directorship,
                            advisory or management role in any veterinary
                            practice by:
                        </b>
                    </span>
                    <ul className="step5list">
                        <li>
                            Any of the applying practice’s owners, or any
                            immediate family members (eg, spouse, child,
                            sibling, parent, "in-law" relation, cousin) thereof;
                        </li>
                        <li>
                            The applying practice's additional designated
                            representative, if any, or any immediate family
                            members (eg, spouse, child, sibling, parent,
                            "in-law" relation, cousin) thereof
                        </li>
                    </ul>
                    <hr></hr>
                    <div className="affiliations_form-wrapper">
                        <div className="form-title">
                            <h6>Affiliations</h6>
                            {isReadOnly ? (
                                <React.Fragment>
                                    <div className="edit_affiliation_wrapper">
                                        <a
                                            className="navigation"
                                            onClick={() => previous()}>
                                            <span className="pe-7s-angle-left"></span>
                                        </a>
                                        {currentAffiliateIndex + 1} of{' '}
                                        {localAffiliates.length}
                                        <a
                                            className="navigation"
                                            onClick={() => next()}>
                                            <span className="pe-7s-angle-right"></span>
                                        </a>
                                        {!props.viewApplication &&
                                        currentAffiliate &&
                                        currentAffiliate.id ? (
                                            <p>
                                                <em>
                                                    <a
                                                        className="edit_affiliation"
                                                        onClick={() => {
                                                            editAffiliation();
                                                        }}>
                                                        Edit Affiliation
                                                    </a>
                                                </em>
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {!props.viewApplication ? (
                                        <a
                                            onClick={addAffiliate}
                                            className="link_add">
                                            + Add An Affiliation
                                        </a>
                                    ) : (
                                        ''
                                    )}
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="affiliations_form_content">
                            {loader ? getLoaderHtml() : getAffiliteFormHtml()}
                        </div>
                    </div>
                </div>
                {/* <div className="step_count">
                    Step 5 of 5
                </div> */}
            </div>
        </Fragment>
    );
};
export default Affiliation;
