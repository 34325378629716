import React, { useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import './discussion-details-modal.scss';
import { Discussion } from '../models/discussion';
import ViewDiscussion from '../view-discussion/view-discussion';
import EditDiscussion from '../edit-discussion/edit-discussion';
import { Member } from '../models/member';
import { GroupType } from '../models/group-type';

interface DiscussionDetailsProps {
    discussion: Discussion;
    currentMemberId: number;
    setIsVisible(value: boolean): void;
    isVisible: boolean;
    pinnedDiscussions: string[];
    mode: string;
    updateDiscussion(
        discussionId: number,
        name: string,
        description: string
    ): Promise<any>;
    members: Array<Member>;
    startVoting(discussionId: number): void;
    posterVote(discussionId: number, vote: boolean): void;
    isUserAdmin: boolean;
    groupType: GroupType;
    cancelPosterVote(discussionId: number): void;
    doesMemberHasViewOnlyAccessToPoc: boolean
}

enum PopupMode {
    view = 'view',
    edit = 'edit',
}

const DiscussionDetailsModal = (props: DiscussionDetailsProps) => {
    const [mode, setMode] = useState(props.mode);

    const hidePopup = () => props.setIsVisible(!props.isVisible);

    const renderMode = (state: string) => {
        switch (mode) {
            case PopupMode.view:
                return (
                    <ViewDiscussion
                        discussion={props.discussion}
                        currentMemberId={props.currentMemberId}
                        pinnedDiscussions={props.pinnedDiscussions}
                        startVoting={props.startVoting}
                        posterVote={props.posterVote}
                        isUserAdmin={props.isUserAdmin}
                        groupType={props.groupType}
                        switchToEditMode={() => setMode(PopupMode.edit)}
                        doesMemberHasViewOnlyAccessToPoc={props.doesMemberHasViewOnlyAccessToPoc}
                        cancelPosterVote={
                            props.cancelPosterVote
                        }></ViewDiscussion>
                );
            case PopupMode.edit:
                return (
                    <EditDiscussion
                        discussion={props.discussion}
                        hide={hidePopup}
                        updateDiscussion={
                            props.updateDiscussion
                        }></EditDiscussion>
                );
            default:
                return (
                    <ViewDiscussion
                        discussion={props.discussion}
                        currentMemberId={props.currentMemberId}
                        pinnedDiscussions={props.pinnedDiscussions}
                        startVoting={props.startVoting}
                        posterVote={props.posterVote}
                        isUserAdmin={props.isUserAdmin}
                        groupType={props.groupType}
                        switchToEditMode={() => setMode(PopupMode.edit)}
                        doesMemberHasViewOnlyAccessToPoc={props.doesMemberHasViewOnlyAccessToPoc}
                        cancelPosterVote={
                            props.cancelPosterVote
                        }></ViewDiscussion>
                );
        }
    };

    return (
        <Modal
            className="discussion-hr-popup"
            isOpen={props.isVisible}
            toggle={hidePopup}
            modalClassName={mode === PopupMode.edit ? 'edit-height' : ''}>
            <ModalHeader toggle={hidePopup}></ModalHeader>
            {renderMode(mode)}
        </Modal>
    );
};

export default DiscussionDetailsModal;
