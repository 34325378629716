import React, { useState } from 'react';
import Rodal from '../../../../../components/Rodal/Rodal';
import { Label, ModalBody, ModalHeader } from 'reactstrap';
import './AddProjectVariablePopup.scss';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import { ProjectVariableOption } from '../../../../../models/DataRequestHub/ProjectVariable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import AddNewVariableOptionForm from '../AddNewVariableOptionForm/AddNewVariableOptionForm';
import { Field, Form } from '@availity/form';

export interface AddProjectVariablePopupProps {
    close(): void;
    onSubmit(): void;
    projectId: number;
}

export const AddProjectVariablePopup = (
    props: AddProjectVariablePopupProps
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newOptionName, setNewOptionName] = useState<string>('');
    const [newOptionValue, setNewOptionValue] = useState<string>('');
    const [variableName, setVariableName] = useState<string>('');
    const axios = useStateSelector((s) => s.core.axios);
    const [options, setOptions] = useState<Array<ProjectVariableOption>>([]);

    const createVariable = () => {
        setIsLoading(true);
        axios
            .post(
                `/api/DataRequestProject/${props.projectId}/ProjectVariables`,
                {
                    name: variableName,
                    options: options,
                }
            )
            .then((response: any) => {
                if (response.status === 200) {
                    props.onSubmit();
                } else {
                    const message = response.response.data.detail;
                    createNotification(message, 'error');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const renderOptionList = (option: ProjectVariableOption, index: number) => (
        <div
            className="project-variable-tile"
            key={`${option}-${option.id}-${index}`}>
            <span className="project-variable-info">
                <span className="project-variable-name">{option.option}</span>
            </span>

            <span
                className="delete-button"
                onClick={() => {
                    const newOptions = [...options];
                    newOptions.splice(index, 1);
                    setOptions(newOptions);
                }}>
                <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
            </span>
        </div>
    );

    return (
        <div className="add-project-variable-popup">
            <Rodal
                visible={true}
                onClose={props.close}
                className="checkout-modal create-event-modal"
                width={600}
                animation={'fade'}
                showMask={false}
                centered>
                <ModalHeader>Add New Variable</ModalHeader>
                <ModalBody>
                    <Form
                        initialValues={{
                            variableName: variableName,
                        }}
                        onSubmit={() => {}}>
                        <div className="variable-name-form">
                            <Label>
                                <strong>Name:</strong>
                            </Label>
                            <Field
                                name="variableName"
                                value={variableName}
                                onChange={(event: any) => {
                                    setVariableName(event.target.value);
                                }}></Field>
                        </div>
                    </Form>
                    <div className="options-list">
                        {options.map(
                            (option: ProjectVariableOption, index: number) =>
                                renderOptionList(option, index)
                        )}
                    </div>
                    <AddNewVariableOptionForm
                        optionName={newOptionName}
                        setOptionName={setNewOptionName}
                        optionValue={newOptionValue}
                        setOptionValue={setNewOptionValue}
                        isLoading={isLoading}
                        add={(value) => {
                            const newOptions = [...options, value];
                            setOptions(newOptions);
                        }}
                        isNestedVariable={false}></AddNewVariableOptionForm>
                    <div className="buttons-wrapper">
                        <button
                            disabled={isLoading}
                            className="btn btn-cancel"
                            onClick={props.close}>
                            Cancel
                        </button>
                        <ButtonLoader
                            buttonText={'Save & Close'}
                            loaderButtonText={''}
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                                createVariable();
                            }}
                            className={'btn btn-primary'}
                        />
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};
