import React, { useEffect, useState } from 'react';
import { USER_ROLES } from '../../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import createNotification from '../../../../utils/createNotification';
import { TokenHelper } from '../../../../utils/tokenHelper';
import { useStateSelector } from '../../../../store/selectors';

export const UserRoleSwitcher = (props) => {
    const [isFlexAccount, setIsFlexAccount] = useState(false);
    const [accountRole, setAccountRole] = useState();
    const navigate = useNavigate();

    const axios = useStateSelector((state) => state.core.axios);

    useEffect(() => {
        const userSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        setIsFlexAccount(userSummary.isFlexAccount);
        setAccountRole(userSummary.roleId);
    }, []);

    const isHttpError = (response) => response?.name === 'AxiosError';

    const switchUserRole = async () => {
        const roleId =
            accountRole === USER_ROLES.ADMIN
                ? USER_ROLES.PRACTICE_OWNER
                : USER_ROLES.ADMIN;
        const result = await axios.post('/api/Users/SetUserRole', {
            RoleId: roleId,
        });
        if (isHttpError(result)) {
            showError(result?.response?.data?.title);
        } else {
            await updateUserAccessAndData();
        }
    };

    const updateUserAccessAndData = async () => {
        const refreshAccessTokenResponse = await refreshAccessTokenRequest();
        if (isHttpError(refreshAccessTokenResponse)) {
            showError(refreshAccessTokenResponse?.response?.data?.title);
        } else {
            const authenticationResult = refreshAccessTokenResponse?.data?.data;
            TokenHelper.setTokenToLocalStorage(authenticationResult);
            await updateSummaryData(authenticationResult);
        }
    };

    const refreshAccessTokenRequest = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const deviceKey = localStorage.getItem('deviceKey');
        const accessToken = localStorage.getItem('accessToken');
        const deviceGroupKey = localStorage.getItem('deviceGroupKey');

        return await axios.post('api/auth/refreshUserToken', {
            refreshToken: refreshToken,
            deviceKey: deviceKey,
            accessToken: accessToken,
            deviceGroupKey: deviceGroupKey,
        });
    };

    const updateSummaryData = async (authenticationResult) => {
        const result = await axios.post(
            'api/Users/GetUserSummary',
            { accessToken: authenticationResult.accessToken },
            {
                headers: {
                    Authorization: `Bearer ${authenticationResult.idToken}`,
                },
            }
        );

        if (isHttpError(result)) {
            showError(
                'Something went wrong, could not load your data. Try signing in again.'
            );
        } else {
            const sumData = result.data.data;
            localStorage.setItem(
                'loggedInUserSummary',
                JSON.stringify(sumData)
            );
            navigateUser(sumData.roleId);
        }
    };

    const showError = (message) => {
        createNotification(
            message ?? 'Unexpected error has occurred.',
            'error'
        );
    };

    const navigateUser = (roleId) => {
        const previousRoleLastPage = localStorage.getItem(
            'previousRoleLastPage'
        );
        localStorage.setItem(
            'previousRoleLastPage',
            document.location.pathname
        );

        if (previousRoleLastPage === document.location.pathname) {
            document.location.reload();
        }

        if (previousRoleLastPage) {
            navigate(previousRoleLastPage);
        } else {
            navigateToDefaultRolePage(roleId);
        }
    };

    const navigateToDefaultRolePage = (roleId) =>
        roleId === USER_ROLES.ADMIN
            ? navigate('/dashboard')
            : navigate('/user-home');

    return (
        <>
            {isFlexAccount ? (
                <a
                    className="app-switch-role-button"
                    onClick={() => switchUserRole()}>
                    {accountRole === USER_ROLES.ADMIN
                        ? 'Practice Owner'
                        : 'Admin Tool'}
                </a>
            ) : (
                []
            )}
        </>
    );
};
