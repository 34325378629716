import { createSlice } from '@reduxjs/toolkit';
import { LoadUserSummaryFromStorage } from './load-user-summary-from-storage';
import { FetchSummary } from './fetch-summary.thunk';
import createNotification from '../../utils/createNotification';
import { UserSummaryState } from './user-summary.state';

const initialState: UserSummaryState = {
    summary: JSON.parse(localStorage.getItem('loggedInUserSummary')),
    isLoading: false,
    status: 'succeeded',
    error: '',
    returnUrl: '',
};

const userSummarySlice = createSlice({
    name: 'userSummary',
    initialState,
    reducers: {
        setReturnUrl(state, action) {
            state.returnUrl = action.payload;
        },
        resetReturnUrl(state) {
            state.returnUrl = ''
        }
    },
    extraReducers(builder) {
        builder
            .addCase(LoadUserSummaryFromStorage.pending, (state) =>
                setPendingState(state)
            )
            .addCase(LoadUserSummaryFromStorage.fulfilled, (state, action) => {
                const summary = action.payload;
                state.summary = JSON.parse(summary);
                setSucceededState(state);
            })
            .addCase(LoadUserSummaryFromStorage.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(FetchSummary.pending, (state) => setPendingState(state))
            .addCase(FetchSummary.fulfilled, (state, action) => {
                let response = action.payload;
                if (response.isError || !response.data || !response.data.data) {
                    createNotification(
                        'Something went wrong, could not load your Dashboard. Try signing in again.',
                        'error'
                    );
                    return;
                }
                if (response && response.data && response.data.data) {
                    const sumData = response.data.data;
                    localStorage.setItem(
                        'loggedInUserSummary',
                        JSON.stringify(sumData)
                    );
                    state.summary = sumData;
                }
                setSucceededState(state);
            })
            .addCase(FetchSummary.rejected, (state) => setRejectedState(state));
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};
export const {
    setReturnUrl,
    resetReturnUrl
} = userSummarySlice.actions;
export default userSummarySlice.reducer;
