import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../../utils/constants';
export const HomeButton = () => {

    const [isLinkEnabled, setIsLinkEnabled] = useState(false);
    const [linkRoute, setLinkRoute] = useState('/user-home');

    useEffect(() => {
        const summary = JSON.parse(localStorage.getItem('loggedInUserSummary'));
        const isOwner = summary?.roleId === USER_ROLES.PRACTICE_OWNER;
        const isAffiliatedMember = summary?.roleId === USER_ROLES.PRACTICE_SECONDARY_USER;
        const hasApprovedPractice = summary?.practices?.some(
            (p: any) => p.status == 1
        );
        const isAdmin = summary?.roleId === USER_ROLES.ADMIN;
        if (isAdmin) {
            setLinkRoute('/dashboard');
        }
        setIsLinkEnabled(((isOwner || isAffiliatedMember) && hasApprovedPractice) || isAdmin);
    }, []);

    const icon = <FontAwesomeIcon icon={faHome} />;
    
    return (
        <span>{isLinkEnabled ? <Link to={linkRoute}>{icon}</Link> : icon}</span>
    );
};
