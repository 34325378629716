import React, { useState } from 'react';
import {
    Button,
    Col,
    Input,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import Rodal from '../../../../components/Rodal/Rodal';
import './PaymentModalPopup.scss';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import createNotification from '../../../../utils/createNotification';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../../store/selectors';

interface PaymentModalPopupProps {
    isVisible: boolean;
    onClose: () => void;
    getCard: () => void;
    card: any;
}

export const PaymentModalPopup = (props: PaymentModalPopupProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [isNameOnCardValid, setIsNameOnCardValid] = useState<boolean>(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState<boolean>(false);
    const [isExpiryValid, setIsExpiryValid] = useState<boolean>(false);
    const [isCvcNumberValid, setIsCvcNumberValid] = useState<boolean>(false);

    const isCardDataValid = (): boolean =>
        isNameOnCardValid &&
        isCardNumberValid &&
        isExpiryValid &&
        isCvcNumberValid;

    const axios = useStateSelector((state) => {
        return state.core.axios;
    });

    const submit = () => {
        setIsLoading(true);
        stripe
            .createPaymentMethod({
                type: 'card',
                card: elements.getElement('cardNumber'),
            })
            .then((result: any) => {
                if (result?.paymentMethod) {
                    const id = result.paymentMethod.id;
                    axios
                        .post('api/Wallet/PaymentMethod', {
                            paymentMethodId: id,
                            shouldUpdatePaymentMethod: props.card
                                ? true
                                : false,
                        })
                        .then((response: any) => {
                            if (response && response.status == 200) {
                                createNotification(
                                    'Payment method saved successfully.',
                                    'success'
                                );
                                props.getCard();
                                setIsLoading(false);
                                props.onClose();
                            } else {
                                createNotification(
                                    'Something went wrong, could not save payment method.',
                                    'error'
                                );
                                setIsLoading(false);
                            }
                        });
                } else if (result.error && result.error.message) {
                    createNotification(result.error.message, 'error');
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                createNotification(
                    'Something went wrong during card validation.',
                    'error'
                );
                setIsLoading(false);
            });
    };

    return (
        <div className="payment-modal-popup">
            <Rodal
                visible={props.isVisible}
                onClose={props.onClose}
                animation={'fade'}
                showMask={false}
                width={500}
                center>
                <ModalHeader className="popup-header">
                    {'Payment Method'}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6}>
                            <span className="label">Name on card</span>
                            <Input
                                style={{ width: '100%' }}
                                type="text"
                                name="name"
                                placeholder="John Doe"
                                onChange={(e) =>
                                    setIsNameOnCardValid(!!e.target.value)
                                }></Input>
                        </Col>
                        <Col md={6}>
                            <span className="label">Card Number</span>
                            <CardNumberElement
                                onChange={(e) =>
                                    setIsCardNumberValid(e.complete && !e.error)
                                }></CardNumberElement>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <span className="label">Expiry</span>
                            <CardExpiryElement
                                onChange={(e) =>
                                    setIsExpiryValid(e.complete && !e.error)
                                }></CardExpiryElement>
                        </Col>
                        <Col md={6}>
                            <span className="label">CVC Number</span>
                            <CardCvcElement
                                onChange={(e) =>
                                    setIsCvcNumberValid(e.complete && !e.error)
                                }></CardCvcElement>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn close-button"
                        onClick={props.onClose}>
                        Cancel
                    </Button>
                    <ButtonLoader
                        className="btn btn-primary submit-button"
                        isLoading={isLoading}
                        loaderButtonText={''}
                        disabled={isLoading || !isCardDataValid()}
                        buttonText={'Save Payment Method'}
                        onClick={submit}></ButtonLoader>
                </ModalFooter>
            </Rodal>
        </div>
    );
};
