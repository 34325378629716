import React, { useEffect } from 'react';
import Loader from 'react-loaders';
import { ModalBody, Button } from 'reactstrap';
import Rodal from '../../../components/Rodal/Rodal';

interface DocSuccessProps {
    type: 'nda' | 'agreement';
}

const DocSuccess = (props: DocSuccessProps) => {
    useEffect(() => {
        setNdaSignedSuccess();
    }, []);

    const setNdaSignedSuccess = () => {
        window.top.postMessage('true', '*');
    };

    const header = props.type == 'nda' ? 'Making Progress' : 'On Board!';
    const text =
        props.type == 'nda'
            ? 'Thank you for executing the referral partner NDA'
            : 'Thank you for signing up the Referral Partner Agreement. We will redirect you shortly once we receive a confirmation. If this takes too long, kindly refresh the page';

    return (
        <Rodal
            visible={true}
            showMask={false}
            width="400"
            showCloseButton={false}>
            <ModalBody height="425px">
                <div
                    className="text-center"
                    style={{
                        marginTop: '30px',
                        marginLeft: '50%',
                        height: '50px',
                    }}>
                    <Loader type="ball-scale-multiple" active />
                </div>
                <h3 className="text-center">{header}</h3>
                <span className="text-center">{text}</span>
                <div className="text-center" style={{ marginTop: '10px' }}>
                    <Button
                        type="button"
                        color="success"
                        onClick={setNdaSignedSuccess}>
                        OK
                    </Button>
                </div>
            </ModalBody>
        </Rodal>
    );
};
export default DocSuccess;
