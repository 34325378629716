import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notification } from '../../../clubhouse/models/notification';

export const addLikeToMessage = createAsyncThunk(
    'supportMessages/like',
    (notification: Notification) => {
        return {
            message: notification.message,
            threadId: notification.chatId,
        };
    }
);
