import { createAsyncThunk } from '@reduxjs/toolkit';
import { RetrieveMessageArg, RetrieveMessageByLikesArg, retrieveMessages, retrieveMessagesByLikes } from './messages-slice-helper';

export const refreshMessageList = createAsyncThunk(
    'clubhouseMessages/refresh',
    async (arg: RetrieveMessageArg, dispatch: any) =>
        await retrieveMessages(arg, dispatch)
);

export const refreshMessageListByLikes = createAsyncThunk(
    'clubhouseMessages/refresh',
    async (arg: RetrieveMessageByLikesArg, dispatch: any) =>
        await retrieveMessagesByLikes(arg, dispatch)
);