import React, { useState, Fragment } from 'react';
import '@availity/yup';
import * as yup from 'yup';
import { Field, Form } from '@availity/form';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { COMMON_REGEX } from '../../utils/constants';
import createNotification from '../../utils/createNotification';
import { useStateSelector } from '../../store/selectors';

const ResetPassword = (props) => {
    const patternMessage =
        'Password must be at least 6 characters long, have at least 1 special character, 1 uppercase letter, 1 lowercase letter, 1 digit and no leading or trailing spaces.';
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const axios = useStateSelector((state) => state.core.axios);

    const resetPassword = () => {
        if (!oldPassword || !newPassword || comparePassword()) {
            return;
        }
        const requestObj = {
            oldPassword,
            newPassword,
        };

        const accessToken = localStorage.getItem('accessToken');
        axios
            .post('/api/auth/resetPassword', requestObj, {
                headers: { accessToken },
            })
            .then((response) => {
                if (response.data) {
                    if (!response.data.isError) {
                        createNotification(
                            'Password reset successfully.',
                            'success'
                        );
                        props.setshowResetPassword(false);
                    } else {
                        createNotification(response.data.message, 'error');
                    }
                } else {
                    const message = response.response.data.message;
                    createNotification(message, 'error');
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    };

    const comparePassword = () => {
        return newPassword !== confirmPassword;
    };

    return (
        <div class="main-card mb-3">
            <Form
                onSubmit={resetPassword}
                initialValues={{
                    oldPassword: '',
                    newProfilePassword: '',
                    confirmPassword: '',
                }}
                validationSchema={yup.object().shape({
                    oldPassword: yup
                        .string()
                        .required( 'Old Password is required.'),
                    newProfilePassword: yup
                        .string()
                        .required( 'New Password is required.')
                        .matches(COMMON_REGEX.STRONG_PASSWORD, patternMessage),
                    confirmPassword: yup
                        .string()
                        .required( 'Confirm Password is required.')
                        .matches(COMMON_REGEX.STRONG_PASSWORD, patternMessage),
                })}
                error={patternMessage}>
                <div class="card-header">Reset Password</div>
                <div class="card-body">
                    <Fragment>
                        <div className="form-wizard-content">
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="txtOldPassword">
                                            Old Password
                                        </Label>
                                        <Field
                                            type="password"
                                            name="oldPassword"
                                            id="txtOldPassword"
                                            placeholder="Enter old password"
                                            onChange={(event) =>
                                                setOldPassword(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="align-items-baseline">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="txtNewPassword">
                                            New Password
                                        </Label>
                                        <Field
                                            type="password"
                                            value={newPassword}
                                            placeholder="Enter new password"
                                            name="newProfilePassword"
                                            id="txtnewPassword"
                                            onChange={(event) =>
                                                setnewPassword(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="txtConfirmPassword">
                                            Confirm Password
                                        </Label>
                                        <Field
                                            type="password"
                                            value={confirmPassword}
                                            invalid={comparePassword()}
                                            placeholder="Confirm new password"
                                            name="confirmPassword"
                                            id="txtConfirmPassword"
                                            onChange={(event) =>
                                                setConfirmPassword(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Fragment>
                </div>
                <div class="d-block text-right card-footer">
                    <button
                        class="mr-2 btn btn-link btn-sm"
                        onClick={() => props.setshowResetPassword(false)}>
                        Cancel
                    </button>
                    <button class="btn btn-success btn-lg">Save</button>
                </div>
            </Form>
        </div>
    );
};

export default ResetPassword;
