import React, { useState } from 'react';
import './DataRequestProjectNameEditor.scss';
import { Input } from 'reactstrap';
import { useStateSelector } from '../../../../store/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk,
    faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import createNotification from '../../../../utils/createNotification';

interface RenderProjectNameEditorProps {
    projectName: string;
    projectId: number;
    onSuccessfulUpdate(newProjectName: string): void;
}

const DataRequestProjectNameEditor = (props: RenderProjectNameEditorProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [editorState, setEditorState] = useState<'view' | 'edit'>('view');
    const [projectName, setProjectName] = useState(props.projectName);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const updateProjectName = () => {
        setIsLoading(true);
        const newProjectName = projectName.trim();
        const encodedProjectName = encodeURIComponent(newProjectName);
        axios
            .put(
                `/api/DataRequestProjects/${props.projectId}/projectName?projectName=${encodedProjectName}`
            )
            .then((response) => {
                if (response.status === 200) {
                    setProjectName(newProjectName);
                    props.onSuccessfulUpdate(newProjectName);
                    setEditorState('view');
                } else {
                    createNotification(
                        'An error occured while updating Project Name',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const validateProjectName = (name: string) => {
        const hasError = name.trim().length <= 0;
        setHasError(hasError);
    };

    const renderEditor = () => (
        <div className="project-name-editor">
            <Input
                invalid={hasError}
                disabled={isLoading}
                value={projectName}
                onChange={(event) => {
                    setProjectName(event.target.value);
                    validateProjectName(event.target.value);
                }}></Input>
            <FontAwesomeIcon
                icon={faFloppyDisk as any}
                onClick={() => {
                    if (!isLoading && !hasError) {
                        updateProjectName();
                    }
                }}
            />
        </div>
    );

    const render = () => {
        switch (editorState) {
            case 'edit':
                return renderEditor();
            default:
                return (
                    <div className="project-name-view">
                        {props.projectName}
                        <FontAwesomeIcon
                            icon={faPenToSquare as any}
                            onClick={() => setEditorState('edit')}
                        />
                    </div>
                );
        }
    };

    return <div className="data-request-project-name-editor">{render()}</div>;
};

export default DataRequestProjectNameEditor;
