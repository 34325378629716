import React, { useEffect, useState } from 'react';
import './DataRequestDashboardPage.scss';
import Layout from '../../../components/Layout';
import { Badge, Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import { HomeButton } from '../../../components/HomeButton/home-button';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import DocumentsRequestForm from '../DocumentsRequestForm/DocumentsRequestForm';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../models/DataRequestHub/DataRequestProject';
import { useLocation, useNavigate } from 'react-router-dom';
import { objectToQueryString } from '../../../utils/queryHelper';
import { ProjectTypeEnum } from '../../../models/DataRequestHub/ProjectTypeEnum';
import { useSearchParams } from 'react-router-dom';
import { DataFormType } from '../../../models/DataRequestHub/DataFormTypeEnum';
import DataLoader from '../../../components/DataLoader/DataLoader';
import QuestionnaireStagesHost from '../QuestionnaireStagesHost/QuestionnaireStagesHost';
import TabNotificationStatus from '../../../models/DataRequestHub/TabNotificationStatus';
import { useDispatch } from 'react-redux';
import {
    initializeDataRequestNotifications,
    resetQuestionsSeenStatusByFormType,
    updateQuestionsAndCells,
} from '../../../models/DataRequestHub/slices/data-request-notifications.slice';
import DataTableStagesHost from '../DataTableStagesHost/DataTableStagesHost';
import { EditorTypeEnum } from '../../../models/DataRequestHub/ProjectEditorEnum';
import { NotificationFlag } from '../../../slices/notification-flags/notification-flag';
import { updateNotificationFlagCountByCode } from '../../../slices/notification-flags/notification-flags.slice';
import { NotificationFlagsCategories } from '../../../models/NotificationFlags/NotificationFlagsCategories';

interface DataRequestDashboardProps {
    projectId?: number;
}

interface TabData {
    formType: EditorTypeEnum;
    displayName: string;
}

export const DataRequestDashboardPage = (props: DataRequestDashboardProps) => {
    const dispatch = useDispatch();
    const questionsSeenStatus = useStateSelector(
        (s) => s.dataRequestsNotifications.questionsSeenStatus
    );
    const tabsNotificationStatus = useStateSelector(
        (s) => s.dataRequestsNotifications.projectTabsNotificationsStatus
    );
    const axios = useStateSelector((s) => s.core.axios);
    const location = useLocation();

    const [isAdminView, setIsAdminView] = useState(
        location.state?.isAdmin ?? false
    );
    const [preselectedQuestionnaireFormId, setPreselectedQuestionnaireFormId] =
        useState(0);
    const [preselectedTableId, setPreselectedTableId] = useState(0);
    const [preselectedTableViewId, setPreselectedTableViewId] = useState(0);
    const [projectName, setProjectType] = useState('');
    const [projectId, setProjectId] = useState('');
    const [practiceName, setPracticeName] = useState('');
    const [projects, setProjects] = useState<DataRequestProject[]>([]);
    const [activeTab, setActiveTab] = useState<EditorTypeEnum>(null);
    const [searchParams] = useSearchParams({});
    const [tabData] = useState<TabData[]>([
        { formType: EditorTypeEnum.Documents, displayName: 'Documents' },
        {
            formType: EditorTypeEnum.FinancialRequest,
            displayName: 'Financial Requests',
        },
        {
            formType: EditorTypeEnum.Questionnaire,
            displayName: 'Questionnaire',
        },
        {
            formType: EditorTypeEnum.DataTable,
            displayName: 'Data Tables',
        },
    ]);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const isAdminView = location.state?.isAdmin ?? false;

        if (isAdminView) {
            setIsAdminView(true);
        }

        const projectName = location.state?.projectName ?? 'Other';
        if (projectName) {
            setProjectType(projectName);
        }

        const practiceName = location.state?.practiceName ?? '';
        if (practiceName) {
            setPracticeName(practiceName);
        }

        const questionnaireId =
            location.state?.preselectedQuestionnaireFormId ?? 0;

        setPreselectedQuestionnaireFormId(questionnaireId);

        const tableId = location.state?.preselectedTableId ?? 0;

        setPreselectedTableId(tableId);

        const tableViewId = location.state?.preselectedTableViewId ?? 0;

        setPreselectedTableViewId(tableViewId);

        fetchData(true);
    }, []);

    useEffect(() => {
        const value = searchParams.get('tab');
        const numberValue = Number(value);
        if (value && numberValue && numberValue >= 1 && numberValue <= 4) {
            const index = numberValue - 1;
            const dataForms = projects
                .flatMap((x) => x.dataForms)
                .filter(
                    (x) =>
                        x.isVisible || x.formType === DataFormType.Questionnaire
                );

            if (
                !dataForms.some((x) => x.formType == index) &&
                !(index === EditorTypeEnum.Questionnaire) &&
                !(index === EditorTypeEnum.DataTable)
            ) {
                setFirstAvailableTab();
            } else {
                setActiveTab(index);
                const type = getDataFormType(tabData[index].formType);
                markFormAsSeen(type);
            }
        } else if (projects.length) {
            setFirstAvailableTab();
        }
    }, [projects]);

    const getNotificationFlagCode = (): string => {
        if (projects.length && tabsNotificationStatus.length) {
            if (projectId) {
                const targetProject = projects.find((f) => f);
                const practiceId = targetProject?.practiceId ?? '';
                const projectId = targetProject?.id ?? '';

                return `${NotificationFlagsCategories.PracticesCode}.${practiceId}.DR.${projectId}.NOT_REMOVABLE`;
            }

            return NotificationFlagsCategories.OtherDataRequests;
        }

        return '';
    };

    useEffect(() => {
        if (!isAdminView) {
            const tabsNotificationCount = tabsNotificationStatus.reduce(
                (a, b) => a + b.countOfNotifications,
                0
            );
            const updatedFlag: NotificationFlag = {
                eventCode: getNotificationFlagCode(),
                count: tabsNotificationCount,
            };

            dispatch(updateNotificationFlagCountByCode(updatedFlag));
        }
    }, [tabsNotificationStatus]);

    const setFirstAvailableTab = () => {
        const dataForms = projects
            .flatMap((x) => x.dataForms)
            .filter((x) => x.isVisible);
        const formTypes = dataForms.map((form) => form?.formType);
        const firstAvailableFormType = Math.min(
            ...formTypes,
            EditorTypeEnum.DataTable
        );
        setActiveTab(firstAvailableFormType);

        const type = getDataFormType(tabData[firstAvailableFormType]?.formType);
        markFormAsSeen(type);
    };

    const changeTab = (index: number) => {
        const tabNumber = index + 1;
        setActiveTab((lastActiveTab) => {
            if (lastActiveTab !== index) {
                dispatch(
                    resetQuestionsSeenStatusByFormType({
                        formType: lastActiveTab,
                    })
                );
            }
            return index;
        });
        const type = getDataFormType(tabData[index].formType);
        markFormAsSeen(type);

        navigate(`${location.pathname}?tab=${tabNumber}`, {
            state: {
                projectId: projectId,
                isAdmin: isAdminView,
                projectName: projectName,
                practiceName: practiceName,
            },
        });
    };

    const fetchData = (isInitialFetch: boolean = false) => {
        const projId =
            location.state?.projectId ?? props.projectId ?? projectId;
        if (projId) {
            setProjectId(projId);
            fetchProjectData(projId, isInitialFetch);
        } else {
            fetchProjectsWithOtherType(isInitialFetch);
        }
    };

    const initializeQuestionsSeenStatus = (projects: DataRequestProject[]) => {
        dispatch(
            initializeDataRequestNotifications({
                projects: projects,
                isAdminView: isAdminView,
            })
        );
    };

    const markQuestionsAsSeen = (
        formId: number,
        questionsIds: number[],
        subQuesitonsIds: number[]
    ) => {
        const putObject = {
            questionsIds: questionsIds,
            subQuesitonsIds: subQuesitonsIds,
        };

        const targetProject = projects.find((project) => {
            const targetForm = project.dataForms.find((f) => f.id === formId);
            return targetForm;
        });
        const targetForm = targetProject.dataForms.find((f) => f.id === formId);

        if ((!targetProject.isSeenByUser || targetForm.isNew) && !isAdminView) {
            axios.put(
                `/api/DataRequestProjects/${targetProject.id}/dataForm/${formId}/seenFlag`
            );
        } else {
            axios.put(
                `/api/DataRequestProject/${targetProject.id}/dataForm/${formId}/questions/seenFlag`,
                putObject
            );
        }
    };

    const markCellsAsSeen = (
        projectId: number,
        tableId: number,
        viewId: number
    ) => {
        if (tableId && viewId) {
            axios.put(
                `/api/dataRequestProjects/${projectId}/dataTables/${tableId}/DataTableViews/${viewId}/seenFlag`
            );
        }
    };

    const resetQuestionnaireFormsIfNewProject = () => {
        const projectFormsInfo = projects
            .filter((project) => !project.isSeenByUser)
            .flatMap((project) => {
                const targetForms = project.dataForms.filter(
                    (f) => f.formType === DataFormType.Questionnaire
                );

                return {
                    projectId: project.id,
                    questionnaireForms: targetForms,
                };
            });

        projectFormsInfo.forEach(({ projectId, questionnaireForms }) => {
            questionnaireForms.forEach((form) => {
                axios.put(
                    `/api/DataRequestProjects/${projectId}/dataForm/${form.id}/seenFlag`
                );
            });
        });
    };

    const resetDataTableViewsIfNewProject = () => {
        const projectsTables = projects
            .filter((project) => !project.isSeenByUser)
            .flatMap((project) => {
                return {
                    projectId: project.id,
                    dataTables: project.dataTables,
                };
            });

        projectsTables.forEach((project) => {
            if (project && project.dataTables) {
                const tableWithViews = project.dataTables.find(
                    (dataTable) => dataTable?.views?.length
                );
                const firstView = tableWithViews?.views?.find((view) => view);

                if (firstView) {
                    axios.put(
                        `/api/dataRequestProjects/${project.projectId}/dataTables/${tableWithViews.id}/DataTableViews/${firstView.id}/seenFlag`
                    );
                }
            }
        });
    };

    const markFormAsSeen = (formTypeToMarkAsSeen: DataFormType) => {
        if (formTypeToMarkAsSeen === DataFormType.DataTable) {
            resetDataTableViewsIfNewProject();
        }

        if (formTypeToMarkAsSeen === DataFormType.Questionnaire) {
            resetQuestionnaireFormsIfNewProject();
        } else {
            const targetFormsToUpdate = projects.flatMap((project) => {
                const targetForm = project.dataForms.find(
                    (f) => f.formType === formTypeToMarkAsSeen
                );

                if (!targetForm) {
                    return {
                        projectId: 0,
                        formId: 0,
                    };
                }

                return {
                    projectId: project.id,
                    formId: targetForm.id,
                };
            });

            targetFormsToUpdate.forEach(({ projectId, formId }) => {
                if (projectId > 0 && formId > 0) {
                    axios.put(
                        `/api/DataRequestProjects/${projectId}/dataForm/${formId}/seenFlag`
                    );
                }
            });
        }
    };

    const fetchProjectData = (projectId: number, isInitialFetch: boolean) => {
        axios
            .get(`/api/dataRequestProjects/${projectId}`)
            .then((response) => {
                if (response.status === 200) {
                    if (isInitialFetch) {
                        initializeQuestionsSeenStatus([{ ...response.data }]);
                    } else {
                        dispatch(
                            updateQuestionsAndCells({
                                projects: [{ ...response.data }],
                            })
                        );
                    }

                    setProjects([{ ...response.data }]);
                } else {
                    createNotification(
                        (response as any).response?.data?.detail ??
                            'An Error occured while fetching project data',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const fetchProjectsWithOtherType = (isInitialFetch: boolean) => {
        axios
            .get(
                `/api/dataRequestProjects?${objectToQueryString({
                    projectType: ProjectTypeEnum.Other,
                })}`
            )
            .then((response) => {
                if (response.status) {
                    setProjects([...response.data]);
                    if (isInitialFetch) {
                        initializeQuestionsSeenStatus(response.data);
                    } else {
                        dispatch(
                            updateQuestionsAndCells({
                                projects: [...response.data],
                            })
                        );
                    }
                } else {
                    createNotification(
                        'An Error occured while fetching project data',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getHeaderTitle = () => {
        const practiceLalel = practiceName
            ? ` - Practice: ${practiceName}`
            : projects.length == 1
            ? ` - Practice: ${projects.find((x) => x).practiceName}`
            : '';
        return `${projectName}` + practiceLalel;
    };

    useEffect(() => {
        if (isAdminView) {
            const refreshProjectInfoInterval = setInterval(() => {
                fetchData();
            }, 10000);

            return () => {
                clearInterval(refreshProjectInfoInterval);
            };
        }
    }, [isAdminView, projectName]);

    const tabContent = () => {
        switch (activeTab) {
            case EditorTypeEnum.Documents:
                return (
                    <DocumentsRequestForm
                        key={'document-view-form'}
                        formType={DataFormType.Documents}
                        isAdminView={isAdminView}
                        projects={projects}
                        onSuccessfulUpload={fetchData}
                        updateProjectsData={fetchData}
                        markAsSeen={() =>
                            markFormAsSeen(DataFormType.Documents)
                        }
                        questionsSeenStatus={questionsSeenStatus}
                    />
                );
            case EditorTypeEnum.FinancialRequest:
                return (
                    <DocumentsRequestForm
                        key={'financial-request-view-form'}
                        formType={DataFormType.FinancialRequest}
                        isAdminView={isAdminView}
                        projects={projects}
                        onSuccessfulUpload={fetchData}
                        updateProjectsData={fetchData}
                        markAsSeen={() =>
                            markFormAsSeen(DataFormType.FinancialRequest)
                        }
                        questionsSeenStatus={questionsSeenStatus}
                    />
                );
            case EditorTypeEnum.Questionnaire:
                return (
                    <QuestionnaireStagesHost
                        preselectedQuestionnaireFormId={
                            preselectedQuestionnaireFormId
                        }
                        projects={projects}
                        isAdminView={isAdminView}
                        updateProjectsData={fetchData}
                        questionsSeenStatus={questionsSeenStatus}
                        markQuestionsAsSeen={markQuestionsAsSeen}
                    />
                );
            case EditorTypeEnum.DataTable:
                return (
                    <DataTableStagesHost
                        projects={projects}
                        preselectedTableId={preselectedTableId}
                        preselectedTableViewId={preselectedTableViewId}
                        isAdminView={isAdminView}
                        updateProjectsData={fetchData}
                        markCellsAsSeen={markCellsAsSeen}
                    />
                );
            default:
                return <></>;
        }
    };

    const getTabClasses = (index: number) => {
        const isTabVisible = projects.some((x) => {
            return (
                x.dataForms.some(
                    (form) => form.formType == index && form.isVisible
                ) ||
                (index === EditorTypeEnum.Questionnaire &&
                    isAdminView &&
                    x.state === DataRequestProjectState.Active) ||
                (index === EditorTypeEnum.DataTable &&
                    ((isAdminView &&
                        x.state === DataRequestProjectState.Active) ||
                        x.dataTables.length))
            );
        });

        if (!isTabVisible) {
            return 'RRT__tab d-none';
        }

        return activeTab == index
            ? 'RRT__tab RRT__tab--first RRT__tab--selected'
            : 'RRT__tab';
    };

    const getDataFormType = (type: EditorTypeEnum) => {
        switch (type) {
            case EditorTypeEnum.Documents:
                return DataFormType.Documents;
            case EditorTypeEnum.FinancialRequest:
                return DataFormType.FinancialRequest;
            case EditorTypeEnum.Questionnaire:
                return DataFormType.Questionnaire;
            case EditorTypeEnum.DataTable:
                return DataFormType.DataTable;
            default:
                return DataFormType.Documents;
        }
    };

    const getTabNotificationState = (
        type: EditorTypeEnum
    ): TabNotificationStatus => {
        const tabFormType = getDataFormType(type);
        return (
            tabsNotificationStatus.find((f) => f.formType === tabFormType) ?? {
                displayName: '',
                formType: tabFormType,
                isNewForm: false,
                countOfNotifications: 0,
                isNewProject: true,
                formId: 0,
            }
        );
    };

    return (
        <Layout>
            <div className="data-request-dashboard">
                <div className="title-content">
                    <div className="page-title">
                        <h3>{getHeaderTitle()}</h3>
                    </div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <HomeButton></HomeButton>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Data Request Dashboard
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="main-card">
                    <div className="body_white_box practice-page-tabs m-0">
                        <div className="white-box-content">
                            {isLoading ? (
                                <DataLoader />
                            ) : (
                                <>
                                    <div className="RRT__container">
                                        <div className="RRT__tabs body-tabs">
                                            {tabData?.map((item, index) => {
                                                const tabState =
                                                    getTabNotificationState(
                                                        item.formType
                                                    );

                                                return (
                                                    <div
                                                        key={index}
                                                        className={getTabClasses(
                                                            index
                                                        )}
                                                        onClick={() => {
                                                            changeTab(index);
                                                        }}
                                                        id={
                                                            'tab-info-' + index
                                                        }>
                                                        {item.displayName}
                                                        {tabState.countOfNotifications &&
                                                        !tabState.isNewProject ? (
                                                            <Badge className="unread-comments-badge">
                                                                {tabState.countOfNotifications >
                                                                9
                                                                    ? '9+'
                                                                    : tabState.countOfNotifications}
                                                            </Badge>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {tabContent()}
                                </>
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};
