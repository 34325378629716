import React from 'react';
import './AccessRestrictedModal.scss';
import Rodal from '../Rodal/Rodal';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {
    SupportContextEnum,
    SupportContextItemList,
} from '../../support-portal/models/SupportContext';
import ContactSupportButton from '../../containers/ContactSupportButton/ContactSupportButton';

interface AccessRestrictedModalProps {
    text: string;
    supportContext?: SupportContextEnum;
}

const AccessRestrictedModal = (props: AccessRestrictedModalProps) => {
    const animation = 'slideTop';

    let navigate = useNavigate();

    const navigateHome = () => {
        navigate('/user-home');
    };

    const mapSupportContextName = (supportContext?: SupportContextEnum) => {
        switch (supportContext) {
            case SupportContextEnum.KnowledgeLibrary:
                return 'Knowledge Library';

            default:
                return SupportContextItemList.find(
                    (x) => x.id == props.supportContext
                ).name;
        }
    };

    return (
        <div className="access-restricted-modal-wrapper">
            <Rodal
                onClose={navigateHome}
                showMask={false}
                visible={true}
                animation={animation}
                width={600}>
                <ModalHeader>Access Restricted</ModalHeader>
                <ModalBody>
                    <div className="access-restricted-modal-content">
                        {props.text}
                    </div>
                    <div className="btn-wrapper">
                        <Button
                            className="btn close-button btn btn-secondary"
                            onClick={navigateHome}>
                            Back Home
                        </Button>
                        {props.supportContext ? (
                            <ContactSupportButton
                                supportContext={props.supportContext}
                                buttonType="button"
                                buttonText={`Contact ${mapSupportContextName(
                                    props.supportContext
                                )} Administrator`}
                            />
                        ) : (
                            []
                        )}
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};
export default AccessRestrictedModal;
