import React, { useEffect, useState } from 'react';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import Rodal from '../../../../components/Rodal/Rodal';
import '../KnowledgeBaseContentLibrary.scss';
import './KnowledgeBaseRequestContentModal.scss';
import RequestedContentTable from '../RequestedContentTable/RequestedContentTable';
import RequestContentForm from '../RequestContentForm/RequestContentForm';

const KnowledgeBaseRequestContentModal = (props) => {
    const [displayedMode, setDisplayMode] = useState(0);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const requestedContentList = (
        <div className="requested-content-list">
            <ModalHeader>
                Requested Content List{' '}
                <Button onClick={() => setDisplayMode(1)}>New Request</Button>
            </ModalHeader>
            <ModalBody>
                <div className="body_white_box">
                    <RequestedContentTable
                        onContentApproved={() =>
                            props.onContentApproved()
                        }></RequestedContentTable>
                </div>
            </ModalBody>
        </div>
    );

    const createRequestedContent = (
        <div className="request-new-content">
            <ModalHeader>Request New Content</ModalHeader>

            <ModalBody className="request-content-body">
                <RequestContentForm
                    onSuccessfullSubmit={() => setDisplayMode(0)}
                    onCancelClicked={() =>
                        setDisplayMode(0)
                    }></RequestContentForm>
            </ModalBody>
        </div>
    );

    const modes = [
        { id: 'contentList', content: requestedContentList },
        { id: 'createRequest', content: createRequestedContent },
    ];

    return (
        <Rodal
            width={displayedMode === 0 ? 750 : 500}
            className={
                displayedMode === 0
                    ? 'request-content-popup'
                    : 'request-content-popup new-request-mode'
            }
            visible={props.isVisible}
            onClose={() => props.setIsVisible(false)}
            animation={'fade'}
            showMask={false}>
            {modes[displayedMode].content}
        </Rodal>
    );
};
export default KnowledgeBaseRequestContentModal;
