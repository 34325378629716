import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface LikeMessageThunkArg {
    connection: HubConnection;
    clubhouseId: number;
    memberId: number;
    messageId: number;
    discussionId: number;
}

export const likeMessage = createAsyncThunk(
    'clubhouseMessages/like',
    async (arg: LikeMessageThunkArg) => {
        let like = {
            memberId: arg.memberId,
            messageId: arg.messageId,
            discussionId: arg.discussionId,
            clubhouseId: arg.clubhouseId,
        };
        await arg.connection.send('LikeMessage', like);
    }
);
