export interface RetrieveMessageArg {
    threadId: number;
}

export const retrieveSupportThreadMessages = async (
    arg: RetrieveMessageArg,
    dispatch: any
) => {
    const axios = dispatch.getState().core.axios;
    const response = await axios.get(
        `api/supportRequests/${arg.threadId}/messages`
    );

    return {
        data: response.data.messages,
        threadId: arg.threadId,
        lastMessageId: response.data.lastMessageId,
    };
};
