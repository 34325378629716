import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {
    selectDiscussion,
    updateOnlineMembers,
    onClubhouseUpdate,
} from '../slices/clubhouse/clubhouse-slice';
import { fetchClubhouseData } from '../slices/clubhouse/fetch-clubhouse-data.thunk';
import { createDiscussion } from '../slices/clubhouse/create-discussion.thunk';
import { updateDiscussion } from '../slices/clubhouse/update-discussion.thunk';
import { AddPosterVote } from '../slices/clubhouse/add-poster-vote.thunk';
import { StartVoting } from '../slices/clubhouse/start-voting.thunk';
import { addToMessageList } from '../slices/messages/add-to-message-list.thunk';
import { updateMessageInList } from '../slices/messages/update-message-in-list.thunk';
import { loadDiscussionMessages } from '../slices/messages/load-discussion-messages.thunk';
import {
    ComparationType,
    loadDiscussionMessagesByLikes,
} from '../slices/messages/load-discussion-messages-by-likes.thunk';
import { sendMessage } from '../slices/messages/send-message.thunk';
import ChatArea from '../chat-area/chat-area';
import DiscussionList from '../disussion-list/discussion-list';
import './clubhouse-area.scss';
import * as signalR from '@microsoft/signalr';
import createNotification from '../../utils/createNotification';
import { BASE_URL } from '../../utils/config';
import { Notification } from '../models/notification';
import { Discussion } from '../models/discussion';
import {
    setIsNewMessageReceived,
    UpdateState,
} from '../slices/messages/messages-slice';
import { checkForChanges } from '../slices/messages/check-for-changes.thunk';
import { refreshMessageList } from '../slices/messages/refresh-message-list.thunk';
import { Clubhouse } from '../models/clubhouse';
import Helpers from '../../utils/helper';
import { GroupType } from '../models/group-type';
import { CancelPosterVote } from '../slices/clubhouse/cancel-poster-vote.thunk';
import { RELOAD_COUNT } from '../../utils/constants';
import { likeMessage } from '../slices/messages/like-message.thunk';
import { addLikeToMessage } from '../slices/messages/add-like-to-message.thunk';
import { UpdateContentStatus } from '../slices/messages/update-content-status.thunk';
import { MessageReference } from '../models/message-reference';
import { refreshDirect } from '../../direct/slices/rooms/direct-rooms-slice';
import { updateMessage } from '../slices/messages/update-message.thunk';
import { useStateSelector } from '../../store/selectors';
import { addNotificationFlagToDelete } from '../../slices/notification-flags/notification-flags.slice';
import { RefreshNotificationFlags } from '../../slices/notification-flags/refresh-notification-flags';
import { PmgPocChatNotificationFlagsHelper } from '../../utils/notification-flags-helpers/pmgPocChatNotificationFlags.helper';
import { TokenHelper } from '../../utils/tokenHelper';

interface ClubhouseAreaProps {
    clubhouseId: number;
    groupType: GroupType;
    isAdmin: boolean;
    isKbAvailable: boolean;
    isDataLoading: boolean;
    setIsDataLoading(value: boolean): void;
    isActive: boolean;
    setIsActive(value: boolean): void;
    setIsForbiddenError(value: boolean): void;
    setIsUnexpectedError(value: boolean): void;
    refreshClubhouseData(): void;
    messageReference: MessageReference;
    resetMessageReference: () => void;
    isDisabledMessageInput: boolean;
    displayTopLikedPosts?: () => void;
    preselectedDiscussionId?: number | null;
}

const ClubhouseArea = (props: ClubhouseAreaProps) => {
    const dispatch: any = useDispatch();
    const messages = useStateSelector(
        (state) => state.clubhouseMessages.discussions
    );
    const clubhouse = useStateSelector((state) => state.clubhouse.clubhouse);
    const isMessageLoading = useStateSelector(
        (state) => state.clubhouseMessages.isMessageLoading
    );
    const isNewMessageReceived = useStateSelector(
        (state) => state.clubhouseMessages.isNewMessageReceived
    );
    const isPageLoading = useStateSelector(
        (state) => state.clubhouseMessages.isPageLoading
    );
    const axios = useStateSelector((state) => state.core.axios);
    const [connection, setConnection] = useState(null);
    const [isHubConnected, setIsHubConnected] = useState(false);
    const notificationFlags = useStateSelector(
        (s) => s.notificationFlags.flags
    );
    const forbiddenStatusCode = 403;
    const pinnedDiscussions =
        props.groupType == GroupType.PMG
            ? ['General']
            : props.groupType == GroupType.IPOC
            ? ['Ecosystem Thoughts', 'IPOC Thoughts']
            : ['Ecosystem Thoughts', 'PLC Thoughts'];

    let currentDiscussionId = 0;
    const userId = TokenHelper.getUserIdFromToken();

    useEffect(() => {
        initializeConnection();
    }, []);

    useEffect(() => {
        startConection().then();

        return () => {
            if (connection) {
                connection._closedCallbacks = [];
                connection.stop();
            }
        };
    }, [connection]);

    useEffect(() => {
        if (props.messageReference) {
            setSelectedDiscussion(props.messageReference.discussionId);
        }
    }, [props.messageReference]);

    const initializeConnection = () => {
        props.setIsDataLoading(true);
        dispatch(fetchClubhouseData(props.clubhouseId)).then((result: any) => {
            if (result.payload.data) {
                const connection = initConnection();
                setConnection(connection);
                props.setIsActive(result.payload.data.isActive);
                const generalDiscusisonId =
                    result.payload.data.discussions.find((d: Discussion) =>
                        pinnedDiscussions.find((p) => d.name === p)
                    )?.id ?? result.payload.data.discussions[0]?.id;
                setPreselectedDiscussion(
                    result.payload.data.discussions,
                    generalDiscusisonId
                );
                connection.onreconnected(() => {
                    reloadClubhouse(generalDiscusisonId, connection);
                });
            } else if (
                result.payload?.response?.status === forbiddenStatusCode
            ) {
                props.setIsForbiddenError(true);
            } else {
                props.setIsUnexpectedError(true);
            }

            props.setIsDataLoading(false);
        });

        return () => connection?.invoke('RemoveFromGroup', props.clubhouseId);
    };

    const setPreselectedDiscussion = (
        discussions: Array<Discussion>,
        generalDiscussionId: number
    ) => {
        if (
            props.preselectedDiscussionId &&
            discussions.find((d) => d.id == props.preselectedDiscussionId)
        ) {
            setSelectedDiscussion(props.preselectedDiscussionId);
        } else {
            setSelectedDiscussion(generalDiscussionId);
        }
    };

    const startConection = async () => {
        if (connection) {
            try {
                await connection.start();
                setIsHubConnected(true);

                connection.on(
                    'MessageReceived',
                    async (notification: Notification) => {
                        await handleMessageReceived(notification);
                    }
                );

                connection.on('LikeMessage', (notification: Notification) => {
                    dispatch(
                        addLikeToMessage({
                            message: notification.message,
                            chatId: notification.chatId,
                        })
                    );
                });
                connection.on(
                    'MessageUpdated',
                    (notification: Notification) => {
                        dispatch(
                            updateMessageInList({
                                message: notification.message,
                                chatId: notification.chatId,
                            })
                        );
                    }
                );
                connection.on(
                    'UpdateOnlineUsers',
                    (onlineMembersIds: Array<number>) => {
                        sessionStorage.removeItem('reloadCount');
                        dispatch(updateOnlineMembers(onlineMembersIds));
                    }
                );
                connection.on('UpdateClubhouse', (notification: Clubhouse) => {
                    if (
                        currentDiscussionId &&
                        notification.discussions.filter(
                            (e) => e.id === currentDiscussionId
                        ).length === 0
                    ) {
                        setSelectedDiscussion(null);
                    }
                    if (
                        notification.members.some(
                            (m) =>
                                m.userId === userId &&
                                (!m.isActive ||
                                    (m.profile && !m.profile.isActive))
                        )
                    ) {
                        window.location.reload();
                    }
                    dispatch(onClubhouseUpdate(notification));
                    dispatch(UpdateState(notification));
                    currentDiscussionId = notification.selectedDiscussionId;
                });
                connection.on('Disconnect', () => {
                    if (!props.isAdmin) {
                        props.setIsForbiddenError(true);
                        connection.stop();
                    }
                });
                connection.onclose(() =>
                    retryOrShowMessage(
                        'Connection lost. Please try again later.'
                    )
                );

                dispatch(refreshDirect());
            } catch {
                if (isHubConnected) {
                    retryOrShowMessage(
                        'Unable to connect to Clubhouse. Please try again later.'
                    );
                }
            }
        }
    };

    const handleMessageReceived = async (notification: Notification) => {
        await dispatch(RefreshNotificationFlags());
        await dispatch(setIsNewMessageReceived(true));
        notification.message.sharedContents =
            notification.message.sharedContents.map((content) => {
                const modified = { ...content };
                modified.shouldBeUpdated = true;
                return modified;
            });
        await dispatch(
            addToMessageList({
                message: notification.message,
                chatId: notification.chatId,
            })
        );
    };

    const retryOrShowMessage = (notificationText: string) => {
        let count = parseInt(sessionStorage.getItem('reloadCount'));

        if (isNaN(count)) {
            sessionStorage.setItem('reloadCount', String(0));
            count = 0;
        }

        if (count < RELOAD_COUNT) {
            sessionStorage.setItem('reloadCount', String(count + 1));
            createNotification('Reconnecting...', 'error');
            setTimeout(() => {
                props.refreshClubhouseData();
            }, 1000);
        } else {
            sessionStorage.removeItem('reloadCount');
            createNotification(notificationText, 'error');
            props.setIsUnexpectedError(true);
        }
    };

    const initConnection = () => {
        let url = BASE_URL == '/' ? window.location.origin : BASE_URL;
        return new signalR.HubConnectionBuilder()
            .withUrl(`${url}/hubs/messages?clubhouseId=${props.clubhouseId}`, {
                accessTokenFactory: () => localStorage.getItem('token'),
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) =>
                    retryContext.elapsedMilliseconds < 120000 ? 5000 : 30000,
            })
            .configureLogging(signalR.LogLevel.Warning)
            .build();
    };

    const reloadClubhouse = (
        discussionId: number,
        connection: signalR.HubConnection
    ) => {
        dispatch(fetchClubhouseData(props.clubhouseId)).then((result: any) => {
            if (result.payload.data) {
                if (discussionId) {
                    dispatch(
                        checkForChanges({
                            clubhouseId: props.clubhouseId,
                            discussionId: discussionId,
                        })
                    );
                }
            } else if (
                result.payload?.response?.status === forbiddenStatusCode
            ) {
                props.setIsForbiddenError(true);
                connection.stop();
            } else {
                props.setIsUnexpectedError(true);
                connection.stop();
            }
        });
        dispatch(RefreshNotificationFlags());
    };

    const sendDiscussionMessage = (
        messageText: string,
        files: Array<number>,
        sharedContentIds: Array<number>
    ) => {
        if (connection.state !== signalR.HubConnectionState.Connected) {
            return false;
        }
        dispatch(
            sendMessage({
                messageText,
                clubhouseId: props.clubhouseId,
                selectedDiscussionId: clubhouse?.selectedDiscussionId,
                connection: connection,
                attachmentsIds: files,
                sharedContentIds: sharedContentIds,
            })
        );
        return true;
    };

    const likeDiscussionMessage = (messageid: number) => {
        if (connection.state !== signalR.HubConnectionState.Connected) {
            return false;
        }
        dispatch(
            likeMessage({
                messageId: messageid,
                clubhouseId: props.clubhouseId,
                memberId: clubhouse?.currentMemberId,
                connection: connection,
                discussionId: clubhouse?.selectedDiscussionId,
            })
        );
        return true;
    };

    const setSelectedDiscussion = (id: number) => {
        if (clubhouse?.selectedDiscussionId !== id) {
            dispatch(selectDiscussion(id));
            dispatch(setIsNewMessageReceived(false));
            if (props.messageReference) {
                dispatch(
                    loadDiscussionMessages({
                        clubhouseId: props.clubhouseId,
                        discussionId: props.messageReference.discussionId,
                        isAscending: false,
                        messageId: props.messageReference.messageId,
                    })
                );
                return;
            }
            if (!messages[id]?.hasFirstPage) {
                dispatch(
                    refreshMessageList({
                        clubhouseId: props.clubhouseId,
                        discussionId: id,
                    })
                );
            }

            if (connection) {
                connection.onreconnected(() => {
                    reloadClubhouse(id, connection);
                });
            }
        } else {
            if (props.messageReference) {
                dispatch(
                    loadDiscussionMessages({
                        clubhouseId: props.clubhouseId,
                        discussionId: clubhouse?.selectedDiscussionId,
                        isAscending: true,
                        messageId: props.messageReference.messageId,
                    })
                );
                return;
            }
        }
    };

    const getSeletedDiscussionName = () =>
        clubhouse?.discussions.find(
            (d: Discussion) => d.id === clubhouse?.selectedDiscussionId
        )?.name;

    const loadMessagesPage = (isAscending: boolean) => {
        if (
            (!isAscending &&
                !messages[clubhouse?.selectedDiscussionId].hasLastPage) ||
            (isAscending &&
                !messages[clubhouse?.selectedDiscussionId].hasFirstPage)
        ) {
            dispatch(
                loadDiscussionMessages({
                    clubhouseId: props.clubhouseId,
                    discussionId: clubhouse?.selectedDiscussionId,
                    isAscending: isAscending,
                    pageSize: null,
                })
            );
        }
    };

    const loadMessagesPageByLikes = (
        isAscending: boolean,
        numberOfLikes: number,
        comparationType: ComparationType
    ) => {
        if (
            (!isAscending &&
                !messages[clubhouse?.selectedDiscussionId].hasLastPage) ||
            (isAscending &&
                !messages[clubhouse?.selectedDiscussionId].hasFirstPage)
        ) {
            dispatch(
                loadDiscussionMessagesByLikes({
                    clubhouseId: props.clubhouseId,
                    discussionId: clubhouse?.selectedDiscussionId,
                    isAscending: isAscending,
                    pageSize: null,
                    numberOfLikes: numberOfLikes,
                    comparationType: comparationType,
                })
            );
        }
    };

    const showNewMessages = (): void =>
        dispatch(
            refreshMessageList({
                clubhouseId: props.clubhouseId,
                discussionId: clubhouse?.selectedDiscussionId,
            })
        );

    const createNewDiscussion = async (
        name: string,
        description: string
    ): Promise<any> =>
        dispatch(
            createDiscussion({
                clubhouseId: props.clubhouseId,
                name: name,
                description: description,
            })
        );

    const updateViewedDiscussion = async (
        discussionId: number,
        name: string,
        description: string
    ): Promise<any> =>
        dispatch(
            updateDiscussion({
                clubhouseId: props.clubhouseId,
                discussionId,
                name,
                description,
            })
        );

    const startDiscussionVoting = async (discussionId: number) => {
        dispatch(StartVoting({ clubhouseId: props.clubhouseId, discussionId }));
    };

    const posterVote = async (discussionId: number, vote: boolean) => {
        dispatch(
            AddPosterVote({
                clubhouseId: props.clubhouseId,
                discussionId,
                vote,
            })
        );
    };

    const cancelPosterVote = async (discussionId: number) => {
        dispatch(
            CancelPosterVote({
                clubhouseId: props.clubhouseId,
                discussionId,
            })
        );
    };

    const openAttachment = (attachmentId: number, messageId: number) => {
        axios
            .get(
                `/api/clubhouses/${props.clubhouseId}/discussions/${clubhouse?.selectedDiscussionId}/messages/${messageId}/attachments/${attachmentId}`
            )
            .then((response: any) => {
                if (response.status === 200) {
                    window.open(response.data, '_blank');
                } else {
                    createNotification(
                        'Unexpected error occurred while opening an attachment.',
                        'error'
                    );
                }
            });
    };

    const updateContent = (
        discussionId: number,
        messageId: number,
        contentId: number,
        isAddedToShelf: boolean
    ) => {
        dispatch(
            UpdateContentStatus({
                discussionId: discussionId,
                messageId: messageId,
                contentId: contentId,
                isAddedToShelf: isAddedToShelf,
            })
        );
    };

    const updateMsg = (message: string, messageId: number) => {
        if (connection.state !== signalR.HubConnectionState.Connected) {
            return false;
        }
        dispatch(
            updateMessage({
                connection: connection,
                clubhouseId: props.clubhouseId,
                messageText: message,
                messageId: messageId,
                selectedDiscussionId: clubhouse?.selectedDiscussionId,
            })
        );
        return true;
    };

    const deleteNotificationFlag = (discussionId: number) => {
        const removalFunction = async (discussionIdParam: number) => {
            const notificationFlagCode =
                PmgPocChatNotificationFlagsHelper.generateNotificationFlag(
                    props.groupType,
                    props.clubhouseId,
                    discussionIdParam
                );
            const flag = notificationFlags.find(
                (f) =>
                    f.eventCode.toLowerCase() ==
                    notificationFlagCode.toLowerCase()
            );
            if (flag) {
                await dispatch(
                    addNotificationFlagToDelete({
                        ...flag,
                        refreshAfterRemoval: true,
                    })
                );
            }
        };
        removalFunction(discussionId);
    };

    return (
        <div className="clubhouse-area ">
            {props.isDataLoading ? (
                <div className="loader">{Helpers.renderTableLoader()}</div>
            ) : (
                <div
                    className={cx(
                        'app-inner-layout chat-layout flex-container',
                        {
                            'open-mobile-menu': props.isActive,
                        }
                    )}>
                    <CSSTransition
                        classNames="TabsAnimation"
                        in={true}
                        appear={false}
                        enter={false}
                        exit={false}
                        timeout={0}>
                        <div className="app-inner-layout__wrapper chat-box-wrapper-main flex-container flex-row">
                            {clubhouse?.selectedDiscussionId > 0 ? (
                                <ChatArea
                                    openAttachment={openAttachment}
                                    isAdmin={props.isAdmin}
                                    authorizedMemberId={
                                        clubhouse?.currentMemberId
                                    }
                                    members={clubhouse?.members}
                                    messages={
                                        messages[
                                            clubhouse?.selectedDiscussionId
                                        ]?.messages
                                    }
                                    selectedDiscussionId={
                                        clubhouse?.selectedDiscussionId
                                    }
                                    clubhouseId={props.clubhouseId}
                                    groupType={props.groupType}
                                    loadMessagesPageByLikes={
                                        loadMessagesPageByLikes
                                    }
                                    discussionName={getSeletedDiscussionName()}
                                    sendMessage={sendDiscussionMessage}
                                    loadMessagesPage={loadMessagesPage}
                                    isMessageLoading={isMessageLoading}
                                    isPageLoading={isPageLoading}
                                    showNewMessages={showNewMessages}
                                    isNewMessageReceived={isNewMessageReceived}
                                    hasFirstPage={
                                        messages[
                                            clubhouse?.selectedDiscussionId
                                        ]?.hasFirstPage
                                    }
                                    setIsNewMessageReceived={(e: any) =>
                                        dispatch(
                                            setIsNewMessageReceived(e.target)
                                        )
                                    }
                                    likeMessage={likeDiscussionMessage}
                                    isKbAvailable={props.isKbAvailable}
                                    updateContent={updateContent}
                                    messageReference={props.messageReference}
                                    isDisableMessageInput={
                                        props.isDisabledMessageInput
                                    }
                                    isDisableKbContent={!props.isKbAvailable}
                                    resetMessageReference={
                                        props.resetMessageReference
                                    }
                                    updateMessage={updateMsg}
                                    onReadNewMessages={() =>
                                        deleteNotificationFlag(
                                            clubhouse?.selectedDiscussionId
                                        )
                                    }></ChatArea>
                            ) : (
                                []
                            )}
                            <DiscussionList
                                clubhouseId={props.clubhouseId}
                                displayTopLikedPosts={
                                    props.displayTopLikedPosts
                                }
                                groupType={props.groupType}
                                discussions={clubhouse?.discussions}
                                members={clubhouse?.members}
                                currentMemberId={clubhouse?.currentMemberId}
                                selectedDiscussionId={
                                    clubhouse?.selectedDiscussionId
                                }
                                selectDiscussion={setSelectedDiscussion}
                                pinnedDiscussions={pinnedDiscussions}
                                createDiscussion={createNewDiscussion}
                                updateDiscussion={updateViewedDiscussion}
                                startVoting={startDiscussionVoting}
                                posterVote={posterVote}
                                doesMemberHasViewOnlyAccessToPoc={
                                    props.isDisabledMessageInput
                                }
                                cancelPosterVote={
                                    cancelPosterVote
                                }></DiscussionList>
                        </div>
                    </CSSTransition>
                </div>
            )}
        </div>
    );
};

export default ClubhouseArea;
