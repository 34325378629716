import React, { useEffect, useState } from 'react';
import './vote-component.scss';
import { Poster } from '../models/poster';
import { MEMBER_ACTIVITY_PERIOD } from '../../utils/constants';
import ButtonLoader from '../../components/Layout/Buttons/ButtonLoader';
import voteRemove from '../../assets/images/voteRemove.png';
import voteKeep from '../../assets/images/voteKeep.png';
import changeVote from '../../assets/images/changeVote.png';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { GroupType } from '../models/group-type';
import InfoTooltip from '../../components/info-tooltip/info-tooltip';

interface VoteComponentProps {
    startedById: number;
    posters: Array<Poster>;
    currentMemberId: number;
    startVoting(): void;
    isUserAdmin: boolean;
    posterVote(vote: boolean): void;
    cancelPosterVote(): void;
    isVotingInitializing?: boolean;
    isVoteSaving?: boolean;
    dateInitiated: Date;
    groupType: GroupType;
}

enum VoteValue {
    keep = 'keep',
    remove = 'remove',
}

const VoteComponent = (props: VoteComponentProps) => {
    const infoButtonText = `A discussion will be permanently<br />
    removed once 80% or more of the<br />
    members who have participated within<br />
    the last 90 days have voted to remove<br />
    the discussion.`;
    const [currentPoster, setCurrentPoster] = useState<Poster>();

    const isActivePoster = (lastMessageDate: Date): boolean => {
        let minLastActivityDate = new Date(props.dateInitiated);
        minLastActivityDate.setDate(
            minLastActivityDate.getDate() - MEMBER_ACTIVITY_PERIOD
        );
        return new Date(lastMessageDate) > minLastActivityDate;
    };
    const startedByName = props.posters?.find(
        (p: Poster) => p.id === props.startedById
    )?.fullName;

    const saveVote = (vote: string) => {
        switch (vote) {
            case VoteValue.keep: {
                props.posterVote(false);
                return;
            }
            case VoteValue.remove: {
                props.posterVote(true);
                return;
            }
        }
    };

    const hasPosterRightToVote = (poster: Poster) =>
        isActivePoster(poster.lastPostedDate) || poster.vote !== null;

    const [options] = useState<ApexOptions>({
        colors: ['#d40000', '#3bc47e'],
        chart: {
            type: 'bar',
            height: 165,
            stackType: '100%',
            toolbar: { show: false },
        },
        dataLabels: {
            formatter: function (val: number) {
                return val + '%';
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                borderRadius: 7,
                barHeight: '50%',
            },
        },
        grid: {
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
            labels: {
                maxWidth: 180,
                align: 'left',
                style: {
                    fontSize: '14px',
                    fontFamily: 'SegoeUI-Semibold',
                    fontWeight: 'bold',
                },
            },
        },
        xaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            axisBorder: {
                show: true,
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'SegoeUI-Semibold',
                    fontWeight: 'bold',
                },
                formatter: function (val: string) {
                    return val;
                },
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
    });
    const [series, setSeries] = useState<Array<any>>([]);

    useEffect(() => {
        setSeries([
            {
                data: [
                    {
                        x: 'Remove Discussion',
                        y: Number(
                            (props.posters.filter((p) => p.vote == true)
                                .length /
                                props.posters.filter((p) =>
                                    isActivePoster(p.lastPostedDate)
                                ).length) *
                                100
                        ).toFixed(1),
                    },
                    {
                        x: 'Keep Discussion',
                        y: Number(
                            (props.posters.filter((p) => p.vote == false)
                                .length /
                                props.posters.filter((p) =>
                                    isActivePoster(p.lastPostedDate)
                                ).length) *
                                100
                        ).toFixed(1),
                    },
                ],
            },
        ]);
        setCurrentPoster(
            props.posters.find((p) => p.id === props.currentMemberId)
        );
    }, [props.posters]);

    return (
        <div className="vote-component-app ">
            {props.dateInitiated ? (
                <>
                    <div className="click-wish-removal">
                        <h5>
                            {startedByName} has initiated a vote to permanently
                            remove this discussion thread. Please cast your vote
                            below.
                            <InfoTooltip
                                text={infoButtonText}
                                idText="vote-removal"></InfoTooltip>
                        </h5>
                    </div>
                    {props.groupType === GroupType.IPOC ||
                    props.groupType === GroupType.CPOC ? (
                        <div>
                            <div className="chart-data">
                                <Chart
                                    options={options}
                                    series={series}
                                    type="bar"
                                    width={480}
                                    height={150}
                                />
                            </div>
                            {!props.isUserAdmin &&
                            currentPoster &&
                            hasPosterRightToVote(currentPoster) ? (
                                <div className="vote-buttons-wrapper">
                                    {currentPoster &&
                                    currentPoster.vote !== null ? (
                                        <button
                                            className={
                                                'change-vote-button ' +
                                                (currentPoster.vote === true
                                                    ? 'delete-button-color'
                                                    : 'keep-button-color')
                                            }
                                            onClick={props.cancelPosterVote}
                                            disabled={props.isVoteSaving}>
                                            <img src={changeVote} /> Change Vote
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                className="vote-btn delete-button-color"
                                                onClick={() =>
                                                    saveVote(VoteValue.remove)
                                                }
                                                disabled={props.isVoteSaving}>
                                                <img src={voteRemove} /> Vote to
                                                Remove
                                            </button>
                                            <button
                                                className="vote-btn keep-button-color"
                                                onClick={() =>
                                                    saveVote(VoteValue.keep)
                                                }
                                                disabled={props.isVoteSaving}>
                                                <img src={voteKeep} /> Vote to
                                                Keep
                                            </button>
                                        </>
                                    )}
                                </div>
                            ) : (
                                []
                            )}
                        </div>
                    ) : props.posters.filter((x) => hasPosterRightToVote(x))
                          .length > 0 ? (
                        <div
                            className="intianl-removal-btn-div"
                            onClick={() => {}}>
                            <div className="vote-detail">
                                <div className="vote-row">
                                    <div className="member">
                                        <h6>Member</h6>
                                    </div>
                                    <div className="vote-to-remove">
                                        <h6>Vote to Remove</h6>
                                    </div>
                                    <div className="vote-to-remove Keep">
                                        <h6>Vote to Keep</h6>
                                    </div>
                                </div>
                                {props.posters.map(
                                    (poster: Poster, i: number) => (
                                        <div key={i}>
                                            {hasPosterRightToVote(poster) ? (
                                                <div
                                                    className="vote-row"
                                                    onChangeCapture={(
                                                        event: any
                                                    ) =>
                                                        saveVote(
                                                            event.target.value
                                                        )
                                                    }>
                                                    <div className="member">
                                                        <label>
                                                            {poster.fullName}
                                                        </label>
                                                    </div>
                                                    <div className="vote-to-remove">
                                                        <div className="check">
                                                            <input
                                                                type="radio"
                                                                id={`vote-remove-${poster.id}`}
                                                                name={`vote-${poster.id}`}
                                                                disabled={
                                                                    props.isVoteSaving
                                                                }
                                                                value={
                                                                    VoteValue.remove
                                                                }
                                                                readOnly={true}
                                                                checked={
                                                                    poster.vote
                                                                }
                                                            />
                                                            {poster.id ===
                                                            props.currentMemberId ? (
                                                                <label
                                                                    htmlFor={`vote-remove-${poster.id}`}
                                                                    id="kenCole"
                                                                    className="label-input"></label>
                                                            ) : (
                                                                <label className="label-input"></label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="vote-to-remove Keep">
                                                        <div className="check">
                                                            <input
                                                                type="radio"
                                                                id={`vote-keep-${poster.id}`}
                                                                name={`vote-${poster.id}`}
                                                                disabled={
                                                                    props.isVoteSaving
                                                                }
                                                                value={
                                                                    VoteValue.keep
                                                                }
                                                                readOnly={true}
                                                                checked={
                                                                    poster.vote ===
                                                                    false
                                                                }
                                                            />
                                                            {poster.id ===
                                                            props.currentMemberId ? (
                                                                <label
                                                                    htmlFor={`vote-keep-${poster.id}`}
                                                                    id="kenCole"
                                                                    className="label-input"></label>
                                                            ) : (
                                                                <label className="label-input"></label>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                []
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        []
                    )}
                    <div className="small-grey">
                        You must have posted in the discussion within the last
                        90 days to be eligible for voting.
                    </div>
                </>
            ) : (
                <>
                    <div className="click-wish-removal app-knowledgeLibrary">
                        <h5>
                            Click the button below if you wish to initiate a
                            vote on the permanent removal of this discussion.
                            <InfoTooltip
                                text={infoButtonText}
                                idText="vote-initial"></InfoTooltip>
                        </h5>
                    </div>
                    <div className="initiate-removal-btn-div">
                        <ButtonLoader
                            buttonText={'Initiate Removal Voting'}
                            loaderButtonText={'Initiate Removal Voting'}
                            onClick={props.startVoting}
                            disabled={
                                props.isUserAdmin || props.isVotingInitializing
                            }
                            isLoading={props.isVotingInitializing}
                            className={
                                props.isVotingInitializing
                                    ? 'btn inital-removal-voting-btn loader-submit'
                                    : 'btn inital-removal-voting-btn'
                            }></ButtonLoader>
                    </div>
                </>
            )}
        </div>
    );
};
export default VoteComponent;
