import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notification } from '../../models/notification';

export const addToMessageList = createAsyncThunk(
    'clubhouseMessages/add',
    (notification: Notification, dispatch: any) => {
        return {
            message: notification.message,
            discussionId: notification.chatId,
        };
    }
);
