import React, { useState } from 'react';
import {
    Button,
    FormGroup,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
} from 'reactstrap';
import './ChangeEmployeesNumberModal.scss';
import Rodal from '../../../../components/Rodal/Rodal';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import '@availity/yup';
import { StandardizationPeriodList } from '../../../../models/AccountingStandardization/StandardizationTypes';
import Standardization from '../../../../models/AccountingStandardization/Standardization';
import moment from 'moment';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../components/arrow-down/arrow-down';

interface ChangeEmployeesNumberModalProps {
    standardizationPurchase: Standardization;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    changeEmployeesNumber: (ftesNumber: number) => void;
    isSaving: boolean;
}
const ChangeEmployeesNumberModal = (props: ChangeEmployeesNumberModalProps) => {
    const [ftesNumber, setFtesNumber] = useState(
        props.standardizationPurchase.employeesNumber
    );
    const getFteDvmsNumberList = () => {
        const fteDvms = [];

        fteDvms.push({ id: 0, value: 'NA' });
        for (let i = 1; i < 50.5; i += 0.5) {
            fteDvms.push({ id: i, value: i.toString() });
        }

        return fteDvms;
    };

    return (
        <Rodal
            visible={props.isVisible}
            onClose={() => {
                props.setIsVisible(false);
            }}
            animation={'fade'}
            showMask={false}
            className="change-employees-number-modal-rodal">
            <div className="change-employees-number-modal">
                <ModalHeader>Change FTE DVMs</ModalHeader>
                <ModalBody>
                    <div className="div-item">
                        <div className="div-label">Practice Name: </div>
                        <div className="div-value">
                            {' '}
                            {props.standardizationPurchase.practiceName}
                        </div>
                    </div>
                    <div className="div-item">
                        <div className="div-label">
                            Standardization Period:{' '}
                        </div>
                        <div className="div-value">
                            {' '}
                            {
                                StandardizationPeriodList[
                                    props.standardizationPurchase.type
                                ]?.name
                            }
                        </div>
                    </div>
                    <div className="div-item">
                        <div className="div-label">Date Ordered: </div>
                        <div className="div-value">
                            {' '}
                            {moment(
                                props.standardizationPurchase.dateCreated
                            ).format('MM/DD/YYYY')}
                        </div>
                    </div>
                    <div className="div-field-item">
                        <FormGroup>
                            <Label className="div-field-label">FTE DVMs:</Label>{' '}
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                className="dropbox-field div-field-value"
                                data={getFteDvmsNumberList()}
                                value={ftesNumber}
                                onChange={(value: any) =>
                                    setFtesNumber(value.id)
                                }
                                dataKey="id"
                                textField="value"
                            />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            props.setIsVisible(false);
                        }}
                        className="btn close-button btn btn-secondary">
                        Cancel
                    </Button>
                    <ButtonLoader
                        buttonText={'Confirm and Save'}
                        disabled={ftesNumber == 0}
                        isLoading={props.isSaving}
                        onClick={() => {
                            props.changeEmployeesNumber(ftesNumber);
                        }}
                        className={'btn btn-primary loader-submit save-btn'}
                        loaderButtonText={undefined}></ButtonLoader>
                </ModalFooter>
            </div>
        </Rodal>
    );
};
export default ChangeEmployeesNumberModal;
