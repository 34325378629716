import React from 'react';
import './AvailableVvCredits.scss';
import Currency from '../../Currency/Currency';

interface AvailableVvCreditsProps {
    storeCredits?: number;
    flexCredits?: number;
    rewardCredits?: number;
}

const AvailableVvCredits = (props: AvailableVvCreditsProps) => {
    return (
        <div className="available-credits">
            <div className="bold">Available VV Credits</div>
            {props?.storeCredits !== undefined ? (
                <div>
                    Store Credits:{' '}
                    <Currency quantity={props.storeCredits} currency="USD" />
                </div>
            ) : (
                []
            )}
            {props?.flexCredits !== undefined ? (
                <div>
                    Flex Credits:{' '}
                    <Currency quantity={props.flexCredits} currency="USD" />
                </div>
            ) : (
                []
            )}
            {props?.rewardCredits !== undefined ? (
                <div>
                    Reward Credits:{' '}
                    <Currency quantity={props.rewardCredits} currency="USD" />
                </div>
            ) : (
                []
            )}
            <div className="bold">
                Total:{' '}
                <Currency
                    quantity={
                        (props?.storeCredits ?? 0) +
                        (props?.flexCredits ?? 0) +
                        (props?.rewardCredits ?? 0)
                    }
                    currency="USD"
                />
            </div>
        </div>
    );
};
export default AvailableVvCredits;
