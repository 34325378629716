import React from 'react';
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import Rodal from '../../components/Rodal/Rodal';
import './poc-about-modal.scss';
import PocGraphic from '../../assets/images/poc_graphic.png';

interface PocAboutModalProps {
    isVisible: boolean;
    closeModal: () => void;
    isCorporatePracticeOwner: boolean;
    corporationName: string;
}
const PocAboutModal = (props: PocAboutModalProps) => {
    return (
        <Rodal
            className="about-poc-modal"
            visible={props.isVisible}
            onClose={() => props.closeModal()}
            animation={'fade'}
            showMask={true}>
            <div className="pdetail-header">
                <ModalHeader>
                    {props.isCorporatePracticeOwner ? (
                        <>
                            About the {props.corporationName} Practice Leader
                            Community (PLC)
                        </>
                    ) : (
                        <>
                            About the Independent Practice Owner Community
                            (IPOC)
                        </>
                    )}
                </ModalHeader>
            </div>

            <ModalBody className="about-knowledge-body">
                {props.isCorporatePracticeOwner ? (
                    <>
                        <p>
                            Welcome to the {props.corporationName} Practice
                            Leader Community (the “PLC”), hosted by VetValue!
                        </p>
                        <p>
                            This community is available only to the local
                            leadership of practices owned or controlled by{' '}
                            {props.corporationName}.
                        </p>
                        <p>
                            Participants may create topically threaded
                            discussions. They may post to, and review, within
                            each of these threads, any relevant information,
                            observations, ideas, perspectives and questions.
                            Unlike many social media venues, no anonymous
                            posting is permitted on the PLC. We believe that
                            this serves to discourage frivolous and/or
                            disrespectful behavior.
                        </p>
                        <p>
                            Ultimately, the members create the “community.”
                            While a post may not be removed from a discussion,
                            PLC members may vote to remove any discussion in its
                            entirety. VetValue reserves the right to redact any
                            portion of a post deemed by VetValue to be
                            inappropriate or completely irrelevant to the
                            discussion. If such behavior persists, then VetValue
                            reserves the right to restrict the poster's PLC
                            access and/or posting privileges. Otherwise,
                            VetValue does not intend to engage in content
                            moderation.
                        </p>
                        <p>
                            VetValue staff may also post occasionally, and we
                            will need to live by the same set of “rules” as
                            everyone else when we do. We believe that all PLC
                            members will benefit most if all posts are
                            business-focused, topically relevant, respectful and
                            solution-oriented.
                        </p>
                        <p>
                            This is yours to build, learn from and contribute
                            to. We hope that you make the most of it.
                        </p>
                        <p>Enjoy!</p>
                    </>
                ) : (
                    <>
                        <p>
                            Welcome to the Independent Practice Owner Community
                            (the “IPOC”), hosted by VetValue!
                        </p>
                        <p>
                            This community is available only to VetValue members
                            that are a current and/or former owners of an
                            independent (not currently affiliated with a
                            corporate consolidator) veterinary practice, and to
                            any such owner's practice manager (or fellow owners)
                            that the owner decides to invite to participate,
                            subject to VetValue's independent verification.
                        </p>
                        <p>
                            Participants may create topically threaded
                            discussions. They may post to, and review, within
                            each of these threads, any relevant information,
                            observations, ideas, perspectives and questions.
                            Unlike many social media venues, no anonymous
                            posting is permitted on the IPOC. We believe that
                            this serves to discourage frivolous and/or
                            disrespectful behavior.
                        </p>
                        <p>
                            Ultimately, the members create the “community.” IPOC
                            members may vote to remove any thread, but no posts
                            may be removed (your posts survive you, even if you
                            later leave the IPOC), because they become an
                            integral part of the “historical dialogue” within
                            the thread. VetValue reserves the right to redact
                            any portion of a post deemed by VetValue to be
                            inappropriate or completely irrelevant to the
                            discussion. If such behavior persists, then VetValue
                            reserves the right to restrict the poster's IPOC
                            access and/or posting priviledges. Otherwise,
                            VetValue does not intend to engage in content
                            moderation.
                        </p>
                        <p>
                            VetValue staff may also post occasionally, and we
                            will need to live by the same set of “rules” as
                            everyone else when we do. We believe that all IPOC
                            members will benefit most if all posts are
                            business-focused, topically relevant, respectful and
                            solution-oriented.
                        </p>
                        <p>
                            This is yours to build, learn from and contribute
                            to. We hope that you make the most of it.
                        </p>
                        <p> Enjoy!</p>
                    </>
                )}
            </ModalBody>
            <div className="diagram-placeholder">
                <img
                    src={PocGraphic}
                    alt={'userImage'}
                    className="default-image"
                />
            </div>
            <Button className="cancel-btn" onClick={() => props.closeModal()}>
                Close
            </Button>
        </Rodal>
    );
};

export default PocAboutModal;
