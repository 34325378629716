export interface Donation {
    id: number;
    name: string;
    dateApproved: string;
    allDonorsLtd: number;
    myTotalLtd: number;
    amount: number;
    designationType: DesignationType;
    desgineeeName: string;
    anonymityStatus: AnonymityStatus;
    senderAffiliatedMemberWalletId?: number;
}

export enum DesignationType {
    InHonorOf = 1,
    InMemoryOf,
    AtTheBehestOf,
}

export const designationTypeList = [
    {
        id: 1,
        name: 'In Honor of',
    },
    {
        id: 2,
        name: 'In Memory of',
    },
    {
        id: 3,
        name: 'At the Behest of',
    },
];

export enum AnonymityStatus {
    NoAnonymity = 1,
    FullAnonymity,
    PartialAnonymity,
}

export const anonymityStatusList = [
    {
        id: 1,
        name: 'NO Anonymity',
    },
    {
        id: 2,
        name: 'FULL Anonymity',
        tooltip: "Donor Anonymous to Nonprofit & Donor & Designee Anonymous to VV Members"
    },
    {
        id: 3,
        name: 'PARTIAL Anonymity',
        tooltip: "Donor & Designee Anonymous to VV Members ONLY"
    },
];
