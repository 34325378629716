import React, { useState } from 'react';
import './DataAndDocumentRequestsDashboard.scss';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { HomeButton } from '../../../../components/HomeButton/home-button';
import createNotification from '../../../../utils/createNotification';
import { useStateSelector } from '../../../../store/selectors';
import UserDetailsModal from '../../Permissions/Users/Modal/UserDetailsModal';
import { CurrentProjectsTable } from './CurrentProjectsTable/CurrentProjectsTable';
import { ArchivedProjectsTable } from './ArchivedProjectsTable/ArchivedProjectsTable';

export const DataAndDocumentRequestsDashboard = () => {
    const axios = useStateSelector((state) => state.core.axios);

    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
        useState(false);
    const [userId, setUserId] = useState(0);
    const [updateStateTrigger, setUpdateStateTrigger] = useState(false);

    const handleArchiveStatusChange = (id: number) => {
        axios
            .put(`/api/DataRequestProjects/${id}/archiveStatus`)
            .then((response) => {
                if (response.status === 200) {
                    setUpdateStateTrigger((prevState) => !prevState);
                } else {
                    createNotification(
                        'An error occured while changing archive status',
                        'error'
                    );
                }
            });
    };

    return (
        <div className="data-and-document-requests-dashboard">
            <div className="title-content">
                <div className="page-title">
                    <h3>Data & Document Requests Dashboard</h3>
                </div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <HomeButton></HomeButton>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        Data & Document Requests Dashboard
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="main-card">
                <CardBody className="card-body-custom data-requests-tables-container">
                    <CurrentProjectsTable
                        updateStateTrigger={updateStateTrigger}
                        setIsUserDetailsModalVisible={
                            setIsUserDetailsModalVisible
                        }
                        setUserId={setUserId}
                        handleArchiveStatusChange={
                            handleArchiveStatusChange
                        }></CurrentProjectsTable>
                    <ArchivedProjectsTable
                        updateStateTrigger={updateStateTrigger}
                        setIsUserDetailsModalVisible={
                            setIsUserDetailsModalVisible
                        }
                        setUserId={setUserId}
                        handleArchiveStatusChange={
                            handleArchiveStatusChange
                        }></ArchivedProjectsTable>
                </CardBody>
            </Card>

            {isUserDetailsModalVisible && (
                <div className="user-details-popup">
                    <UserDetailsModal
                        onClose={() => {
                            setIsUserDetailsModalVisible(false);
                        }}
                        isVisisble={isUserDetailsModalVisible}
                        userID={userId}></UserDetailsModal>{' '}
                </div>
            )}
        </div>
    );
};
