import React from 'react';
import './GroupRebateInfo.scss';
import { Row } from 'reactstrap';
import InfoTooltip from '../../info-tooltip/info-tooltip';
import Currency from '../../Currency/Currency';

interface GroupRebateInfoProps {
    groupRebatePercent: number;
    price: number;
}

const GroupRebateInfo = (props: GroupRebateInfoProps) => {
    return (
        <Row className="rebates-row">
            <div className="rebates-section">
                <div className="rebates-item">
                    <div className="rebate-label">
                        <span>Projected Group Rebate %: </span>
                        <InfoTooltip
                            idText="group-rebate-percent-tooltip"
                            text="This is projected group rebate, based on the group order. Actual<br/>rebate percentage is determined by how many group members<br/>complete the purchase of their order."
                        />
                    </div>
                    <div className="rebate-value">
                        {props.groupRebatePercent}%
                    </div>
                </div>
                <div className="rebates-item">
                    <div className="rebate-label">
                        <span>Projected Group Rebate $: </span>
                        <InfoTooltip
                            idText="group-rebate-amount-tooltip"
                            text="Upon order payment completion by all<br/>group members, the actual rebate will be<br/>credited to your member wallet."
                        />
                    </div>
                    <div className="rebate-value">
                        <Currency
                            quantity={
                                (props.price * props.groupRebatePercent) / 100
                            }
                            currency="USD"
                        />
                    </div>
                </div>
            </div>
        </Row>
    );
};
export default GroupRebateInfo;
