import { createAsyncThunk } from '@reduxjs/toolkit';
import { PutPosterVote } from './clubhouse-slice-helper';

export const AddPosterVote = createAsyncThunk(
    'clubhouse/vote',
    async (args: PutPosterVote, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.put(
            `api/clubhouses/${args.clubhouseId}/discussions/${args.discussionId}/removalVoting`,
            {
                vote: args.vote,
            }
        );

        return response;
    }
);
