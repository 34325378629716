import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchClubhouseData = createAsyncThunk(
    'clubhouse/fetchData',
    async (clubhouseId: number, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/clubhouses/${clubhouseId}`);
        return response;
    }
);
