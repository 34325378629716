import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface LikeMessageThunkArg {
    connection: HubConnection;
    messageId: number;
    chatId: number;
}

export const likeDirectMessage = createAsyncThunk(
    'directMessages/like',
    async (arg: LikeMessageThunkArg) => {
        let like = {
            messageId: arg.messageId,
            chatId: arg.chatId,
        };
        await arg.connection.send('LikeMessage', like);
    }
);
