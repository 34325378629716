import React from 'react';

export interface SortingSymbolInterface {
    order: 'asc' | 'desc' | undefined;
}
const SortingSymbol = (props: SortingSymbolInterface) => {
    return (
        <span
            className={
                props.order === 'asc'
                    ? 'caret-4-asc'
                    : props.order === 'desc'
                    ? 'caret-4-desc'
                    : 'order-4'
            }></span>
    );
};
export default SortingSymbol;
