import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRooms = createAsyncThunk(
    'directRooms/fetchData',
    async (_args, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/direct/rooms`);
        return response;
    }
);
