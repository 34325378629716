import React, { useEffect, useState } from 'react';
import './TableSettingsPopup.scss';
import Rodal from '../../../../../components/Rodal/Rodal';
import { Button, Col, Input, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DropdownList, Multiselect } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { DataForm } from '../../../../../models/DataRequestHub/DataForm';
import { DataFormType } from '../../../../../models/DataRequestHub/DataFormTypeEnum';
import ReactSwitch from 'react-switch';
import Helpers from '../../../../../utils/helper';
import { DataTable } from '../../../../../models/DataRequestHub/DataTable';
import { TemplateDropdownOption } from '../../../../../models/DataRequestHub/TemplateDropdownOption';

export interface TableSettingsPopupProps {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit(): void;
    dataForms: DataForm[];
    dataTables: DataTable[];
    projectId: number;
}

interface QuestionnaireStageVisibilityParams {
    id: number;
    isVisible: boolean;
    formName: string;
}

export const TableSettingsPopup = (props: TableSettingsPopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);

    const [documentTemplateOptions, setDocumentTemplateOptions] = useState([]);
    const [
        financialRequestsTemplateOptions,
        setFinancialRequestsTemplateOptions,
    ] = useState([]);
    const [questionnaireTemplateOptions, setquestionnaireTemplateOptions] =
        useState([]);
    const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] =
        useState<number>(null);
    const [
        selectedFinancialRequestsTemplateId,
        setSelectedFinancialRequestsTemplateId,
    ] = useState<number>(null);
    const [
        selectedQuestionnaireTemplateId,
        setSelectedQuestionnaireTemplateId,
    ] = useState<number>(null);
    const [questionnaireFormName, setQuestionnaireFormName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDocumentDataFormVisible, setIsDocumentDataFormVisible] =
        useState(true);
    const [
        isFinancialRequestsDataFormVisible,
        setIsFinancialRequestsDataFormVisible,
    ] = useState(true);
    const [isQuestionnaireDataFormVisible, setIsQuestionnaireDataFormVisible] =
        useState(true);
    const [questionnaireStagesVisibility, setQuestionnaireStagesVisibility] =
        useState<QuestionnaireStageVisibilityParams[]>([]);
    const [isDataTableAvailable, setIsDataTableAvailable] = useState(false);
    const [isDataTablesVisible, setIsDataTablesVisible] = useState(false);
    const [selectedDataTableTemplateIds, setSelectedDataTableTemplateIds] =
        useState<number[]>(null);
    const [dataTableTemplateOptions, setDataTableTemplateOptions] = useState<
        TemplateDropdownOption[]
    >([]);
    const [isTableListOpened, setIsTableListOpened] = useState(false);

    useEffect(() => {
        getTemplateOptions();
    }, []);

    useEffect(() => {
        const isDocumentFormVisible = props.dataForms.some(
            (x) => x.formType === DataFormType.Documents && x.isVisible
        );
        setIsDocumentDataFormVisible(isDocumentFormVisible);

        const isFinancialRequestsFormVisible = props.dataForms.some(
            (x) => x.formType === DataFormType.FinancialRequest && x.isVisible
        );
        setIsFinancialRequestsDataFormVisible(isFinancialRequestsFormVisible);

        const isQuestionnaireDataFormVisible = props.dataForms.some(
            (x) => x.formType === DataFormType.Questionnaire && x.isVisible
        );

        setIsQuestionnaireDataFormVisible(isQuestionnaireDataFormVisible);

        const stagesVisibilityParams = props.dataForms
            .filter((form) => form.formType === DataFormType.Questionnaire)
            .map(
                (form): QuestionnaireStageVisibilityParams => ({
                    id: form.id,
                    isVisible: form.isVisible,
                    formName: form.originalTemplateName,
                })
            );

        setQuestionnaireStagesVisibility(stagesVisibilityParams);

        setIsDataTableAvailable(!isDataTableExist());
    }, [props]);

    const updateStageVisibility = (formId: number, isVisible: boolean) => {
        const targetStage = questionnaireStagesVisibility.find(
            (stage) => stage.id === formId
        );
        const stageIndex = questionnaireStagesVisibility.indexOf(targetStage);

        const updatedStagesVisibility = [...questionnaireStagesVisibility];
        updatedStagesVisibility[stageIndex] = {
            ...targetStage,
            isVisible: isVisible,
        };

        setQuestionnaireStagesVisibility(updatedStagesVisibility);
        setIsQuestionnaireDataFormVisible(
            isAnyQuestionnaireDataFormsVisible(updatedStagesVisibility)
        );
    };

    const isAnyQuestionnaireDataFormsVisible = (
        stagesVisibility: QuestionnaireStageVisibilityParams[]
    ): boolean => {
        return stagesVisibility.some((x) => x.isVisible);
    };

    const getFilteredTemplateOptions = (
        templateForms: DataForm[],
        targetFormType: DataFormType
    ) => {
        const result = templateForms
            .map((obj: DataForm) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: obj.formType,
            }))
            .filter((x) => x.formType === targetFormType)
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));

        return result;
    };

    const getMappedDataTableTemplateOptions = (
        templateTables: DataTable[]
    ): TemplateDropdownOption[] => {
        const result = templateTables
            .map((obj: DataTable) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: undefined,
            }))
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));

        return result;
    };

    const getTemplateOptions = () => {
        axios
            .get('/api/dataRequestTemplateProject/dataForms')
            .then((response) => {
                if (response.status === 200) {
                    const filteredDocumentTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.Documents
                        );

                    setDocumentTemplateOptions(filteredDocumentTemplateOptions);

                    const filteredFinancalRequestsTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.FinancialRequest
                        );

                    setFinancialRequestsTemplateOptions(
                        filteredFinancalRequestsTemplateOptions
                    );

                    const filteredQuestionnaireTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.Questionnaire
                        );

                    setquestionnaireTemplateOptions(
                        filteredQuestionnaireTemplateOptions
                    );
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .finally();
        axios
            .get('/api/dataRequestTemplateProject/dataTables')
            .then((response) => {
                if (response.status === 200) {
                    const filteredDataTableTemplateOptions =
                        getMappedDataTableTemplateOptions(response.data);

                    setDataTableTemplateOptions(
                        filteredDataTableTemplateOptions
                    );
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            });
    };

    const handleDocumentTemplateOptionChange = (templateId: number) => {
        setSelectedDocumentTemplateId(templateId);
    };

    const handleFinancialRequestsTemplateOptionChange = (
        templateId: number
    ) => {
        setSelectedFinancialRequestsTemplateId(templateId);
    };

    const handleQuestionnaireTemplateOptionChange = (templateId: number) => {
        setSelectedQuestionnaireTemplateId(templateId);
    };

    const getUpdatedDataFormState = (
        type: DataFormType,
        formName: string = ''
    ) => {
        let isDataFormVisible = false;
        let selectedTemplate = 0;

        switch (type) {
            case DataFormType.Documents:
                isDataFormVisible = isDocumentDataFormVisible;
                selectedTemplate = selectedDocumentTemplateId;
                break;
            case DataFormType.FinancialRequest:
                isDataFormVisible = isFinancialRequestsDataFormVisible;
                selectedTemplate = selectedFinancialRequestsTemplateId;
                break;
            case DataFormType.Questionnaire:
                isDataFormVisible = isQuestionnaireDataFormVisible;
                selectedTemplate = selectedQuestionnaireTemplateId;
                break;
        }

        const result = isDataFormExist(type)
            ? {
                  id: props.dataForms.find((x) => x.formType === type).id,
                  templateId: 0,
                  isVisible: isDataFormVisible,
                  formName: formName,
              }
            : isDataFormVisible
            ? {
                  id: 0,
                  templateId: selectedTemplate,
                  isVisible: isDataFormVisible,
                  formName: formName,
              }
            : null;
        return result;
    };

    const updateTableSettings = () => {
        setIsLoading(true);

        const documentForm = getUpdatedDataFormState(DataFormType.Documents);
        const financialRequestsForm = getUpdatedDataFormState(
            DataFormType.FinancialRequest
        );
        const questionnaireForm = getUpdatedDataFormState(
            DataFormType.Questionnaire,
            questionnaireFormName
        );

        let params = [];

        if (documentForm && (documentForm.id || documentForm.templateId)) {
            params.push(documentForm);
        }

        if (
            financialRequestsForm &&
            (financialRequestsForm.id || financialRequestsForm.templateId)
        ) {
            params.push(financialRequestsForm);
        }

        if (questionnaireStagesVisibility.length) {
            params.push(...questionnaireStagesVisibility);
        } else if (
            questionnaireForm &&
            (questionnaireForm.id || questionnaireForm.templateId)
        ) {
            params.push(questionnaireForm);
        }

        const shouldDataTablesBeCreated =
            isDataTablesVisible && selectedDataTableTemplateIds?.length > 0;

        const shouldDataFormsBeUpdated = params.length > 0;

        if (shouldDataTablesBeCreated) {
            createDataTable(
                shouldDataFormsBeUpdated,
                params,
                shouldDataTablesBeCreated
            );
        } else if (shouldDataFormsBeUpdated) {
            updateDataFormVisibleStatuses(params, shouldDataTablesBeCreated);
        }
    };

    const updateDataFormVisibleStatuses = (
        params: any[],
        shouldDataTablesBeCreated: boolean
    ) => {
        axios
            .put(
                `/api/DataRequestProjects/${props.projectId}/dataFormVisibleStatuses`,
                params
            )
            .then((response: any) => {
                if (response.status !== 200) {
                    createNotification(response.response.data.detail, 'error');
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while project creation',
                    'error'
                );
            })
            .finally(() => {
                props.setIsVisible(false);
                props.onSubmit();
                setIsLoading(false);
            });
    };

    const createDataTable = (
        shouldDataFormsBeUpdated: boolean,
        params: any[],
        shouldDataTablesBeCreated: boolean
    ) => {
        const requestParams = {
            dataTableTemplateIds: selectedDataTableTemplateIds,
        };
        axios
            .post(
                `api/DataRequestProjects/${props.projectId}/dataTables`,
                requestParams
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while creating new table',
                        'error'
                    );
                } else if (shouldDataFormsBeUpdated) {
                    updateDataFormVisibleStatuses(
                        params,
                        shouldDataTablesBeCreated
                    );
                }
            })
            .finally(() => {
                if (!shouldDataFormsBeUpdated) {
                    props.setIsVisible(false);
                    props.onSubmit();
                    setIsLoading(false);
                }
            });
    };

    const isDataFormExist = (type: DataFormType) => {
        return props.dataForms.some((x) => x.formType === type);
    };

    const isDataTableExist = () => {
        return props.dataTables.length > 0;
    };

    const isSubmitButtonDisabled = () => {
        const isDocumentTableSettingsValid =
            isDataFormExist(DataFormType.Documents) ||
            (isDocumentDataFormVisible
                ? selectedDocumentTemplateId != null
                : true);

        const isFinancialRequestsTableSettingsValid =
            isDataFormExist(DataFormType.FinancialRequest) ||
            (isFinancialRequestsDataFormVisible
                ? selectedFinancialRequestsTemplateId != null
                : true);

        const isQuestionnaireTableSettingsValid =
            isDataFormExist(DataFormType.Questionnaire) ||
            (isQuestionnaireDataFormVisible
                ? selectedQuestionnaireTemplateId != null
                : true);

        const isDataTableSettingsValid =
            isDataTableExist() ||
            (isDataTablesVisible
                ? selectedDataTableTemplateIds?.length > 0
                : true);

        return (
            !isDocumentTableSettingsValid ||
            !isFinancialRequestsTableSettingsValid ||
            !isQuestionnaireTableSettingsValid ||
            !isDataTableSettingsValid ||
            !isAnyFormSelected()
        );
    };

    const isAnyFormSelected = (): boolean =>
        isDocumentDataFormVisible ||
        isFinancialRequestsDataFormVisible ||
        isQuestionnaireDataFormVisible ||
        isDataTablesVisible ||
        !isDataTableAvailable;

    const renderAvailableStagesList = () => {
        return questionnaireStagesVisibility.map((stage) => (
            <div className="field-switcher">
                <ReactSwitch
                    offColor="#d92550"
                    checked={stage.isVisible}
                    onChange={(event) => {
                        updateStageVisibility(stage.id, event);
                    }}
                    height={22}></ReactSwitch>
                <div className="field-label">{stage.formName}</div>
            </div>
        ));
    };

    const handleDataTableTemplateOptionsChange = (templateIds: number[]) => {
        setSelectedDataTableTemplateIds(templateIds);
    };

    const getMultiselectOptions = () => {
        return dataTableTemplateOptions.map((x) => {
            return { id: x.id, option: x.name };
        });
    };

    const renderDataTableField = () => {
        return (
            <div className="multiselect">
                <Row>
                    <Col md={6}>
                        <div className="field-switcher">
                            <ReactSwitch
                                offColor="#d92550"
                                checked={isDataTablesVisible}
                                onChange={(event) => {
                                    setIsDataTablesVisible(event);
                                }}
                                height={22}></ReactSwitch>
                            <div className="field-label">Data Tables</div>
                        </div>
                    </Col>
                    <Col md={6} className="data-table-section-input">
                        <Multiselect
                            open={isTableListOpened}
                            onFocus={() => {
                                setIsTableListOpened(true);
                            }}
                            onBlur={() => {
                                setIsTableListOpened(false);
                            }}
                            filter="contains"
                            disabled={!isDataTablesVisible}
                            selectIcon={<ArrowDown />}
                            data={getMultiselectOptions()}
                            dataKey="option"
                            textField="option"
                            groupBy="type"
                            onChange={(value) => {
                                const ids = value.map((x) => {
                                    return x.id;
                                });
                                handleDataTableTemplateOptionsChange(ids);
                                if (
                                    ids.length ===
                                    getMultiselectOptions().length
                                ) {
                                    setIsTableListOpened(false);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div className="table-settings">
            <Rodal
                visible={props.isVisible}
                onClose={() => {
                    props.setIsVisible(false);
                }}
                width={500}
                className="checkout-modal"
                animation={'fade'}
                showMask={false}
                centered>
                <ModalHeader>Settings</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col
                            md={
                                isDataFormExist(DataFormType.Documents) ? 12 : 6
                            }>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isDocumentDataFormVisible}
                                    onChange={(event) => {
                                        setIsDocumentDataFormVisible(event);
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    Document Template
                                </div>
                            </div>
                        </Col>
                        {!isDataFormExist(DataFormType.Documents) && (
                            <Col md={6}>
                                <DropdownList
                                    selectIcon={<ArrowDown />}
                                    disabled={!isDocumentDataFormVisible}
                                    data={documentTemplateOptions}
                                    defaultValue={documentTemplateOptions[0]}
                                    onChange={(value) => {
                                        handleDocumentTemplateOptionChange(
                                            value.id
                                        );
                                    }}
                                    dataKey="id"
                                    filter="contains"
                                    textField="name"
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col
                            md={
                                isDataFormExist(DataFormType.FinancialRequest)
                                    ? 12
                                    : 6
                            }>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isFinancialRequestsDataFormVisible}
                                    onChange={(event) => {
                                        setIsFinancialRequestsDataFormVisible(
                                            event
                                        );
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    Financial Requests Template
                                </div>
                            </div>
                        </Col>
                        {!isDataFormExist(DataFormType.FinancialRequest) && (
                            <Col md={6}>
                                <DropdownList
                                    selectIcon={<ArrowDown />}
                                    disabled={
                                        !isFinancialRequestsDataFormVisible
                                    }
                                    data={financialRequestsTemplateOptions}
                                    defaultValue={
                                        financialRequestsTemplateOptions[0]
                                    }
                                    onChange={(value) => {
                                        handleFinancialRequestsTemplateOptionChange(
                                            value.id
                                        );
                                    }}
                                    dataKey="id"
                                    filter="contains"
                                    textField="name"
                                />
                            </Col>
                        )}
                    </Row>
                    {isDataTableAvailable ? (
                        <Row>
                            <Col>{renderDataTableField()}</Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                    <Col>
                        <Row className="section-header">
                            Questionnaire Stages
                        </Row>
                        {!isDataFormExist(DataFormType.Questionnaire) ? (
                            <Row className="add-first-stage-container">
                                <Col md={4}>
                                    <Row>
                                        <ReactSwitch
                                            offColor="#d92550"
                                            checked={
                                                isQuestionnaireDataFormVisible
                                            }
                                            onChange={(event) => {
                                                setIsQuestionnaireDataFormVisible(
                                                    event
                                                );
                                            }}
                                            height={22}></ReactSwitch>
                                    </Row>
                                </Col>
                                <Col
                                    md={8}
                                    className="questionnaire-section-input">
                                    <DropdownList
                                        selectIcon={<ArrowDown />}
                                        disabled={
                                            !isQuestionnaireDataFormVisible
                                        }
                                        data={questionnaireTemplateOptions}
                                        defaultValue={
                                            questionnaireTemplateOptions[0]
                                        }
                                        onChange={(value) => {
                                            handleQuestionnaireTemplateOptionChange(
                                                value.id
                                            );
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                    <Input
                                        disabled={
                                            !isQuestionnaireDataFormVisible
                                        }
                                        placeholder="Optional..."
                                        onChange={(event) => {
                                            setQuestionnaireFormName(
                                                event.target.value.trim()
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Row className="stages-settings-list">
                                {renderAvailableStagesList()}
                            </Row>
                        )}
                    </Col>

                    <Row className="mb-0">
                        <Col className="button-container">
                            <Button
                                type="button"
                                className="btn btn-cancel"
                                disabled={isLoading}
                                onClick={() => {
                                    props.setIsVisible(false);
                                }}>
                                Cancel & Close
                            </Button>
                            <ButtonLoader
                                buttonText={`Save & Close`}
                                loaderButtonText={''}
                                disabled={isSubmitButtonDisabled()}
                                isLoading={isLoading}
                                onClick={() => {
                                    updateTableSettings();
                                }}
                                className={
                                    'btn btn-primary loader-submit'
                                }></ButtonLoader>
                        </Col>
                    </Row>
                </ModalBody>
            </Rodal>
        </div>
    );
};
