import React, { useState } from 'react';
import ButtonLoader from '../Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../store/selectors';
import createNotification from '../../utils/createNotification';

interface ManualUploadsButtonProps {
    targetUserId: number;
    onSuccessfullUpload(): void;
}

const ManualUploadsButton = (props: ManualUploadsButtonProps) => {
    const axios = useStateSelector((s) => s.core.axios);

    const [isLoading, setIsLoading] = useState(false);

    const uploadFiles = async (files: File[]) => {
        const formFiles = files.map((f) => {
            const formData = new FormData();
            formData.append('File', f);
            return formData;
        });

        const requests = formFiles.map((f) =>
            axios.post(`/api/temporaryfiles`, f, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        );

        const responses = await Promise.all(requests);

        if (responses.some((r) => r.status != 200)) {
            createNotification(
                'Some files are not uploaded due to an unexpected error.',
                'error'
            );
            setIsLoading(false);
            throw new Error('An error occurent while file uploading');
        }

        return responses.map((m) => {
            return m.data;
        });
    };

    const saveUploads = async (fileList: FileList) => {
        setIsLoading(true);

        const selectedFiles = Array.from(fileList).map((m) => m);
        const uploadedFiles = await uploadFiles(selectedFiles);
        const fileIds = uploadedFiles.map((m) => m.id);

        axios
            .post('/api/adminManualUploadedDocuments', {
                fileIds: fileIds,
                targetUserId: props.targetUserId,
            })
            .then((response) => {
                if (response.status === 200) {
                    createNotification(
                        `Document ${
                            uploadedFiles.find((f) => f).name
                        } is successfully uploaded`,
                        'success'
                    );

                    props.onSuccessfullUpload();
                } else {
                    createNotification(
                        `An error occured on document uploading`,
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const addDocument = () =>
        document.getElementById('manual-uploads-input').click();

    return (
        <div className="">
            <ButtonLoader
                buttonText={'Manual Document Upload'}
                loaderButtonText={''}
                disabled={isLoading}
                isLoading={isLoading}
                onClick={addDocument}
                className={'btn btn-primary'}
            />
            <input
                hidden
                type="file"
                id="manual-uploads-input"
                onChange={(event) => {
                    saveUploads(event.target.files);
                    event.target.value = null;
                }}></input>
        </div>
    );
};

export default ManualUploadsButton;
