import React from 'react';
import vetValueBigLogo from '../../assets/demo-ui/images/VetValueBig.svg';

const ComingSoon = props => {
    return (
        <div className="onboard-wrapper">
            <span className="onboarding-text" style={{ marginTop: '20%' }}>
                Coming soon!
            </span>
            <img
                className="user-onboarding-img"
                src={vetValueBigLogo}
                alt="Coming soon!"></img>
        </div>
    );
};

export default ComingSoon;
