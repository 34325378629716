import { createAsyncThunk } from "@reduxjs/toolkit";

export enum ComparationType
{
    More = 0,
    Less = 1,
    Equal = 2
}

export interface LoadDiscussionMessagesByLikesArg {
    discussionId: number;
    clubhouseId: number;
    isAscending: boolean;
    pageSize?: number;
    numberOfLikes: number;
    comparationType: ComparationType;
}

export const loadDiscussionMessagesByLikes = createAsyncThunk(
    'clubhouseMessages/loadDiscussionMessagesByLike',
    async (arg: LoadDiscussionMessagesByLikesArg, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const clubhouse = dispatch.getState().clubhouse.clubhouse;
        const messages = dispatch.getState().clubhouseMessages.discussions;

        const discussionMessages =
            messages[clubhouse.selectedDiscussionId]?.messages;
        const message = arg.isAscending
            ? discussionMessages?.at(-1)
            : discussionMessages?.at(0);

        const response = await axios.get(
            `api/clubhouses/${arg.clubhouseId}/discussions/${arg.discussionId}/messages/byLikes`,
            {
                params: {
                    messageId: message?.id,
                    isAscending: arg.isAscending,
                    pageSize: arg.pageSize,
                    numberOfLikes: arg.numberOfLikes,
                    comparationType: arg.comparationType
                },
            }
        );

        return {
            data: response.data.messages,
            discussionId: arg.discussionId,
            isAscending: arg.isAscending,
            lastMessageId: response.data.lastMessageId,
        };
    }
);