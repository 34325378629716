import React from 'react';
import './UploadedFileTile.scss';
import Helpers from '../../utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface UploadedFileTileProps {
    file: File;
    onDeleteClick(file: File): void;
}

const UploadedFileTile = (props: UploadedFileTileProps) => {
    return (
        <div className="uploaded-file-tile">
            <span className="file-info">
                <span className="file-name">{props.file.name}</span>
                <span className="file-size">
                    {Helpers.formatBytes(props.file.size)}
                </span>
            </span>

            <span
                className="delete-button"
                onClick={() => props.onDeleteClick(props.file)}>
                <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
            </span>
        </div>
    );
};

export default UploadedFileTile;
