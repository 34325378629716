import { HubConnection } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface SendMessageThunkArg {
    connection: HubConnection;
    messageText: string;
    roomId: number;
    attachmentsIds: Array<number>;
}

export const sendDirectRoomMessage = createAsyncThunk(
    'directMessages/send',
    async (arg: SendMessageThunkArg) => {
        let chatMessage = {
            roomId: arg.roomId,
            htmlContent: arg.messageText,
            attachmentsIds: arg.attachmentsIds,
        };
        await arg.connection.send('SendMessage', chatMessage);
    }
);
