import { DiscountCodeType } from './DiscountCodeType';

export interface DiscountCode {
    code: string;
    ownerWalletId: number;
    ownerWalletType: WalletOwnerType;
    isPrimary: boolean;
    codeType: DiscountCodeType;
    createdDate: Date;
    label: string;
    canBeDeleted: boolean,
    affiliatedMemberName: string
}

export interface DiscountCodeOwner {
    code: string;
    ownerWalletId: number;
    ownerWalletType: WalletOwnerType;
    ownerName: string;
}

export enum WalletOwnerType {
    User = 1,
    Charity = 2,
}
