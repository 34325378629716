export class DateTimeHelper {
    static differenceInMinutes = (date1: Date, date2: Date): number => {
        date1 = new Date(date1);
        date2 = new Date(date2);
        const diffInSeconds = Math.floor(
            Math.abs(date2.getTime() / 1000 - date1.getTime() / 1000)
        );
        const minutes = Math.ceil(diffInSeconds / 60);
        return minutes;
    };

    static areDatesEqualIgnoringTime = (value1: string, value2: string): boolean => {
        const date1 = new Date(value1);
        const date2 = new Date(value2);
    
        if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
            return false;
        }
        
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };
}
