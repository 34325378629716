import React, { useState } from 'react';
import {
    Button,
    Col,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import Rodal from '../../../components/Rodal/Rodal';
import { Field, Form } from '@availity/form';
import './NotePopup.scss';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';

interface GroupNotePopupProps {
    headerPrefix?: string;
    note: string;
    resetModal(): void;
    save(note: string): void;
}
const NotePopup = (props: GroupNotePopupProps) => {
    const [note, setNote] = useState(props.note);
    let modalForm: any;
    const [isSaving, setIsSaving] = useState(false);

    const initSubmit = () => {
        modalForm.handleSubmit();
    };

    const saveNotes = async () => {
        setIsSaving(true);
        await props.save(note);
        setIsSaving(false);
    };
    return (
        <div className="note-popup">
            <Rodal
                visible={true}
                onClose={() => props.resetModal()}
                animation={'fade'}
                showMask={false}
                className="group-note-rodal">
                <div className="group-note">
                    <ModalHeader>
                        {props.headerPrefix
                            ? `${props.headerPrefix}: Admin Notes`
                            : `Admin Notes`}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            innerRef={(c) => (modalForm = c)}
                            onSubmit={() => saveNotes()}
                            initialValues={{
                                note: props.note,
                            }}>
                            <Row>
                                <Col>
                                    <Field
                                        className="note-textarea"
                                        type="textarea"
                                        label=""
                                        name="note"
                                        onChange={(event) =>
                                            setNote(event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => props.resetModal()}
                            className="discard-button">
                            Discard Changes
                        </Button>
                        <ButtonLoader
                            buttonText={'Save'}
                            disabled={false}
                            isLoading={isSaving}
                            onClick={initSubmit}
                            className={'btn btn-primary loader-submit save-btn'}
                            loaderButtonText={undefined}></ButtonLoader>
                    </ModalFooter>
                </div>
            </Rodal>
        </div>
    );
};
export default NotePopup;
