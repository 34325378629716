import React from 'react';
import { Tooltip } from 'react-tooltip';
import sponsor from '../../assets/images/sponsor.svg';
import './SponsorIcon.scss';

export interface SponsorIconProps {
    id: string;
}
export const SponsorIcon = (props: SponsorIconProps) => {
    return (
        <>
            <img
                src={sponsor}
                className="sponsor"
                id={props.id}
                data-tooltip-content="Your Sponsor"></img>
            <Tooltip
                place="top"
                className={'tooltip-content'}
                anchorId={props.id}
                classNameArrow="sponsor-tooltip-arrow"
                offset={15}
            />
        </>
    );
};
