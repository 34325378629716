import React from 'react';
import PropTypes from 'prop-types';

const PaginationTotal = (props) => {
    const {
        from = 0,
        to = 0,
        dataSize = 0,
        paginationTotalRenderer = null,
    } = props;

    if (paginationTotalRenderer) {
        return paginationTotalRenderer(from, to, dataSize);
    }

    return (
        <span className="react-bootstrap-table-pagination-total">
            &nbsp;Showing rows {from} to&nbsp;{to} of&nbsp;{dataSize}
        </span>
    );
};

PaginationTotal.propTypes = {
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    dataSize: PropTypes.number.isRequired,
    paginationTotalRenderer: PropTypes.func,
};

export default PaginationTotal;
