import { createAsyncThunk } from '@reduxjs/toolkit';

export const LoadPricing = createAsyncThunk(
    'pricing/loadPricing',
    async (_, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/Pricing`);
        return response;
    }
);
