import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { USER_ROLES } from '../../../utils/constants';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../../utils/associateMembersConstants';
import './KnowledgeBaseContentLibrary.scss';
import Helpers from '../../../utils/helper';
import createNotification from '../../../utils/createNotification';
import searchLogo from '../../../assets/images/searchLogo.png';
import verticalLine from '../../../assets/images/verticalLine.png';
import KnowledgeBaseContentTile from './KnowledgeBaseContentTile';
import {
    CONTENT_LEVEL,
    KNOWLEDGE_BASE_SORT_OPTIONS,
    KNOWLEDGE_BASE_TYPES,
} from '../../../utils/knowledgeBaseConstants';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import { Form, Input } from '@availity/form';
import LibraryItemsWrapperComponent from '../LibraryItemsWrapperComponent/LibraryItemsWrapperComponent';
import { useStateSelector } from '../../../store/selectors';

const KnowledgebaseContentSharePocLibrary = (props) => {
    useEffect(() => {
        setIsLoading(true);
        setInitialData();
    }, []);

    const axios = useStateSelector((state) => state.core.axios);
    const [fullContentsList, setFullContentsList] = useState([]);
    const [displayedContentsList, setDisplayedContentsList] = useState([]);
    const [shelfList, setShelfList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [topics, setTopics] = useState();
    const [isMyShelfShared, setIsMyShelfShared] = useState(false);

    const defaultStateSettings = {
        filter: {
            text: '',
            topic: '',
            type: '',
        },
        sort: {
            date: 'desc',
            popularity: '',
        },
    };
    const [stateSettings, setStateSettings] = useState({
        ...defaultStateSettings,
    });

    useEffect(() => {
        setSelectedContent();
    }, [fullContentsList]);

    const setInitialData = () => {
        if (isKbAvailableForUser()) {
            const libraryRequestResult = axios
                .get('/api/KnowledgeBase')
                .then((response) => {
                    if (
                        Helpers.isResponseSuccessful(
                            response,
                            'An error occured while fetching the Knowledge Library.'
                        )
                    ) {
                        let list = response.data.data.contentsList;
                        list = list.map((item) => {
                            item.isSelected = fullContentsList?.find(
                                (f) => f.id === item.id
                            )?.isSelected;
                            return item;
                        });
                        setFullContentsList(list);
                        runFiltering(stateSettings, list);
                        mapTopics(list);
                        let responseShelfList = list.filter(
                            (item) => item.isAddedToShelf
                        );

                        responseShelfList.sort(
                            (a, b) =>
                                new Date(b.addedToShelfOn) -
                                new Date(a.addedToShelfOn)
                        );
                        setIsMyShelfShared(response.data.data.isShelfShared);
                    }
                })
                .catch((error) => {
                    createNotification(error.message, 'error');
                });

            const shelfResultRequest = axios
                .get(
                    `/api/KnowledgeBase/myShelf/contentLevel/${CONTENT_LEVEL[1].id}`
                )
                .then((response) => {
                    if (
                        Helpers.isResponseSuccessful(
                            response,
                            'An error occured while fetching My Shelf.'
                        )
                    ) {
                        let responseData = response.data.data;
                        responseData = responseData.map((item) => {
                            item.isSelected = fullContentsList.find(
                                (f) => f.id === item.id
                            )?.isSelected;

                            return item;
                        });
                        setShelfList(response.data.data);
                    }
                })
                .catch((error) => {
                    createNotification(error.message, 'error');
                });

            Promise.allSettled([libraryRequestResult, shelfResultRequest]).then(
                () => setIsLoading(false)
            );
        }
    };

    const updateShelfSharedStatus = (state) => {
        axios
            .post('api/knowledgeBase/update-shelf-share-status', null, {
                params: { isMyShelfShared: state },
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    };

    const mapTopics = (contentsList) => {
        const onlyUnique = (value, index, self) =>
            self.findIndex((v) => v.topicId == value.topicId) == index;
        let topicsArrays = contentsList.map((p) => p.topics);
        let topics = [].concat.apply([], topicsArrays);
        topics = topics
            .filter(onlyUnique)
            .sort((a, b) => (a.tag > b.tag ? 1 : -1));
        setTopics(topics);
    };

    const selectItem = (contentId) => {
        const library = updateStatusContentList(
            fullContentsList,
            contentId,
            true
        );
        setFullContentsList(library);

        const displayedLibrary = updateStatusContentList(
            displayedContentsList,
            contentId,
            true
        );
        setDisplayedContentsList(displayedLibrary);

        const shelf = updateStatusContentList(shelfList, contentId, true);
        setShelfList(shelf);
    };

    const deselectItem = (contentId) => {
        const library = updateStatusContentList(
            fullContentsList,
            contentId,
            false
        );
        setFullContentsList(library);

        const displayedLibrary = updateStatusContentList(
            displayedContentsList,
            contentId,
            false
        );
        setDisplayedContentsList(displayedLibrary);

        const shelf = updateStatusContentList(shelfList, contentId, false);
        setShelfList(shelf);
    };

    const setSelectedContent = () => {
        const selectedContent = fullContentsList
            ?.filter((f) => f.isSelected)
            ?.map((item) => {
                return { id: item?.id, title: item?.title };
            });
        props.onSelect(selectedContent);
    };

    const updateStatusContentList = (list, contentId, selectedStatus) => {
        const itemIndex = list.findIndex((f) => f.id === contentId);
        const updatedList = [...list];
        const item = { ...updatedList[itemIndex] };
        item.isSelected = selectedStatus;
        updatedList[itemIndex] = item;
        return updatedList;
    };

    const isKbAvailableForUser = () => {
        let loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        if (loggedInUserSummary) {
            const summary = JSON.parse(loggedInUserSummary);
            const isOwner = summary.roleId === USER_ROLES.PRACTICE_OWNER;
            const isAssociate =
                summary.roleId === USER_ROLES.PRACTICE_OWNER ||
                summary.hasReferralRequest ||
                summary.roleId === USER_ROLES.USER ||
                (summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
                    summary.associateMember &&
                    summary.associateMember.some((x) =>
                        x.practices.some((p) =>
                            p.acceptedAccessRights.includes(
                                ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                            )
                        )
                    ));
            if (
                (isOwner &&
                    summary.practices?.filter((x) => x.status == 1).length >
                        0) ||
                isAssociate
            ) {
                setIsError(false);
                return true;
            }
        }

        return false;
    };

    const runFiltering = (settings, list = null) => {
        let filteredList = list ? list : fullContentsList;
        if (settings.filter.topic) {
            filteredList = filteredList.filter((c) =>
                c.topics.some((t) => t.topicId == settings.filter.topic)
            );
        }
        if (settings.filter.type) {
            filteredList = filteredList.filter(
                (t) => t.type == settings.filter.type
            );
        }

        const sortingFunc = getSortingFunction(
            settings.sort.date,
            settings.sort.popularity
        );
        filteredList = filteredList.sort(sortingFunc);
        if (settings.filter.text) {
            const filteredByTitle = filteredList.filter(
                (c) =>
                    c.title
                        .toUpperCase()
                        .indexOf(settings.filter.text.toUpperCase()) !== -1
            );
            const filteredByIntroText = filteredList.filter(
                (c) =>
                    c.introText
                        .toUpperCase()
                        .indexOf(settings.filter.text.toUpperCase()) !== -1
            );
            filteredList = filteredByTitle.concat(filteredByIntroText);
            filteredList = filteredList.filter((item, index) => {
                return filteredList.findIndex((t) => t.id == item.id) == index;
            });
        }
        setDisplayedContentsList(filteredList);
    };

    const getSortingFunction = (date, popularity) => {
        return (a, b) => {
            const sortByDate = (date) =>
                date == 'asc'
                    ? compare(a.postedOn, b.postedOn)
                    : compare(b.postedOn, a.postedOn);
            const sortByPopularity = (popularity) =>
                popularity == 'asc'
                    ? compare(a.popularity, b.popularity)
                    : compare(b.popularity, a.popularity);

            return date ? sortByDate(date) : sortByPopularity(popularity);
        };
    };

    const compare = (a, b) => {
        if (a > b) return +1;
        if (a < b) return -1;
        return 0;
    };

    const filterByDate = (order) => {
        stateSettings.sort.date = order;
        stateSettings.sort.popularity = '';
        setStateSettings({
            ...stateSettings,
        });
        runFiltering(stateSettings);
    };

    const filterByPopularity = (order) => {
        stateSettings.sort.popularity = order;
        stateSettings.sort.date = '';
        setStateSettings({
            ...stateSettings,
        });
        runFiltering(stateSettings);
    };
    return (
        <>
            {isError ? (
                <h2 className="error-message">
                    You do not have access to view this page
                </h2>
            ) : (
                <div className="associate-members-table kb-content-library">
                    <div className="responsiveClass">
                        <div className="body_white_box knowledgeLibraryMainSSection">
                            <div className="overFlowClass">
                                <div className="browseHistorysearchWrapper">
                                    <div className="browseTitle">
                                        <label>Browse The Library</label>
                                    </div>
                                    <div className="sortByDate">
                                        <label>Sort by Date</label>
                                        <div className="customerStyle">
                                            <select
                                                className="selectCustomer"
                                                value={stateSettings.sort.date}
                                                onChange={(e) => {
                                                    filterByDate(
                                                        e.target.value
                                                    );
                                                }}>
                                                {KNOWLEDGE_BASE_SORT_OPTIONS.map(
                                                    (opt) => (
                                                        <option value={opt.id}>
                                                            {opt.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="borderWrapper">
                                        <div className="borderOne">
                                            <img
                                                className="lineOne"
                                                src={verticalLine}
                                            />
                                        </div>
                                    </div>
                                    <div className="sortByDate popularity">
                                        <label>Sort by Popularity</label>
                                        <div className="customerStyle">
                                            <select
                                                className="selectCustomer"
                                                value={
                                                    stateSettings.sort
                                                        .popularity
                                                }
                                                onChange={(e) => {
                                                    filterByPopularity(
                                                        e.target.value
                                                    );
                                                }}>
                                                {KNOWLEDGE_BASE_SORT_OPTIONS.map(
                                                    (opt) => (
                                                        <option value={opt.id}>
                                                            {opt.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <InfoTooltip
                                        text="Popularity is a measure of the number of<br/> times a content piece is viewed vs saved<br/> to a personal shelf"
                                        idText="kb-popularity"
                                        place="bottom"
                                    />
                                    <div className="borderWrapper">
                                        <div className="borderOne">
                                            <img
                                                className="lineOne"
                                                src={verticalLine}
                                            />
                                        </div>
                                    </div>
                                    <div className="sortByDate browseBy">
                                        <label>Browse By</label>
                                        <div className="customerStyle allTopics">
                                            <select
                                                className="selectCustomer"
                                                value={
                                                    stateSettings.filter.topic
                                                }
                                                onChange={(e) => {
                                                    stateSettings.filter.topic =
                                                        e.target.value;
                                                    setStateSettings({
                                                        ...stateSettings,
                                                    });
                                                    runFiltering(stateSettings);
                                                }}>
                                                <option value={''}>
                                                    All Topics
                                                </option>
                                                {topics
                                                    ? topics.map((t) => (
                                                          <option
                                                              value={t.topicId}>
                                                              {t.tag}
                                                          </option>
                                                      ))
                                                    : []}
                                            </select>
                                        </div>

                                        <div className="customerStyle alltypes">
                                            <select
                                                className="selectCustomer"
                                                value={
                                                    stateSettings.filter.type
                                                }
                                                onChange={(e) => {
                                                    stateSettings.filter.type =
                                                        e.target.value;
                                                    setStateSettings({
                                                        ...stateSettings,
                                                    });
                                                    runFiltering(stateSettings);
                                                }}>
                                                <option value={''}>
                                                    All Types
                                                </option>
                                                {KNOWLEDGE_BASE_TYPES.map(
                                                    (t) => (
                                                        <option value={t.id}>
                                                            {t.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="borderWrapper">
                                        <div className="borderOne">
                                            <img
                                                className="lineOne"
                                                src={verticalLine}
                                            />
                                        </div>
                                    </div>
                                    <div className="searchBox">
                                        <img src={searchLogo} />
                                        <input
                                            type="text"
                                            className="searchIcon"
                                            placeholder="Search"
                                            value={stateSettings.filter.text}
                                            onChange={(e) => {
                                                stateSettings.filter.text =
                                                    e.target.value;
                                                setStateSettings({
                                                    ...stateSettings,
                                                });
                                                runFiltering(stateSettings);
                                            }}
                                        />
                                    </div>
                                    <div className="borderWrapper">
                                        <div className="borderOne searchBoxLine">
                                            <img
                                                className="lineOne"
                                                src={verticalLine}
                                            />
                                            <img
                                                className="lineTwo"
                                                src={verticalLine}
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        className="clearResultBtn"
                                        onClick={() => {
                                            setStateSettings({
                                                ...defaultStateSettings,
                                            });
                                            runFiltering(defaultStateSettings);
                                        }}>
                                        Clear Results
                                    </Button>
                                </div>
                            </div>
                            {isLoading ? (
                                Helpers.renderTableLoader()
                            ) : (
                                <div className="browse_the_library_main">
                                    {displayedContentsList &&
                                    displayedContentsList.length > 0 ? (
                                        <div className="browse_the_library_wrapper">
                                            <LibraryItemsWrapperComponent
                                                isSelectable={true}
                                                contentList={
                                                    displayedContentsList
                                                }
                                                setInitialData={setInitialData}
                                                selectItem={selectItem}
                                                deselectItem={
                                                    deselectItem
                                                }></LibraryItemsWrapperComponent>
                                        </div>
                                    ) : (
                                        <div className="no-content-label">
                                            No Content available
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="associate-members-table kb-content-library">
                        <div className="body_white_box myShelfSection">
                            <div className="body_header_title">
                                <div className="browseTitle">
                                    <div className="shelf-header">
                                        <div className="shelf-title">
                                            <label>My Shelf</label>
                                            <InfoTooltip
                                                text="You may save any content from the
                            library to<br/> your own personal 'shelf'"
                                                idText="kb-myshelf"
                                            />
                                        </div>
                                        <div className="shared-status">
                                            <Form
                                                initialValues={{
                                                    sharedStatus:
                                                        isMyShelfShared,
                                                }}>
                                                <label className="checkbox-container">
                                                    {`I consent to sharing 'My Shelf' with my Practice Management Group(s)`}
                                                    <Input
                                                        name={`sharedStatus`}
                                                        type="checkbox"
                                                        checked={
                                                            isMyShelfShared
                                                        }
                                                        onChange={() => {
                                                            setIsMyShelfShared(
                                                                !isMyShelfShared
                                                            );

                                                            updateShelfSharedStatus(
                                                                !isMyShelfShared
                                                            );
                                                        }}
                                                    />

                                                    <span className="checkmark"></span>
                                                </label>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoading ? (
                                Helpers.renderTableLoader()
                            ) : shelfList && shelfList.length ? (
                                <div className="browse_the_library_main">
                                    <div className="browse_the_library_wrapper">
                                        <div className="browse_the_library_item">
                                            {shelfList.map((cont, _index) => (
                                                <KnowledgeBaseContentTile
                                                    isSelectable={true}
                                                    updateTables={() =>
                                                        setInitialData()
                                                    }
                                                    data={cont}
                                                    selectItem={selectItem}
                                                    deselectItem={
                                                        deselectItem
                                                    }></KnowledgeBaseContentTile>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="browse_the_library_main">
                                    <div className="no-content-label">
                                        No Content available
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default KnowledgebaseContentSharePocLibrary;
