import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Form, Input } from '@availity/form';
import Rodal from '../../../../components/Rodal/Rodal';

const AssociateRepresentativeModal = props => {
    const [selectedAssociate, setSelectedAssociate] = useState();

    useEffect(() => {
        setSelectedAssociate(props.selectedAssociate);
    }, [props]);

    return (
        <Rodal
            visible={props.isVisible}
            animation={'slideTop'}
            showMask={true}
            width={600}
            showCloseButton={true}
            onClose={() => {
                props.setIsVisible(false);
            }}
            className="edit_membership_profile center-modal">
            <ModalHeader>Affiliated Practice Representative</ModalHeader>
            <ModalBody>
                <b className="dynamic_data">
                    Practice owners are permitted to invite an affiliated member
                    to assist them with Practice Management Group activities.
                    Affiliated Members must first be registered with VetValue
                    through the Affiliated Member Page. Due to the sensitive
                    nature of topics discussed within a PMG, all affiliated
                    representatives must be approved by VetValue and you must
                    disclose any other veterinary pratice affiliations of this
                    affiliated member, so that VetValue can assure there are no
                    conflicts of interest vis-a-vis any other member of the PMG
                    group.
                </b>

                <Form
                    initialValues={{
                        associate: props.selectedAssociate,
                    }}
                    onSubmit={() => {}}>
                    <FormGroup>
                        <Input
                            className="dynamic_data"
                            type="select"
                            name="associate"
                            value={selectedAssociate}
                            onChange={event => {
                                setSelectedAssociate(event.target.value);
                            }}>
                            {props.associateMembers
                                ? props.associateMembers.map(
                                      (option, index) => (
                                          <option key={index} value={option.id}>
                                              {option.name}
                                          </option>
                                      )
                                  )
                                : ''}
                        </Input>
                    </FormGroup>
                </Form>

                <div className="form-submit-btn input-group">
                    <button
                        type="button"
                        className="btn mr-2"
                        onClick={() => {
                            props.setIsVisible(false);
                        }}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-submit"
                        onClick={() => {
                            props.onSubmit(selectedAssociate);
                        }}>
                        Submit
                    </button>
                </div>
            </ModalBody>
        </Rodal>
    );
};
export default AssociateRepresentativeModal;
