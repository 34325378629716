import React from 'react';
import { Multiselect } from 'react-widgets/cjs';
import { FILE_EXTENSIONS } from '../../../../../utils/filesConstants';
import { Form, Field } from '@availity/form';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import { ProjectVariableOption } from '../../../../../models/DataRequestHub/ProjectVariable';
import './AddNewVariableOptionForm.scss';

interface MultiselectOption {
    type: string;
    option: string;
}

interface DisplayFormatFormProps {
    optionName: string;
    setOptionName(value: string): void;
    optionValue: string;
    setOptionValue(value: string): void;
    isLoading: boolean;
    add(newOption: any): void;
    isNestedVariable: boolean;
}

const AddNewVariableOptionForm = (props: DisplayFormatFormProps) => {
    const getMultiselectOptions = () =>
        FILE_EXTENSIONS.flatMap((m): MultiselectOption[] => {
            const mappedOptions = m.extensions?.map((e) => {
                return { type: m.name, option: e };
            }) ?? [{ type: m.name, option: 'Any' }];
            return mappedOptions;
        });

    const isAddButtonDisabled = () => {
        return (
            !props.optionName.trim() ||
            (props.isNestedVariable && !props.optionValue)
        );
    };

    return (
        <Form
            initialValues={{
                newOptionName: props.optionName,
            }}
            onSubmit={() => {}}>
            <div className={'add-new-option-form-row'}>
                <Field
                    name="newOptionName"
                    value={props.optionName}
                    onChange={(event: any) => {
                        props.setOptionName(event.target.value);
                    }}></Field>
                <button
                    className="btn btn-primary"
                    disabled={isAddButtonDisabled()}
                    onClick={() => {
                        let newOption: ProjectVariableOption;
                        if (props.isNestedVariable) {
                            newOption = {
                                id: 0,
                                name: props.optionName.trim(),
                                option: props.optionValue.trim(),
                            };
                        } else {
                            newOption = {
                                id: 0,
                                name: '',
                                option: props.optionName.trim(),
                            };
                        }

                        props.add(newOption);

                        props.setOptionName('');
                        props.setOptionValue('');
                    }}>
                    + Add
                </button>
            </div>
            {props.isNestedVariable ? (
                <div className="multiselect">
                    <Multiselect
                        filter="contains"
                        selectIcon={<ArrowDown />}
                        placeholder="Select Extensions..."
                        data={getMultiselectOptions()}
                        dataKey="option"
                        textField="option"
                        groupBy="type"
                        onChange={(value) => {
                            if (value?.length) {
                                const result = value
                                    .map((m) => m.option)
                                    .join('; ');
                                props.setOptionValue(result);
                            }
                        }}
                    />
                </div>
            ) : (
                []
            )}
        </Form>
    );
};

export default AddNewVariableOptionForm;
