export const objectToQueryString = (
    obj: any,
    prefix: string = null
): string => {
    const parts = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value !== null && value !== undefined && value !== '') {
                const prefixedKey = prefix ? `${prefix}.${key}` : key;

                if (typeof value === 'object' && !Array.isArray(value)) {
                    parts.push(objectToQueryString(value, prefixedKey));
                } else if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        const itemPrefixedKey = `${prefixedKey}[${index}]`;
                        parts.push(objectToQueryString(item, itemPrefixedKey));
                    });
                } else {
                    parts.push(
                        `${encodeURIComponent(
                            prefixedKey
                        )}=${encodeURIComponent(value)}`
                    );
                }
            }
        }
    }

    const queryString = parts.join('&');
    return queryString.startsWith('&') ? queryString.substring(1) : queryString;
};
