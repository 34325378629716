import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';

export interface BooleanAnswerFieldProps {
    question: DataFormQuestion;
    isAdminView: boolean;
    isReadonly: boolean;
    updateBooleanAnswer(questionId: number, value: string): void;
    updateNotification(questionId: number): void;
}

export const BooleanAnswerField = (props: BooleanAnswerFieldProps) => {
    const [answer, setAnswer] = useState<boolean | null>(null);
    const yesAnswer = 'Yes';
    const noAnswer = 'No';

    useEffect(() => {
        const answerText = props.question.answer?.answerText;
        let answer = null;
        if (answerText === yesAnswer) {
            answer = true;
        }
        if (answerText === noAnswer) {
            answer = false;
        }
        setAnswer(answer);
    }, [props]);

    const isAnswerChanged = (currentAnswer: string) => {
        const answerText = props.question.answer?.answerText;
        return answerText != currentAnswer;
    };

    const renderAdminField = () => {
        return (
            <div className="radio-button-wrapper">
                <div className="radio-button-block">
                    <Input
                        className={`text-size-field`}
                        type="radio"
                        checked={answer === true}
                        readOnly={true}></Input>
                    <Label className="radio-label">{yesAnswer}</Label>
                </div>
                <div className="radio-button-block">
                    <Input
                        className={`text-size-field`}
                        type="radio"
                        checked={answer === false}
                        readOnly={true}></Input>
                    <Label className="radio-label">{noAnswer}</Label>
                </div>
            </div>
        );
    };

    const renderUserField = () => {
        return (
            <div className="radio-button-wrapper">
                <div className="radio-button-block">
                    <Input
                        className={
                            props.isReadonly
                                ? `text-size-field`
                                : `text-size-field clickable`
                        }
                        type="radio"
                        readOnly={props.isReadonly}
                        checked={answer === true}
                        onClick={() => {
                            props.updateNotification(props.question.id);
                        }}
                        onChange={() => {
                            if (
                                !props.isReadonly &&
                                isAnswerChanged(yesAnswer)
                            ) {
                                setAnswer(true);
                                props.updateBooleanAnswer(
                                    props.question.id,
                                    yesAnswer
                                );
                            }
                        }}></Input>
                    <Label className="radio-label">{yesAnswer}</Label>
                </div>
                <div className="radio-button-block">
                    <Input
                        className={
                            props.isReadonly
                                ? `text-size-field`
                                : `text-size-field clickable`
                        }
                        type="radio"
                        readOnly={props.isReadonly}
                        checked={answer === false}
                        onClick={() => {
                            props.updateNotification(props.question.id);
                        }}
                        onChange={() => {
                            if (
                                !props.isReadonly &&
                                isAnswerChanged(noAnswer)
                            ) {
                                setAnswer(false);
                                props.updateBooleanAnswer(
                                    props.question.id,
                                    noAnswer
                                );
                            }
                        }}></Input>
                    <Label className="radio-label">{noAnswer}</Label>
                </div>
            </div>
        );
    };

    return props.isAdminView ? renderAdminField() : renderUserField();
};
