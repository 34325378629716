import React, { Component, Fragment } from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';

class ChatExample extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let data = this.props.data;
        return (
            <Fragment>
                <div className="scroll-area-md">
                    <PerfectScrollbar>
                        <div className="p-3">
                            <VerticalTimeline
                                layout="1-column"
                                className="vertical-without-time">
                                {data.map(doc => (
                                    <VerticalTimelineElement
                                        className="vertical-timeline-item"
                                        icon={
                                            <i className={doc.badgeColor}> </i>
                                        }>
                                        <h4 className="timeline-title">
                                            {doc.text}
                                        </h4>
                                        <p>
                                            <a href="#;">{doc.date}</a>
                                        </p>
                                    </VerticalTimelineElement>
                                ))}
                            </VerticalTimeline>
                        </div>
                    </PerfectScrollbar>
                </div>
            </Fragment>
        );
    }
}

export default ChatExample;
