import DataFormQuestion, { isQuestionVisibleByLogic } from './DataFormQuestion';

export default interface QuestionSeenStatus extends DataFormQuestion {
    projectId: number;
    isNewProject: boolean;
    formId: number;
    isNewForm: boolean;
}

export const calculateQuestionUserNotifications = (
    questionStatus: QuestionSeenStatus,
    allFormItems: DataFormQuestion[]
): number => {
    let count = 0;

    const targetQuestion = allFormItems.find(
        (question) => question.id === questionStatus.id
    );

    if (!targetQuestion?.isVisible) {
        return count;
    }

    const isVisibleByLogic = isQuestionVisibleByLogic(
        allFormItems,
        targetQuestion
    );

    if (!isVisibleByLogic) {
        return count;
    }

    if (questionStatus.isNewProject || questionStatus.isNewForm) {
        return count;
    }

    if (questionStatus.isAdminFeedbackHighlighted) {
        count += 1;
    }

    if (!questionStatus.isSeenByUser) {
        count += 1;
    }

    return count;
};
