import { createAsyncThunk } from '@reduxjs/toolkit';
interface RefreshNotificationFlagsPayload {
    prefixesToIgnore: string[];
}

export const RefreshNotificationFlagsExceptPrefix = createAsyncThunk(
    'notificationFlags/refreshExceptPrefix',
    async (_payload: RefreshNotificationFlagsPayload, dispatch: any) => {
        const token = localStorage.getItem('token');
        if (token) {
            const axios = dispatch.getState().core.axios;
            const response = await axios.get(`api/notificationflags`);
            return response;
        }
        return { data: [] };
    }
);
