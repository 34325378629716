import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchMembers = createAsyncThunk(
    'directMembers/fetchData',
    async (_, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`api/direct/members`);
        return response;
    }
);
