import React, { useState } from 'react';
import { Button, Modal, ModalBody, Table } from 'reactstrap';
import '@availity/yup';
import './create-room-modal.scss';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import usersGroupIcon from '../../../assets/images/userGroups.png';
import {
    Box,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    SortDirection,
    TableSortLabel,
    TableBody,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StateAbbreviationService } from '../../../utils/stateAbbreviationHelper';
import { DirectMember, Practice } from '../../models/direct-member';
import { RoomItem } from '../../models/direct-room';
import createNotification from '../../../utils/createNotification';

interface CreateRoomModalProps {
    isVisible: boolean;
    hide(): void;
    members: Array<DirectMember>;
    rooms: Array<RoomItem>;
    currentUserId: number;
    createRoom(userId: number): Promise<any>;
    openMemberProfileModal: (id: number) => void;
}
const CreateRoomModal = (props: CreateRoomModalProps) => {
    const [selected, setSelected] = useState<DirectMember>();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('fullName');
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const data = {
        members: [...props.members]
            .filter((item) => item.userId !== props.currentUserId)
            .filter((item) => {
                const hasRoomsWithCurrentUser = props.rooms.some((roomItem) =>
                    roomItem.room.members.some(
                        (member) => member.id == item.userId
                    )
                );
                return !item.isCharityAdmin || hasRoomsWithCurrentUser;
            })
            .filter((poster) => poster.fullName.toLowerCase().includes(search)),
    };

    const handleSearch = (event: any) => {
        setSearch(event.target.value.toLowerCase());
    };

    const headCells = [
        {
            id: 'fullName',
            numeric: false,
            label: 'Name',
        },
        {
            id: 'practices',
            numeric: false,
            label: 'Practice(s)',
        },
        {
            id: 'graduationYear',
            numeric: false,
            label: 'Vet School, Year',
        },
        {
            id: 'numberOfPosts',
            numeric: true,
            label: '# of Posts',
        },
    ];

    const handleRequestSort = (property: any) => (_event: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const comparator = (left: any, right: any) => {
        if (left > right) return 1;
        if (left < right) return -1;
        return 0;
    };

    const stableSort = (posters: DirectMember[]) => {
        let sortedArray = posters;
        sortedArray.sort((a, b) => {
            if (orderBy === 'practices') {
                return comparator(
                    a.profile[orderBy][0]?.name,
                    b.profile[orderBy][0]?.name
                );
            }
            if (orderBy === 'graduationYear') {
                const compResult = comparator(
                    a.profile.veterinarySchool,
                    b.profile.veterinarySchool
                );
                if (!compResult) {
                    return comparator(a.profile[orderBy], b.profile[orderBy]);
                }

                return compResult;
            } else {
                return comparator(a[orderBy], b[orderBy]);
            }
        });

        const isAsc = order === 'asc';
        if (!isAsc) {
            sortedArray.reverse();
        }

        return sortedArray;
    };

    const createRoom = () => {
        setIsLoading(true);
        props
            .createRoom(selected.userId)
            .then((response) => {
                if (!response || response.payload?.status === 200) {
                    props.hide();
                } else {
                    createNotification(
                        'An unexpected error occured while creating direct chat.',
                        'error'
                    );
                }
            })
            .catch(() =>
                createNotification(
                    'An unexpected error occured while creating direct chat.',
                    'error'
                )
            )
            .finally(() => setIsLoading(false));
    };

    const resetModal = () => {
        setSelected(null);
        setSearch('');
        setOrder('asc');
        setOrderBy('');
    };

    return (
        <>
            <Modal
                className="create-room-modal"
                isOpen={props.isVisible}
                onLoad={() => resetModal()}
                toggle={props.hide}>
                <ModalBody className="">
                    <div>
                        <h5 className="modal-title">New Direct Message</h5>
                        <p className="disclaimer">
                            We will be monitoring chats to ensure no competitive
                            information is shared in the IPOC or direct messages
                            with individuals outside of your PMG.
                        </p>
                    </div>
                    <div className="searchBar">
                        <img src={usersGroupIcon} />
                        <input
                            type="text"
                            placeholder="Search Directory"
                            onChange={handleSearch}
                        />
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Paper>
                            <TableContainer>
                                <Table
                                    className="posterDirectorTable"
                                    sx={{ minWidth: 450 }}
                                    aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={
                                                        headCell.numeric
                                                            ? 'center'
                                                            : 'left'
                                                    }
                                                    sortDirection={
                                                        orderBy === headCell.id
                                                            ? (order as SortDirection)
                                                            : false
                                                    }>
                                                    <TableSortLabel
                                                        active={
                                                            orderBy ===
                                                            headCell.id
                                                        }
                                                        direction={
                                                            orderBy ===
                                                            headCell.id
                                                                ? (order as
                                                                      | 'asc'
                                                                      | 'desc')
                                                                : 'asc'
                                                        }
                                                        onClick={handleRequestSort(
                                                            headCell.id
                                                        )}>
                                                        {headCell.label}
                                                        {orderBy ===
                                                        headCell.id ? (
                                                            <Box component="span">
                                                                {order ===
                                                                'desc'
                                                                    ? ''
                                                                    : ''}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {(!data.members ||
                                            data.members.every(
                                                (m) => !m.isActive
                                            )) && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={12}
                                                    align="center">
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {data.members &&
                                            stableSort(
                                                data.members.filter(
                                                    (m) => m.isActive
                                                )
                                            ).map((roomMember, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={
                                                            roomMember.fullName
                                                        }
                                                        onClick={() => {
                                                            setSelected(
                                                                roomMember
                                                            );
                                                        }}
                                                        className={
                                                            selected?.userId ===
                                                            roomMember.userId
                                                                ? 'selected-row'
                                                                : ''
                                                        }>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            align="left">
                                                            {roomMember.profile
                                                                ?.avatarPath ? (
                                                                <div
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        props.openMemberProfileModal(
                                                                            roomMember.userId
                                                                        );
                                                                        e.stopPropagation();
                                                                    }}
                                                                    className="table-avatar-icon">
                                                                    <span className="clientLogin">
                                                                        <img
                                                                            src={
                                                                                roomMember
                                                                                    .profile
                                                                                    .avatarPath
                                                                            }
                                                                            className="poster-member-icon"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        props.openMemberProfileModal(
                                                                            roomMember.userId
                                                                        );
                                                                        e.stopPropagation();
                                                                    }}
                                                                    className="poster-member-icon"
                                                                    style={{
                                                                        background:
                                                                            roomMember.backgroundColorCode,
                                                                    }}>
                                                                    <div
                                                                        className="poster-member-initials"
                                                                        style={{
                                                                            color: roomMember.foregroundColorCode,
                                                                        }}>
                                                                        {
                                                                            roomMember.initials
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <a
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    props.openMemberProfileModal(
                                                                        roomMember.userId
                                                                    );
                                                                    e.stopPropagation();
                                                                }}>
                                                                {
                                                                    roomMember.fullName
                                                                }
                                                            </a>

                                                            {roomMember.onlineState ? (
                                                                <span className="onlineDot"></span>
                                                            ) : (
                                                                <span className="offlineDot"></span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {roomMember.profile?.practices?.map(
                                                                (
                                                                    practice: Practice,
                                                                    index: number
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }>
                                                                        {practice.website ? (
                                                                            <Link
                                                                                target={
                                                                                    '_blank'
                                                                                }
                                                                                to={
                                                                                    '//' +
                                                                                    practice.website
                                                                                }>
                                                                                {
                                                                                    practice.name
                                                                                }
                                                                            </Link>
                                                                        ) : (
                                                                            practice.name
                                                                        )}{' '}
                                                                        -{' '}
                                                                        {
                                                                            practice.city
                                                                        }
                                                                        ,{' '}
                                                                        {StateAbbreviationService.getAbbreviation(
                                                                            practice.state
                                                                        )}{' '}
                                                                        <br />
                                                                    </span>
                                                                )
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {`${
                                                                roomMember
                                                                    .profile
                                                                    ?.veterinarySchool ??
                                                                '-'
                                                            }, ${
                                                                roomMember.profile?.graduationYear?.toString() ??
                                                                '-'
                                                            }`}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {roomMember.numberOfPosts ??
                                                                '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                    <div className="btn-wrapper">
                        <Button
                            className="cancel-btn"
                            onClick={() => props.hide()}>
                            Cancel
                        </Button>
                        <ButtonLoader
                            buttonText={'Begin Direct Messaging'}
                            loaderButtonText={''}
                            disabled={isLoading || !selected}
                            isLoading={isLoading}
                            onClick={() => {
                                createRoom();
                            }}
                            className={
                                true
                                    ? 'btn save-btn loader-submit'
                                    : 'btn save-btn'
                            }></ButtonLoader>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default CreateRoomModal;
