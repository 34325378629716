import * as signalR from '@microsoft/signalr';

export const initializaeSignalRConnection = (targetHubUrl: string) =>
    new signalR.HubConnectionBuilder()
        .withUrl(targetHubUrl, {
            accessTokenFactory: () => localStorage.getItem('token'),
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) =>
                retryContext.elapsedMilliseconds < 120000 ? 5000 : 30000,
        })
        .configureLogging(signalR.LogLevel.Warning)
        .build();
