import { createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../models/notification";

export const addLikeToMessage = createAsyncThunk(
    'clubhouseMessages/like',
    (notification: Notification, dispatch: any) => {
        return {
            message: notification.message,
            discussionId: notification.chatId,
        };
    }
);