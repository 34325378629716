import { createAsyncThunk } from '@reduxjs/toolkit';
import { PutDiscussion } from './clubhouse-slice-helper';
export const updateDiscussion = createAsyncThunk(
    'clubhouse/updateDiscussion',
    async (discussion: PutDiscussion, dispatch: any) => {
        const axios = dispatch.getState().core.axios;

        return await axios.put(
            `api/clubhouses/${discussion.clubhouseId}/discussions/${discussion.discussionId}`,
            {
                name: discussion.name,
                description: discussion.description,
            }
        );
    }
);
