import React from 'react';
import './CheckoutInfo.scss';
import { Row } from 'reactstrap';
import AvailableVvCredits from '../AvailableVvCredits/AvailableVvCredits';
import { Discount } from '../../../models/Discount/Discount';
import { DiscountType } from '../../../models/Discount/DiscountType';
import InfoTooltip from '../../info-tooltip/info-tooltip';
import Currency from '../../Currency/Currency';
import { PriceItem } from '../PaymentDialog';

interface CheckoutFormProps {
    storeCredits?: number;
    flexCredits?: number;
    rewardCredits?: number;
    discountList: Discount[];
    priceList: PriceItem[];
    wrapperClass?: string;
}

const CheckoutInfo = (props: CheckoutFormProps) => {
    const referralDiscount = props.discountList?.find(
        (f) => f.type === DiscountType.Referral
    );
    const frequencyDiscount = props.discountList?.find(
        (f) => f.type === DiscountType.Frequency
    );

    const renderPriceList = () => (
        <div
            className={`price-items-wrapper${
                !!props.wrapperClass ? ` ${props.wrapperClass}` : ''
            }`}>
            {props.priceList.map((item, index) => {
                return item.priceType === 'spacer' ? (
                    <div className="spacer"></div>
                ) : (
                    <div
                        key={`price-item-${index}`}
                        className={
                            item.priceType === 'summary'
                                ? 'price-item payment-due-title'
                                : 'price-item'
                        }>
                        <div>
                            {item.label}{' '}
                            {item.tooltipText ? (
                                <InfoTooltip
                                    idText={`tooltop-pricing-item-${index}`}
                                    text={item.tooltipText}
                                />
                            ) : (
                                []
                            )}
                        </div>
                        {item.value && (
                            <div>
                                {item.valueType === 'currency' ? (
                                    <div>
                                        <Currency
                                            quantity={item.value}
                                            currency="USD"></Currency>
                                    </div>
                                ) : (
                                    []
                                )}
                                {item.valueType === 'percent' ? (
                                    <div>{`${item.value}%`}</div>
                                ) : (
                                    []
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className="checkout-form">
            <Row className="congratulations">
                {referralDiscount ? (
                    <div className="discount-alert">
                        Referral Discount {referralDiscount.discountPercent}%
                    </div>
                ) : (
                    []
                )}
                {frequencyDiscount ? (
                    <div className="discount-alert">
                        {frequencyDiscount.name} Frequency Discount{' '}
                        {frequencyDiscount.discountPercent}%
                    </div>
                ) : (
                    []
                )}
            </Row>
            <Row className="order-information-body">
                {renderPriceList()}
                <AvailableVvCredits
                    storeCredits={props.storeCredits}
                    flexCredits={props.flexCredits}
                    rewardCredits={props.rewardCredits}></AvailableVvCredits>
            </Row>
        </div>
    );
};
export default CheckoutInfo;
