import React from 'react';
import './DataTableFormPopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import { ModalBody, ModalHeader } from 'reactstrap';
import { DataTableForm } from '../DataTableForm/DataTableForm';
import { DataRequestProject } from '../../../models/DataRequestHub/DataRequestProject';
import { DataTable } from '../../../models/DataRequestHub/DataTable';

interface DataTableFormPopupProps {
    header: string;
    triggerNext?: () => void;
    triggerBack?: () => void;
    selectedTableViewId: number;
    selectedDataTable: DataTable;
    project: DataRequestProject;
    isAdminView: boolean;
}

export const DataTableFormPopup = (props: DataTableFormPopupProps) => {
    const handleBackStep = () => {
        if (props.triggerBack) {
            props.triggerBack();
        }
    };

    return (
        <Rodal
            visible={true}
            onClose={handleBackStep}
            className="table-form-popup"
            width={500}
            animation="fade"
            closeMaskOnClick={false}
            centered>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalBody>
                <DataTableForm
                    isAdminView={props.isAdminView}
                    projectId={props.project.id}
                    variables={props.project.variables}
                    dataTable={props.selectedDataTable}
                    selectedTableViewId={props.selectedTableViewId}
                    updateProjectsData={() => {}}
                    markCellsAsSeen={() => {}}
                    isReadonlyMode={true}
                />
            </ModalBody>
        </Rodal>
    );
};
