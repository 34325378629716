import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSupportThreads = createAsyncThunk(
    'supportRequests/fetchThreads',
    async (_args, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get('api/supportRequests');
        return response;
    }
);
