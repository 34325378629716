import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import './StandardizationServicesHost.scss';
import { TableFilter } from '../../../components/Table/models/table-filter';
import { objectToQueryString } from '../../../utils/queryHelper';
import createNotification from '../../../utils/createNotification';
import moment from 'moment';
import Loader from 'react-loaders';
import BootstrapTable from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import UserDetailsModal from '../Permissions/Users/Modal/UserDetailsModal';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../../../components/arrow-down/arrow-down';
import Standardization from '../../../models/AccountingStandardization/Standardization';
import Helpers from '../../../utils/helper';
import {
    StandardizationPeriodList,
    StandardizationTypeEnum,
} from '../../../models/AccountingStandardization/StandardizationTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import NotePopup from '../GroupNotePopup/NotePopup';
import { Tooltip } from 'react-tooltip';
import sanitizeHtml from 'sanitize-html';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import IssueRefundModal from './IssueRefundModal/IssueRefundModal';
import {
    StandardizationStatusEnum,
    StandardizationStatusList,
} from '../../../models/AccountingStandardization/StandardizationStatus';
import ChangeEmployeesNumberModal from './ChangeEmployeesNumberModal/ChangeEmployeesNumberModal';
import { useStateSelector } from '../../../store/selectors';

const StandardizationServicesHost = () => {
    const [tableData, setTableData] = useState<Array<Standardization>>([]);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [userId, setUserID] = useState(0);
    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [confirmationIsLoading, setConfirmationIsLoading] = useState(false);
    const [isNotePopupVisible, setIsNotePopupVisible] = useState(false);
    const [isIssueRefundModalVisible, setIsIssueRefundModalVisible] =
        useState(false);
    const [
        isChangeEmployeesNumberModalVisible,
        setIsChangeEmployeesNumberModalVisible,
    ] = useState(false);
    const [
        selectedStandardizationPurchase,
        setSelectedStandardizationPurchase,
    ] = useState<Standardization>(null);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
        useState(false);
    const [noteText, setNoteText] = useState('');
    const axios = useStateSelector((state) => state.core.axios);
    const [isSaving, setIsSaving] = useState(false);
    const defaultSortDirection: { dataField: any; order: any } = {
        dataField: 'dateCreated',
        order: 'desc',
    };
    const [sortingState, setSortingState] = useState<any>({
        data: [],
        filters: {},
        page: page,
        searchText: '',
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });
    const [tableFilters, setTalbeFilters] = useState<
        { field: string; value: string; operator: string }[]
    >([]);
    const tableTooltipTargetId = 'standardization-services-host';

    const mapFteDvmStatus = (isEmployeesNumberConfirmed: boolean) =>
        isEmployeesNumberConfirmed ? 'Confirmed' : 'Unconfirmed';

    useEffect(() => {
        if (sortingState) {
            fetchStandardizationServicesData(sortingState);
        }
    }, [sortingState, tableFilters]);

    const handleTableChange = (_type: any, newState: any) => {
        setSortingState(newState);
    };

    const showUserDetailPopup = (userId: number) => {
        setIsUserDetailsModalVisible(true);
        setUserID(userId);
    };

    const mapPaymentStatusToLabel = (
        paymentStatus: number,
        id: number,
        error: string,
        unableToCompleteExplanation: string,
        refundPercent: number,
        refundedAmount: number
    ) => {
        switch (paymentStatus) {
            case StandardizationStatusEnum.InProcess:
            case StandardizationStatusEnum.Completed:
            case StandardizationStatusEnum.Processing:
            case StandardizationStatusEnum.FinalPaymentRequested:
                return (
                    <div>{StandardizationStatusList[paymentStatus].name}</div>
                );
            case StandardizationStatusEnum.Failed:
                return (
                    <div>
                        {StandardizationStatusList[paymentStatus].name}{' '}
                        <InfoTooltip
                            place="left"
                            idText={`payment-error-tooltip${id}`}
                            text={`Error message: ${error}`}
                        />
                    </div>
                );
            case StandardizationStatusEnum.UnableToComplete: {
                let tooltipText = `Refund Percent: ${refundPercent}%<br>Refunded Amount: ${refundedAmount}<br>Explanation: ${unableToCompleteExplanation}`;
                return (
                    <div>
                        {StandardizationStatusList[paymentStatus].name}
                        <InfoTooltip
                            place="left"
                            idText={`unable-to-complete-explanation-tooltip${id}`}
                            text={tooltipText}
                        />
                    </div>
                );
            }
            default:
                break;
        }
    };

    const fetchStandardizationServicesData = (sortingState: any) => {
        let tableFilter = new TableFilter(
            { filters: tableFilters, logic: '' },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder,
                field: sortingState.sortField,
            }
        );
        axios
            .get(
                `api/standardizationPurchases?${objectToQueryString(
                    tableFilter
                )}`
            )
            .then((response: any) => {
                if (response?.status === 200) {
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'Error occured while fetching Standardization Services',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const shouldActionButtonsBeVisible = (status: number) => {
        switch (status) {
            case StandardizationStatusEnum.InProcess:
            case StandardizationStatusEnum.Failed:
                return true;
            case StandardizationStatusEnum.Completed:
            case StandardizationStatusEnum.UnableToComplete:
            case StandardizationStatusEnum.Processing:
            case StandardizationStatusEnum.FinalPaymentRequested:
            default:
                return false;
        }
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'dateCreated',
            text: 'Date Ordered',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Standardization Period',
            sort: true,
            formatter: (cellContent: StandardizationTypeEnum) =>
                StandardizationPeriodList[cellContent]?.name ?? '-',
        },
        {
            dataField: 'totalPrice',
            text: 'Price',
            formatter: (cellContent: number) =>
                Helpers.currencyFormatWithBrackets(cellContent),
            sort: true,
        },
        {
            dataField: 'memberName',
            text: 'Member Name',
            sort: true,
            formatter: (cellContent: string, row: Standardization) =>
                row.isMemberInvalidated ? (
                    cellContent
                ) : (
                    <div
                        className="referenced-text"
                        onClick={() => showUserDetailPopup(row.memberId)}>
                        {cellContent}
                    </div>
                ),
        },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            sort: true,
        },
        {
            dataField: 'employeesNumber',
            text: 'FTE DVMs',
            sort: true,
            formatter: (cellContent: number, row: Standardization) => (
                <div className="dvm-table-container">
                    <div>{cellContent}</div>
                    {shouldActionButtonsBeVisible(row.status) && (
                        <FontAwesomeIcon
                            size="lg"
                            className="edit-icon"
                            icon={faEdit}
                            onClick={() => {
                                setSelectedStandardizationPurchase(row);
                                setIsChangeEmployeesNumberModalVisible(true);
                            }}
                        />
                    )}
                </div>
            ),
        },
        {
            dataField: 'isEmployeesNumberConfirmed',
            text: 'FTE DVMs Status',
            formatter: (cellContent: boolean) => mapFteDvmStatus(cellContent),
            sort: true,
        },
        {
            dataField: 'lastCompletedFinancials',
            text: 'Most Recent Month of Completed Financials',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'serviceDeadline',
            text: 'Service Deadline',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cellContent: number, row: Standardization) => (
                <div>
                    {mapPaymentStatusToLabel(
                        cellContent,
                        row.id,
                        row.finalPaymentErrorMessage,
                        row.refundAdminExplanation,
                        row.refundPercent,
                        row.refundedAmount
                    )}
                </div>
            ),
            sort: true,
        },
        {
            dataField: 'id',
            text: 'Action',
            formatter: (cellContent: number, row: Standardization) => {
                let id = `note-icon-id-${row.adminNote}-${cellContent}`.replace(
                    /^[^a-z]+|[^\w:.-]+/gi,
                    ''
                );
                return (
                    <div className="action-table-container">
                        <button
                            id={id}
                            data-tooltip-html={sanitizeHtml(row.adminNote)}
                            type="button"
                            className="btn btn-primary admin-notes-button"
                            onClick={() => {
                                setNoteText(row.adminNote);
                                setSelectedStandardizationPurchase(row);
                                setIsNotePopupVisible(true);
                            }}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            {row.adminNote && (
                                <Tooltip
                                    place={'left'}
                                    className={'tooltip-content place-top'}
                                    anchorId={id}
                                    classNameArrow="arrow"
                                    offset={15}
                                />
                            )}
                        </button>
                        {shouldActionButtonsBeVisible(row.status) && (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-primary complete-payment-button"
                                    onClick={() => {
                                        setSelectedStandardizationPurchase(row);
                                        setIsConfirmationModalVisible(true);
                                    }}>
                                    Request Final Payment
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary issue-refund-button"
                                    onClick={() => {
                                        setSelectedStandardizationPurchase(row);
                                        setIsIssueRefundModalVisible(true);
                                    }}>
                                    Issue Refund
                                </button>
                            </>
                        )}
                    </div>
                );
            },
            sort: false,
        },
    ];

    const handlePeriodChange = (changedProperty: number) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'type');
        if (StandardizationPeriodList.some((s) => s.id === changedProperty)) {
            newFilters.push({
                field: 'type',
                value: changedProperty.toString(),
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const confirmFinalPayment = () => {
        setConfirmationIsLoading(true);
        axios
            .post(
                `/api/StandardizationPurchases/${selectedStandardizationPurchase.id}/requestFinalPayment`
            )
            .then((response: any) => {
                if (response?.request?.status === 200) {
                    fetchStandardizationServicesData(sortingState);
                    setIsConfirmationModalVisible(false);
                    createNotification(
                        `Payment completed successfully!`,
                        'success'
                    );
                } else {
                    createNotification('Payment failed', 'error');
                }
            })
            .catch(() => {
                createNotification('Unexpected error occurred.', 'error');
            })
            .finally(() => {
                setConfirmationIsLoading(false);
            });
    };

    const saveNotes = async (note: string) => {
        const postObject = {
            standardizationPurchaseId: selectedStandardizationPurchase.id,
            note: note,
        };

        await axios
            .post('/api/standardizationPurchases/AdminNotes', postObject)
            .then((response: any) => {
                if (response?.request?.status === 200) {
                    setIsNotePopupVisible(false);
                    fetchStandardizationServicesData(sortingState);
                    createNotification(`Note saved successfully!`, 'success');
                } else {
                    createNotification(
                        'Error occurred while saving note. Please try again.',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification('Unexpected error occurred.', 'error');
            });
    };

    const changeEmployeesNumber = (ftesNumber: number) => {
        setIsSaving(true);
        let data = {
            standardizationId: selectedStandardizationPurchase.id,
            confirmedEmployeesNumber: ftesNumber,
        };
        axios
            .post('/api/StandardizationPurchases/ChangeEmployeesNumber', data)
            .then(async (response: any) => {
                if (response.status === 200) {
                    createNotification(
                        'Number of employees was successfully changed.',
                        'success'
                    );
                    setIsChangeEmployeesNumberModalVisible(false);
                    fetchStandardizationServicesData(sortingState);
                    setTimeout(() => {
                        fetchStandardizationServicesData(sortingState);
                    }, 1500);
                } else {
                    createNotification(response.response.data.title, 'error');
                }
            })
            .catch(() => {
                createNotification(
                    `Error during changing number of employees.`,
                    'error'
                );
            })
            .finally(() => setIsSaving(false));
    };

    const handleStatusChange = (changedProperty: number) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'status');
        if (StandardizationStatusList.some((s) => s.id === changedProperty)) {
            newFilters.push({
                field: 'status',
                value: changedProperty.toString(),
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    return (
        <>
            <Row className="standardization-services-host">
                <Col md="12">
                    <Card className="main-card mb-3">
                        <CardHeader>
                            <span className="practice-heading">
                                {'Standardizations'}
                            </span>
                        </CardHeader>
                        <CardBody>
                            <div className="standardization-services-table">
                                {isLoading ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minHeight: '200px',
                                        }}>
                                        <Loader
                                            active={isLoading}
                                            type="ball-scale-multiple"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="filters-bar">
                                            <FormGroup className="filter-scroller">
                                                <div className="filter-item member-input">
                                                    <label>Status</label>
                                                    <DropdownList
                                                        className="credit-type-select"
                                                        selectIcon={
                                                            <ArrowDown />
                                                        }
                                                        data={[
                                                            {
                                                                id: -1,
                                                                name: 'None',
                                                            },
                                                            ...StandardizationStatusList.filter(
                                                                (x) => x.id != 4
                                                            ),
                                                        ]}
                                                        onChange={(value) => {
                                                            handleStatusChange(
                                                                value.id
                                                            );
                                                        }}
                                                        dataKey="id"
                                                        filter="contains"
                                                        textField="name"
                                                    />
                                                </div>
                                                <div className="line-separator"></div>
                                                <div className="filter-item member-input">
                                                    <label>
                                                        Standardization Period
                                                    </label>
                                                    <DropdownList
                                                        className="credit-type-select"
                                                        selectIcon={
                                                            <ArrowDown />
                                                        }
                                                        data={[
                                                            {
                                                                id: 8,
                                                                name: 'None',
                                                            },
                                                            ...StandardizationPeriodList,
                                                        ]}
                                                        onChange={(value) => {
                                                            handlePeriodChange(
                                                                value.id
                                                            );
                                                        }}
                                                        dataKey="id"
                                                        filter="contains"
                                                        textField="name"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <BootstrapTable
                                            remote
                                            bootstrap4
                                            keyField="StandardizationId"
                                            data={tableData}
                                            columns={getColumns()}
                                            filter={filterFactory({
                                                page,
                                                sizePerPage,
                                                totalSize,
                                            })}
                                            pagination={paginationFactory({
                                                page,
                                                sizePerPage,
                                                totalSize,
                                                tooltipTargetId:
                                                    tableTooltipTargetId,
                                            })}
                                            defaultSorted={[
                                                defaultSortDirection,
                                            ]}
                                            onTableChange={handleTableChange}
                                            noDataIndication={
                                                'No Data available'
                                            }
                                            rowClasses={(_row) =>
                                                ''
                                            }></BootstrapTable>
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {isUserDetailsModalVisible && (
                <UserDetailsModal
                    onClose={() => {
                        setIsUserDetailsModalVisible(false);
                    }}
                    isVisisble={isUserDetailsModalVisible}
                    userID={userId}></UserDetailsModal>
            )}
            {isNotePopupVisible ? (
                <NotePopup
                    resetModal={() => {
                        setIsNotePopupVisible(false);
                    }}
                    note={noteText}
                    save={saveNotes}></NotePopup>
            ) : (
                []
            )}
            {isConfirmationModalVisible && (
                <ConfirmationModal
                    isVisible={isConfirmationModalVisible}
                    setIsVisible={setIsConfirmationModalVisible}
                    header="Confirmation"
                    confirmationText="Are you sure you want to request the payment for this order?"
                    onConfirm={confirmFinalPayment}
                    params={undefined}
                    closeOnConfirm={false}
                    isLoading={confirmationIsLoading}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"></ConfirmationModal>
            )}
            {isIssueRefundModalVisible && (
                <IssueRefundModal
                    isVisible={isIssueRefundModalVisible}
                    setIsVisible={setIsIssueRefundModalVisible}
                    successfullCallBack={() => {
                        setIsIssueRefundModalVisible(false);
                        fetchStandardizationServicesData(sortingState);
                    }}
                    standardizationPurchase={
                        selectedStandardizationPurchase
                    }></IssueRefundModal>
            )}
            {isChangeEmployeesNumberModalVisible && (
                <ChangeEmployeesNumberModal
                    isVisible={isChangeEmployeesNumberModalVisible}
                    setIsVisible={setIsChangeEmployeesNumberModalVisible}
                    changeEmployeesNumber={changeEmployeesNumber}
                    isSaving={isSaving}
                    standardizationPurchase={
                        selectedStandardizationPurchase
                    }></ChangeEmployeesNumberModal>
            )}
        </>
    );
};

export default StandardizationServicesHost;
