import { createAsyncThunk } from '@reduxjs/toolkit';

export const createRoom = createAsyncThunk(
    'directRooms/createRoom',
    async (userId: number, dispatch: any) => {
        const axios = dispatch.getState().core.axios;

        return await axios.post(`api/direct/rooms/${userId}`);
    }
);

export const createAnonymousRoom = createAsyncThunk(
    'directRooms/createAnonymousRoomRoom',
    async (userId: number, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        return await axios.post(`api/direct/rooms/${userId}/anonymous`);
    }
);
