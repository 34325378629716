import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './StandardizationServicesPage.scss'
import StandardizationServicesHost from '../Admin/StandardizationServices/StandardizationServicesHost';
import { HomeButton } from '../../components/HomeButton/home-button';

const StandardizationServicesPage = () => {
    return (
        <div className="subscriptions-page">
            <div className="title-wrapper">
                <div className="page-title">
                    <h3>Standardizations</h3>
                </div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <HomeButton></HomeButton>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Standardizations</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <StandardizationServicesHost />
        </div>
    );
};

export default StandardizationServicesPage;
