import React, { useState, useEffect } from 'react';
import './DocDecline.scss';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';

const DocDecline = () => {
    const [show] = useState(true);

    useEffect(() => {
        setNdaSignedSuccess();
    }, []);
    const setNdaSignedSuccess = () => {
        window.top.postMessage('true', '*');
    };
    const html = ReactDOMServer.renderToStaticMarkup(
        <div className="doc-decline">
            <h1>Oops!</h1>
            <p>
                You wont be able to proceed further as you have declined to sign
                the agreement.
            </p>
        </div>
    );
    Swal.fire({
        icon: 'warning',
        show: show,
        closeOnEsc: false,
        closeOnClickOutside: false,
        html: html,
        buttonsStyling: false,
        preConfirm: () => {
            setNdaSignedSuccess();
        },
    });
    return <div></div>;
};
export default DocDecline;
