import { createSlice } from '@reduxjs/toolkit';
import { fetchSupportMembers } from './fetch-members.thunk';
import { SupportPortalMember } from '../../models/SupportPortalMember';
import { SupportPortalMembersState } from './support-portal-members-state';
import { GetSupportMemberContract } from '../../models/GetSupportMemberContract';
import { ADMIN_PROFILE_ICON_DEFAULT } from '../../../utils/config';

const initialState: SupportPortalMembersState = {
    members: [],
    isLoading: false,
    isInitiated: false,
    status: '',
    error: '',
};

const suportPortalMembersSlice = createSlice({
    name: 'supportMembers',
    initialState,
    reducers: {
        updateOnlineStatus(state, action) {
            const status = action.payload as SupportPortalMember;
            const member = state.members.find(
                (m) => m.userId === status.userId
            );
            if (member) {
                member.onlineState = status.onlineState;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                fetchSupportMembers.pending,
                (state: SupportPortalMembersState) => {
                    setPendingState(state);
                }
            )
            .addCase(
                fetchSupportMembers.fulfilled,
                (state: SupportPortalMembersState, action: any) => {
                    if (action.payload.status == 200) {
                        const members = action.payload.data.map(
                            (member: GetSupportMemberContract) =>
                                mapMember(member)
                        );
                        state.members = members;
                        setSucceededState(state);
                        state.isInitiated = true;
                    } else {
                        setRejectedState(state);
                    }
                }
            )
            .addCase(fetchSupportMembers.rejected, (state: any) => {
                setRejectedState(state);
            });
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

const mapMember = (member: GetSupportMemberContract): SupportPortalMember => {
    const avatarPath = member.isAdmin
        ? ADMIN_PROFILE_ICON_DEFAULT
        : member.avatarPath;
    const mappedMember: SupportPortalMember = {
        userId: member.userId,
        onlineState: member.onlineState,
        initials: (
            member.firstName.charAt(0) + member.lastName.charAt(0)
        )?.toUpperCase(),
        firstName: member.firstName,
        lastName: member.lastName,
        fullName: member.fullName,
        id: member.userId,
        backgroundColorCode: member.backgroundColorCode,
        foregroundColorCode: member.foregroundColorCode,
        isActive: member.isActive,
        dateCreated: member.dateCreated,
        profile: {
            avatarPath: avatarPath,
            affiliation: '',
            graduationYear: 0,
            veterinarySchool: '',
            memberSince: null,
            isActive: true,
            practices: [],
        },
        isPocMember: false,
        isAdmin: member.isAdmin,
        numberOfPosts: member.numberOfPosts,
    };

    if (!member.backgroundColorCode) {
        member.backgroundColorCode = '#dce0e4';
    }

    if (!member.foregroundColorCode) {
        member.foregroundColorCode = '#6c757d';
    }

    return mappedMember;
};

export const { updateOnlineStatus } = suportPortalMembersSlice.actions;
export default suportPortalMembersSlice.reducer;
