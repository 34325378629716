import React from 'react';
import './FileUploadsList.scss';
import UploadedFileTile from '../UploadedFileTile/UploadedFileTile';

interface FileUploadsListProps {
    fileList: File[];
    onDeleteClick(file: File): void;
}

const FileUploadsList = (props: FileUploadsListProps) => {
    return (
        <div className="file-uploads-list">
            {props.fileList.map((file, index) => (
                <div key={`uploaded-file-tile-${index}`}>
                    <UploadedFileTile
                        key={`file-item-${index}`}
                        file={file}
                        onDeleteClick={props.onDeleteClick}
                    />
                </div>
            ))}
        </div>
    );
};

export default FileUploadsList;
