import { DateTimeHelper } from '../../utils/dateTimeHelper';
import { ConditionValue, ItemDisplayCondition } from './ItemDisplayCondition';

export interface DataTable {
    id: number;
    originalTemplateName: string;
    name: string;
    dateComplete: Date;
    progressStatus: DataTableProgressStatus;
    isComplete: boolean;
    isSnapshot: boolean;
    snapshotComment?: string;
    isAutomatedSnapshot?: boolean;
    snapshotCreatorId?: number;
    originalTableId?: number;
    views: DataTableView[];
    rows: DataTableRow[];
    columns: DataTableColumn[];
    dateCreated: Date;
    isNew: boolean;
    practiceName?: string;
}

export enum DataTableProgressStatus {
    Open,
    InProgress,
    Partial,
    Complete,
}

export const DataTableProgressStatusList = [
    { id: 0, name: 'Open', color: '#000000' },
    { id: 1, name: 'In Progress', color: '#535bd6' },
    { id: 2, name: 'Partial', color: '#deb24d' },
    { id: 3, name: 'Complete', color: '#66cdaa' },
];

export const emptyDataTable: DataTable = {
    id: 0,
    originalTemplateName: '',
    name: '',
    dateComplete: new Date(),
    progressStatus: DataTableProgressStatus.Open,
    isComplete: false,
    isSnapshot: false,
    views: [],
    rows: [],
    columns: [],
    dateCreated: new Date(),
    isNew: false,
};

export interface DataTableColumn extends ItemDisplayCondition {
    id: number;
    customColumnId: string;
    name: string;
    columnTooltip: string;
    defaultValue: string;
    answerType: string;
    formType: string;
    isVisible: boolean;
    formList: string;
    displayOrder: number;
}

export interface DataTableRow {
    id: number;
    customRowId: string;
    isRemovalRequested: boolean;
    isVisible: boolean;
    isSeenByAdmin: boolean;
    isSeenByUser: boolean;
    cells: DataTableViewCell[];
}

export interface DataTableViewCell {
    id: number;
    customColumnId: string;
    answerText: string;
    version: any;
    isSeenByUser: boolean;
    isSeenByAdmin: boolean;
    isAvailableByLogic: boolean;
}

export interface DataTableView {
    id: number;
    description: string;
    name: string;
    viewColumns: DataTableViewColumn[];
    dateCreated: Date;
    dueDate: Date;
    originalTableViewId?: number;
    progressStatus: DataTableViewProgressStatus;
    isReadyForReview: boolean;
    isComplete: boolean;
    isSeenByUser: boolean;
}

export enum DataTableViewProgressStatus {
    Open,
    InProgress,
    InReview,
    Complete,
}

export const TableViewProgressStatusList = [
    { id: 0, name: 'New', color: '#000000' },
    { id: 1, name: 'In Progress', color: '#535bd6' },
    { id: 2, name: 'In Review', color: '#deb24d' },
    { id: 3, name: 'Complete', color: '#66cdaa' },
];

export const emptyTableView: DataTableView = {
    id: 0,
    description: '',
    name: '',
    viewColumns: [],
    dateCreated: new Date(),
    dueDate: null,
    progressStatus: DataTableViewProgressStatus.Open,
    isReadyForReview: false,
    isComplete: false,
    isSeenByUser: true,
};

export interface DataTableViewColumn {
    id: number;
    customColumnId: string;
    isSeenByAdmin: boolean;
    isSeenByUser: boolean;
    isVisible: boolean;
    isFilterEnabled?: boolean;
    filterData?: ViewColumnFilterData[];
    overridenDisplayOrder?: number;
    isIncluded?: boolean;
}

export interface ViewColumnFilterData {
    id: number;
    data: string;
}

export interface DataTableAndTableViewFlatRow {
    rowId: string;
    tableId: number;
    viewId: number;
    name: string;
    dateCreated: Date;
    description?: string;
    rowType: TableRowType;
    practiceName?: string;
    dueDate?: Date;
    progressStatus?: DataTableViewProgressStatus;
}

export enum TableRowType {
    DataTable,
    DataTableView,
}

export const isCellAvailableByLogic = (
    column: DataTableColumn,
    row: DataTableRow,
    viewsCustomColumnIds: string[],
    tableColumns: DataTableColumn[]
): boolean => {
    if (!column.isDisplayLogicEnabled) {
        return true;
    }

    const isColumnVisibleByLogic = viewsCustomColumnIds.some(
        (customColumnId) => customColumnId === column.logicParentCustomId
    );

    if (!isColumnVisibleByLogic) {
        return false;
    }

    const parentCell = row.cells.find(
        (cell) => cell.customColumnId === column.logicParentCustomId
    );

    if (!parentCell) {
        return false;
    }

    const parentColumnDefinition = tableColumns.find(
        (tableColumn) =>
            tableColumn.customColumnId === parentCell.customColumnId
    );
    const isParentCellAvailable = isCellAvailableByLogic(
        parentColumnDefinition,
        row,
        viewsCustomColumnIds,
        tableColumns
    );

    return isParentCellAvailable
        ? isAnswerSatisfyCondition(
              parentColumnDefinition.answerType,
              parentCell.answerText,
              column.conditionValues
          )
        : false;
};

export const isAnswerSatisfyCondition = (
    answerType: string,
    answerText: string,
    conditionValues: ConditionValue[]
): boolean => {
    if (conditionValues.length <= 0) {
        return true;
    }

    const conditionalsComparationResult = conditionValues.some(
        (conditionValue) => {
            switch (answerType) {
                case 'Date':
                    const dateComparationResult =
                        DateTimeHelper.areDatesEqualIgnoringTime(
                            conditionValue.data,
                            answerText
                        );
                    return dateComparationResult;
                default:
                    const stringComparationResult =
                        conditionValue.data === answerText;
                    return stringComparationResult;
            }
        }
    );

    return conditionalsComparationResult;
};

export const isTableColumnVisibleByLogic = (
    targetColumn: DataTableColumn,
    tableColumns: DataTableColumn[]
): boolean => {
    if (!targetColumn.isDisplayLogicEnabled) return true;

    const parentColumn = tableColumns.find(
        (tableColumn) =>
            tableColumn.customColumnId === targetColumn.logicParentCustomId
    );

    if (!parentColumn) {
        return false;
    }

    if (parentColumn?.isDisplayLogicEnabled) {
        const isParentColumnVisible = isTableColumnVisibleByLogic(
            parentColumn,
            tableColumns
        );
        return parentColumn?.isVisible && isParentColumnVisible;
    }

    return parentColumn.isVisible;
};

export const isRowAvailableByViewFilter = (
    row: DataTableRow,
    view: DataTableView
) => {
    const result = row.cells.every((cell) => {
        const viewColumn = view.viewColumns.find(
            (viewColumn) => viewColumn.customColumnId === cell.customColumnId
        );
        return isCellVisibleByViewFilterCondition(cell, viewColumn);
    });

    return result;
};

export const isRowCellsAvailableByViewFilter = (
    cells: DataTableViewCell[],
    viewColumns: DataTableViewColumn[]
): boolean => {
    const result = cells.every((cell) => {
        const viewColumn = viewColumns.find(
            (viewColumn) => viewColumn.customColumnId === cell.customColumnId
        );
        return isCellVisibleByViewFilterCondition(cell, viewColumn);
    });

    return result;
};

export const isCellVisibleByViewFilterCondition = (
    cell: DataTableViewCell,
    viewColumn: DataTableViewColumn
) => {
    if (
        cell.answerText !== null &&
        cell.answerText !== undefined &&
        cell.answerText !== '' &&
        viewColumn?.isFilterEnabled
    ) {
        const doesAnswerMatchFilter = viewColumn.filterData.some(
            (filter) => filter.data === cell.answerText
        );

        return doesAnswerMatchFilter;
    }

    return true;
};

export const copyDataTableTemplate = (originTable: DataTable): DataTable => {
    const columnsCopy = originTable.columns.map((column) => {
        const conditionalValuesCopy = column.conditionValues.map(
            (conditionalValue) => ({ ...conditionalValue, id: 0 })
        );
        return {
            ...column,
            id: 0,
            conditionValues: conditionalValuesCopy,
        };
    });

    const viewsCopy = originTable.views.map((view) =>
        copyDataTableView(view, false)
    );

    const dataTableCopy: DataTable = {
        ...originTable,
        id: 0,
        originalTemplateName: originTable.originalTemplateName + ' - Copy',
        views: viewsCopy,
        columns: columnsCopy,
    };

    return dataTableCopy;
};

export const copyDataTableView = (
    originTableView: DataTableView,
    modifyName: boolean
) => {
    const columnsCopy = originTableView.viewColumns.map(
        (viewColumn): DataTableViewColumn => {
            const filtersCopy = viewColumn.filterData.map((filter) => ({
                ...filter,
                id: 0,
            }));
            return { ...viewColumn, id: 0, filterData: filtersCopy };
        }
    );
    const viewCopy: DataTableView = {
        id: 0,
        description: originTableView.description,
        name: modifyName
            ? `${originTableView.name} - Copy`
            : originTableView.name,
        viewColumns: columnsCopy,
        dateCreated: new Date(),
        dueDate: null,
        progressStatus: originTableView.progressStatus,
        isReadyForReview: false,
        isComplete: false,
        isSeenByUser: false,
    };

    return viewCopy;
};
