import React, { useEffect, useState } from 'react';
import './MarketplaceTemplatePreview.component.scss';
import Helpers from '../../../utils/helper';
import GanttChart from '../../GanttChartComponent/GanttChart';
import { DataGenerator } from './DataGenerator';
interface MarketplaceTemplatePreviewProps {
    timelineTemplateId: number;
}
export const MarketplaceTemplatePreviewComponent = (
    props: MarketplaceTemplatePreviewProps
) => {
    const [isLoading, setIsLoading] = useState(true);
    const config = {
        header: {
            month: {
                dateFormat: 'MMMM  YYYY',
                style: {
                    background: 'linear-gradient( grey, black)',
                    textShadow: '0.5px 0.5px black',
                    fontSize: 12,
                },
            },
            dayOfWeek: {
                style: {
                    background: 'linear-gradient( orange, grey)',
                    fontSize: 9,
                },
            },
            dayTime: {
                style: {
                    background: 'linear-gradient( grey, black)',
                    fontSize: 9,
                    color: 'orange',
                },
                selectedStyle: {
                    background: 'linear-gradient( #d011dd ,#d011dd)',
                    fontWeight: 'bold',
                    color: 'white',
                },
            },
        },
        taskList: {
            title: {
                label: 'Task Todo',
                style: {
                    background: 'linear-gradient( grey, black)',
                },
            },
            task: {
                style: {
                    backgroundColor: 'grey',
                    color: 'white',
                },
            },
            verticalSeparator: {
                style: {
                    backgroundColor: '#fbf9f9',
                },
                grip: {
                    style: {
                        backgroundColor: 'red',
                    },
                },
            },
        },
        dataViewPort: {
            rows: {
                style: {
                    backgroundColor: 'white',
                    borderBottom: 'solid 1px silver',
                },
            },
            task: {
                showLabel: true,
                style: {
                    borderRadius: 1,
                    boxShadow: '2px 2px 8px #888888',
                },
            },
        },
    };
    const [generator] = useState(new DataGenerator());
    const [allData, setAllData] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [state, setState] = useState<{
        itemheight: number;
        selectedItem: any;
        links: any[];
        daysWidth: number;
        startDate: Date;
        endDate: Date;
    }>();

    useEffect(() => {
        let result = generator.generateData();
        setAllData(result.data);
        const endDate = new Date()
        endDate.setDate(endDate.getDate() + 29);
        setState({
            itemheight: 30,
            selectedItem: null,
            links: result.links,
            daysWidth: 0,
            startDate: new Date(),
            endDate: endDate
        });
        setIsLoading(false);
    }, []);

    const onHorizonChange = (start: number, end: number) => {
        // let result = allData.filter((item: { start: number; end: number }) => {
        //     return (
        //         (item.start < start && item.end > end) ||
        //         (item.start > start && item.start < end) ||
        //         (item.end > start && item.end < end)
        //     );
        // });
        // console.log('Calculating ');
        setData(allData);
    };

    const onSelectItem = (item: any) => {
        console.log(`Select Item ${item}`);
        setState({ ...state, selectedItem: item });
    };

    return (
        <div className="associate-members-table mt-3">
            <div className="marketplace-template-preview">
                {isLoading ? (
                    Helpers.renderTableLoader()
                ) : (
                    <div className="without_title_content">
                        <GanttChart
                            config={config}
                            data={data}
                            links={state.links}
                            onHorizonChange={onHorizonChange}
                            onSelectItem={onSelectItem}
                            itemheight={state.itemheight}
                            selectedItem={state.selectedItem}
                            startDate={state.startDate}
                            endDate={state.endDate}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
