import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    GridValidRowModel,
} from '@mui/x-data-grid';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import {
    CustomIdConstants,
    CustomIdTypeEnum,
} from '../../models/DataRequestHub/CustomIdConstants';
import DataFormQuestion from '../../models/DataRequestHub/DataFormQuestion';
import { EditorTypeEnum } from '../../models/DataRequestHub/ProjectEditorEnum';

interface EditToolbarProps {
    isLoading?: boolean;
    rows: GridValidRowModel[];
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
    focusField?: string;
    editorType: EditorTypeEnum;
}

const EditToolbar = forwardRef((props: EditToolbarProps, ref) => {
    const [lastCustomId, setLastCustomId] = useState('');
    const { rows, setRows, setRowModesModel, focusField, editorType } = props;

    useEffect(() => {
        switch (editorType) {
            case EditorTypeEnum.DataTable:
                const customIds = rows.map((m) => m.customColumnId);
                const maxDataTableColumnCustomId =
                    CustomIdConstants.getMaxDataTableCustomId(
                        [...customIds, lastCustomId],
                        CustomIdTypeEnum.DataTableColumn
                    );

                setLastCustomId(maxDataTableColumnCustomId);
                break;
        }
    }, [rows]);

    useImperativeHandle(ref, () => ({
        addRow(): number {
            return handleClick();
        },
    }));

    const handleClick = (): number => {
        const gridRowId = new Date().getTime();
        setRows((oldRows) => {
            let nextCustomId = '';
            if (editorType === EditorTypeEnum.Questionnaire) {
                nextCustomId =
                    CustomIdConstants.getNextQuestionnaireQuestionCustomId(
                        oldRows as DataFormQuestion[]
                    );
            }

            if (editorType === EditorTypeEnum.DataTable) {
                nextCustomId = CustomIdConstants.getNextDataTableCustomId(
                    lastCustomId,
                    CustomIdTypeEnum.DataTableColumn
                );
            }

            return [
                ...oldRows,
                {
                    gridId: gridRowId,
                    isVisible: true,
                    isNew: true,
                    customQuestionId: nextCustomId,
                    customColumnId: nextCustomId,
                },
            ];
        });
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [gridRowId]: {
                mode: GridRowModes.Edit,
                fieldToFocus: focusField ?? '',
            },
        }));

        return gridRowId;
    };

    return <></>;
});

export default EditToolbar;
