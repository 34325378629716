import React, { useEffect, useState } from 'react';
import './CharityViewPopup.scss';
import Rodal from '../Rodal/Rodal';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import { SubmittedCharity } from '../../models/Charity/SubmittedCharity';
import moment from 'moment';
import InfoTooltip from '../info-tooltip/info-tooltip';
import Helpers from '../../utils/helper';
import { charityTypeNameList } from '../../models/Charity/CharityTypes';
import createNotification from '../../utils/createNotification';
import Loader from 'react-loaders';
import DonationHistory from '../PaymentHistory/DonationHistory';
import { useStateSelector } from '../../store/selectors';
import { DirectButton } from '../../direct/direct-button/direct-button';
import { USER_ROLES } from '../../utils/constants';

interface CharityViewPopupProps {
    charityId: number;
    charityName?: string;
    charity?: SubmittedCharity;
    isVisible: boolean;
    close(): void;
}

const CharityViewPopup = (props: CharityViewPopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const summary = useStateSelector((state) => state.userSummary.summary);
    const [charity, setCharity] = useState(props.charity);
    const [isLoadingCharity, setIsLoadingCharity] = useState<boolean>(
        !!!props.charity
    );
    const [isAdmin, setIsAdmin] = useState<boolean>();
    const memberSinceInfoTooltipText =
        'Accepting donations on the VetValue<br/>platform on this date forward.';
    const totalLtdInfoTooltipText =
        'Total Donations, Life-to-Date (Since<br/>starting on the VV platform)';
    const totalLtmInfoTooltipText = 'Total Donations, Last Twelve Months';

    useEffect(() => {
        if (!charity) {
            fetchCharity();
            const isAdmin = summary?.roleId === USER_ROLES.ADMIN;
            setIsAdmin(isAdmin);
        }
    }, []);

    const fetchCharity = () => {
        setIsLoadingCharity(true);
        axios
            .get(`/api/Charities/${props.charityId}`)
            .then((response: any) => {
                if (response.data) {
                    setCharity(response.data);
                } else {
                    createNotification(
                        response.response.data.title ??
                            'An unexpected error occurred while loading Charity.',
                        'error'
                    );
                    props.close();
                }
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while loading Charity.',
                    'error'
                );
                props.close();
            })
            .finally(() => setIsLoadingCharity(false));
    };

    const renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '65vw', height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader
                            active={isLoadingCharity}
                            type="ball-scale-multiple"
                        />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    const getUrlToCharityNavigator = (charity: SubmittedCharity) => {
        return `https://www.charitynavigator.org/ein/${charity.taxId.replace(
            /\D/g,
            ''
        )}`;
    };

    const renderOrganizationInformation = () => (
        <Card className="organization-information">
            <CardHeader>Organization Information</CardHeader>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                Organization Type:
                            </Col>
                            <Col sm={6}>
                                {charityTypeNameList[charity.type - 1]?.name ??
                                    '-'}
                            </Col>
                        </Row>
                        <Row className="info-row">
                            <Col className="info-label">City: </Col>
                            <Col sm={6}>
                                {charity.city ? charity.city : '-'}
                            </Col>
                        </Row>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                State:
                            </Col>
                            <Col sm={6}>
                                {charity.state ? charity.state : '-'}
                            </Col>
                        </Row>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                Zipcode:
                            </Col>
                            <Col sm={6}>
                                {charity.zipCode ? charity.zipCode : '-'}
                            </Col>
                        </Row>
                        {charity.taxId &&
                        charity.isDisplayLinkToCharityNavigatorPage ? (
                            <Row className="info-row">
                                <Col sm={6} className="info-label">
                                    Charity Navigator Page:
                                </Col>
                                <Col
                                    sm={6}
                                    className={charity.taxId ? 'link-text' : ''}
                                    onClick={() =>
                                        Helpers.openWebsite(
                                            getUrlToCharityNavigator(charity)
                                        )
                                    }>
                                    {getUrlToCharityNavigator(charity)}
                                </Col>
                            </Row>
                        ) : (
                            []
                        )}
                        {charity.accounts?.filter((a) => !a.isSuspended)
                            .length > 0 ? (
                            <Row className="info-row">
                                <Col sm={6} className="info-label">
                                    Accounts:
                                </Col>
                                <Col sm={6}>
                                    <div className="accounts-column">
                                        <span>General</span>
                                        {charity.accounts
                                            .filter((a) => !a.isSuspended)
                                            .map((a) => (
                                                <span>{a.name}</span>
                                            ))}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            []
                        )}
                    </Col>
                    <Col md={6}>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                Street Address:
                            </Col>
                            <Col sm={6}>
                                {charity.streetAddress
                                    ? charity.streetAddress
                                    : '-'}
                            </Col>
                        </Row>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                Phone #:
                            </Col>
                            <Col sm={6}>
                                {charity.phone
                                    ? Helpers.getFormattedPhoneNumberWithoutPrefix(
                                          charity.phone
                                      )
                                    : '-'}
                            </Col>
                        </Row>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                Website:
                            </Col>
                            <Col
                                sm={6}
                                className={charity.website ? 'link-text' : ''}
                                onClick={() =>
                                    Helpers.openWebsite(charity.website)
                                }>
                                {charity.website ? charity.website : '-'}
                            </Col>
                        </Row>
                        <Row className="info-row">
                            <Col sm={6} className="info-label">
                                Contact:
                            </Col>
                            <Col
                                sm={6}
                                className="contact-charity-admin-wrapper">
                                <div className="contact-charity-admin">
                                    <span>
                                        {charity.contact
                                            ? charity.contact
                                            : '-'}
                                    </span>
                                    {charity.adminId ? (
                                        <DirectButton
                                            size="medium"
                                            isAnonymousRoom={false}
                                            memberId={
                                                charity.adminId
                                            }></DirectButton>
                                    ) : (
                                        []
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {isAdmin && charity.taxId ? (
                    <Row>
                        <Col md={6}>
                            <Row className="info-row">
                                <Col sm={6} className="info-label">
                                    EIN:
                                </Col>
                                <Col sm={6}>{charity.taxId}</Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    []
                )}
            </CardBody>
        </Card>
    );

    const renderTotalDonationInformation = () => (
        <Card className="total-donation-information">
            <CardHeader>Total Donations</CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <FormGroup className="donation-info-field">
                            <div className="field-label">
                                <label className="info-label">
                                    Member Since:{' '}
                                </label>
                                <InfoTooltip
                                    text={memberSinceInfoTooltipText}
                                    place="top"
                                    idText="member-since-charity-view-popup"
                                />
                            </div>
                            <div className="field-value">
                                {charity.dateApproved
                                    ? moment(charity.dateApproved).format('L')
                                    : 'Not Approved Yet'}
                            </div>
                        </FormGroup>
                        <FormGroup className="donation-info-field">
                            <div className="field-label">
                                <label className="info-label">
                                    Total LTD:{' '}
                                </label>
                                <InfoTooltip
                                    text={totalLtdInfoTooltipText}
                                    place="top"
                                    idText="total-ltd-charity-view-popup"
                                />
                            </div>
                            <div className="field-value">
                                {Helpers.GetCurrencyFormat(
                                    charity.donorsLtd,
                                    null,
                                    true
                                )}
                            </div>
                        </FormGroup>
                        <FormGroup className="donation-info-field">
                            <div className="field-label">
                                <label className="info-label">
                                    Total LTM:{' '}
                                </label>
                                <InfoTooltip
                                    text={totalLtmInfoTooltipText}
                                    place="top"
                                    idText="total-ltm-charity-view-popup"
                                />
                            </div>
                            <div className="field-value">
                                {Helpers.GetCurrencyFormat(
                                    charity.donorsLtm,
                                    null,
                                    true
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

    const renderDonationHistorySection = () => (
        <Card className="donation-history-section main-card mg-bottom-20p">
            <CardHeader>Donation History</CardHeader>
            <CardBody className="card-body-custom">
                <div className="font-14p">
                    <DonationHistory
                        type="CharityPageHistory"
                        charityId={props.charityId}></DonationHistory>
                </div>
            </CardBody>
        </Card>
    );

    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    return (
        <div className="charity-view-popup">
            <Rodal
                visible={props.isVisible}
                onClose={() => {
                    document.body.style.overflow = 'unset';
                    props.close();
                }}
                className=""
                width={1000}
                animation={'fade'}
                showMask={false}>
                <ModalHeader>{charity?.name}</ModalHeader>
                <ModalBody>
                    {isLoadingCharity ? (
                        renderLoader()
                    ) : (
                        <>
                            <Row className="logo-container">
                                <img
                                    className="logo-image"
                                    src={charity.logoUrl}></img>
                            </Row>
                            <Row className="summary">
                                <Col className="summary-section" md={9}>
                                    {renderOrganizationInformation()}
                                </Col>
                                <Col className="summary-section" md={3}>
                                    {renderTotalDonationInformation()}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                                <Col>{renderDonationHistorySection()}</Col>
                            </Row>
                        </>
                    )}
                </ModalBody>
            </Rodal>
        </div>
    );
};
export default CharityViewPopup;
