import React, { useState } from 'react';
import DVMContext from './index';

const AuthProvider = props => {
    const [token, setToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [expiresIn, setExpiresIn] = useState('');
    const [loggedInUserSummary, setLoggedInUserSummary] = useState({});

    return (
        <DVMContext.Provider
            value={{
                token: token,
                setToken,
                refreshToken,
                setRefreshToken,
                expiresIn,
                setExpiresIn,
                loggedInUserSummary,
                setLoggedInUserSummary,
            }}>
            {props.children}
        </DVMContext.Provider>
    );
};

export default AuthProvider;
