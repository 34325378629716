import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Modal, ModalBody } from 'reactstrap';
import share from '../../assets/images/share.png';
import editInactive from '../../assets/images/editInactive.jpg';
import upload from '../../assets/images/upload.png';
import './message-input-area.scss';
import createNotification from '../../utils/createNotification';
import { USER_ROLES } from '../../utils/constants';
import UploadFilesModal from '../upload-files-modal/upload-files-modal';
import Helpers from '../../utils/helper';
import closeIcon from '../../assets/images/closeIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Attachment } from '../models/attachment';
import {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    convertFromHTML,
    DraftHandleValue,
    Editor,
    EditorChangeType,
    EditorCommand,
    EditorState,
    Modifier,
    RichUtils,
    SelectionState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import sanitizeHtml from 'sanitize-html';
import {
    faBold,
    faItalic,
    faUnderline,
    faLink,
    faListUl,
    faListOl,
    faBook,
} from '@fortawesome/fontawesome-free-solid';
import ShareKnowledgebaseContent from '../share-knowledgebase-modal/share-knowledgebase-modal';
import { SharedKbContent } from '../models/shared-kb-content';
import { Message } from '../models/message';
import {
    faRefresh,
    faSquare as filledSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useStateSelector } from '../../store/selectors';
import { GroupType } from '../models/group-type';

interface MessageInputAreaProps {
    sendMessage(
        message: string,
        files: Array<number>,
        sharedContentIds: Array<number>
    ): boolean;
    discussionId: number;
    isKbAvailable: boolean;
    isReadonlyMode: boolean;
    editingMessage?: Message;
    isAdmin?: boolean;
    groupId?: number;
    groupType?: GroupType;
}
const MessageInputArea = (props: MessageInputAreaProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [messageText, setMessageText] = useState('');
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [editingMessageId, setEditingMessageId] = useState<
        number | undefined
    >(undefined);
    const [
        isShareKnowledgebaseContentVisible,
        setIsShareKnowledgebaseContentVisible,
    ] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<Attachment[]>([]);
    const [sharedKbContent, setSharedKbContent] = useState<SharedKbContent[]>(
        []
    );
    let isUserAdmin =
        JSON.parse(localStorage.getItem('loggedInUserSummary'))?.roleId ===
        USER_ROLES.ADMIN;
    const isSendAllowed = () =>
        messageText.trim().length === 0 &&
        uploadedFiles.length === 0 &&
        sharedKbContent.length === 0 &&
        editorState.getCurrentContent().getPlainText().trim().length === 0;

    const [isToolbarActive, setToolbarActive] = useState(false);
    const [isToolSelected, setIsToolSelected] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setShowLinkModal(false);
        };
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        let message = '';
        if (props.editingMessage) {
            if (props.editingMessage.id === editingMessageId) return;
            message = props.editingMessage.htmlContent;
            setMessageText(props.editingMessage.htmlContent);
            setEditingMessageId(props.editingMessage.id);
        }
        const blocksFromHtml = convertFromHTML(message);
        const contentDataState = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
        );
        const editorDataState = EditorState.createWithContent(contentDataState);
        setEditorState(editorDataState);
    }, [props]);

    const findLinkEntities = (
        contentBlock: ContentBlock,
        callback: any,
        contentState: ContentState
    ) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        }, callback);
    };

    const Link = (props: any) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return <a href={url}>{props.children}</a>;
    };
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty(decorator)
    );
    const [showLinkModal, setShowLinkModal] = useState(false);

    const editor = React.useRef(null);
    const editorToolbarRef = useRef(null);

    const allowedOptions = {
        allowedTags: [
            'b',
            'i',
            'u',
            'strong',
            'br',
            'ul',
            'li',
            'p',
            'ol',
            'a',
            'span',
        ],
        allowedAttributes: {
            a: ['href', 'target'],
        },
    };
    const [textStyles, setTextStyles] = useState<
        Array<{ label: string; style: string; icon: any }>
    >([]);

    useEffect(() => {
        if (uploadedFiles.length > 0) {
            deleteFiles(uploadedFiles);
            setUploadedFiles([]);
        }
        setMessageText('');
    }, [props.discussionId]);

    useEffect(() => {
        focusEditor();
        if (props.isAdmin) {
            setTextStyles([
                {
                    label: 'BL',
                    style: 'BLACKOUT',
                    icon: filledSquare,
                },
                {
                    label: 'UBL',
                    style: 'UNBLACKOUT',
                    icon: faRefresh,
                },
            ]);
        } else {
            setTextStyles([
                { label: 'B', style: 'BOLD', icon: faBold },
                { label: 'I', style: 'ITALIC', icon: faItalic },
                { label: 'U', style: 'UNDERLINE', icon: faUnderline },
                { label: 'L', style: 'LINK', icon: faLink },
                { label: 'UL', style: 'unordered-list-item', icon: faListUl },
                { label: 'OL', style: 'ordered-list-item', icon: faListOl },
            ]);
        }
    }, []);

    const sendMessage = () => {
        if (
            messageText ||
            uploadedFiles.length > 0 ||
            sharedKbContent.length > 0
        ) {
            const message = Helpers.trimByWord(
                messageText.trim(),
                '<p><br></p>'
            );
            const isConnected = props.sendMessage(
                message,
                uploadedFiles.map((f) => f.id),
                sharedKbContent.map((m) => m.id)
            );
            if (isConnected) {
                setEditingMessageId(undefined);
                setMessageText('');
                resetEditorState(editorState);
                setIsToolSelected(false);
                setUploadedFiles([]);
                setSharedKbContent([]);
            } else {
                createNotification(
                    'Sending message error! Please try again later!',
                    'error'
                );
            }
        }
    };

    const deleteFiles = (
        files: Attachment[],
        shouldReAssignFiles: boolean = false
    ) => {
        axios
            .delete(`/api/temporaryfiles`, { data: files.map((f) => f.id) })
            .then()
            .finally(() => {
                if (shouldReAssignFiles) {
                    const updatedList = uploadedFiles.filter((uf) =>
                        files.every((f) => uf.id !== f.id)
                    );
                    setUploadedFiles(updatedList);
                }
            });
    };

    const deleteSharedItem = (content: SharedKbContent) =>
        setSharedKbContent(sharedKbContent.filter((f) => f.id != content.id));

    const focusEditor = () => {
        if (!isUserAdmin && !props.isReadonlyMode) {
            editor.current.focus();
        }
    };

    const removeSelectedBlocksStyle = (editorState: EditorState) => {
        const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
        if (newContentState) {
            return EditorState.push(
                editorState,
                newContentState,
                'change-block-type'
            );
        }
        return editorState;
    };
    const resetEditorState = (editorState: EditorState) => {
        const blocks = editorState.getCurrentContent().getBlockMap().toList();
        const updatedSelection = editorState.getSelection().merge({
            anchorKey: blocks.first().get('key'),
            anchorOffset: 0,
            focusKey: blocks.last().get('key'),
            focusOffset: blocks.last().getLength(),
            isBackward: false,
        });
        const newContentState = Modifier.removeRange(
            editorState.getCurrentContent(),
            updatedSelection,
            'forward'
        );

        const newState = EditorState.push(
            editorState,
            newContentState,
            'remove-range'
        );

        setEditorState(removeSelectedBlocksStyle(newState));
    };

    const StyleButton = (props: any) => {
        const blockType = editorState
            .getCurrentContent()
            .getLastBlock()
            .getType();
        let activeState =
            editorState.getCurrentInlineStyle().has(props.style) ||
            blockType === props.style ||
            (showLinkModal && props.style === 'LINK');

        let onClickButton = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setIsToolSelected(!activeState);
            props.onToggle(props.style);
        };

        return (
            <div
                className="inline-rich-text-tool-control-bg"
                style={{ backgroundColor: activeState ? '#e2e2e2' : '#f4f4f4' }}
                onMouseDown={onClickButton}>
                <FontAwesomeIcon
                    style={{
                        backgroundColor: activeState ? '#e2e2e2' : '#f4f4f4',
                    }}
                    icon={props.icon}
                    className="inline-rich-text-tool-control"
                />
            </div>
        );
    };

    const StyleControls = (props: any) => {
        return (
            <>
                {textStyles.map((type) => {
                    return (
                        <StyleButton
                            key={type.label}
                            label={type.label}
                            onToggle={props.onToggle}
                            style={type.style}
                            icon={type.icon}
                        />
                    );
                })}
            </>
        );
    };

    const onChange = (editorState: EditorState) => {
        setEditorState(editorState);
        if (!props.editingMessage) {
            let msg = stateToHTML(editorState.getCurrentContent());
            if (editorState.getCurrentContent().getPlainText().trim().length) {
                setMessageText(msg);
            } else {
                setMessageText('');
            }
        }
    };

    const onControlClick = (e: any) => {
        let nextState = editorState;

        if (e === 'BOLD' || e === 'ITALIC' || e === 'UNDERLINE')
            nextState = RichUtils.toggleInlineStyle(editorState, e);
        if (e === 'unordered-list-item' || e === 'ordered-list-item')
            nextState = RichUtils.toggleBlockType(editorState, e);
        if (e === 'LINK') {
            setShowLinkModal(!showLinkModal);
            return;
        }
        if (e == 'BLACKOUT') {
            blackOut();
            return;
        }
        if (e == 'UNBLACKOUT') {
            unBlackOut();
            return;
        }

        setEditorState(nextState);
    };

    const toggleToolbar = () => {
        setToolbarActive(!isToolbarActive);
    };

    const handlePastedText = (
        text: string,
        html: string,
        changedEditorState: EditorState
    ): DraftHandleValue => {
        if (props.editingMessage) return 'handled';
        let newEditorState = changedEditorState;

        text = text.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
        text = sanitizeHtml(text, allowedOptions);

        const blocks = convertFromHTML(text);
        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(
                blocks.contentBlocks,
                blocks.entityMap
            ).getBlockMap()
        );
        newEditorState = EditorState.push(
            newEditorState,
            contentState,
            'insert-characters'
        );
        if (newEditorState !== changedEditorState) {
            onChange(newEditorState);
        }

        return 'handled';
    };

    const handleKeyCommand = (
        command: EditorCommand,
        editorState: EditorState,
        eventTimeStamp: number
    ): DraftHandleValue => {
        if (props.editingMessage) return 'handled';
        const newEditorState = RichUtils.handleKeyCommand(editorState, command);

        if (newEditorState) {
            setEditorState(newEditorState);
            return 'handled';
        }

        return 'not-handled';
    };

    const AddLinkPopup = (props: any) => {
        const [modalStyle, setModalStyle] = useState({
            top: '0px',
            left: '0px',
        });

        const inputTextRef = useRef(null);
        const inputUrlRef = useRef(null);
        const [errorMessage, setErrorMessage] = useState('');
        const hasMessageError = () => {
            return errorMessage !== '';
        };
        const [canInsertLink, setCanInsertLink] = useState(false);
        const handleOpen = () => {
            let defaultPosition = {
                top: '0px',
                left: '0px',
                position: 'absolute',
            };

            if (editorToolbarRef) {
                const position =
                    editorToolbarRef.current.getBoundingClientRect();
                defaultPosition.left = position.x - 5 + 'px';
                defaultPosition.top = position.y - 235 + 'px';
            }

            setModalStyle(defaultPosition);

            inputTextRef.current.value = getSelectedText(editorState);
            inputUrlRef.current.value = getLinkFromSelection(editorState);

            if (inputTextRef.current.value) {
                inputUrlRef.current.focus();
            } else {
                inputTextRef.current.focus();
            }

            if (Helpers.isValidUrl(inputUrlRef.current.value)) {
                setCanInsertLink(true);
            }
        };
        const handleOnChange = (event: any) => {
            setCanInsertLink(false);
            const urlString = event.target.value;
            if (urlString === '') {
                setErrorMessage('Website could not be empty');
            } else if (!Helpers.isValidUrl(urlString)) {
                setErrorMessage('Invalid URL');
            } else {
                setErrorMessage('');
                setCanInsertLink(true);
            }
        };

        return (
            <Modal
                style={modalStyle}
                isOpen={props.isLinkModalOpen}
                onOpened={handleOpen}
                toggle={() => {
                    setShowLinkModal(!props.isLinkModalOpen);
                }}
                className="add-link-popup-box"
                backdropClassName="backdrop-link">
                <ModalBody>
                    <button
                        onClick={() => {
                            setShowLinkModal(false);
                        }}
                        type="button"
                        className="btn btn-close"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="add-link-box-content">
                        <label className="add-link-label">
                            Text to display
                        </label>
                        <div
                            style={{ display: 'flex' }}
                            className="add-link-flex-controls">
                            <input
                                maxLength={100}
                                ref={inputTextRef}
                                placeholder={'Text to display'}
                                className="insert-link-textarea"
                                type="text"
                            />
                        </div>
                        <label
                            style={{ marginTop: '10px' }}
                            className="add-link-label">
                            Website
                        </label>
                        <div className="add-link-flex-controls">
                            <input
                                maxLength={255}
                                ref={inputUrlRef}
                                placeholder={'eg. www.google.com'}
                                className={
                                    hasMessageError()
                                        ? 'insert-link-textarea invalid-input-textarea'
                                        : 'insert-link-textarea'
                                }
                                type="text"
                                onChange={handleOnChange}
                            />
                            <button
                                disabled={canInsertLink !== true}
                                type="button"
                                className="btn btn-primary btn btn-primary"
                                onClick={() => {
                                    if (canInsertLink) {
                                        insertLink(
                                            inputTextRef.current.value,
                                            inputUrlRef.current.value
                                        );
                                    }
                                }}>
                                Insert
                            </button>
                        </div>
                        {hasMessageError() && (
                            <div className="add-link-flex-controls error-message">
                                <span>
                                    <label>{errorMessage}</label>
                                </span>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const getLinkFromSelection = (editorState: EditorState) => {
        let url = '';
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const startOffset = selection.getStartOffset();

            const blockWithLinkAtBeginning =
                contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
        }

        return url;
    };

    const getSelectedText = (editorState: EditorState): string => {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startOffset = selection.getFocusOffset();
            const endOffset = selection.getAnchorOffset();

            const currentBlock = contentState.getBlockForKey(
                selection.getAnchorKey()
            );
            const text = currentBlock.getText();

            if (startOffset > endOffset) {
                return text.substring(startOffset, endOffset);
            } else {
                return text.substring(endOffset, startOffset);
            }
        }

        return '';
    };

    const insertLink = (text: string, url: string) => {
        if (!text) {
            text = url;
        }

        var httpsPrefix = 'https://';
        var httpPrefix = 'http://';
        if (
            url.substring(0, httpsPrefix.length) !== httpsPrefix &&
            url.substring(0, httpPrefix.length) !== httpPrefix
        ) {
            url = httpsPrefix + url;
        }

        const newEditorState = editorState;
        const contentState = newEditorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                url: url,
                target: '_blank',
            }
        );
        let nextEditorState = EditorState.set(newEditorState, {
            currentContent: contentStateWithEntity,
        });

        const newSelection = nextEditorState.getSelection();

        let entityKey = nextEditorState
            .getCurrentContent()
            .getLastCreatedEntityKey();
        const modifierContent = Modifier.replaceText(
            nextEditorState.getCurrentContent(),
            nextEditorState.getSelection(),
            text,
            null,
            entityKey
        );
        nextEditorState = EditorState.push(
            editorState,
            modifierContent,
            'insert-characters'
        );
        nextEditorState = EditorState.createWithContent(
            modifierContent,
            decorator
        );

        const focusPosition =
            newSelection.getFocusOffset() < newSelection.getAnchorOffset()
                ? newSelection.getFocusOffset()
                : newSelection.getAnchorOffset();
        const curetPosition = focusPosition + text.length;
        const updateSelection = new SelectionState({
            anchorKey: newSelection.getAnchorKey(),
            anchorOffset: curetPosition,
            focusKey: newSelection.getFocusKey(),
            focusOffset: curetPosition,
            isBackward: false,
        });
        nextEditorState = EditorState.forceSelection(
            nextEditorState,
            updateSelection
        );

        setShowLinkModal(false);
        onChange(nextEditorState);
    };

    const blackOut = () => {
        let selection = editorState.getSelection();
        const initialStartOffset = selection.getStartOffset();
        let selectedText = getTextSelectionToBlackout(editorState);

        // Replace all non-'▪️' symbols by ▪️
        let contentStateWithStyles = Modifier.replaceText(
            editorState.getCurrentContent(),
            selection,
            selectedText.replace(/[^\s\t\n▪️]/g, '▪️')
        );

        let newEditorState = EditorState.push(
            editorState,
            contentStateWithStyles,
            'insert-characters'
        );

        // Update selection area
        let newSelection = new SelectionState({
            anchorKey: newEditorState.getSelection().getAnchorKey(),
            anchorOffset: initialStartOffset,
            focusKey: newEditorState.getSelection().getFocusKey(),
            focusOffset: newEditorState.getSelection().getFocusOffset(),
        });
        newEditorState = EditorState.forceSelection(
            newEditorState,
            newSelection
        );
        selection = newEditorState.getSelection();

        // Add black background
        selectedText = newEditorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getText()
            .slice(selection.getStartOffset(), selection.getEndOffset());
        for (
            let i = selection.getStartOffset();
            i < selection.getEndOffset();
            i++
        ) {
            if (/\S/.test(selectedText[i - selection.getStartOffset()])) {
                contentStateWithStyles = Modifier.applyInlineStyle(
                    contentStateWithStyles,
                    new SelectionState({
                        anchorKey: selection.getStartKey(),
                        anchorOffset: i,
                        focusKey: selection.getStartKey(),
                        focusOffset: i + 1,
                    }),
                    'STRIKETHROUGH'
                );
            }
        }

        newEditorState = EditorState.push(
            newEditorState,
            contentStateWithStyles,
            'change-inline-style' as EditorChangeType
        );

        setEditorState(newEditorState);
        setMessageText(convertToHtml(newEditorState));
    };

    const getTextSelectionToBlackout = (editorState: EditorState) => {
        const contentState = editorState.getCurrentContent();
        let selection = editorState.getSelection();
        const startKey = selection.getStartKey();
        const endKey = selection.getEndKey();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();

        const selectedBlocks = contentState
            .getBlockMap()
            .skipUntil((_, key) => key === startKey)
            .takeUntil((_, key) => key === endKey)
            .concat([[endKey, contentState.getBlockForKey(endKey)]]);

        let selectedText = '';
        selectedBlocks.forEach((block, key) => {
            const blockText = block.getText();
            const blockType = block.getType();
            const text = blockText.slice(
                key === startKey ? startOffset : 0,
                key === endKey ? endOffset : undefined
            );

            if (
                blockType === 'ordered-list-item' ||
                blockType === 'unordered-list-item'
            ) {
                const listType = blockType === 'ordered-list-item' ? '1.' : '-';
                selectedText += listType + ' ' + text + '\n';
            } else {
                selectedText += text + '\n';
            }
        });
        selectedText = selectedText.replace(/\n+$/, '');
        return selectedText;
    };

    const customStyleMap: any = {
        STRIKETHROUGH: {
            backgroundColor: '#888f96',
            color: '#888f96',
            fontSize: '16px',
            userSelect: 'none',
        },
    };

    const convertToHtml = (editorState: EditorState) => {
        const contentState = editorState.getCurrentContent();
        const html = stateToHTML(contentState, {
            inlineStyles: {
                STRIKETHROUGH: {
                    style: customStyleMap.STRIKETHROUGH,
                },
            },
        });

        return html;
    };

    const unBlackOut = () => {
        const message =
            props.editingMessage.initialHtmlContent ??
            props.editingMessage.htmlContent;
        setMessageText(message);
        const blocksFromHtml = convertFromHTML(message);
        const contentDataState = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
        );
        const editorDataState = EditorState.createWithContent(contentDataState);
        setEditorState(editorDataState);
    };

    return (
        <div className="app-inner-layout__bottom-pane d-block text-center app-message-input-area">
            <FormGroup className="mb-0" row>
                <Col sm={12}>
                    <div
                        className={
                            !props.isReadonlyMode || props.editingMessage
                                ? 'search-btn-wrapper edit-search-wrapper chat-card-input'
                                : 'search-btn-wrapper readonly chat-card-input disabled'
                        }>
                        <div
                            className="search-box edit-search"
                            onClick={focusEditor}>
                            {isToolbarActive && (
                                <div className="inline-text-editor-area">
                                    <div className="inline-text-editor-controls">
                                        <StyleControls
                                            onToggle={onControlClick}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="message-input-textarea">
                                <img
                                    ref={editorToolbarRef}
                                    className="edit-image"
                                    src={editInactive}
                                    onClick={toggleToolbar}
                                    style={{
                                        opacity: isToolbarActive
                                            ? '100%'
                                            : '75%',
                                        top: isToolbarActive ? '40px' : '5px',
                                    }}
                                />
                                <div className="message-input-editor">
                                    <Editor
                                        onCut={(_, e) => e.preventDefault()}
                                        ref={editor}
                                        editorState={editorState}
                                        placeholder={
                                            !(
                                                messageText.length ||
                                                isToolSelected
                                            ) && 'Type a message'
                                        }
                                        onChange={onChange}
                                        handlePastedText={handlePastedText}
                                        handleReturn={() =>
                                            props.editingMessage
                                                ? 'handled'
                                                : 'not-handled'
                                        }
                                        handleKeyCommand={handleKeyCommand}
                                        handleBeforeInput={(
                                            chars,
                                            _editorState
                                        ) =>
                                            props.editingMessage
                                                ? 'handled'
                                                : 'not-handled'
                                        }
                                        customStyleMap={customStyleMap}
                                    />
                                </div>
                            </div>

                            <div className="attachments">
                                {uploadedFiles.map((file) => (
                                    <div
                                        key={file.id}
                                        className="chat-box attached-content">
                                        <div>
                                            <FontAwesomeIcon
                                                icon={file.type.icon}
                                                className="file-icon"
                                            />
                                        </div>
                                        <div className="attachment-name-container">
                                            <div
                                                className="file-name"
                                                title={file.name}>
                                                {file.name}
                                            </div>
                                            <span className="file-size">
                                                {Helpers.formatBytes(file.size)}
                                            </span>{' '}
                                        </div>
                                        <div
                                            className="close-icon-div"
                                            onClick={() =>
                                                deleteFiles([file], true)
                                            }>
                                            <img
                                                src={closeIcon}
                                                className="close-icon-img"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="attachments">
                                {sharedKbContent.map((content) => (
                                    <div
                                        key={content.id}
                                        className="attached-content shared-content">
                                        <div className="content-name-container">
                                            <div
                                                className="content-name"
                                                title={content.title}>
                                                {content.title}
                                            </div>
                                        </div>
                                        <div
                                            className="close-icon-div"
                                            onClick={() =>
                                                deleteSharedItem(content)
                                            }>
                                            <img
                                                src={closeIcon}
                                                className="close-icon-img"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="upload-share-wrapper">
                            {props.isKbAvailable ? (
                                <div
                                    className="share-kb-content-image"
                                    onClick={() =>
                                        setIsShareKnowledgebaseContentVisible(
                                            true
                                        )
                                    }>
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faBook as any}
                                    />
                                </div>
                            ) : (
                                []
                            )}
                            <div
                                className="upload-img"
                                onClick={() =>
                                    !props.editingMessage
                                        ? setIsUploadModalVisible(true)
                                        : undefined
                                }>
                                <img
                                    src={upload}
                                    className="choose-file-label"
                                />
                            </div>
                            <div
                                className={
                                    isSendAllowed()
                                        ? 'share-img disabled'
                                        : 'share-img'
                                }
                                onClick={() => sendMessage()}>
                                <img src={share} />
                            </div>
                        </div>
                    </div>
                </Col>
            </FormGroup>
            {isUploadModalVisible ? (
                <UploadFilesModal
                    isVisible={isUploadModalVisible}
                    hide={() => {
                        setIsUploadModalVisible(false);
                    }}
                    setUploadedFiles={setUploadedFiles}
                    uploadedFiles={uploadedFiles}
                    deleteUploadedFiles={deleteFiles}></UploadFilesModal>
            ) : (
                []
            )}
            {isShareKnowledgebaseContentVisible ? (
                <ShareKnowledgebaseContent
                    groupType={props.groupType}
                    groupId={props.groupId}
                    isVisible={isShareKnowledgebaseContentVisible}
                    hide={() => setIsShareKnowledgebaseContentVisible(false)}
                    setSharedKbContent={(e: SharedKbContent[]) =>
                        setSharedKbContent(e)
                    }
                />
            ) : (
                []
            )}
            <AddLinkPopup isLinkModalOpen={showLinkModal} />
        </div>
    );
};

export default MessageInputArea;
