import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Alert,
    ModalFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './poc-pending-profile-modal.scss';

export const PendingProfileModal = () => {
    return (
        <Modal
            className={
                'create-new-discussion practice-owner-community pending-profile-modal'
            }
            isOpen={true}>
            <ModalHeader>IPOC profile</ModalHeader>
            <ModalBody className="practice-owner-community-body pending-profile-modal-body">
                <Alert>Your IPOC profile is successfully submitted.</Alert>
            </ModalBody>
            <ModalFooter className="pending-profile-modal-footer">
                <div className="pending-profile-button btn-wrapper">
                    <Link to="/user-home">
                        <Button className="cancel-btn back-home-btn">
                            Back Home
                        </Button>
                    </Link>
                </div>
            </ModalFooter>
        </Modal>
    );
};
