import React, { useEffect, useState } from 'react';
import AreYouStillHerePopup from '../are-you-still-here-popup/are-you-still-here-popup';
import { useDispatch } from 'react-redux';
import {
    setExpirationDate,
    setIsOpened,
} from '../are-you-still-here-slice/are-you-still-here-slice';
import { LoadPricing } from '../../slices/pricing/load-pricing.thunk';
import { useStateSelector } from '../../store/selectors';
import { gtmEvent } from '../../components/Tracking';
import { TokenHelper } from '../../utils/tokenHelper';
import { useNavigate } from 'react-router-dom';

const AreYouStillHereHost = () => {
    let navigate = useNavigate();
    const isOpened: boolean = useStateSelector(
        (state) => state.areYouStillHere.isOpened
    );
    const expirationDate: () => Date = useStateSelector(
        (state) => state.areYouStillHere.expirationDate
    );
    const pricing = useStateSelector((state) => state.pricing.pricing);
    const dispatch: any = useDispatch();
    const millisecondsPerSecond = 1000;
    const secondsPerMinute = 60;
    const [showPopupTimeout, setShowPopupTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        dispatch(LoadPricing());
        var timer = setInterval(() => {
            schedulePopupDisplayingAndClearOldTimeout();
        }, 10000);
        return () => {
            clearInterval(timer);
        };
    }, [pricing?.awayFromKeyboardPopupTimeout, isOpened]);

    const schedulePopupDisplayingAndClearOldTimeout = () => {
        clearTimeout(showPopupTimeout);
        const expireTimeInSeconds =
            (expirationDate().getTime() - new Date().getTime()) /
            millisecondsPerSecond;
        if (!isNaN(expireTimeInSeconds) && expireTimeInSeconds < 0) {
            gtmEvent('click');
            navigate('/login');
            TokenHelper.clearLocalStorage(false);
            dispatch(setExpirationDate(new Date()));
            dispatch(setIsOpened(false));
            return;
        }
        const expireInSecondsIsDefinedAndPositive =
            !isNaN(expireTimeInSeconds) && Number(expireTimeInSeconds) > 0;
        const isAwayFromKeyboardPopupTimeoutExist =
            pricing?.awayFromKeyboardPopupTimeout;
        const awayFromKeyboardPopupTimeout = isAwayFromKeyboardPopupTimeoutExist
            ? Number(pricing.awayFromKeyboardPopupTimeout)
            : 0;
        const awayFromKeyboardPopupTimeoutInSeconds =
            awayFromKeyboardPopupTimeout * secondsPerMinute;
        if (
            expireInSecondsIsDefinedAndPositive &&
            awayFromKeyboardPopupTimeout > 0
        ) {
            const popupDisplayingDelay =
                Number(expireTimeInSeconds) -
                awayFromKeyboardPopupTimeoutInSeconds;

            const timeout = setTimeout(() => {
                const currentExpireTimeInSeconds =
                    (expirationDate().getTime() - new Date().getTime()) /
                    millisecondsPerSecond;
                const newPopupDisplayingDelay =
                    Number(currentExpireTimeInSeconds) -
                    awayFromKeyboardPopupTimeoutInSeconds;
                const accessToken = localStorage.getItem('accessToken');
                if (newPopupDisplayingDelay <= 0 && accessToken) {
                    dispatch(setIsOpened(true));
                }
            }, popupDisplayingDelay * millisecondsPerSecond);
            setShowPopupTimeout(timeout);
        } else {
            dispatch(setIsOpened(false));
        }
    };

    return isOpened ? (
        <AreYouStillHerePopup
            maxDiffMs={
                Number(pricing.awayFromKeyboardPopupTimeout) *
                secondsPerMinute *
                millisecondsPerSecond
            }
            callBackOnMaxDiffExceeded={() => {
                dispatch(setIsOpened(false));
            }}></AreYouStillHerePopup>
    ) : (
        <></>
    );
};

export default AreYouStillHereHost;
