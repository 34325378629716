import React from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import './PortalTooltip.scss';

interface PortalTooltipProps {
    anchorId: string;
    delayShow: number;
    content: string | React.JSX.Element;
    place?:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end';
    container?: Element | DocumentFragment;
}

const PortalTooltip = (props: PortalTooltipProps) => {
    return createPortal(
        <Tooltip
            anchorId={props.anchorId}
            className="portal-tooltip tooltip-content"
            id="event"
            delayShow={props.delayShow}
            place={props.place ?? 'top'}>
            {props.content}
        </Tooltip>,
        props.container ?? document.body
    );
};

export default PortalTooltip;
