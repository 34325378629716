import { createAsyncThunk } from '@reduxjs/toolkit';

export const GetProfileRegistrationData = createAsyncThunk(
    'clubhouse/getRegistrationProfileData',
    async (_args, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(
            '/api/clubhouses/pocprofile/registration-data'
        );
        return response;
    }
);
