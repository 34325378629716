import React from 'react';
import {
    Tooltip as ReactstrapTooltip,
    UncontrolledTooltipProps,
} from 'reactstrap';
import './Tooltip.scss';

const Tooltip = (props: UncontrolledTooltipProps) => {
    return (
        <ReactstrapTooltip
            {...props}
            popperClassName="custom-reactstrap-tooltip"
            delay={0}
            modifiers={{
                offset: {
                    offset: '0, 10px',
                },
            }}></ReactstrapTooltip>
    );
};
export default Tooltip;
