import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ActiveSubscriptionsPaginatedTable from '../../../components/ActiveSubscriptionsPaginatedTable/ActiveSubscriptionsPaginatedTable';
import SubscriptionsPaymentHistoryPaginatedTable from '../../../components/PaymentHistoryPaginatedTable/SubscriptionsPaymentHistoryPaginatedTable';

interface PmgSubscriptionsTabProps {
    typeOptions: any[];
    memberNameOptions: any[];
    practiceNameOptions: any[];
    preselectedMemberId: number | undefined;
}
export const PmgSubscriptionsTab = (props: PmgSubscriptionsTabProps) => {
    const [dateSubscriptionsLastUpdate, setDateSubscriptionsLastUpdate] =
        useState(new Date());

    return (
        <Row>
            <Col md="12">
                <Card className="main-card mb-3">
                    <CardHeader>
                        <span className="practice-heading">
                            {'Active Subscriptions'}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <ActiveSubscriptionsPaginatedTable
                            typeOptions={props.typeOptions}
                            memberNameOptions={props.memberNameOptions}
                            practiceNameOptions={props.practiceNameOptions}
                            isUserDetailsAvailable={!props.preselectedMemberId}
                            onTryPaymentAgainFinished={() =>
                                setDateSubscriptionsLastUpdate(new Date())
                            }
                        />
                    </CardBody>
                </Card>
                <Card className="main-card mb-3">
                    <CardHeader>
                        <span className="practice-heading">
                            {'Payment History'}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <SubscriptionsPaymentHistoryPaginatedTable
                            typeOptions={props.typeOptions}
                            memberNameOptions={props.memberNameOptions}
                            practiceNameOptions={props.practiceNameOptions}
                            isUserDetailsAvailable={!props.preselectedMemberId}
                            subscriptionsUpdatingTracker={
                                dateSubscriptionsLastUpdate
                            }
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
