export enum CharityTypesEnum {
    VeterinaryMedicine = 1,
    AnimalWelfare,
    HumanAnimalConnection,
}

export const charityTypeNameList = [
    {
        id: 1,
        name: 'Veterinary Medicine',
    },
    {
        id: 2,
        name: 'Animal Welfare',
    },
    {
        id: 3,
        name: 'Human Animal Connection',
    },
];
