import React, { useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import '../KnowledgeBaseContentLibrary.scss';
import { Field, Form } from '@availity/form';
import * as yup from 'yup';
import './RequestContentForm.scss';
import createNotification from '../../../../utils/createNotification';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../../store/selectors';

export const RequestContentForm = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const topicMaxLenght = 50;
    const contentMaxLenght = 1000;
    let modalForm = {};

    const [isSaving, setIsSaving] = useState(false);
    const [topic, setTopic] = useState('');
    const [content, setContent] = useState('');

    const saveContentRequest = (e) => {
        setIsSaving(true);

        axios
            .post('/api/knowledgeBaseRequestedContent', {
                topic: e.topic,
                content: e.content,
            })
            .then((response) => {
                if (
                    response &&
                    response.request &&
                    response.request.status === 200
                ) {
                    createNotification(
                        'Your request sent successfully!',
                        'success'
                    );
                    props.onSuccessfullSubmit();
                } else {
                    createNotification(
                        'Error occurred while sending your proposal. Please try again.',
                        'error'
                    );
                }

                setIsSaving(false);
            })
            .catch(() => {
                setIsSaving(false);
                createNotification('Unexpected error occurred.', 'error');
            });
    };

    const initSubmit = () => modalForm.handleSubmit();

    return (
        <div className="request-content-form">
            <Form
                innerRef={(c) => (modalForm = c)}
                onSubmit={(e) => saveContentRequest(e)}
                initialValues={{
                    topic: topic,
                    content: content,
                }}
                validationSchema={yup.object().shape({
                    topic: yup
                        .string()
                        .max(
                            topicMaxLenght,
                            `Topic must be at most ${topicMaxLenght} characters`
                        )
                        .required('Topic is a required field'),
                    content: yup
                        .string()
                        .max(
                            contentMaxLenght,
                            `Content must be at most ${contentMaxLenght} characters`
                        )
                        .required('Content is a required field'),
                })}>
                <Row>
                    <Col md={12}>
                        <p className="description-text">
                            Request any particular type of content you would
                            like to see added to the knowledge library. Please
                            be as specific as possible.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="topic">Requested Content Topic</Label>
                            <Field
                                name="topic"
                                type="text"
                                onChange={(event) =>
                                    setTopic(event.target.value)
                                }></Field>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Describe Requested Content</Label>
                            <Field
                                name="content"
                                type="textarea"
                                onChange={(event) =>
                                    setContent(event.target.value)
                                }></Field>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <div className="buttons-wrapper">
                <Button
                    className="cancel-btn"
                    onClick={() => props.onCancelClicked()}>
                    Cancel
                </Button>
                <ButtonLoader
                    buttonText={'Request Content'}
                    disabled={false}
                    isLoading={isSaving}
                    onClick={initSubmit}
                    className={
                        'btn btn-primary loader-submit save-btn'
                    }></ButtonLoader>
            </div>
        </div>
    );
};
export default RequestContentForm;
