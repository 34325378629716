export enum RewardTypeEnum {
    Referral,
    AdminEntry,
    IndirectReferral
}

export const RewardTypeList = [
    { id: 0, name: 'Referral' },
    { id: 1, name: 'Admin Entry' },
    { id: 2, name: 'Indirect Referral' },
];
