import React from 'react';
import { Button, ModalBody } from 'reactstrap';
import Rodal from '../../components/Rodal/Rodal';
import './are-you-still-here-popup.scss';
import RealTimeDifferenceTracker from '../date-time/real-time-difference-tracker';
import { gtmEvent } from '../../components/Tracking';
import { useNavigate } from 'react-router-dom';
import { TokenHelper } from '../../utils/tokenHelper';
import { useDispatch } from 'react-redux';
import {
    setExpirationDate,
    setIsOpened,
} from '../are-you-still-here-slice/are-you-still-here-slice';
import { useStateSelector } from '../../store/selectors';

export interface AreYouStillHerePopupProps {
    maxDiffMs: number;
    callBackOnMaxDiffExceeded(): void;
}
const AreYouStillHerePopup = (props: AreYouStillHerePopupProps) => {
    let navigate = useNavigate();
    const getExpirationDate: () => Date = useStateSelector(
        (state) => state.areYouStillHere.expirationDate
    );
    const dispatch: any = useDispatch();

    const axios = useStateSelector((state) => state.core.axios);

    const getTimeLeftToBeLoggedOut = () => {
        return (
            <RealTimeDifferenceTracker
                maxDiffMs={props.maxDiffMs}
                callBackOnMaxDiffExceeded={props.callBackOnMaxDiffExceeded}
                callBackOnDeadlineReached={logMeOut}
                getDeadlineToTrack={
                    getExpirationDate
                }></RealTimeDifferenceTracker>
        );
    };

    const logMeOut = () => {
        gtmEvent('click');
        navigate('/login');
        TokenHelper.clearLocalStorage(false);
        dispatch(setExpirationDate(new Date()));
        dispatch(setIsOpened(false));
    };

    const refreshToken = () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const deviceKey = localStorage.getItem('deviceKey');
        const accessToken = localStorage.getItem('accessToken');
        const deviceGroupKey = localStorage.getItem('deviceGroupKey');
        axios
            .post(`/api/auth/refreshUserToken`, {
                refreshToken: refreshToken,
                deviceKey: deviceKey,
                accessToken: accessToken,
                deviceGroupKey: deviceGroupKey,
            })
            .then((response: any) => {
                const authenticationResult = response?.data?.data;
                TokenHelper.setTokenToLocalStorage(authenticationResult);
                let expirationDate = new Date();
                expirationDate.setSeconds(
                    expirationDate.getSeconds() + authenticationResult.expiresIn
                );
                dispatch(setExpirationDate(expirationDate));
                dispatch(setIsOpened(false));
            });
    };

    return (
        <Rodal
            visible={true}
            onClose={() => refreshToken()}
            closeMaskOnClick={true}
            className="are-you-still-here-modal"
            width={350}>
            <ModalBody>
                <div className="are-you-still-here-modal-title">
                    Are you still there?
                </div>
                <div className="are-you-still-here-modal-text">
                    To protect your account and information, you will
                    automatically be logged off if you don't respond.
                </div>
                <div className="are-you-still-here-modal-time">
                    {getTimeLeftToBeLoggedOut()}
                </div>
                <div className="action-buttons-wrapper are-you-still-here-modal-buttons">
                    <Button
                        onClick={() => {
                            refreshToken();
                        }}
                        color="primary">
                        Yes, I am still here
                    </Button>
                    <Button
                        onClick={() => {
                            logMeOut();
                        }}
                        className="close-button">
                        Log me out
                    </Button>
                </div>
            </ModalBody>
        </Rodal>
    );
};

export default AreYouStillHerePopup;
