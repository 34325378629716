import React, { useEffect, useState } from 'react';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import { Button } from 'reactstrap';
import { useStateSelector } from '../../../../store/selectors';
import { UserSubscriptionType } from '../../../../utils/constants/UserSubscriptionType.enum';
import Helpers from '../../../../utils/helper';
import moment from 'moment';
import createNotification from '../../../../utils/createNotification';
import { PracticeOwnerType } from '../../../../utils/constants/PracticeOwnerType.enum';
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';

interface UserSubscription {
    id: number;
    dateStart: string;
    dateEnd: string;
    price: number;
    type: UserSubscriptionType;
    isManuallyCancelled: boolean;
}

export const UserSubscriptionSection = () => {
    const axios = useStateSelector((s) => s.core.axios);
    const summary = useStateSelector((s) => s.userSummary.summary);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<UserSubscription[]>([]);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isUpdatingSubscriptionState, setIsUpdatingSubscriptionState] =
        useState(false);
    useEffect(() => {
        if (
            summary.practiceOwnerType === PracticeOwnerType.Corporate ||
            summary.practiceOwnerType === PracticeOwnerType.Independent
        ) {
            fetchSubscriptions();
        }
    }, []);

    const fetchSubscriptions = () => {
        axios
            .get('api/UserSubscriptions')
            .then((response: any) => {
                if (response.status === 204) {
                    setData([]);
                } else if (response.status === 200) {
                    const subscription = response.data as UserSubscription;
                    setData([subscription]);
                } else {
                    createNotification(
                        'An unexpected error occurred while loading Full Membership Subscription',
                        'error'
                    );
                }
            })
            .catch(() =>
                createNotification(
                    'An unexpected error occurred while loading Full Membership Subscription',
                    'error'
                )
            )
            .finally(() => setIsLoading(false));
    };

    const updateSubscriptionState = () => {
        const errorText = `An unexpected error occurred during Full Membership ${
            data[0].isManuallyCancelled ? 'Renewal' : 'Cancelling'
        }`;
        const apiEndpoint = `api/UserSubscriptions/${
            data[0].isManuallyCancelled ? 'renew' : 'cancel'
        }`;
        setIsUpdatingSubscriptionState(true);
        axios
            .put(apiEndpoint)
            .then((response: any) => {
                if (response.status === 200) {
                    createNotification(
                        `Your Full Membership Subscription was successfully ${
                            data[0].isManuallyCancelled
                                ? 'Renewed'
                                : 'Cancelled'
                        }`,
                        'success'
                    );
                    fetchSubscriptions();
                } else {
                    createNotification(errorText, 'error');
                }
            })
            .catch(() => createNotification(errorText, 'error'))
            .finally(() => {
                setIsUpdatingSubscriptionState(false);
                setIsConfirmModalVisible(false);
            });
    };

    const getGroupsGridColumns = () => [
        {
            dataField: 'id',
            text: 'Subscription',
            formatter: () => 'Ecosystem Full Membership',
        },

        {
            dataField: 'price',
            text: 'Price',
            formatter: (cellContent: number, row: UserSubscription) => (
                <span>
                    {Helpers.GetCurrencyFormat(cellContent, 2, true)}/
                    {row.type === UserSubscriptionType.Annual
                        ? 'year'
                        : 'month'}
                </span>
            ),
        },
        {
            dataField: 'dateEnd',
            text: 'Renewal Date',
            formatter: (cellContent: string, row: UserSubscription) => (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span>{moment(cellContent).format('DD-MM-YYYY')}</span>
                    <div
                        style={{
                            display: 'flex',
                        }}>
                        <Button
                            className="cancel-subscription-button"
                            color="primary"
                            onClick={() => setIsConfirmModalVisible(true)}>
                            {row.isManuallyCancelled
                                ? 'Renew Subscription'
                                : 'Cancel Subscription'}
                        </Button>
                    </div>
                </div>
            ),
        },
    ];

    return !isLoading && data.length > 0 ? (
        <div className="group-grid">
            <p className="user-name semi-bold">{'My Subscriptions'}</p>
            <BootstrapTable
                remote
                bootstrap4
                keyField="id"
                data={data}
                columns={getGroupsGridColumns()}
                noDataIndication={'No Data available'}
            />
            {isConfirmModalVisible ? (
                <ConfirmationModal
                    header="Are you sure?"
                    isVisible={isConfirmModalVisible}
                    setIsVisible={() => setIsConfirmModalVisible(false)}
                    confirmationText={`Are you sure you want to ${
                        data[0].isManuallyCancelled ? 'Renew' : 'Cancel'
                    } your Full Membership Subscription?`}
                    params={undefined}
                    closeOnConfirm={false}
                    isLoading={isUpdatingSubscriptionState}
                    onConfirm={() => {
                        updateSubscriptionState();
                    }}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"></ConfirmationModal>
            ) : (
                []
            )}
        </div>
    ) : (
        <></>
    );
};
