import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    RetrieveMessageArg,
    retrieveSupportThreadMessages,
} from './message-slice-helper';

export const refreshSupportThreadMessageList = createAsyncThunk(
    'supportMessages/refresh',
    async (arg: RetrieveMessageArg, dispatch: any) => {
        const result = await retrieveSupportThreadMessages(arg, dispatch);
        return result;
    }
);
