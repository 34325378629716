import React from 'react';
import { Button, ModalBody } from 'reactstrap';
import './view-discussion.scss';
import moment from 'moment';
import { Discussion } from '../models/discussion';
import VoteComponent from '../vote-component/vote-component';
import { Poster } from '../models/poster';
import { GroupType } from '../models/group-type';

interface DiscussionDetailsProps {
    discussion: Discussion;
    currentMemberId: number;
    pinnedDiscussions: string[];
    switchToEditMode(): void;
    startVoting(discussionId: number): void;
    posterVote(discussionId: number, vote: boolean): void;
    isUserAdmin: boolean;
    groupType: GroupType;
    cancelPosterVote(discussionId: number): void;
    doesMemberHasViewOnlyAccessToPoc: boolean
}

const ViewDiscussion = (props: DiscussionDetailsProps) => {
    const isMemberBelongsToPosters = (): boolean =>
        props.discussion.posters.find(
            (p: Poster) => p.id === props.currentMemberId
        )
            ? true
            : props.isUserAdmin;

    const isPinnedDiscussion = (): boolean =>
        props.pinnedDiscussions.some((d) => d === props.discussion.name);

    return (
        <div className="view-details">
            <div className="knowledge-contetnt-popup-header">
                <div className="tile-edit-btn-wrapper">
                    <h5>Discussion Name: {props.discussion.name}</h5>
                    {props.currentMemberId &&
                    props.currentMemberId === props.discussion.createdById ? (
                        <Button
                            hidden={props.doesMemberHasViewOnlyAccessToPoc}
                            className="save-btn edit"
                            onClick={props.switchToEditMode}>
                            Edit
                        </Button>
                    ) : (
                        []
                    )}
                </div>
            </div>
            <ModalBody className="edit-title-body edit-detail">
                {!isPinnedDiscussion() ? (
                    <div className="intro-text-wrapper">
                        <label>Description:</label>
                        <div className="intro-text-area">
                            <span>{props.discussion.description}</span>
                        </div>
                    </div>
                ) : (
                    <div className="no-description-wrapper"></div>
                )}
                <div className="title-wrapper">
                    <div className="title">
                        <label>Created By:</label>
                        <div className="input-title">
                            {isPinnedDiscussion() ? (
                                <span>VetValue</span>
                            ) : (
                                <span>{props.discussion.createdBy}</span>
                            )}
                        </div>
                    </div>
                    <div className="author-input">
                        <label>Created Date:</label>
                        <div className="author-input">
                            <span>
                                {moment(props.discussion.dateCreated).format(
                                    'M/D/YYYY'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="edited-post-wrapper">
                    <div className="title">
                        <label>Number of Posts:</label>
                        <div className="input-title">
                            <span>{props.discussion.totalMessages}</span>
                        </div>
                    </div>
                    <div className="author-input">
                        <label>Number of Posters:</label>
                        <div className="author-input">
                            <span>{props.discussion.posters?.length ?? 0}</span>
                        </div>
                    </div>
                </div>
                <div></div>
                {!isPinnedDiscussion() && isMemberBelongsToPosters() && !props.doesMemberHasViewOnlyAccessToPoc ? (
                    <VoteComponent
                        startedById={props.discussion.voting?.memberId}
                        posters={props.discussion.posters}
                        currentMemberId={props.currentMemberId}
                        isUserAdmin={props.isUserAdmin}
                        isVotingInitializing={
                            props.discussion.isVotingInitializing
                        }
                        isVoteSaving={props.discussion.isVoteSaving}
                        startVoting={() =>
                            props.startVoting(props.discussion.id)
                        }
                        posterVote={(vote) =>
                            props.posterVote(props.discussion.id, vote)
                        }
                        dateInitiated={props.discussion.voting?.dateInitiated}
                        groupType={props.groupType}
                        cancelPosterVote={() =>
                            props.cancelPosterVote(props.discussion.id)
                        }></VoteComponent>
                ) : (
                    []
                )}
            </ModalBody>
        </div>
    );
};

export default ViewDiscussion;
