export enum ProjectProgressStatus {
    Open,
    InProgress,
    Partial,
    Complete,
}
export const ProjectProgressStatusList = [
    { id: 0, name: 'Open', color: '#000000' },
    { id: 1, name: 'In Progress', color: '#535bd6' },
    { id: 2, name: 'Partial', color: '#deb24d' },
    { id: 3, name: 'Complete', color: '#66cdaa' },
];
