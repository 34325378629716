import React from 'react';
import { Row, Button } from 'reactstrap';

export const TableHeader = props => {
    return (
        <Row className="tableHeader">
            <div>
                <div className="heading">{props.title}</div>
                <div className="sub-title">{props.subTitle}</div>
            </div>
            {props.hasToolbar && (
                <div>
                    {props.buttons &&
                        props.buttons.map((btn, index) => (
                            <Button
                                key={index}
                                color="primary"
                                onClick={btn.onClick}
                                disabled={btn.disabled}>
                                {btn.buttonText}
                            </Button>
                        ))}
                </div>
            )}
        </Row>
    );
};
