export const ASSOCIATE_MEMBER_ROLES = [
    { id: 1, name: 'Fellow Practice Owner' },
    { id: 2, name: 'Practice Manager' },
    { id: 3, name: 'Company Bookkeeper' },
    { id: 4, name: 'Outside Accountant' },
];

export const ASSOCIATE_MEMBER_ROLES_ENUM = {
    FellowPracticeOwner: 1,
    PracticeManager: 2,
    CompanyBookkeeper: 3,
    OutsideAccountant: 4,
};

export const ASSOCIATE_MEMBER_STATUS = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Approved' },
];

export const ASSOCIATE_MEMBER_RIGHTS = [
    { id: 1, name: 'IPOC' },
    { id: 2, name: 'Knowledge Library' },
    { id: 3, name: 'Valuation Reports Input Form' },
    { id: 4, name: 'Valuation Reports Procurement' },
    { id: 5, name: 'Valuation Reports Viewing' },
    { id: 6, name: 'Referral Partner Extension' },
    { id: 7, name: 'PmgRepresentativeAccessRights' }
];

export const ASSOCIATE_MEMBER_RIGHTS_ENUM = {
    POC: 1,
    KnowledgeBase: 2,
    ValuationReportInputForms: 3,
    ValuationReportsProcurement: 4,
    ValuationReportsViewing: 5,
    ReferralPartnerExtension: 6,
    PmgRepresentativeAccessRights: 7
};

export const getDefaultAssosiateMemberRights = () => {
    return {
        practiceOwnerCommunity: false,
        knowledgeBase: false,
        valuationReportInputForms: false,
        valuationReportsProcurement: false,
        valuationReportsViewing: false,
        referralPartnerExtension: false,
        pmgRepresentativeAccessRights: false
    };
};

export const getMappedAssosiateMemberRights = (numericArrayToMap) => {
    if(!numericArrayToMap)
    {
        return getDefaultAssosiateMemberRights();
    }
    return {
        practiceOwnerCommunity: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.POC
        ),
        knowledgeBase: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
        ),
        valuationReportInputForms: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportInputForms
        ),
        valuationReportsProcurement: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportsProcurement
        ),
        valuationReportsViewing: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportsViewing
        ),
        referralPartnerExtension: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ReferralPartnerExtension
        ),
        pmgRepresentativeAccessRights: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.PmgRepresentativeAccessRights
        )
    };
};
