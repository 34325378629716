import React from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import '../../../components/PaginatedTable/PaginatedTable.scss';
import Rodal from '../../../components/Rodal/Rodal';

const KnowledgeBaseRequestContentPreviewModal = (props) => {
    return (
        <Rodal
            visible={props.isVisible}
            onClose={() => {
                props.setIsVisible(false);
            }}
            animation={'fade'}
            showMask={true}
            className="modal-style content-view-modal announcement-modal view-modal-rodal">
            <div className="editTitleHeader">
                <div className="body_white_box associate_member">
                    <ModalHeader>Requested Content</ModalHeader>
                </div>

                <ModalBody className="editTitleBody">
                    <div className="edit-title-dec-wrapper">
                        <label>Requested By</label>
                        <div className="edit-title-intro-text-wrapper">
                            <p className="deleteModalBodyP">
                                {props.data.requestedByName}
                            </p>
                        </div>
                    </div>
                    <div className="edit-title-dec-wrapper">
                        <label>Topic</label>
                        <div className="edit-title-intro-text-wrapper">
                            <p className="deleteModalBodyP">
                                {props.data.topic}
                            </p>
                        </div>
                    </div>
                    <div className="edit-title-dec-wrapper">
                        <label>Description</label>
                        <div className="edit-title-intro-text-wrapper">
                            <p className="deleteModalBodyP">
                                {props.data.content}
                            </p>
                        </div>
                    </div>
                </ModalBody>
            </div>
        </Rodal>
    );
};

export default KnowledgeBaseRequestContentPreviewModal;
