import { createAsyncThunk } from '@reduxjs/toolkit';

export const GetTopLikedMessages = createAsyncThunk(
    'clubhouseMessages/getTopLiked',
    async (clubhouseId: number, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(
            `/api/clubhouses/${clubhouseId}/messages/topLikedMessages`
        );
        return response;
    }
);
