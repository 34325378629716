import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ActiveUserSubscriptionsPaginatedTable from './ActiveUserSubscriptionsPaginatedTable/ActiveUserSubscriptionsPaginatedTable';
import UserSubscriptionsPaymentHistoryPaginatedTable from './UserSubscriptionsPaymentHistoryPaginatedTable/UserSubscriptionsPaymentHistoryPaginatedTable';

interface UsersSubscriptionsTabProps {
    memberNameOptions: any[];
    preselectedMemberId: number | undefined;
}
export const UsersSubscriptionsTab = (props: UsersSubscriptionsTabProps) => {
    return (
        <Row>
            <Col md="12">
                <Card className="main-card mb-3">
                    <CardHeader>
                        <span className="practice-heading">
                            {'Active Subscriptions'}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <ActiveUserSubscriptionsPaginatedTable
                            memberNameOptions={props.memberNameOptions}
                            isUserDetailsAvailable={
                                !props.preselectedMemberId
                            }></ActiveUserSubscriptionsPaginatedTable>
                    </CardBody>
                </Card>
                <Card className="main-card mb-3">
                    <CardHeader>
                        <span className="practice-heading">
                            {'Payment History'}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <UserSubscriptionsPaymentHistoryPaginatedTable
                            memberNameOptions={props.memberNameOptions}
                            isUserDetailsAvailable={
                                !props.preselectedMemberId
                            }></UserSubscriptionsPaymentHistoryPaginatedTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
