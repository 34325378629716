import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    RetrieveMessageArg,
    RetrieveMessageByLikesArg,
    retrieveDirectRoomMessages,
    retrieveDirectRoomMessagesByLikes,
} from './messages-slice-helper';

export const refreshDirectRoomMessageList = createAsyncThunk(
    'directMessages/refresh',
    async (arg: RetrieveMessageArg, dispatch: any) =>
        await retrieveDirectRoomMessages(arg, dispatch)
);

export const refreshDirectRoomMessageListByLikes = createAsyncThunk(
    'directMessages/refresh',
    async (arg: RetrieveMessageByLikesArg, dispatch: any) =>
        await retrieveDirectRoomMessagesByLikes(arg, dispatch)
);
