import React, { useEffect, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
    PriceItem,
    PaymentDialog,
} from '../../../../components/PaymentDialog/PaymentDialog';
import { useStateSelector } from '../../../../store/selectors';
import createNotification from '../../../../utils/createNotification';
import { KnowledgeLibraryContent } from './KnowledgeLibraryContent';
import './KnowledgeBaseContentAccessPaymentComponent.scss';

interface KnowledgeBaseContentAccessPaymentComponentProps {
    isVisible: boolean;
    setIsVisible: (isVisible: React.SetStateAction<boolean>) => void;
    content: KnowledgeLibraryContent;
    paymentSuccess: (transactionId: string) => void;
}

export const KnowledgeBaseContentAccessPaymentComponent = (
    props: KnowledgeBaseContentAccessPaymentComponentProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const stripe = useStripe();
    const elements = useElements();

    const [originalServicePrice, setOriginalServicePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [appliedCredits, setAppliedCredits] = useState(0);
    const [isPaymentInProcess, setIsPaymentInProcess] = useState(false);

    useEffect(() => {
        setOriginalServicePrice(Number(props.content.price));
    }, [props]);

    const calculateTotalPrice = () => originalServicePrice - appliedCredits;

    useEffect(() => {
        setTotalPrice(calculateTotalPrice());
    }, [appliedCredits, originalServicePrice]);

    const priceList: PriceItem[] = [
        {
            priceType: 'summary',
            label: 'Cost of Content Access:',
            value: originalServicePrice,
            valueType: 'currency',
        },
    ];

    const purchaseRequest = (
        shouldUseSavedPaymentMethod: boolean,
        paymentTokenId: string = ''
    ) => {
        setIsPaymentInProcess(true);
        const videoClip = {
            contentId: props.content.id,
            contentCost: props.content.price,
            source: paymentTokenId,
            useSavedPaymentMethod: shouldUseSavedPaymentMethod,
            useWalletBalance: appliedCredits > 0,
            walletAmount: appliedCredits,
        };
        axios
            .post(`/api/wallet/BuyKnowledgeLibraryContent`, videoClip)
            .then((response: any) => {
                if (response.status === 200) {
                    props.paymentSuccess(response.data);
                } else {
                    createNotification(
                        response.response.data?.title ??
                            'Error occured while during payment for Knowledge Library Content',
                        'error'
                    );

                    setIsPaymentInProcess(false);
                    props.setIsVisible(false);
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while during payment for Knowledge Library Content',
                    'error'
                );
            })
            .finally(() => {
                setIsPaymentInProcess(false);
            });
    };

    const directPayment = () => purchaseRequest(false);

    return (
        <div className="kl-payment-component">
            <PaymentDialog
                isVisible={props.isVisible}
                setIsVisible={props.setIsVisible}
                stripe={stripe}
                elements={elements}
                priceList={priceList}
                directPayment={directPayment}
                originalServicePrice={originalServicePrice}
                servicePrice={originalServicePrice}
                totalProductPrice={totalPrice}
                apliedCredits={appliedCredits}
                setAppliedCredits={(value) => setAppliedCredits(value)}
                chargeRequest={purchaseRequest}
                isParrentLoading={false}
                isPaymentInProcess={isPaymentInProcess}
                isAuthorizationRequired={false}></PaymentDialog>
        </div>
    );
};

export default KnowledgeBaseContentAccessPaymentComponent;
