import React from 'react';
import './SubscriptionsPage.scss';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { HomeButton } from '../../components/HomeButton/home-button';
import SubscriptionsHost from '../SubscriptionsHost/SubscriptionsHost';

const SubscriptionsPage = () => {
    return (
        <div className="subscriptions-page">
            <div className="title-wrapper">
                <div className="page-title">
                    <h3>Subscriptions</h3>
                </div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <HomeButton></HomeButton>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Subscriptions</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <SubscriptionsHost />
        </div>
    );
};

export default SubscriptionsPage;
