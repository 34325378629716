import React, { useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Field } from '@availity/form';
import '@availity/yup';
import * as yup from 'yup';
import ButtonLoader from '../../components/Layout/Buttons/ButtonLoader';
import './create-discussion-modal.scss';
import createNotification from '../../utils/createNotification';

interface CreateDiscussionModalProps {
    isVisible: boolean;
    hide(): void;
    createDiscussion(name: string, description: string): Promise<any>;
}
const CreateDiscussionModal = (props: CreateDiscussionModalProps) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const modalForm = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const ResourceAlreadyExistStatusCode = 409;

    const initSubmit = () => {
        modalForm.current.handleSubmit();
    };

    const createDiscussion = () => {
        setIsLoading(true);
        props.createDiscussion(name, description).then((response: any) => {
            if (
                response.payload.response?.status ===
                ResourceAlreadyExistStatusCode
            ) {
                createNotification(
                    `The discussion ${name} already exists`,
                    'error'
                );
                setIsLoading(false);
            } else {
                resetDialog();
                props.hide();
            }
        });
    };

    const resetDialog = () => {
        setIsLoading(false);
        setDescription('');
        setName('');
    };

    return (
        <Modal
            className="app-create-new-discussion"
            onOpened={resetDialog}
            isOpen={props.isVisible}
            toggle={props.hide}>
            <div className="create-new-discussion-popup-header">
                <ModalHeader toggle={props.hide}>
                    {' '}
                    Create a New Discussion
                </ModalHeader>
            </div>
            <ModalBody className="create-new-discussion-body">
                <Form
                    innerRef={modalForm}
                    initialValues={{
                        discussionName: name,
                        description: description,
                    }}
                    validationSchema={yup.object().shape({
                        discussionName: yup.string().max(30).required(),
                        description: yup.string().max(140).required(),
                    })}
                    onSubmit={() => {
                        createDiscussion();
                    }}>
                    <div className="author-posted-wrapper">
                        <div className="author-input">
                            <div className="author-input">
                                <label>Discussion Name:</label>
                                <Field
                                    type="text"
                                    name="discussionName"
                                    maxLength={30}
                                    onChange={(event: any) =>
                                        setName(event.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="text-count">
                            <span>{name.length}/30</span>
                        </div>

                        <div className="originally-based-col">
                            <label>Description:</label>
                            <Field
                                type="textarea"
                                name="description"
                                maxLength={140}
                                onChange={(event: any) =>
                                    setDescription(event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="text-count">
                        <span>{description.length}/140</span>
                    </div>
                </Form>

                <div className="btn-wrapper">
                    <Button
                        className="cancel-btn"
                        onClick={() => {
                            resetDialog();
                            props.hide();
                        }}>
                        Cancel
                    </Button>
                    <ButtonLoader
                        buttonText={'Create Discussion'}
                        loaderButtonText={''}
                        disabled={false}
                        isLoading={isLoading}
                        onClick={initSubmit}
                        className={
                            isLoading
                                ? 'btn discussion-btn loader-submit'
                                : 'btn discussion-btn'
                        }></ButtonLoader>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default CreateDiscussionModal;
