import React, { useEffect, useState } from 'react';
import InfoTooltip from '../info-tooltip/info-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './DiscountCoupon.scss';
import { useStateSelector } from '../../store/selectors';
import createNotification from '../../utils/createNotification';
import moment from 'moment';
import Helpers from '../../utils/helper';

interface DiscountCouponProps {
    onDiscountAvailable: (isAvailable: boolean) => void;
}

export const DiscountCoupon = (props: DiscountCouponProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isCouponAvailable, setIsCouponAvailable] = useState(true);
    const [percent, setPercent] = useState<number>();
    const [expiration, setExpiration] = useState<Date>();

    const axios = useStateSelector((s) => s.core.axios);
    const pricing = useStateSelector((s) => s.pricing.pricing);

    useEffect(() => {
        axios
            .get('api/discounts/standard')
            .then((response) => {
                if (response.status === 200) {
                    setIsCouponAvailable(response.data.isAvailable);
                    setPercent(response.data.discount);
                    setExpiration(response.data.expirationDate);
                    props.onDiscountAvailable(response.data.isAvailable);
                } else {
                    createNotification(
                        'Some unexpected error occurred during fetching Discount Coupon',
                        'error'
                    );
                }
            })
            .catch(() =>
                createNotification(
                    'Some unexpected error occurred during fetching Discount Coupon',
                    'error'
                )
            )
            .finally(() => setIsLoading(false));
    }, []);

    return isLoading || !isCouponAvailable ? (
        <></>
    ) : (
        <div className="discount-coupon">
            <div className="main-container">
                <div className="svg-background"></div>
                <div className="my-component">
                    <h3>{percent}% Off</h3>
                    <h3>
                        Coupon{' '}
                        <InfoTooltip
                            text={`This coupon is valid for all purchases on the EcoSystem over ${Helpers.GetCurrencyFormat(
                                pricing.refMinimumPurchaseAmount,
                                2,
                                true
                            )}, prior to the expiration date. Practice Brokerage is excluded.`}></InfoTooltip>
                    </h3>
                    <h5>Expires: {moment(expiration).format('MM/DD/YYYY')}</h5>
                </div>
            </div>
        </div>
    );
};
