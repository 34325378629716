import React, { useState } from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import '../../../../components/PaginatedTable/PaginatedTable.scss';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import Rodal from '../../../../components/Rodal/Rodal';
import { Field, Form } from '@availity/form';
import * as yup from 'yup';
import './SuggestionModal.scss';
import createNotification from '../../../../utils/createNotification';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../../store/selectors';

const SuggestionModal = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const contentMaxLenght = 1000;
    const [content, setContent] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    let modalForm = {};
    const initSubmit = () => modalForm.handleSubmit();

    const saveContentRequest = (e) => {
        setIsSaving(true);
        axios
            .post(`/api/knowledgeBaseRequestedContent/${props.data}/reject`, {
                rejectedReason: content,
            })
            .then((response) => {
                if (
                    response &&
                    response.request &&
                    response.request.status === 200
                ) {
                    props.getRequestedContent();
                    props.setIsVisible(false);
                }
                setIsSaving(false);
            })
            .catch(() => {
                setIsSaving(false);
                createNotification('Unexpected error occurred.', 'error');
            });
    };
    return (
        <Rodal
            visible={props.isVisible}
            width={600}
            onClose={() => {
                props.setIsVisible(false);
            }}
            animation={'fade'}
            showMask={true}
            className="request-content-popup">
            <div className="request-new-content">
                <div className="body_white_box associate_member">
                    <ModalHeader>What improvement do you suggest?</ModalHeader>
                </div>

                <ModalBody className="request-content-body">
                    <div className="suggestion-modal">
                        <Form
                            innerRef={(c) => (modalForm = c)}
                            onSubmit={(e) => saveContentRequest(e)}
                            initialValues={{
                                content: content,
                            }}
                            validationSchema={yup.object().shape({
                                content: yup
                                    .string()
                                    .trim()
                                    .max(
                                        contentMaxLenght,
                                        `Suggestion must be at most ${contentMaxLenght} characters`
                                    )
                                    .required('Suggestion is a required field'),
                            })}>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Field
                                            name="content"
                                            type="textarea"
                                            onChange={(event) =>
                                                setContent(event.target.value)
                                            }></Field>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        <div className="buttons-wrapper">
                            <div
                                className="cancel-btn"
                                onClick={() => props.setIsVisible(false)}>
                                Cancel
                            </div>
                            <ButtonLoader
                                buttonText={'Send Feedback'}
                                disabled={false}
                                isLoading={isSaving}
                                onClick={initSubmit}
                                className={
                                    'btn btn-primary loader-submit save-btn'
                                }></ButtonLoader>
                        </div>
                    </div>
                </ModalBody>
            </div>
        </Rodal>
    );
};

export default SuggestionModal;
