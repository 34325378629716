import React, { useEffect } from 'react';
import { useStateSelector } from '../../store/selectors';
import { useDispatch } from 'react-redux';
import { NotificationFlag } from '../../slices/notification-flags/notification-flag';
import { deleteNotificationFlags } from '../../slices/notification-flags/notification-flags.slice';
import { RefreshNotificationFlags } from '../../slices/notification-flags/refresh-notification-flags';

export const NotificationFlagsProvider = () => {
    const dispatch: any = useDispatch();
    const axios = useStateSelector((state) => state.core.axios);
    const flagsToDelete = useStateSelector(
        (s) => s.notificationFlags.flagsToDelete
    );
    const deletedFlags = useStateSelector(
        (s) => s.notificationFlags.deletedFlags
    );

    const refreshAfterRemoval = useStateSelector(
        (s) => s.notificationFlags.refreshAfterRemoval
    );

    useEffect(() => {
        const timeoutId = setTimeout(
            () => cleanNotifications(flagsToDelete, deletedFlags),
            500
        );
        return () => clearTimeout(timeoutId);
    }, [flagsToDelete, deletedFlags]);

    const cleanNotifications = (
        flagsToDelete: NotificationFlag[],
        deletedFlags: NotificationFlag[]
    ) => {
        const flagsToDeleteThatStillExist = flagsToDelete.filter((f) =>
            deletedFlags.every(
                (d) => d.eventCode.toLowerCase() !== f.eventCode.toLowerCase()
            )
        );
        if (flagsToDeleteThatStillExist.length > 0) {
            axios
                .delete(`api/NotificationFlags`, {
                    data: flagsToDeleteThatStillExist.map((f) => f.eventCode),
                })
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(
                            deleteNotificationFlags(flagsToDeleteThatStillExist)
                        );
                        if (refreshAfterRemoval) {
                            dispatch(RefreshNotificationFlags());
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return <></>;
};
