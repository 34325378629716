import React from 'react';

interface PopupsWizardProps {
    popups: React.ReactElement[];
}

export const PopupsWizard = (props: PopupsWizardProps) => {
    const [currentPopupIndex, setCurrentPopupIndex] = React.useState(0);
    const { popups } = props;

    const handleNext = () => {
        if (currentPopupIndex < popups.length - 1) {
            setCurrentPopupIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handleBack = () => {
        if (currentPopupIndex > 0) {
            setCurrentPopupIndex((prevIndex) => prevIndex - 1);
        }
    };

    const currentPopup = React.cloneElement(popups[currentPopupIndex], {
        triggerNext: handleNext,
        triggerBack: handleBack,
    });

    return <div>{currentPopup}</div>;
};
