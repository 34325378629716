export enum W9DocumentStatus {
    INREVIEW,
    ACCEPTED,
    REJECTED,
}

export const w9DocuemntStatusNamesList = [
    {
        id: 0,
        name: 'Submitted',
    },
    {
        id: 1,
        name: 'Approved',
    },
    {
        id: 2,
        name: 'Rejected',
    },
];
