import './UpgradeSubscriptionModal.scss';
import Rodal from '../Rodal/Rodal';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import BootstrapTable from '../../components/react-bootstrap-table-next/react-bootstrap-table2';
import {
    PracticeOption,
    corporatePracticeLeaderOptions,
    independentPracticeOwnerOptions,
    independentPracticeOwnerText,
    corporatePracticeLeaderText,
} from './subscriptionData';
import {
    PRACTICE_OWNERSHIP_TYPES,
    PracticeOwnerType,
} from '../../utils/constants/PracticeOwnerType.enum';
import { Button, Input, InputGroup, ModalHeader } from 'reactstrap';
import { RadioInput } from '../RadioInput/RadioInput.component';
import { useStateSelector } from '../../store/selectors';
import Helpers from '../../utils/helper';
import { UserSubscriptionType } from '../../utils/constants/UserSubscriptionType.enum';
import { PostUserSubscriptionRequest } from './postUserSubscriptionRequest';
import CardPaymentForm from '../CardPaymentForm/CardPaymentForm';
import PoweredByStripe from '../../assets/images/poweredByStripe.png';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, PaymentMethodResult } from '@stripe/stripe-js';
import createNotification from '../../utils/createNotification';
import ButtonLoader from '../Layout/Buttons/ButtonLoader';
import { PaymentsHelper } from '../../utils/paymentsHelper';
import { useDispatch } from 'react-redux';
import { FetchSummary } from '../../slices/user-summary/fetch-summary.thunk';
import { LoadLimits } from '../../slices/current-user/load-limits.thunk';
import { Tooltip } from 'react-tooltip';

interface UpgradeSubscriptionModalProps {
    onClose: () => void;
    isVisible: boolean;
    ownerType: PracticeOwnerType;
}

const UpgradeSubscriptionModal = (props: UpgradeSubscriptionModalProps) => {
    const dispatch: any = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const pricing = useStateSelector((s) => s.pricing.pricing);
    const axios = useStateSelector((s) => s.core.axios);
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionRequest, setSubscriptionRequest] =
        useState<PostUserSubscriptionRequest>();
    const [upgradeStage, setUpgradeStage] = useState(false);
    const [savedCard, setSavedCard] = useState();
    const [shouldSavePaymentMethod, setShouldSavePaymentMethod] =
        useState<boolean>();
    const [isCardDataVaild, setIsCardDataValid] = useState<boolean>();
    const [isUpgrading, setIsUpgrading] = useState(false);
    const limits = useStateSelector((s) => s.currentUser.limits);

    useEffect(() => {
        GetSavedCard();
    }, []);

    useEffect(() => {
        const subscriptionRequest: PostUserSubscriptionRequest = {
            price: Number(
                props.ownerType === PracticeOwnerType.Independent
                    ? pricing.subscriptions
                          .independentPracticeOwnerAnnualSubscription
                    : pricing.subscriptions.corporateLeaderAnnualSubscription
            ),
            type: UserSubscriptionType.Annual,
            isSavedPaymentMethod: savedCard ? true : false,
            areCreditsAllowedToUse: false,
            paymentMethodTokenId: undefined,
        };
        setSubscriptionRequest(subscriptionRequest);
        setIsLoading(false);
    }, [props.ownerType, props.isVisible, savedCard]);

    const GetSavedCard = () => {
        axios.get('api/Wallet/PaymentMethod').then((response: any) => {
            if (response.data && response.data.lastDigits) {
                setSavedCard(response.data);
            }
        });
    };

    const getTextForPracticeOwner = (ownerType: PracticeOwnerType): string => {
        switch (ownerType) {
            case PRACTICE_OWNERSHIP_TYPES.INDEPENDENT:
                return independentPracticeOwnerText;
            case PRACTICE_OWNERSHIP_TYPES.CORPORATE:
                return corporatePracticeLeaderText;
            default:
                return '';
        }
    };

    const getDataForTable = (
        ownerType: PracticeOwnerType
    ): PracticeOption[] => {
        switch (ownerType) {
            case PRACTICE_OWNERSHIP_TYPES.INDEPENDENT:
                return independentPracticeOwnerOptions;
            case PRACTICE_OWNERSHIP_TYPES.CORPORATE:
                return corporatePracticeLeaderOptions;
            default:
                return [];
        }
    };

    const getSubscriptionTypes = () => {
        return props.ownerType === PracticeOwnerType.Corporate ? (
            <InputGroup className="subscription-types">
                <RadioInput
                    label={`Annual - ${Helpers.GetCurrencyFormatWithCents(
                        pricing.subscriptions.corporateLeaderAnnualSubscription
                    )}`}
                    value={UserSubscriptionType.Annual}
                    checked={
                        subscriptionRequest.type === UserSubscriptionType.Annual
                    }
                    setter={() =>
                        setSubscriptionRequest({
                            ...subscriptionRequest,
                            type: UserSubscriptionType.Annual,
                            price: Number(
                                pricing.subscriptions
                                    .corporateLeaderAnnualSubscription
                            ),
                        })
                    }
                    key="radio-subscription-type-annual"
                    disabled={false}></RadioInput>
                <RadioInput
                    label={`Monthly - ${Helpers.GetCurrencyFormatWithCents(
                        pricing.subscriptions.corporateLeaderMonthlySubscription
                    )}`}
                    value={UserSubscriptionType.Monthly}
                    checked={
                        subscriptionRequest.type ===
                        UserSubscriptionType.Monthly
                    }
                    setter={() =>
                        setSubscriptionRequest({
                            ...subscriptionRequest,
                            type: UserSubscriptionType.Monthly,
                            price: Number(
                                pricing.subscriptions
                                    .corporateLeaderMonthlySubscription
                            ),
                        })
                    }
                    key="radio-subscription-type-monthly"
                    disabled={false}></RadioInput>
            </InputGroup>
        ) : (
            <label className="subscription-types">
                Price:{' '}
                {Helpers.GetCurrencyFormatWithCents(subscriptionRequest.price)}
            </label>
        );
    };

    const getCreditsCheckbox = () => {
        return (
            <label className="checkbox-label checkbox-section">
                <Input
                    type="checkbox"
                    checked={subscriptionRequest.areCreditsAllowedToUse}
                    onChange={() =>
                        setSubscriptionRequest({
                            ...subscriptionRequest,
                            areCreditsAllowedToUse:
                                !subscriptionRequest.areCreditsAllowedToUse,
                        })
                    }
                    className="checkbox-input"
                />
                <span className="wallet-text-ref">
                    I would like to use available VV Credits to reduce the
                    subscription fee automatically
                </span>
            </label>
        );
    };

    const columns = [
        {
            dataField: 'option',
            text: '',
            sort: false,
            headerStyle: { borderTop: '0' },
            width: 200,
        },
        {
            dataField: 'free',
            text: 'Free Version',
            sort: false,
            headerAlign: 'left',
            align: 'left',
            headerStyle: { borderTop: '0' },
        },
        {
            dataField: 'paid',
            text: 'Paid Version',
            sort: false,
            headerAlign: 'left',
            align: 'left',
            headerStyle: { borderTop: '0' },
        },
    ];

    const renderComponent = () => {
        const textForPracticeOwner = getTextForPracticeOwner(props.ownerType);
        const dataForTable = getDataForTable(props.ownerType);

        return (
            <div className="m-4">
                <div>{textForPracticeOwner}</div>

                <div className="bootstrap-table-container p-4 scroll-area-x">
                    <BootstrapTable
                        striped={false}
                        bordered={false}
                        bootstrap4
                        keyField="option"
                        data={dataForTable}
                        columns={columns}
                        defaultSorted={[{ dataField: 'option', order: 'asc' }]}
                        noDataIndication="No Data available"
                    />
                </div>

                {getSubscriptionTypes()}
                {getCreditsCheckbox()}
                <div className="my-4 d-flex align-items-center flex-column flex-md-row">
                    <div className="d-flex justify-content-center w-100 order-2 order-md-1 mb-3 mb-md-0">
                        <div className="w-50 d-none d-md-block" />
                        <button
                            onClick={onClose}
                            className="btn btn-secondary w-100 w-md-25 mt-md-0 mt-4">
                            Cancel
                        </button>
                        <div className="w-25 d-none d-md-block" />
                    </div>
                    <div className="d-flex justify-content-center w-100 order-1 order-md-2">
                        <div className="w-25 d-none d-md-block" />
                        <button
                            id="upgrade-button"
                            disabled={!limits.isUpgradeAvailable}
                            onClick={() => setUpgradeStage(true)}
                            data-tooltip-content="You have pending upgrade request. We will send an email on confirmation"
                            className="btn btn-primary w-100 w-md-25">
                            Upgrade
                        </button>

                        {!limits.isUpgradeAvailable ? (
                            <Tooltip
                                place="top"
                                className={'tooltip-content'}
                                anchorId="upgrade-button"
                                offset={15}
                            />
                        ) : (
                            []
                        )}
                        <div className="w-50 d-none d-md-block" />
                    </div>
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <div className="loader-container" style={{ height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading...</h6>
                </div>
            </div>
        );
    };

    const savePaymentMethod = (paymentMethod: PaymentMethod) => {
        return axios.post('api/Wallet/PaymentMethod', {
            paymentMethodId: paymentMethod.id,
            shouldUpdatePaymentMethod: savedCard ? true : false,
        });
    };

    const submit = () => {
        setIsUpgrading(true);
        const upgradeRequest = { ...subscriptionRequest };
        const stripeRequest = stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement('cardNumber'),
        });
        if (
            !subscriptionRequest.isSavedPaymentMethod &&
            !shouldSavePaymentMethod
        ) {
            stripeRequest
                .then((result: PaymentMethodResult) => {
                    if (result?.paymentMethod) {
                        const paymentMethodId = result.paymentMethod.id;
                        upgradeRequest.paymentMethodTokenId = paymentMethodId;
                        savePaymentInfo(upgradeRequest);
                    } else if (result.error && result.error.message) {
                        createNotification(result.error.message, 'error');
                        setIsUpgrading(false);
                    }
                })
                .catch(() => {
                    createNotification(
                        'Something went wrong during card validation.',
                        'error'
                    );
                    setIsUpgrading(false);
                });
        } else if (shouldSavePaymentMethod) {
            stripeRequest
                .then((result: PaymentMethodResult) => {
                    if (result?.paymentMethod) {
                        let savePaymentPromise: Promise<any> =
                            Promise.resolve();
                        if (shouldSavePaymentMethod) {
                            savePaymentPromise = savePaymentMethod(
                                result.paymentMethod
                            );
                        }
                        savePaymentPromise
                            .then((response: any) => {
                                if (response && response.status != 200) {
                                    createNotification(
                                        response?.response?.data
                                            ? response.response.data
                                            : 'Something went wrong, could not save payment method.',
                                        'error'
                                    );
                                    setIsUpgrading(false);
                                } else {
                                    upgradeRequest.paymentMethodTokenId = null;
                                    upgradeRequest.isSavedPaymentMethod = true;
                                    savePaymentInfo(upgradeRequest);
                                }
                            })
                            .catch(() => {
                                setIsUpgrading(false);
                                createNotification(
                                    'Something went wrong, could not save payment method.',
                                    'error'
                                );
                            });
                    } else if (result.error && result.error.message) {
                        createNotification(result.error.message, 'error');
                        setIsUpgrading(false);
                    }
                })
                .catch(() => {
                    createNotification(
                        'Something went wrong during card validation.',
                        'error'
                    );
                    setIsUpgrading(false);
                });
        } else {
            savePaymentInfo(upgradeRequest);
        }
        setSubscriptionRequest(upgradeRequest);
    };

    const onApproval = () => {
        dispatch(LoadLimits());
        dispatch(FetchSummary()).then(() => {
            setTimeout(() => document.location.reload(), 500);
        });
    };

    const savePaymentInfo = (upgradeRequest: PostUserSubscriptionRequest) => {
        axios
            .post('/api/usersubscriptions/initiate', upgradeRequest)
            .then((response: any) => {
                if (
                    response &&
                    response.response?.status != 200 &&
                    response.response?.status != 204 &&
                    response.status != 200 &&
                    response.status != 204
                ) {
                    createNotification(
                        response?.response?.data?.title
                            ? response.response.data?.title
                            : 'Something went wrong during application submission.',
                        'error'
                    );
                    onClose();
                } else {
                    if (response.data) {
                        PaymentsHelper.waitForPaymentConfirmation(
                            axios,
                            onClose,
                            () => onApproval(),
                            'Your subscription has been successfully activated',
                            'An unexpected error ocurred during payment confirmation',
                            response.data
                        );
                    } else {
                        createNotification(
                            'Your application is on review. We will send an email notification after approval',
                            'success'
                        );
                        onClose();
                        setIsUpgrading(false);
                        dispatch(LoadLimits());
                    }
                }
            })
            .catch(() => {
                createNotification(
                    'Something went wrong during application submission.',
                    'error'
                );
                setIsUpgrading(false);
            });
    };

    const onClose = () => {
        setUpgradeStage(false);
        setIsUpgrading(false);
        props.onClose();
    };
    return props.isVisible ? (
        <div>
            <Rodal
                visible={!upgradeStage}
                animation="slideTop"
                onClose={onClose}
                showMask={false}
                width={950}
                closeOnEsc
                center
                className="upgrade-subscription-modal">
                <div className="text-center mt-3 mb-3">
                    <h3>Upgrade for Complete Access</h3>
                </div>
                <div>{isLoading ? renderLoader() : renderComponent()}</div>
            </Rodal>
            {upgradeStage ? (
                <Rodal
                    visible={upgradeStage}
                    animation="slideTop"
                    onClose={() => setUpgradeStage(false)}
                    showMask={false}
                    width={600}
                    closeOnEsc
                    center
                    className="upgrade-payment-modal">
                    <ModalHeader>
                        Credit Card Authorization: User Paid Subscription
                    </ModalHeader>
                    <div>
                        <CardPaymentForm
                            doesCardExist={savedCard}
                            setIsUseDifferentMethodChecked={(
                                isChecked: boolean
                            ) => {
                                setSubscriptionRequest({
                                    ...subscriptionRequest,
                                    isSavedPaymentMethod: !isChecked,
                                });
                            }}
                            isUseDifferentMethodChecked={
                                !subscriptionRequest.isSavedPaymentMethod
                            }
                            setIsCardSelected={(isChecked: boolean) => {
                                setSubscriptionRequest({
                                    ...subscriptionRequest,
                                    isSavedPaymentMethod: isChecked,
                                });
                            }}
                            isCardSelected={
                                subscriptionRequest.isSavedPaymentMethod
                            }
                            savedCard={savedCard}
                            shouldSavePaymentMethod={shouldSavePaymentMethod}
                            setShouldSavePaymentMethod={
                                setShouldSavePaymentMethod
                            }
                            setIsFormValid={(isValid) => {
                                setIsCardDataValid(isValid);
                            }}></CardPaymentForm>
                        <div className="action-buttons bootom_page_navigation">
                            <Button
                                className="btn-shadow float-left btn-wide btn-pill btn btn-outline-secondary"
                                color="secondary"
                                outline
                                onClick={() => setUpgradeStage(false)}>
                                Cancel
                            </Button>
                            <ButtonLoader
                                buttonText={
                                    'Submit Application and Authorize Payments'
                                }
                                loaderButtonText={
                                    'Submit Application and Authorize Payments'
                                }
                                disabled={!isCardDataVaild}
                                isLoading={isUpgrading}
                                onClick={() => {
                                    submit();
                                }}
                                className={
                                    'btn-shadow btn-wide float-right btn-pill btn-hover-shine submit-button btn btn-primary'
                                }></ButtonLoader>
                        </div>
                        <div className="default-div-image">
                            <img
                                src={PoweredByStripe}
                                alt={'userImage'}
                                className="default-image"
                            />
                        </div>
                    </div>
                </Rodal>
            ) : (
                <></>
            )}
        </div>
    ) : (
        <></>
    );
};

export default UpgradeSubscriptionModal;
