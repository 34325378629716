import React, { createRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './upload-files-modal.scss';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import uploadArrow from '../../assets/images/uploadArrow.png';
import Helpers from '../../utils/helper';
import { FILE_EXTENSIONS } from '../../utils/filesConstants';
import createNotification from '../../utils/createNotification';
import ButtonLoader from '../../components/Layout/Buttons/ButtonLoader';
import { Attachment } from '../models/attachment';
import { useStateSelector } from '../../store/selectors';

interface UploadFilesModalProps {
    isVisible: boolean;
    hide(): void;
    setUploadedFiles(files: Attachment[]): void;
    uploadedFiles: Attachment[];
    deleteUploadedFiles(files: Attachment[]): void;
}
const UploadFilesModal = (props: UploadFilesModalProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const dropzoneRef = createRef<DropzoneRef>();
    const [files, setFiles] = useState<File[]>([]);
    const maxSize = 104857600;
    const [uploadedFilesToDelete, setUploadedFilesToDelete] = useState<
        Attachment[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const allowedExtensions = FILE_EXTENSIONS.map(
        (e) => e.extensions?.join(',') ?? null
    )
        .filter(Boolean)
        .join(',');
    const openUploadDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const uploadFiles = async (files: File[]) => {
        setIsLoading(true);
        const formFiles = files.map((f) => {
            const formData = new FormData();
            formData.append('File', f);
            return formData;
        });
        const requests = formFiles.map((f) =>
            axios.post(`/api/temporaryfiles`, f, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        );
        const responses = await Promise.all(requests);
        if (uploadedFilesToDelete?.length > 0) {
            props.deleteUploadedFiles(uploadedFilesToDelete);
        }
        if (responses.some((r) => r.status != 200)) {
            createNotification(
                'Some files are not uploaded due to an unexpected error.',
                'error'
            );
        }
        props.setUploadedFiles(
            getUploadedFiles().concat(
                responses
                    .filter((r) => r.data)
                    .map(
                        (r) =>
                            new Attachment(r.data.id, r.data.name, r.data.size)
                    )
            )
        );
        setIsLoading(false);
        props.hide();
    };

    const deleteFile = (file: File) => {
        const index = files.indexOf(file);
        if (index > -1) {
            files.splice(index, 1);
            setFiles([...files]);
        }
    };

    const addFiles = (newFiles: File[]) => {
        newFiles = newFiles.filter(
            (nf) =>
                files.every(
                    (f) => f.name.toLowerCase() !== nf.name.toLowerCase()
                ) &&
                getUploadedFiles().every(
                    (f) => f.name.toLowerCase() !== nf.name.toLowerCase()
                )
        );
        if (newFiles.some((f) => f.size > maxSize)) {
            createNotification('Max file size is 100 Mb.', 'error');
            newFiles = newFiles.filter((f) => f.size <= maxSize);
        }
        if (newFiles.some((f) => f.size < 1)) {
            createNotification('You can not upload empty file.', 'error');
            newFiles = newFiles.filter((f) => f.size > 0);
        }
        if (files.length + newFiles.length + getUploadedFiles().length > 5) {
            createNotification(
                'You can only upload a maximum of 5 files',
                'error'
            );
            return;
        }
        setFiles(files.concat(newFiles));
    };

    const getUploadedFiles = () => {
        return props.uploadedFiles.filter(
            (uf) => !uploadedFilesToDelete.includes(uf)
        );
    };

    return (
        <div>
            <Modal
                className="knowledge-contetnt-popup app-upload-files"
                isOpen={props.isVisible}
                toggle={() => {
                    props.hide();
                }}>
                <div className="knowledge-contetnt-popup-header">
                    <ModalHeader
                        toggle={() => {
                            props.hide();
                        }}>
                        File to Upload
                    </ModalHeader>
                </div>

                <ModalBody className="edit-title-body edit-detail">
                    <Dropzone
                        noClick
                        noKeyboard
                        ref={dropzoneRef}
                        onDrop={(acceptedFiles: File[]) =>
                            addFiles(acceptedFiles)
                        }>
                        {({ getRootProps, getInputProps }) => {
                            return (
                                <div className="container">
                                    <div
                                        {...getRootProps({
                                            className: 'dropzone',
                                        })}>
                                        <input
                                            {...getInputProps()}
                                            accept={allowedExtensions}
                                        />
                                        <div>
                                            <img src={uploadArrow} />
                                        </div>
                                        <p
                                            className="drop-text"
                                            style={{
                                                fontSize: '13px',
                                                color: '#888f96',
                                            }}>
                                            Drop Files or Click to Upload
                                        </p>
                                        <span
                                            className="or-option"
                                            style={{
                                                fontSize: '13px',
                                                color: '#888f96',
                                            }}>
                                            ------ or -------
                                        </span>
                                        <button
                                            type="button"
                                            onClick={openUploadDialog}
                                            className="browse-btn">
                                            Browse Files
                                        </button>
                                    </div>
                                    <aside>
                                        <ul>
                                            {getUploadedFiles().map((file) => (
                                                <li
                                                    key={file.name}
                                                    className="file-info-item">
                                                    <div>
                                                        {file.name} -{' '}
                                                        {Helpers.formatBytes(
                                                            file.size
                                                        )}
                                                    </div>
                                                    <span
                                                        className="file-info-delete-button"
                                                        onClick={() => {
                                                            uploadedFilesToDelete.push(
                                                                file
                                                            );
                                                            setUploadedFilesToDelete(
                                                                [
                                                                    ...uploadedFilesToDelete,
                                                                ]
                                                            );
                                                        }}>
                                                        ×
                                                    </span>
                                                </li>
                                            ))}
                                            {files.map((file) => (
                                                <li
                                                    key={file.name}
                                                    className="file-info-item">
                                                    <div className="file-info-name">
                                                        {file.name} -{' '}
                                                        {Helpers.formatBytes(
                                                            file.size
                                                        )}
                                                    </div>
                                                    <span
                                                        className="file-info-delete-button"
                                                        onClick={() => {
                                                            deleteFile(file);
                                                        }}>
                                                        ×
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="canel-upload-wrapper">
                                            <button
                                                className="cancel-btn"
                                                onClick={() => props.hide()}>
                                                Cancel
                                            </button>
                                            <ButtonLoader
                                                buttonText={'Upload'}
                                                loaderButtonText={''}
                                                disabled={
                                                    files.length === 0 &&
                                                    getUploadedFiles()
                                                        .length === 0
                                                }
                                                isLoading={isLoading}
                                                onClick={() => {
                                                    uploadFiles(files);
                                                }}
                                                className={
                                                    isLoading
                                                        ? 'btn upload-button loader-submit'
                                                        : 'btn upload-button'
                                                }></ButtonLoader>
                                        </div>
                                    </aside>
                                </div>
                            );
                        }}
                    </Dropzone>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UploadFilesModal;
