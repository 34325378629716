import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from 'react-loaders';
import createNotification from '../../utils/createNotification';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { VERIFICATION_STATUS } from '../../utils/constants';
import Rodal from '../Rodal/Rodal';
import { useStateSelector } from '../../store/selectors';
import './ThankYou.scss';

const Thankyou = () => {
    const [loaderState, setLoaderState] = useState(false);
    const [verifiedState, setVerifiedState] = useState(
        VERIFICATION_STATUS.NOT_VERIFIED
    );
    const location = useLocation();
    const axios = useStateSelector((state) => state.core.axios);

    useEffect(() => {
        setLoaderState(true);
        if (
            location &&
            location.search &&
            location.search.indexOf('?user=') > -1
        ) {
            let hashKey = location.search.substring(
                location.search.indexOf('?user=') + 6,
                location.search.length
            );

            axios
                .post('/api/Users/VerifyUser', {
                    User: hashKey,
                })
                .then((response) => {
                    setLoaderState(false);
                    if (response && response.data) {
                        if (response.data.isError) {
                            if (
                                response.data.message ===
                                'You are already verified. Please login.'
                            ) {
                                setVerifiedState(
                                    VERIFICATION_STATUS.PREVIOUSLY_VERIFIED
                                );
                            }
                            createNotification(response.data.message, 'error');
                        } else if (
                            response.data.data &&
                            response.data.data.isNewUserVerified &&
                            response.data.data.isRegistrationConfirmed
                        ) {
                            setVerifiedState(VERIFICATION_STATUS.VERIFIED);
                            createNotification(
                                'Verified Successfully!',
                                'success'
                            );
                        } else {
                            setVerifiedState(VERIFICATION_STATUS.NOT_VERIFIED);
                            createNotification(
                                'Oops! The verification process failed! Please contact the administrator',
                                'error'
                            );
                        }
                    } else {
                        setVerifiedState(
                            VERIFICATION_STATUS.PREVIOUSLY_VERIFIED
                        );
                    }
                });
        }
    }, []);

    const renderLoader = () => {
        return (
            <div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">
                        Hold on a moment while we confirm your email address...
                    </h6>
                </div>
            </div>
        );
    };

    const renderConfirmationMessage = () => {
        return (
            <div className="thank-you">
                <Rodal
                    visible={verifiedState === VERIFICATION_STATUS.VERIFIED}
                    showMask={false}
                    width={710}
                    className="thank-you-rodal">
                    <ModalHeader>
                        Congratulations! You have succesfully completed Step 1,
                        the creation of your secure account.
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            Save your password securely. Your email and password
                            are required for login.
                        </p>
                        <p>
                            Mobile phone access is also needed during login - a
                            6-digit code will be sent via text for{' '}
                            <span className="no-wrap">2-factor</span>{' '}
                            authentication ("2FA") to ensure account security.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <h5 className="modal-title pr-0">
                            Now its time to complete Step 2, your registration
                            as a member of the EcoSystem.
                        </h5>
                        <Link
                            to="/login"
                            className="text-primary mb-2 mt-2 ml-0 btn-icon btn-link"
                            color="link">
                            Click here to login, in order to register for
                            membership on the EcoSystem
                        </Link>
                    </ModalFooter>
                </Rodal>

                <Rodal
                    visible={
                        verifiedState ===
                        VERIFICATION_STATUS.PREVIOUSLY_VERIFIED
                    }
                    showCloseButton={false}
                    showMask={false}>
                    <ModalHeader>Your account has been verified</ModalHeader>
                    <ModalBody>
                        <p>
                            Click{' '}
                            <Link
                                to="/login"
                                className="text-primary"
                                color="link">
                                here
                            </Link>{' '}
                            to enter the system with your credentials
                        </p>
                    </ModalBody>
                </Rodal>

                <Rodal
                    visible={verifiedState === VERIFICATION_STATUS.NOT_VERIFIED}
                    showMask={false}>
                    <ModalHeader>
                        Oops! Some error occurred in user confirmation.
                    </ModalHeader>
                </Rodal>
            </div>
        );
    };

    return loaderState ? renderLoader() : renderConfirmationMessage();
};

export default Thankyou;
