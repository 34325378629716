import { createSlice } from '@reduxjs/toolkit';
import {
    DirectMember,
    DirectMemberOnlineStatus,
} from '../../models/direct-member';
import { fetchMembers } from './fetch-members.thunk';
import { updateMember } from './update-member.thunk';
import { DirectMembersState } from './direct-members.state';
import { fetchCurrentMember } from './fetch-current-member.thunk';

let members: DirectMember[];

const initialState: DirectMembersState = {
    members: members,
    currentMember: undefined,
    isLoading: false,
    isInitiated: false,
    status: '',
    error: '',
};

const directMembersSlice = createSlice({
    name: 'directMembers',
    initialState,
    reducers: {
        updateOnlineStatus(state, action) {
            const status = action.payload as DirectMemberOnlineStatus;
            const member = state.members.find(
                (m) => m.userId === status.userId
            );
            if (member) {
                member.onlineState = status.onlineState;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMembers.pending, (state: DirectMembersState) => {
                setPendingState(state);
            })
            .addCase(
                fetchMembers.fulfilled,
                (state: DirectMembersState, action: any) => {
                    if (action.payload.status == 200) {
                        state.members = action.payload.data;
                        state.members.forEach((member) => mapMember(member));
                        setSucceededState(state);
                        state.isInitiated = true;
                    } else {
                        setRejectedState(state);
                    }
                }
            )
            .addCase(fetchMembers.rejected, (state: any) => {
                setRejectedState(state);
            })
            .addCase(updateMember.pending, (state: DirectMembersState) => {
                setPendingState(state);
            })
            .addCase(
                updateMember.fulfilled,
                (state: DirectMembersState, action: any) => {
                    if (action.payload.status == 200) {
                        let member: DirectMember = action.payload.data;
                        mapMember(member);
                        const index = state.members.findIndex(
                            (m) => m.userId === member.userId
                        );
                        if (index !== -1) {
                            state.members[index] = member;
                        } else {
                            state.members.push(member);
                        }
                        setSucceededState(state);
                    } else {
                        setRejectedState(state);
                    }
                }
            )
            .addCase(updateMember.rejected, (state: any) => {
                setRejectedState(state);
            })
            .addCase(fetchCurrentMember.rejected, (state: any) => {
                setRejectedState(state);
            })
            .addCase(
                fetchCurrentMember.pending,
                (state: DirectMembersState) => {
                    setPendingState(state);
                }
            )
            .addCase(
                fetchCurrentMember.fulfilled,
                (state: DirectMembersState, action: any) => {
                    if (action.payload.status == 200) {
                        state.currentMember = action.payload.data;
                        mapMember(state.currentMember.member);
                        setSucceededState(state);
                    } else {
                        setRejectedState(state);
                    }
                }
            );
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

const mapMember = (member: DirectMember) => {
    member.id = member.userId;
    member.fullName = member.firstName.concat(' ', member.lastName);
    member.initials = (
        member.firstName.charAt(0) + member.lastName.charAt(0)
    )?.toUpperCase();
    if (!member.profile) {
        member.profile = {
            affiliation: null,
            graduationYear: null,
            veterinarySchool: null,
            memberSince: null,
            isActive: false,
            practices: [],
            avatarPath: null,
        };
    }
};

export const { updateOnlineStatus } = directMembersSlice.actions;
export default directMembersSlice.reducer;
