import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notification } from '../../../clubhouse/models/notification';

export const addToMessageList = createAsyncThunk(
    'directMessages/add',
    (notification: Notification) => {
        return {
            message: notification.message,
            roomId: notification.chatId,
        };
    }
);
