import { createSlice } from '@reduxjs/toolkit';
import { SupportRequestsState } from './support-requests-state';
import { SupportThread } from '../../models/SupportThread';
import { SupportContextEnum } from '../../models/SupportContext';
import { fetchSupportThreads as fetchSupportThreads } from './fetch-support-threads.thunk';
import { SupportThreadInitiatorInfo } from '../../models/SupportThreadMemberInfo';

const initialState: SupportRequestsState = {
    threads: [],
    selectedThreadId: undefined,
    selectedContext: SupportContextEnum.Poc,
    isLoading: false,
    status: '',
    error: '',
    isInitiated: false,
    isNewRequestInitiated: false,
};

const supportRequestsSlice = createSlice({
    name: 'supportThreads',
    initialState,
    reducers: {
        setContext(state, action) {
            const newContext = action.payload as SupportContextEnum;
            state.selectedContext = newContext;

            const contextThreads = state.threads.filter((f) => {
                if (newContext === SupportContextEnum.Capvar) {
                    return (
                        f.supportContext === SupportContextEnum.Capvar ||
                        f.supportContext ===
                            SupportContextEnum.CapvarInterpretationSupport
                    );
                } else {
                    return f.supportContext === newContext;
                }
            });
            const isSelectedThreadInContext = !!contextThreads.find(
                (f) => f.id === state.selectedThreadId
            );

            if (!isSelectedThreadInContext) {
                state.selectedThreadId = contextThreads.find((f) => f)?.id;
            }
        },
        setSelectedThreadId(state, action) {
            const threadId = action.payload;
            if (state.threads.some((r) => r.id === threadId)) {
                state.selectedThreadId = threadId;
            } else {
                state.selectedThreadId =
                    state.threads.length > 0 ? state.threads[0].id : undefined;
            }
        },
        setIsNewRequestInitiated(state, action) {
            state.isNewRequestInitiated = action.payload;
        },
        addUpdateThread(state, action) {
            const thread = action.payload as SupportThread;
            if (state.threads.some((r) => r.id === thread.id)) {
                const index = state.threads.findIndex(
                    (r) => r.id === thread.id
                );
                state.threads[index] = { ...thread };
            } else {
                state.threads.push(thread);
                if (state.threads.length === 1) {
                    state.selectedThreadId = thread.id;
                }
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                fetchSupportThreads.pending,
                (state: SupportRequestsState) => {
                    setPendingState(state);
                }
            )
            .addCase(
                fetchSupportThreads.fulfilled,
                (state: SupportRequestsState, action: any) => {
                    const responseData: fetchSupportThreadsResponseData[] =
                        action.payload.data;
                    const threads: SupportThread[] = [];
                    responseData.forEach(
                        (m: fetchSupportThreadsResponseData) => {
                            const newThreads = mapSupportThreadsResponseData(m);
                            threads.push(...newThreads);
                        }
                    );
                    if (action.payload.status == 200) {
                        state.threads = threads;

                        if (
                            !state.selectedThreadId ||
                            threads.every(
                                (t) => t.id !== state.selectedThreadId
                            )
                        ) {
                            state.selectedThreadId =
                                threads.length > 0 ? threads[0].id : undefined;
                        }

                        setSucceededState(state);
                        state.isInitiated = true;
                    } else {
                        setRejectedState(state);
                    }
                }
            )
            .addCase(
                fetchSupportThreads.rejected,
                (state: SupportRequestsState) => {
                    setRejectedState(state);
                }
            );
    },
});

interface fetchSupportThreadsResponseData {
    initiator: SupportThreadInitiatorInfo;
    supportThreads: SupportThread[];
}

const mapSupportThreadsResponseData = (
    response: fetchSupportThreadsResponseData
): SupportThread[] => {
    let initiator: SupportThreadInitiatorInfo = response.initiator;

    const result = response.supportThreads.map((m) => {
        const newThread: SupportThread = {
            id: m.id,
            initiator: initiator,
            totalMessages: m.totalMessages,
            lastMessageDate: m.lastMessageDate,
            supportContext: m.supportContext,
            numberOfViewedMessages: m.numberOfViewedMessages,
            status: m.status,
            dateCreated: m.dateCreated,
            dateInitiated: m.dateInitiated,
        };

        return newThread;
    });
    return result;
};

const setRejectedState = (state: SupportRequestsState) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = JSON.stringify(state);
};

const setPendingState = (state: SupportRequestsState) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: SupportRequestsState) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

export const {
    setContext,
    setSelectedThreadId,
    addUpdateThread,
    setIsNewRequestInitiated,
} = supportRequestsSlice.actions;
export default supportRequestsSlice.reducer;
