import React, { Fragment, Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import logo from '../../../../assets/demo-ui/images/vetValue.png';

import { CardBody, Card, Col, Row, Table } from 'reactstrap';

import Moment from 'react-moment';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import Helpers from '../../../../utils/helper';
import { useParams } from 'react-router-dom';

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class FreemiumReportView extends Component {
    constructor(props) {
        super(props);
        this.currencyFormat.bind(this);
        this.state = {
            active: false,
            collapse: false,
            accordion: [true, true, true],
            custom: [true, false],
            status: 'Closed',
            fadeIn: true,
            timeout: 300,
            freemiumResult: {},
        };
    }

    componentDidMount() {
        const { id } = this.props.params;
        this.props.axios
            .get('api/FreemiumReport/' + id)
            .then((response) => response.data)
            .then((response) => {
                if (response.isError) {
                    return;
                }
                const { data } = response;
                this.setState({
                    freemiumResult: data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    currencyFormat(num) {
        return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    negativeNumberConverter(number) {
        return Number(number) < 0
            ? `(${Math.abs(Number(number)).toFixed(1)})`
            : `${Number(number).toFixed(1)} `;
    }

    render() {
        const result = this.state.freemiumResult;
        const { PreliminaryConclusion } = result;
        const imgMyimageexample = require('../../../../assets/demo-ui/images/vetValue.png');
        const divStyle = {
            background: `linear - gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${imgMyimageexample})`,
            backgroundPosition: '53% 45%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '55% 25%',
            border: '1px solid black',
            backgroundColor: 'rgba(255,255,255,0.7)',
            // backgroundBlendMode: 'lighten',
        };
        const header = {
            color: 'rgb(83, 104, 114)',
            fontWeight: 700,
        };
        const btop = {
            borderTop: '1px solid #808080',
            padding: '0.75rem 1.25rem',
            marginBottom: 0,
            borderBottom: '1px solid #808080',
        };
        return (
            <Fragment>
                <div
                    className={cx('app-inner-layout chat-layout', {
                        'open-mobile-menu': this.state.active,
                    })}>
                    <CSSTransition
                        classNames="TabsAnimation"
                        in={true}
                        appear={true}
                        enter={false}
                        exit={false}
                        timeout={1000}>
                        <div className="free-report">
                            <div className="app-inner-layout__wrapper row-fluid no-gutters">
                                <Card className="col-md-12 app-inner-layout__content">
                                    <div className="pb-5 pl-5 pr-5 pt-3">
                                        <div>
                                            <div className="mt-3 mb-3">
                                                <Card style={divStyle}>
                                                    <Row className="">
                                                        <Col
                                                            xs
                                                            md={3}
                                                            className="logo-pos">
                                                            <img
                                                                width="149"
                                                                height="82"
                                                                alt="logo"
                                                                src={
                                                                    logo
                                                                }></img>
                                                        </Col>
                                                        <Col
                                                            xs
                                                            md={6}
                                                            className="txt-center">
                                                            <h2
                                                                style={header}
                                                                className="mt-4">
                                                                Summary
                                                                Valuation Report
                                                            </h2>
                                                        </Col>

                                                        <Col xs md={3}>
                                                            <Table
                                                                className="mb-0 color-lt-green"
                                                                borderless
                                                                responsive="sm">
                                                                <tbody
                                                                    style={{
                                                                        fontSize:
                                                                            '17px',
                                                                        float: 'right',
                                                                    }}>
                                                                    <tr>
                                                                        <td>
                                                                            <b className="font-header">
                                                                                Hospital
                                                                            </b>
                                                                        </td>
                                                                        <td>
                                                                            <b className="font-header">
                                                                                {
                                                                                    result.PracticeName
                                                                                }
                                                                            </b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b className="font-header">
                                                                                Date
                                                                            </b>
                                                                        </td>
                                                                        <td>
                                                                            <b className="font-header">
                                                                                {' '}
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {
                                                                                        result.ReportDate
                                                                                    }
                                                                                </Moment>
                                                                            </b>
                                                                        </td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                    <td style={{ color: "rgb(83, 104, 114)" }}><b>LTM Period Ended</b></td>
                                                                    <td style={{ color: "rgb(83, 104, 114)" }}>
                                                                        <b>  <Moment format="YYYY-MM-DD">
                                                                            {result.LTMPeriodEnd}
                                                                        </Moment></b>
                                                                    </td>
                                                                </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>

                                                    {result.KeyFinancialInputs && (
                                                        <div className="section1">
                                                            <div
                                                                style={btop}
                                                                className="b-radius-0"
                                                                id="headingTwo">
                                                                <div className="text-left m-0 p-0">
                                                                    <h3
                                                                        style={
                                                                            header
                                                                        }
                                                                        className="text-left m-0 p-0 form-heading">
                                                                        1.
                                                                        Preliminary
                                                                        Conclusion
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <CardBody
                                                                style={{
                                                                    padding:
                                                                        ' 1.25rem 2rem',
                                                                }}>
                                                                <span className="font-20 font-weight-600">
                                                                    Based on the
                                                                    metrics
                                                                    provided our
                                                                    estimated
                                                                    valuation
                                                                    for your
                                                                    practice is{' '}
                                                                </span>
                                                                <br />
                                                                <br />
                                                                <Row>
                                                                    <Col className="txt-center">
                                                                        <b className="font-20 font-family-serif color-black">
                                                                            <span className="ml-2 mr-2">
                                                                                {Helpers.currencyFormat(
                                                                                    PreliminaryConclusion[0]
                                                                                        .Valuation
                                                                                )}
                                                                            </span>
                                                                            {PreliminaryConclusion[0]
                                                                                .Valuation !==
                                                                            PreliminaryConclusion[1]
                                                                                .Valuation ? (
                                                                                <>
                                                                                    <span>
                                                                                        to
                                                                                    </span>
                                                                                    <span className="ml-2 mr-2">
                                                                                        {Helpers.currencyFormat(
                                                                                            PreliminaryConclusion[1]
                                                                                                .Valuation
                                                                                        )}
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                []
                                                                            )}
                                                                        </b>
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                                <br />
                                                                {result.IsRetiringIn2Years ? (
                                                                    <Row className="margin-bt-20">
                                                                        <Col className="txt-center">
                                                                            <i className="font-1rem font-weight-400">
                                                                                <span className="color-brinjal">
                                                                                    Practicing
                                                                                    owners{' '}
                                                                                </span>{' '}
                                                                                retiring
                                                                                from
                                                                                clinical
                                                                                practice
                                                                                in
                                                                                the
                                                                                near-term
                                                                                incur
                                                                                a
                                                                                risk
                                                                                adjustment
                                                                                to
                                                                                their
                                                                                clinical
                                                                                production
                                                                            </i>
                                                                        </Col>
                                                                    </Row>
                                                                ) : null}
                                                                <Row>
                                                                    <Col className="txt-center">
                                                                        <i className="font-1rem font-weight-400 color-lt-red">
                                                                            NOTE:
                                                                            Whether
                                                                            you
                                                                            can
                                                                            achieve
                                                                            this
                                                                            price,
                                                                            or a
                                                                            higher
                                                                            price
                                                                            in a
                                                                            sale,
                                                                            depends
                                                                            on
                                                                            the
                                                                            sale
                                                                            process
                                                                            you
                                                                            run.
                                                                        </i>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </div>
                                                    )}

                                                    {result.KeyFinancialInputs && (
                                                        <div className="section1">
                                                            <div
                                                                style={btop}
                                                                className="b-radius-0"
                                                                id="headingTwo">
                                                                <div className="text-left m-0 p-0">
                                                                    <h3
                                                                        style={
                                                                            header
                                                                        }
                                                                        className="text-left m-0 p-0 form-heading">
                                                                        2. Key
                                                                        Inputs
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <CardBody
                                                                style={{
                                                                    padding:
                                                                        ' 1.25rem 2rem',
                                                                }}>
                                                                <Row className="jst-content-center">
                                                                    <Col
                                                                        lg={{
                                                                            span: 4,
                                                                        }}
                                                                        md={{
                                                                            span: 2,
                                                                        }}>
                                                                        <Table
                                                                            borderless
                                                                            responsive="sm">
                                                                            <thead className="font-family-serif ">
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                    <th>
                                                                                        LTM
                                                                                    </th>
                                                                                </tr>
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}>
                                                                                    <th></th>
                                                                                    <th>
                                                                                        <u>
                                                                                            CY{' '}
                                                                                            {
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .CY2
                                                                                            }
                                                                                        </u>
                                                                                    </th>
                                                                                    <th>
                                                                                        <u>
                                                                                            CY{' '}
                                                                                            {
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .CY1
                                                                                            }
                                                                                        </u>
                                                                                    </th>
                                                                                    <th>
                                                                                        <u>
                                                                                            {
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .CY
                                                                                            }
                                                                                        </u>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'left',
                                                                                    }}>
                                                                                    <td>
                                                                                        <u>
                                                                                            Key
                                                                                            Financials
                                                                                        </u>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '30px',
                                                                                        }}>
                                                                                        <b>
                                                                                            Gross
                                                                                            Revenue
                                                                                        </b>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <NumericFormat
                                                                                            value={parseInt(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .LTM2Revenue
                                                                                            )}
                                                                                            displayType={
                                                                                                'text'
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            prefix={
                                                                                                '$'
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <NumericFormat
                                                                                            value={parseInt(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .LTM1Revenue
                                                                                            )}
                                                                                            displayType={
                                                                                                'text'
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            prefix={
                                                                                                '$'
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <NumericFormat
                                                                                            value={parseInt(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .LTMRevenue
                                                                                            )}
                                                                                            displayType={
                                                                                                'text'
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            prefix={
                                                                                                '$'
                                                                                            }
                                                                                        />{' '}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingRight:
                                                                                                '70px',
                                                                                        }}>
                                                                                        <i>
                                                                                            %
                                                                                            Growth
                                                                                        </i>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <i>
                                                                                            {this.negativeNumberConverter(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .GrowthPercentLTM1
                                                                                            )}

                                                                                            %
                                                                                        </i>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <i>
                                                                                            {this.negativeNumberConverter(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .GrowthPercentLTM
                                                                                            )}

                                                                                            %
                                                                                        </i>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}>
                                                                                    <td>
                                                                                        <i>
                                                                                            Gross
                                                                                            Margin
                                                                                            (%)
                                                                                        </i>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <i>
                                                                                            {this.negativeNumberConverter(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .GrossMarginLTM
                                                                                            )}

                                                                                            %
                                                                                        </i>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '30px',
                                                                                        }}>
                                                                                        <b>
                                                                                            EBITDA
                                                                                        </b>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <NumericFormat
                                                                                            value={parseInt(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .EBITDA
                                                                                            )}
                                                                                            displayType={
                                                                                                'text'
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            prefix={
                                                                                                '$'
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '25px',
                                                                                        }}>
                                                                                        <i>
                                                                                            EBITDA
                                                                                            Margin
                                                                                            (%)
                                                                                        </i>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <i>
                                                                                            {this.negativeNumberConverter(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .EBITDAMargin
                                                                                            )}
                                                                                        </i>

                                                                                        %
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'left',
                                                                                    }}>
                                                                                    <td>
                                                                                        <u>
                                                                                            Productivity
                                                                                        </u>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '30px',
                                                                                        }}>
                                                                                        Rev
                                                                                        /
                                                                                        FTE
                                                                                        Dvm{' '}
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}>
                                                                                        -
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            textAlign:
                                                                                                'right',
                                                                                        }}>
                                                                                        <NumericFormat
                                                                                            value={parseInt(
                                                                                                result
                                                                                                    .KeyFinancialInputs
                                                                                                    .RevPerFTE
                                                                                            )}
                                                                                            displayType={
                                                                                                'text'
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            prefix={
                                                                                                '$'
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="header-color">
                                                                    {/* <Col><i>Note: FTE DVM assumes {result.FullTimeHrsPerWeek} hours worked per week. EBITDA adjusted to normalize owner clinical and management compensation.</i></Col> */}
                                                                    <Col>
                                                                        <i>
                                                                            EBITDA
                                                                            adjusted
                                                                            to
                                                                            normalize
                                                                            owner
                                                                            clinical
                                                                            and
                                                                            management
                                                                            compensation
                                                                        </i>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </div>
                                                    )}

                                                    {
                                                        <div className="section1 header-color">
                                                            <div
                                                                style={btop}
                                                                className="b-radius-0"
                                                                id="headingTwo">
                                                                <div className="text-left m-0 p-0">
                                                                    <h3
                                                                        style={
                                                                            header
                                                                        }
                                                                        className="text-left m-0 p-0 form-heading">
                                                                        About
                                                                        VetValue.pet
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <Row>
                                                                <Col>
                                                                    <div
                                                                        style={{
                                                                            fontSize:
                                                                                ' 17px',
                                                                            padding:
                                                                                ' 1rem',
                                                                            fontWeight:
                                                                                '500',
                                                                        }}>
                                                                        <p>
                                                                            VetValue
                                                                            is a
                                                                            data-based
                                                                            provider
                                                                            of
                                                                            enterprise
                                                                            valuation,
                                                                            practice
                                                                            brokerage
                                                                            and
                                                                            financial
                                                                            benchmarking
                                                                            services
                                                                            to
                                                                            the
                                                                            veterinary
                                                                            services
                                                                            industry.
                                                                            Founded
                                                                            in
                                                                            2020,
                                                                            our
                                                                            principals
                                                                            have
                                                                            created
                                                                            in
                                                                            excess
                                                                            of
                                                                            $1BN
                                                                            of
                                                                            wealth
                                                                            for
                                                                            independent
                                                                            veterinary
                                                                            practice
                                                                            owners.{' '}
                                                                        </p>

                                                                        <p>
                                                                            For
                                                                            more
                                                                            information
                                                                            on
                                                                            any
                                                                            of
                                                                            our
                                                                            services
                                                                            please
                                                                            review
                                                                            our
                                                                            website,
                                                                            our
                                                                            members
                                                                            area
                                                                            and
                                                                            our
                                                                            resources.
                                                                            To
                                                                            speak
                                                                            with
                                                                            a
                                                                            veterinary
                                                                            practice
                                                                            financial
                                                                            services
                                                                            expert,
                                                                            call
                                                                            us
                                                                            at
                                                                            (561)
                                                                            666-3081,
                                                                            or
                                                                            email
                                                                            us:
                                                                            Connect
                                                                            Practice
                                                                            Brokerage:
                                                                            jerry@vetvalue.pet;
                                                                            Practice
                                                                            Valuation:
                                                                            carson@vetvalue.pet;
                                                                            all
                                                                            other:
                                                                            info@vetvalue.pet
                                                                        </p>

                                                                        {/* <p>Founded in 2020 to provide financial services for independent veterinary practices navigating a
                                                                changing industry. We provide independent veterinary practice owners with practice valuations
                                                                that are accurate, inexpensive, quick and easy.</p>

                                                                <p>We improve valuation accuracy by applying our superior knowledge of private market veterinary
                                                                practice transaction values.</p>

                                                                <p><b>More Efficient</b> - No more consultant site visits, or multi-hour phone calls. We have made valuations
                                                                quicker and cheaper by offering a web-based application to manage your entire valuation</p>

                                                                <p><b>More Accurate</b> - We have applied our superior knowledge of private market veterinary practice transaction values to better estimate the value of your practice in today’s market</p>

                                                                <p><b>More Intelligence</b> - Our valuation reports help you manage your practice more effectively today, or better prepare for a practice transition</p> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="justify-content-md-center text-center align-center mt-3 color-black">
                                                                <Col
                                                                    xs
                                                                    lg={{
                                                                        span: 3,
                                                                    }}>
                                                                    <Moment format="MM/DD/YYYY">
                                                                        {
                                                                            result.ReportDate
                                                                        }
                                                                    </Moment>
                                                                </Col>
                                                                <Col
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                    }}
                                                                    xs
                                                                    lg={{
                                                                        span: 3,
                                                                    }}>
                                                                    <a
                                                                        className="color-black"
                                                                        href="https://vetvalue.pet"
                                                                        target="_blank">
                                                                        www.vetvalue.pet
                                                                    </a>{' '}
                                                                    <br></br>
                                                                    <i>
                                                                        Proprietary
                                                                        and
                                                                        Confidential
                                                                    </i>
                                                                </Col>
                                                                <Col
                                                                    xs
                                                                    lg={{
                                                                        span: 6,
                                                                    }}>
                                                                    1
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </Card>
                                            </div>
                                            <div className="mt-5" />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    axios: state.core.axios,
});

export default withParams(connect(mapStateToProps, null)(FreemiumReportView));
