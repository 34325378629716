import { createAsyncThunk } from '@reduxjs/toolkit';

export const DoesMemberAuthorizedInPoc = createAsyncThunk(
    'clubhouse/doesMemberAuthorizedInPoc',
    async (_args, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const response = await axios.get(`/api/clubhouses/pocprofile`);
        return response;
    }
);
