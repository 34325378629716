import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { useStateSelector } from '../../store/selectors';
import createNotification from '../../utils/createNotification';
import ManualUploadsButton from '../ManualUploadsButton/ManualUploadsButton';
import './UserDocumentsTable.scss';
import moment from 'moment';
import { DocumentTypeEnum } from '../../models/Document/DocumentTypeEnum';
import { Document } from '../../models/Document/Document';
import ConfirmationModal from '../Modal/ConfirmationModal';

interface UserDocumentsTableProps {
    userId: number;
    documents: Document[];
    refrechDocumentsList(): void;
}

const UserDocumentsTable = (props: UserDocumentsTableProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [
        isDeleteConfirmationPopupVisible,
        setIsDeleteConfirmationPopupVisible,
    ] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<Document>();
    const { documents, userId } = props;

    const downloadSignedDocument = (data: Document) => {
        axios
            .post('/api/Documents/DownloadUserDocument', {
                DocTypeId: data.docTypeId,
                UserId: props.userId,
            })
            .then((response) => {
                if (response && response.data && response.data.isError) {
                    createNotification(
                        'Sorry, there was some error while downloading document. Please try again later',
                        'error'
                    );
                } else {
                    createNotification(
                        'Document was downloaded successfully',
                        'success'
                    );
                    const newWindow = window.open(response.data.data);
                    if (newWindow) newWindow.opener = null;
                }
            })
            .catch((err) => console.log(err));
    };

    const downloadManuallyUploadedDocument = (data: Document) => {
        axios
            .get(`/api/adminManualUploadedDocuments/${data.documentId}/fileUrl`)
            .then((response) => {
                if (response.status === 200) {
                    createNotification(
                        'Document was downloaded successfully',
                        'success'
                    );
                    const newWindow = window.open(response.data);
                    if (newWindow) newWindow.opener = null;
                } else {
                    createNotification(
                        'Sorry, there was some error while downloading document. Please try again later',
                        'error'
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    const downloadDocument = (data: Document) => {
        switch (data.docTypeId) {
            case DocumentTypeEnum.AdminManualUpload:
                downloadManuallyUploadedDocument(data);
                break;
            default:
                downloadSignedDocument(data);
                break;
        }
    };

    const deleteManuallyUploadedDocument = (data: Document) => {
        axios
            .delete(`/api/adminManualUploadedDocuments`, {
                params: { documentId: data.documentId },
            })
            .then((response) => {
                if (response.status === 200) {
                    props.refrechDocumentsList();
                } else {
                    createNotification(
                        'An error occured on document removal. Please try again later',
                        'error'
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    const deleteDocument = (data: Document) => {
        switch (data.docTypeId) {
            case DocumentTypeEnum.AdminManualUpload:
                deleteManuallyUploadedDocument(data);
                break;
            default:
                break;
        }
    };

    const renderTableRow = (document: Document, index: number) => {
        return (
            <tr>
                <th scope="row">{index + 1}</th>
                <td>{document.documentName}</td>
                <td>
                    {document.timeOfUpload
                        ? moment(document.timeOfUpload).format('L')
                        : 'NA'}
                </td>
                <td style={{ textAlign: 'center' }}>
                    <div className="action-buttons-wrapper">
                        <FontAwesomeIcon
                            icon={faDownload}
                            onClick={() => downloadDocument(document)}
                            className="action-button"
                        />
                        {document.docTypeId ===
                        DocumentTypeEnum.AdminManualUpload ? (
                            <FontAwesomeIcon
                                icon={faTrashAlt as any}
                                onClick={() => {
                                    setSelectedDocument(document);
                                    setIsDeleteConfirmationPopupVisible(true);
                                }}
                                className="action-button"
                            />
                        ) : (
                            []
                        )}
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="user-documents-table">
            <div className="manual-uploader-section">
                <ManualUploadsButton
                    targetUserId={userId}
                    onSuccessfullUpload={props.refrechDocumentsList}
                />
            </div>

            {documents.length > 0 ? (
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{documents.map(renderTableRow)}</tbody>
                </Table>
            ) : (
                <div className="empty-msg">No Data found</div>
            )}
            {isDeleteConfirmationPopupVisible ? (
                <div className="document-removal-modal">
                    <ConfirmationModal
                        isVisible={true}
                        setIsVisible={setIsDeleteConfirmationPopupVisible}
                        header={'Document Removal Confirmation'}
                        confirmationText={`Are you sure you want to delete ${selectedDocument.documentName} document?`}
                        onConfirm={deleteDocument}
                        nextButtonText={'Delete Document'}
                        cancelButtonText={'Cancel'}
                        params={selectedDocument}
                    />
                </div>
            ) : (
                []
            )}
        </div>
    );
};

export default UserDocumentsTable;
