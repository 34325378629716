import React, { useState, useEffect } from 'react';
import './UserSubscriptionsPaymentHistoryPaginatedTable.scss';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { DropdownList } from 'react-widgets/cjs';
import { FormGroup } from 'reactstrap';
import Loader from 'react-loaders';
import { useStateSelector } from '../../../../store/selectors';
import Helpers from '../../../../utils/helper';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import createNotification from '../../../../utils/createNotification';
import { objectToQueryString } from '../../../../utils/queryHelper';
import UserDetailsModal from '../../../Admin/Permissions/Users/Modal/UserDetailsModal';
import { ArrowDown } from '../../../../components/arrow-down/arrow-down';
import moment from 'moment';

interface UserSubscriptionsPayment {
    transactionId: string;
    userId: number;
    userName: string;
    type: string;
    dueDate: string | Date;
    paymentDate: string | Date;
    price: number;
    paymentStatus: string;
    paymentMethod: string;
}

interface UserSubscriptionsPaymentHistoryPaginatedTableProps {
    memberNameOptions: Array<any>;
    isUserDetailsAvailable?: boolean;
}
const UserSubscriptionsPaymentHistoryPaginatedTable = (
    props: UserSubscriptionsPaymentHistoryPaginatedTableProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
        useState(false);
    const [userId, setUserID] = useState(0);
    const tableTooltipTargetId = 'subscriptions-history-paginated-table';
    const defaultSortDirection: { dataField: any; order: any } = {
        dataField: 'paymentDate',
        order: 'desc',
    };

    const [sortingState, setSortingState] = useState<any>({
        data: [],
        filters: {},
        page: page,
        searchText: '',
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });

    const [tableFilters, setTalbeFilters] = useState<
        { field: string; value: string; operator: string }[]
    >([]);
    useEffect(() => {
        if (
            props.memberNameOptions.length === 1 &&
            props.memberNameOptions[0]?.id
        ) {
            handleMemberNameChange(props.memberNameOptions[0].id);
        }
    }, [props]);

    useEffect(() => {
        if (sortingState) {
            fetchSubscriptionsData(sortingState);
        }
    }, [sortingState, tableFilters]);

    const handleTypeNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'type');
        if (changedProperty) {
            newFilters.push({
                field: 'type',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handleMemberNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'userId');
        if (changedProperty) {
            newFilters.push({
                field: 'userId',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handlePaymentStatusChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'paymentStatus');
        if (changedProperty) {
            newFilters.push({
                field: 'paymentStatus',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const showUserDetailPopup = (userId: number) => {
        setIsUserDetailsModalVisible(true);
        setUserID(userId);
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'userName',
            text: 'Member Name',
            sort: true,
            formatter: (_cellContent: any, row: UserSubscriptionsPayment) => (
                <div
                    className={
                        props.isUserDetailsAvailable ? 'referenced-text' : ''
                    }
                    onClick={() =>
                        props.isUserDetailsAvailable
                            ? showUserDetailPopup(row.userId)
                            : []
                    }>
                    {row.userName}
                </div>
            ),
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
        },
        {
            dataField: 'transactionId',
            text: 'TX Number',
            sort: true,
        },
        {
            dataField: 'dueDate',
            text: 'Due Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'paymentDate',
            text: 'Payment Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'price',
            text: 'Subscription Amount',
            formatter: (cellContent: number) =>
                Helpers.currencyFormatWithBrackets(cellContent, true, true),
            sort: true,
        },
        {
            dataField: 'paymentStatus',
            text: 'Payment Status',
            sort: true,
        },
        {
            dataField: 'paymentMethod',
            text: 'Payment Method',
            sort: true,
            formatter: (cellContent: string) => (
                <div>
                    {cellContent.split(', ').map((v) => (
                        <div>{v}</div>
                    ))}
                </div>
            ),
        },
    ];

    const handleTableChange = (_type: any, newState: any) => {
        setSortingState(newState);
    };

    const fetchSubscriptionsData = (sortingState: any) => {
        let tableFilter = new TableFilter(
            { filters: tableFilters, logic: '' },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder,
                field: sortingState.sortField,
            }
        );

        axios
            .get(
                `api/UserSubscriptions/paymentsHistory?${objectToQueryString(
                    tableFilter
                )}`
            )
            .then((response: any) => {
                if (response?.status === 200) {
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'Error occured while fetching subscriptions payment history',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while fetching subscriptions payment history',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <div className="user-subscriptions-payment-history-paginated-table">
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '200px',
                        }}>
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                ) : (
                    <>
                        <div className="filters-bar">
                            <FormGroup className="filter-scroller">
                                <div className="filter-item member-input">
                                    <label>Type</label>
                                    <DropdownList
                                        className="credit-type-select filter-enabled"
                                        selectIcon={<ArrowDown />}
                                        data={[
                                            { id: null, displayName: 'None' },
                                            {
                                                id: 'Annual',
                                                displayName: 'Annual',
                                            },
                                            {
                                                id: 'Monthly',
                                                displayName: 'Monthly',
                                            },
                                        ]}
                                        onChange={(value) => {
                                            handleTypeNameChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="displayName"
                                    />
                                </div>
                                <div className="line-separator"></div>
                                <div className="filter-item member-input">
                                    <label>Member Name</label>
                                    <DropdownList
                                        className="credit-type-select filter-enabled"
                                        selectIcon={<ArrowDown />}
                                        data={props.memberNameOptions}
                                        value={
                                            props.memberNameOptions.length ===
                                                1 &&
                                            props.memberNameOptions[0]?.id
                                                ? {
                                                      ...props
                                                          .memberNameOptions[0],
                                                  }
                                                : undefined
                                        }
                                        disabled={
                                            props.memberNameOptions.length === 1
                                        }
                                        onChange={(value) => {
                                            handleMemberNameChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                </div>
                                <div className="line-separator"></div>
                                <div className="filter-item member-input">
                                    <label>Payment Status</label>
                                    <DropdownList
                                        className="credit-type-select"
                                        selectIcon={<ArrowDown />}
                                        data={[
                                            { id: null, displayName: 'None' },
                                            {
                                                id: 'Pending',
                                                displayName: 'Pending',
                                            },
                                            {
                                                id: 'Confirmed',
                                                displayName: 'Confirmed',
                                            },
                                            {
                                                id: 'Declined',
                                                displayName: 'Declined',
                                            },
                                        ]}
                                        onChange={(value) => {
                                            handlePaymentStatusChange(value.id);
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="displayName"
                                    />
                                </div>
                            </FormGroup>
                        </div>
                        <BootstrapTable
                            remote
                            bootstrap4
                            keyField="id"
                            data={tableData}
                            columns={getColumns()}
                            defaultSorted={[defaultSortDirection]}
                            filter={filterFactory()}
                            pagination={paginationFactory({
                                page,
                                sizePerPage,
                                totalSize,
                                tooltipTargetId: tableTooltipTargetId,
                            })}
                            onTableChange={handleTableChange}
                            noDataIndication={
                                'No Data available'
                            }></BootstrapTable>
                    </>
                )}
            </div>
            {isUserDetailsModalVisible && (
                <UserDetailsModal
                    onClose={() => {
                        setIsUserDetailsModalVisible(false);
                    }}
                    isVisisble={isUserDetailsModalVisible}
                    userID={userId}></UserDetailsModal>
            )}
        </div>
    );
};

export default UserSubscriptionsPaymentHistoryPaginatedTable;
