import React, { useEffect, useRef, useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    FormGroup,
    InputGroup,
    InputGroupText,
    Label,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import './poc-profile-forms.scss';
import { Form, Field, Input } from '@availity/form';
import '@availity/yup';
import { PocRegistrationData } from '../models/pocRegistrationData';
import { Practice } from '../models/practice';
import { TextareaAutosize } from '@mui/material';
import createNotification from '../../utils/createNotification';
import ButtonLoader from '../../components/Layout/Buttons/ButtonLoader';
import AvatarCroppingTool from '../avatar-cropping-tool/avatar-cropping-tool';
import { ProfileInfo } from '../models/profile-info';
import * as yup from 'yup';
import { StateAbbreviationService } from '../../utils/stateAbbreviationHelper';
import Helpers from '../../utils/helper';
import { useStateSelector } from '../../store/selectors';
import { PRACTICE_OWNER_TYPES } from '../../utils/constants';

enum modalMode {
    create = 'create',
    edit = 'edit',
}

export interface PocProfileFormsProps {
    cancelModal(): void;
    closeModal(): void;
    registrationData: PocRegistrationData;
    profile?: ProfileInfo;
    modalMode: string;
    refreshPoc?(): void;
    corporationName: string;
}

export class ProfileContract implements ProfileInfo {
    constructor(
        public veterinarySchoolId: number,
        public graduationYear: number,
        public affiliations: string,
        public avatarPath: string,
        public otherSchoolName: string
    ) {}
}

export const PocProfileForms = (props: PocProfileFormsProps) => {
    const summary = useStateSelector((state) => state.userSummary.summary);
    const [isLoading, setIsLoading] = useState(false);
    const [schoolId, setSchoolId] = useState<number>();
    const [graduationYear, setGraduationYear] = useState<number>();
    const axios = useStateSelector((state) => state.core.axios);
    const [graduationYears, setGraduationYears] = useState<Array<number>>();
    const [affiliations, setAffiliations] = useState<string>();
    const [isCroppingModalVisible, setIsCroppingModalVisible] = useState(false);
    const [newerFile, setNewerFile] = useState<File>();
    const modalForm = useRef(null);
    const [selectedFile, setSelectedFile] = useState<File>();
    const allowedImageExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
    const maxFileSize = 5 * 1024 * 1024;
    const [isOtherFieldVisible, setIsOtherFieldVisible] = useState(
        props.profile?.veterinarySchoolId == -1
    );
    const [otherFieldValue, setOtherFieldValue] = useState(
        props.profile?.veterinarySchoolId == -1
            ? props.profile?.otherSchoolName ?? ''
            : ''
    );

    useEffect(() => {
        let years = [];
        for (var i = 1950; i <= new Date().getFullYear(); i++) {
            years.unshift(i);
        }

        setGraduationYears(years);
        if (props.modalMode === modalMode.edit) {
            setSchoolId(props.profile?.veterinarySchoolId);
            setGraduationYear(props.profile?.graduationYear);
            setAffiliations(props.profile?.affiliations);
        } else {
            let schoolId = getSchoolId(props.registrationData.veterinarySchool);
            if (schoolId != 0) {
                setSchoolId(schoolId);
            }
            setGraduationYear(props.registrationData?.graduationYear);
            setAffiliations(props.registrationData?.affiliations);
        }
    }, []);

    const initSubmit = () => {
        modalForm.current.handleSubmit();
    };

    const getSchoolId = (name?: string): number => {
        let schoolId = props.registrationData.veterinarySchools?.find(
            (school) => school.value === name
        )?.key;
        if (!schoolId) {
            return name ? -1 : 0;
        }

        return schoolId;
    };

    const submit = () => {
        setIsLoading(true);
        const profile = new ProfileContract(
            schoolId,
            graduationYear,
            affiliations,
            props?.profile?.avatarPath,
            otherFieldValue
        );
        const formData = new FormData();
        formData.append('File', newerFile);
        formData.append('Data', JSON.stringify(profile));
        if (props.modalMode === modalMode.create) {
            axios
                .post('/api/clubhouses/pocprofile', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response: any) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        props.closeModal();
                        if (typeof props.refreshPoc === 'function') {
                            props.refreshPoc();
                        }
                    } else {
                        createNotification(
                            'Something went wrong while creating profile!',
                            'error'
                        );
                    }
                });
        }

        if (props.modalMode === modalMode.edit) {
            axios
                .put('/api/clubhouses/pocprofile', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response: any) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        props.closeModal();
                    } else {
                        createNotification(
                            'Something went wrong while editing profile!',
                            'error'
                        );
                    }
                });
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.currentTarget.files[0];
        if (!selectedFile) {
            return;
        }
        if (
            allowedImageExtensions.every((e) => !selectedFile.name.endsWith(e))
        ) {
            createNotification(
                'You are allowed to upload only jpg, jpeg, png or gif files.',
                'error'
            );
            return;
        }
        if (selectedFile.size > maxFileSize) {
            createNotification('Max file size is 5 Mb.', 'error');
            return;
        }
        const url = URL.createObjectURL(selectedFile);
        const img = new Image();
        img.src = url;
        img.onerror = () => {
            createNotification(
                'The file cannot be read, please try other one.',
                'error'
            );
        };
        img.onload = () => {
            if (img.height < 70 || img.width < 70) {
                createNotification(
                    'The picture should be at least 70px width and 70px height.',
                    'error'
                );
                return;
            }
            setSelectedFile(selectedFile);
            setIsCroppingModalVisible(true);
        };
    };

    const getFileName = (path: string): string => {
        if (!path) {
            return '';
        }
        var fileName = path?.replace(/^.*[\\\/]/, '');
        var fileId = fileName?.slice(fileName.length - 22, fileName.length - 4);
        return fileName?.replace(fileId, '');
    };

    return (
        <div className="update-poc-popup">
            <div className="editTitleHeader">
                <ModalHeader toggle={props.cancelModal}>
                    <span style={{ textTransform: 'capitalize' }}>
                        {props.modalMode}
                    </span>{' '}
                    {props.corporationName ? (
                                <>
                                    Your {props.corporationName} PLC Profile
                                </>
                            ) : (
                                <>
                                    Your IPOC Profile
                                </>
                            )}
                </ModalHeader>
            </div>
            {isCroppingModalVisible ? (
                <ModalBody>
                    <AvatarCroppingTool
                        newerFile={newerFile}
                        src={selectedFile}
                        setIsCroppingModalVisible={setIsCroppingModalVisible}
                        setSrc={setSelectedFile}
                        setNewerFile={setNewerFile}></AvatarCroppingTool>
                </ModalBody>
            ) : (
                <Form
                    innerRef={modalForm}
                    initialValues={{
                        otherSchool: otherFieldValue,
                    }}
                    validationSchema={yup.object().shape({
                        otherSchool: yup
                            .string()
                            .max(100)
                            .test(() => {
                                if (isOtherFieldVisible && !otherFieldValue)
                                    return false;
                                else return true;
                            }),
                    })}
                    onSubmit={() => {
                        submit();
                    }}>
                    <ModalBody className="edit-profile-modal">
                        <div className="title-wrapper">
                            <div className="title">
                                <label>First Name</label>
                                <div className="input-title">
                                    <span>
                                        {props.registrationData.firstName}
                                    </span>
                                </div>
                            </div>
                            <div className="info-field">
                                <label>Last Name</label>
                                <div className="info-field">
                                    <span>
                                        {props.registrationData.lastName}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="edit-wrapper">
                            <div className="title">
                                <label>Practice(s)</label>
                                <div className="input-title">
                                    {props.registrationData.practices?.map(
                                        (p: Practice) => (
                                            <>
                                                <span>
                                                    {p.website ? (
                                                        <a
                                                            onClick={() => {
                                                                Helpers.openWebsite(
                                                                    p.website
                                                                );
                                                            }}
                                                            target="_blank"
                                                            rel="noreferrer">
                                                            {p.name}
                                                        </a>
                                                    ) : (
                                                        p.name
                                                    )}{' '}
                                                    - {p.city},{' '}
                                                    {StateAbbreviationService.getAbbreviation(
                                                        p.state
                                                    )}
                                                </span>
                                                <br />
                                            </>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="info-field">
                                <label>VetValue Member Since</label>
                                <div className="info-field">
                                    <span>
                                        {
                                            props.registrationData
                                                .yearOfRegistration
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="select-file-wrapper">
                            <div className="year-drop-down width-100-percent">
                                <div className="year-drop-down-margin">
                                    <label>Veterinary School</label>
                                    <div className="customerStyle">
                                        <FormGroup>
                                            <Input
                                                tag="select"
                                                name="school"
                                                value={schoolId}
                                                onChange={(event: any) => {
                                                    setSchoolId(
                                                        event.target.value
                                                    );
                                                    if (
                                                        event.target.value == -1
                                                    ) {
                                                        setIsOtherFieldVisible(
                                                            true
                                                        );
                                                    } else {
                                                        setIsOtherFieldVisible(
                                                            false
                                                        );
                                                    }
                                                }}>
                                                <option
                                                    value={null}
                                                    key={'year ' + 0}></option>
                                                {[
                                                    ...props.registrationData
                                                        .veterinarySchools,
                                                ].map((item) => (
                                                    <option
                                                        value={item.key}
                                                        key={
                                                            'school ' + item.key
                                                        }>
                                                        {item.value}
                                                    </option>
                                                ))}
                                                <option
                                                    value={-1}
                                                    key={'other'}>
                                                    Other
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>

                            <div className="year-drop-down type-title">
                                <div className="type-title">
                                    <label>Graduation Year</label>
                                    <div className="customerStyle">
                                        <FormGroup>
                                            <Input
                                                type="select"
                                                name="year"
                                                value={graduationYear ?? 0}
                                                onChange={(event: any) => {
                                                    setGraduationYear(
                                                        event.target.value
                                                    );
                                                }}>
                                                <option
                                                    value={null}
                                                    key={'year ' + 0}></option>
                                                {graduationYears
                                                    ? [...graduationYears].map(
                                                          (item) => (
                                                              <option
                                                                  value={item}
                                                                  key={
                                                                      'year ' +
                                                                      item
                                                                  }>
                                                                  {item}
                                                              </option>
                                                          )
                                                      )
                                                    : []}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isOtherFieldVisible ? (
                            <div className="other-field">
                                <label>Veterinary School Name</label>
                                <Input
                                    className="other-field"
                                    type="text"
                                    name="otherSchool"
                                    onChange={(value) => {
                                        setOtherFieldValue(value.target.value);
                                    }}></Input>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="intro-text-wrapper">
                            <label>
                                Other Professional Accreditations / Affiliations
                            </label>
                            <div className="intro-text-area">
                                <TextareaAutosize
                                    name="affiliations"
                                    value={affiliations}
                                    onKeyDown={(e: any) => {
                                        if (e.key === 'Enter' && e.ctrlKey) {
                                            const position =
                                                e.target.selectionEnd;
                                            e.target.value =
                                                e.target.value.substring(
                                                    0,
                                                    position
                                                ) +
                                                '\n' +
                                                e.target.value.substring(
                                                    position
                                                );
                                            e.target.selectionEnd =
                                                position + 1;
                                        }
                                    }}
                                    className="form-control-lg form-control"
                                    maxRows={2}
                                    onChange={(e) =>
                                        setAffiliations(e.target.value)
                                    }></TextareaAutosize>
                            </div>
                        </div>
                        <FormGroup className="file-section">
                            <Label for="sourceFile">
                                Upload Profile Picture
                            </Label>
                            <Label for="upload-file" className="upload-now">
                                Upload New
                            </Label>
                            <InputGroup className="file-section">
                                <InputGroupText className="upload-file-group">
                                    <label
                                        htmlFor="upload-file"
                                        className="choose-file-label">
                                        Choose File
                                    </label>
                                    <input
                                        type="file"
                                        name="file"
                                        id="upload-file"
                                        accept={allowedImageExtensions.join(
                                            ','
                                        )}
                                        className="upload-file-input"
                                        onChange={handleFileChange}
                                    />
                                </InputGroupText>
                                <Field
                                    type="text"
                                    name="sourceFile"
                                    value={
                                        selectedFile?.name
                                            ? selectedFile.name
                                            : getFileName(
                                                  props?.profile?.avatarPath
                                              )
                                    }
                                    disabled={true}
                                    style={{
                                        backgroundColor: '#fff',
                                    }}
                                    className="input-file-text"
                                />
                                <InputGroupText
                                    className="trash-icon"
                                    onClick={() => {
                                        if (
                                            props &&
                                            props.profile &&
                                            props.profile.avatarPath
                                        ) {
                                            props.profile.avatarPath = '';
                                        }
                                        setSelectedFile(null);
                                        setNewerFile(null);
                                    }}>
                                    {' '}
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <div className="btn-wrapper">
                            <Button
                                className="cancel-btn"
                                onClick={props.cancelModal}>
                                Cancel
                            </Button>
                            <ButtonLoader
                                buttonText={
                                    props.modalMode === modalMode.create &&
                                    summary.practiceOwnerType ===
                                        PRACTICE_OWNER_TYPES.FORMER
                                        ? 'Submit Profile'
                                        : 'Save Profile'
                                }
                                loaderButtonText={''}
                                disabled={false}
                                isLoading={isLoading}
                                onClick={initSubmit}
                                className={
                                    isLoading
                                        ? 'btn save-btn loader-submit'
                                        : 'btn save-btn'
                                }></ButtonLoader>
                        </div>
                    </ModalBody>
                </Form>
            )}
        </div>
    );
};
