import React, { useEffect, useState } from 'react';
import './CreateStagePopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import {
    Button,
    Col,
    Input,
    Label,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../../../components/arrow-down/arrow-down';
import { useStateSelector } from '../../../store/selectors';
import { DataForm } from '../../../models/DataRequestHub/DataForm';
import Helpers from '../../../utils/helper';
import { TemplateDropdownOption } from '../../../models/DataRequestHub/TemplateDropdownOption';
import { DataFormType } from '../../../models/DataRequestHub/DataFormTypeEnum';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import createNotification from '../../../utils/createNotification';
import { DataRequestProject } from '../../../models/DataRequestHub/DataRequestProject';

interface CreateStagePopupProps {
    project: DataRequestProject;
    onClose(): void;
    onAdd(): void;
}

const CreateStagePopup = (props: CreateStagePopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [questionnaireTemplateOptions, setquestionnaireTemplateOptions] =
        useState<TemplateDropdownOption[]>([]);
    const [
        selectedQuestionnaireTemplateId,
        setSelectedQuestionnaireTemplateId,
    ] = useState<number>(null);
    const [formName, setFormName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchTemplateOptions();
    }, []);

    const handleQuestionnaireTemplateOptionChange = (templateId: number) => {
        setSelectedQuestionnaireTemplateId(templateId);
    };

    const getFilteredTemplateOptions = (
        templateForms: DataForm[],
        targetFormType: DataFormType
    ): TemplateDropdownOption[] => {
        const result = templateForms
            .map((obj: DataForm) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: obj.formType,
            }))
            .filter((x) => x.formType === targetFormType)
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));
        return result;
    };

    const fetchTemplateOptions = () => {
        axios.get('/api/dataRequestTemplateProject/dataForms').then((response) => {
            if (response.status === 200) {
                const filteredQuestionnaireTemplateOptions =
                    getFilteredTemplateOptions(
                        response.data,
                        DataFormType.Questionnaire
                    );

                setquestionnaireTemplateOptions(
                    filteredQuestionnaireTemplateOptions
                );
            }
        });
    };

    const addFormToProject = () => {
        setIsLoading(true);

        const params = {
            name: formName.trim(),
            templateFormId: selectedQuestionnaireTemplateId,
        };
        axios
            .post(`api/DataRequestProjects/${props.project.id}/dataForms`, params)
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while creating new stage',
                        'error'
                    );
                } else {
                    props.onAdd();
                    props.onClose();
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Rodal
            visible={true}
            onClose={props.onClose}
            className="create-stage-popup"
            width={400}
            animation="fade"
            closeMaskOnClick={false}
            centered>
            <ModalHeader>Create Additional Questionnaire</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={4}>
                        <Label>Name:</Label>
                    </Col>
                    <Col md={8}>
                        <Input
                            placeholder="Optional..."
                            onChange={(event) =>
                                setFormName(event.target.value)
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Label>Template:</Label>
                    </Col>
                    <Col md={8}>
                        <DropdownList
                            selectIcon={<ArrowDown />}
                            data={questionnaireTemplateOptions}
                            defaultValue={questionnaireTemplateOptions[0]}
                            onChange={(value) => {
                                handleQuestionnaireTemplateOptionChange(
                                    value.id
                                );
                            }}
                            dataKey="id"
                            filter="contains"
                            textField="name"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="buttons-wrapper">
                        <Button
                            className="btn btn-cancel btn btn-secondary"
                            onClick={props.onClose}>
                            Close
                        </Button>
                        <ButtonLoader
                            onClick={addFormToProject}
                            className={'btn btn-primary'}
                            buttonText={'Create'}
                            loaderButtonText={''}
                            isLoading={isLoading}
                            disabled={
                                !selectedQuestionnaireTemplateId
                            }></ButtonLoader>
                    </Col>
                </Row>
            </ModalBody>
        </Rodal>
    );
};

export default CreateStagePopup;
