import { createSlice } from '@reduxjs/toolkit';
import { Message } from '../../../clubhouse/models/message';
import { Attachment } from '../../../clubhouse/models/attachment';
import { loadThreadMessages } from './load-thread-messages.thunk';
import { refreshSupportThreadMessageList } from './refresh-support-thread-message-list.thunk';
import { addToMessageList } from './add-to-messages-list.thunk';
import { addLikeToMessage } from './add-like-to-message.thunk';
import { SupportMessagesState } from './support-threads-messages-state';

const initialState: SupportMessagesState = {
    threads: [],
    isPageLoading: false,
    isMessageLoading: false,
    status: '',
    error: '',
    maxMessagesCount: 600,
    isNewMessageReceived: false,
    numberOfUnreadMessages: 0,
};

const supportThreadsMessagesSlice = createSlice({
    name: 'supportThreadsMessages',
    initialState,
    reducers: {
        setIsNewSupportMessageReceived(state, action) {
            state.isNewMessageReceived = action.payload;
        },
        setNumberOfSupportUnreadMessages(state, action) {
            state.numberOfUnreadMessages = action.payload;
        },
        UpdateState(state, action) {
            Object.keys(state.threads).forEach((id: any) => {
                const threadIds = action.payload.threads.map(
                    (thread: any) => thread.id
                );
                if (!threadIds.includes(Number(id))) {
                    delete state.threads[id];
                }
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadThreadMessages.pending, (state) => {
                setPendingState(state);
            })
            .addCase(loadThreadMessages.fulfilled, (state, action) => {
                const thread = state.threads[action.payload.threadId];
                if (thread) {
                    if (!action.payload.isAscending) {
                        if (action.payload.data.length === 0) {
                            thread.hasLastPage = true;
                        } else {
                            thread.hasLastPage = false;
                        }
                    }
                    thread.messages = mergeMessagesAndTrim(
                        thread.messages,
                        action.payload.data,
                        state.maxMessagesCount
                    );
                    thread.lastMessageId = action.payload.lastMessageId;
                    thread.hasFirstPage = hasMessageWithId(
                        thread.messages,
                        action.payload.lastMessageId
                    );
                    state.threads = {
                        ...state.threads,
                        [action.payload.threadId]: { ...thread },
                    };
                } else {
                    state.threads = mapThread(state, action.payload);
                }

                setSucceededState(state);
            })
            .addCase(loadThreadMessages.rejected, (state) => {
                setRejectedState(state);
            })
            .addCase(refreshSupportThreadMessageList.pending, (state) => {
                setPendingState(state);
            })
            .addCase(
                refreshSupportThreadMessageList.fulfilled,
                (state, action) => {
                    state.threads = mapThread(state, action.payload);
                    state.threads[action.payload.threadId].hasFirstPage = true;
                    setSucceededState(state);
                }
            )
            .addCase(refreshSupportThreadMessageList.rejected, (state) => {
                setRejectedState(state);
            })
            .addCase(addToMessageList.pending, (state) => {
                setPendingState(state);
            })
            .addCase(addToMessageList.fulfilled, (state, action) => {
                const thread = state.threads[action.payload.threadId];
                const newMessage = { ...action.payload.message };
                if (
                    thread &&
                    (thread.hasFirstPage || thread.messages.length === 0)
                ) {
                    if (thread.messages.length === 0) {
                        thread.hasFirstPage = true;
                    }
                    mapAttachments(newMessage);
                    thread.messages.push(newMessage);
                    thread.lastMessageId = newMessage.id;
                    state.threads = {
                        ...state.threads,
                        [action.payload.threadId]: {
                            ...thread,
                        },
                    };
                }

                setSucceededState(state);
            })
            .addCase(addToMessageList.rejected, (state) => {
                setRejectedState(state);
            })
            .addCase(addLikeToMessage.fulfilled, (state, action) => {
                if (action && action.payload && action.payload.threadId) {
                    const thread = state.threads[action.payload.threadId];
                    if (thread) {
                        if (thread.messages.length === 0) {
                            thread.hasFirstPage = true;
                        }
                        let messageToUpdate = thread.messages.find(
                            (x) => x.id === action.payload.message.id
                        );
                        if (messageToUpdate) {
                            var index =
                                thread.messages.indexOf(messageToUpdate);
                            messageToUpdate.likerIds =
                                action.payload.message.likerIds;
                            if (index !== -1) {
                                thread.messages[index] = messageToUpdate;
                            }
                        } else {
                            thread.messages.push(action.payload.message);
                            thread.messages.sort((a, b) => a.id - b.id);
                        }
                        state.threads = {
                            ...state.threads,
                            [action.payload.threadId]: {
                                ...thread,
                            },
                        };
                    }
                }
                setSucceededState(state);
            });
    },
});

const setRejectedState = (state: any) => {
    state.isPageLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isPageLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isPageLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

const mergeMessagesAndTrim = (
    storedMessages: Array<Message>,
    newMessages: Array<Message>,
    frameSize: number
) => {
    let filteredNewMessages = newMessages.filter(
        (m: Message) => !storedMessages.find((s: Message) => s.id === m.id)
    );
    filteredNewMessages = newMessages.map((m: Message) => {
        mapAttachments(m);
        return m;
    });
    const elementsToCut =
        storedMessages.length + filteredNewMessages.length - frameSize;
    if (filteredNewMessages.length && storedMessages.length) {
        const isPrepending =
            new Date(filteredNewMessages[0].dateCreated) <
            new Date(storedMessages[0].dateCreated);
        if (isPrepending) {
            const prependedUntrimmedResult = [
                ...filteredNewMessages,
                ...storedMessages,
            ];

            return trimFromRight(prependedUntrimmedResult, elementsToCut);
        }
    }
    const appendedUntrimmedResult = [...storedMessages, ...filteredNewMessages];
    return trimFromLeft(appendedUntrimmedResult, elementsToCut);
};

const trimFromLeft = (array: Array<Message>, elementsToCut: number) => {
    if (elementsToCut > 0) {
        return array.slice(elementsToCut);
    }
    return array;
};

const trimFromRight = (array: Array<Message>, elementsToCut: number) => {
    if (elementsToCut > 0) {
        return array.slice(0, array.length - elementsToCut);
    }
    return array;
};

const mapThread = (state: any, response: any) => {
    const messages = response.data.map((m: Message) => {
        mapAttachments(m);
        return m;
    });
    return {
        ...state.threads,
        [response.threadId]: {
            messages: messages,
            lastMessageId: response.lastMessageId,
            hasFirstPage: hasMessageWithId(
                response.data,
                response.lastMessageId
            ),
        },
    };
};

const mapAttachments = (message: Message) => {
    message.attachments = message.attachments?.map(
        (a: Attachment) => new Attachment(a.id, a.name, a.size)
    );
};

const hasMessageWithId = (messages: Array<Message>, messageId: number) =>
    messages.some((m: Message) => m.id === messageId);

export const {
    setIsNewSupportMessageReceived,
    UpdateState,
    setNumberOfSupportUnreadMessages,
} = supportThreadsMessagesSlice.actions;
export default supportThreadsMessagesSlice.reducer;
