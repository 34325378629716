export interface VetValueService {
    id: string;
    vvServiceType: number;
    label: string;
    price: number;
    hasCustomPrice: boolean;
}

export interface RecipientInfo {
    id?: number;
    firstName?: string;
    lastName?: string;
    practiceName?: string;
    practiceLocationCity?: string;
    practiceLocationState?: string;
    codeUsed?: string;
    hasPaidSubscription?: boolean;
    practiceOwnerType?: number;
}

export interface RecipietPricing {
    capvar: number;
    pmgAnnualSubscription: number;
    pmgMembershipFee: number;
    pmgRejoinFee: number;
}

export interface AssociateInfo {
    id: number;
    name: string;
    discountCode?: string;
}

export const GiftCardServiceNameMapping = [
    { id: 1, name: 'CAPVAR' },
    { id: 2, name: 'APVAR' },
    { id: 3, name: 'PMG Membership, 1st year' },
    { id: 4, name: 'PMG Membership, 12 months' },
    {
        id: 5,
        name: 'Accounting Standardization Credit',
    },
    { id: 6, name: 'PMG KPI Benchmarking Analysis' },
    { id: 7, name: 'General' },
]