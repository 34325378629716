export enum DiscountType {
    Referral,
    Frequency,
}

export enum FrequencyDiscountType {
    Quarterly,
    SemiAnnual,
    Annual,
}

export const frequencyDiscountNameList = [
    { id: 0, name: 'Quarterly' },
    { id: 1, name: 'Semi-Annual' },
    { id: 2, name: 'Annual' },
];
