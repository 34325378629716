import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import './direct-button.scss';
import { useDispatch } from 'react-redux';
import {
    setIsOpened,
    setSelectedRoomId,
} from '../slices/rooms/direct-rooms-slice';
import { USER_ROLES } from '../../utils/constants';
import { DirectRoomsState } from '../slices/rooms/direct-rooms.state';
import { DirectMembersState } from '../slices/members/direct-members.state';
import { useStateSelector } from '../../store/selectors';
import {
    createAnonymousRoom,
    createRoom,
} from '../slices/rooms/create-room.thunk';
import Tooltip from '../../components/Tooltip/Tooltip';

export interface DirectIconProps {
    isMainButton?: boolean;
    size?: 'small' | 'medium';
    clickCallback?: () => void;
    memberId?: number;
    isAnonymousRoom?: boolean;
}
export const DirectButton = (props: DirectIconProps) => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const dispatch: any = useDispatch();
    const directRooms: DirectRoomsState = useStateSelector(
        (state) => state.directRooms
    );
    const [isTooltipVisile, setIsTooltipVisile] = useState(false);

    const supportThreads = useStateSelector((state) => state.supportThreads);

    const directMembers: DirectMembersState = useStateSelector(
        (state) => state.directMembers
    );

    const numberOfDirectUnreadMessages: number = useStateSelector(
        (state) => state.directMessages.numberOfUnreadMessages
    );

    const numberOfSupportUnreadMessages: number = useStateSelector(
        (state) => state.supportThreadsMessages.numberOfUnreadMessages
    );

    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0);

    useEffect(() => {
        setNumberOfUnreadMessages(
            numberOfDirectUnreadMessages + numberOfSupportUnreadMessages
        );
    }, [numberOfDirectUnreadMessages, numberOfSupportUnreadMessages]);

    const currentUserId = useStateSelector(
        (state) => state.currentUser.user?.id
    );
    const currentUserRoleId = useStateSelector(
        (state) => state.currentUser.user?.roleId
    );
    const key =
        'direct' +
        Date.now().toString() +
        Math.floor(Math.random() * 900000) +
        100000;
    const [uniqueId] = useState('info-tooltip-' + key);

    const toggle = () => {
        if (props.memberId && props.isAnonymousRoom) {
            handleAnonymousRoom();
        }
        if (props.memberId && !props.isAnonymousRoom) {
            const room = directRooms.rooms.find(
                (r) =>
                    r.members.every((m) => !m.isAnonymousUser) &&
                    r.members.some((m) => m.id === props.memberId)
            );
            if (room) {
                dispatch(setSelectedRoomId(room.id));
            } else {
                dispatch(createRoom(props.memberId)).then((response: any) => {
                    dispatch(setSelectedRoomId(response.payload.data));
                });
            }
        }

        props.isMainButton
            ? dispatch(setIsOpened(!directRooms.isOpened))
            : dispatch(setIsOpened(true));
        if (props.clickCallback) {
            props.clickCallback();
        }
    };

    const handleAnonymousRoom = () => {
        const room = directRooms.rooms.find((r) =>
            r.members.some((m) => m.id === props.memberId && m.isAnonymousUser)
        );
        if (!room) {
            dispatch(createAnonymousRoom(props.memberId)).then(
                (response: any) => {
                    dispatch(setSelectedRoomId(response.payload.data));
                }
            );
        } else {
            dispatch(setSelectedRoomId(room.id));
        }
    };

    useEffect(() => {
        const hasDirectAccount = directMembers.members?.some(
            (m) => m.userId == props.memberId && m.isActive
        );
        let shouldShowButton =
            props.isMainButton ||
            (props.memberId !== currentUserId &&
                currentUserRoleId != USER_ROLES.ADMIN &&
                hasDirectAccount);

        if (shouldShowButton && !directMembers.currentMember?.hasFullAccess) {
            if (props.isMainButton && !directRooms.rooms.length) {
                shouldShowButton = false;
            }
        }

        setIsButtonVisible(
            (directRooms.isAvailable &&
                directRooms.isInitiated &&
                directMembers.isInitiated &&
                shouldShowButton) ||
                (supportThreads.isInitiated &&
                    supportThreads.threads.length > 0)
        );
    }, [
        directRooms.isAvailable,
        directRooms.isInitiated,
        directMembers.isInitiated,
        directRooms.rooms,
        currentUserId,
        currentUserRoleId,
        directMembers.members,
        supportThreads.isInitiated,
        supportThreads.threads,
        directMembers.currentMember
    ]);

    const showTooltip = () => setIsTooltipVisile(true);
    const hideTooltip = () => setIsTooltipVisile(false);

    return isButtonVisible ? (
        <div className="direct-button">
            <button className="btn toggle-button">
                <div>
                    <FontAwesomeIcon
                        className={props.size ? `${props.size}-icon` : 'icon'}
                        id={uniqueId}
                        onClick={toggle}
                        icon={faEnvelope}
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                    />
                    {props.isMainButton && numberOfUnreadMessages ? (
                        <Badge className="unread-messages" onClick={toggle}>
                            {numberOfUnreadMessages > 999
                                ? '999+'
                                : numberOfUnreadMessages}
                        </Badge>
                    ) : (
                        []
                    )}
                </div>
                <div className="info-tooltip">
                    <Tooltip
                        className="custom-tooltip"
                        placement="auto"
                        isOpen={isTooltipVisile}
                        target={uniqueId}>
                        Direct Message Center
                    </Tooltip>
                </div>
            </button>
        </div>
    ) : (
        <></>
    );
};
