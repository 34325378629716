export function wrapInPromise(
    saveRequestFunction: CallableFunction,
    delayMs: number,
    signal: any
) {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            if (signal.aborted) {
                reject('Promise was rejected');
            } else {
                saveRequestFunction();
                resolve('Wrapped function was successful');
            }
        }, delayMs);

        signal.onabort = () => clearTimeout(timeoutId);
    });
}
