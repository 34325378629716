import React from 'react';
import { Helmet } from 'react-helmet-async';

const DefaultMeta = () => (
    <Helmet>
        <title>VetValue Practice Owner Ecosystem</title>
        <meta
            name="description"
            content="Veterinary business education, resources and services center for independent practice owners"
        />

        <meta itemProp="name" content="VetValue Practice Owner Ecosystem" />
        <meta
            itemProp="description"
            content="Veterinary business education, resources and services center for independent practice owners"
        />
        <meta
            itemProp="image"
            content="https://app.vetvalue.pet/logo-meta.png"
        />
        <meta property="og:url" content="https://app.vetvalue.pet" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="VetValue Practice Owner Ecosystem" />
        <meta
            property="og:description"
            content="Veterinary business education, resources and services center for independent practice owners"
        />
        <meta
            property="og:image"
            content="https://app.vetvalue.pet/logo-meta.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
            name="twitter:title"
            content="VetValue Practice Owner Ecosystem"
        />
        <meta
            name="twitter:description"
            content="Veterinary business education, resources and services center for independent practice owners"
        />
        <meta
            name="twitter:image"
            content="https://app.vetvalue.pet/logo-meta.png"
        />
        <link rel="canonical" href="https://app.vetvalue.pet/login" />
    </Helmet>
);

export default DefaultMeta;
