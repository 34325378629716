export enum ZoneType {
    Standard,
    Daylight,
}
export class TimeZone {
    constructor(
        public abbreviation: string,
        public offset: number,
        public type: ZoneType
    ) {}
}

const DateZones: TimeZone[] = [
    new TimeZone('EST', -5, ZoneType.Standard),
    new TimeZone('EDT', -4, ZoneType.Daylight),
    new TimeZone('AST', -4, ZoneType.Standard),
    new TimeZone('CST', -6, ZoneType.Standard),
    new TimeZone('CDT', -5, ZoneType.Daylight),
    new TimeZone('MST', -7, ZoneType.Standard),
    new TimeZone('MDT', -6, ZoneType.Daylight),
    new TimeZone('PST', -8, ZoneType.Standard),
    new TimeZone('PDT', -7, ZoneType.Daylight),
    new TimeZone('AKST', -9, ZoneType.Standard),
    new TimeZone('AKDT', -8, ZoneType.Daylight),
    new TimeZone('HST', -10, ZoneType.Standard),
    new TimeZone('HAST', -10, ZoneType.Standard),
    new TimeZone('HADT', -9, ZoneType.Daylight),
    new TimeZone('SST', -11, ZoneType.Standard),
    new TimeZone('SDT', -10, ZoneType.Daylight),
    new TimeZone('CHST', 10, ZoneType.Standard),
];

export class TimeZoneHelper {
    public static getDateZones(date: Date): TimeZone[] {
        const zoneTypes = this.getZoneTypes(date);
        return DateZones.filter((f) => zoneTypes.includes(f.type));
    }

    public static getZoneTypes(date: Date): ZoneType[] {
        const year = date.getUTCFullYear();
        let result: ZoneType[] = [];
        if (
            date >= this.secondSundayOfMarch(year, true) &&
            date <= this.firstSundayOfNovember(year, false)
        ) {
            result.push(ZoneType.Daylight);
        }
        if (
            date <= this.secondSundayOfMarch(year, false) ||
            date >= this.firstSundayOfNovember(year, true)
        ) {
            result.push(ZoneType.Standard);
        }

        return result;
    }

    public static haveSameZones(date1: Date, date2: Date) {
        const types1 = this.getZoneTypes(date1);
        const types2 = this.getZoneTypes(date2);
        return (
            types1[0] === types2[0] &&
            types1.length === 1 &&
            types2.length === 1
        );
    }

    private static secondSundayOfMarch(year: number, isStartOfDay: boolean) {
        const start = new Date(year, 2, 7);
        const secondSunday = 7 + (7 - start.getDay());
        return new Date(
            year,
            2,
            secondSunday,
            isStartOfDay ? 0 : 23,
            isStartOfDay ? 0 : 59,
            isStartOfDay ? 0 : 59
        );
    }

    private static firstSundayOfNovember(year: number, isStartOfDay: boolean) {
        const start = new Date(year, 10, 7);
        const secondSunday = 7 - start.getDay();
        return new Date(
            year,
            10,
            secondSunday,
            isStartOfDay ? 0 : 23,
            isStartOfDay ? 0 : 59,
            isStartOfDay ? 0 : 59
        );
    }

    public static getTimeZoneByDate(date: Date) {
        date = new Date(date);
        const browserDate = new Date();
        const isNowStdTime = this.isStdTime(browserDate);
        const isDateStdTime = this.isStdTime(date);
        let offset = -browserDate.getTimezoneOffset() / 60;
        if (isNowStdTime && !isDateStdTime) {
            offset += 1;
        } else if (!isNowStdTime && isDateStdTime) {
            offset -= 1;
        }
        const zones = this.getDateZones(date);
        const zone = zones.find((z) => z.offset === offset) ?? zones[0];
        return zone;
    }

    private static isStdTime(date: Date) {
        const year = date.getUTCFullYear();
        return (
            date < this.secondSundayOfMarch(year, true) ||
            date >= this.firstSundayOfNovember(year, true)
        );
    }

    public static getTimeZoneByAbbreviation(abbreviation: string) {
        return DateZones.find((d) => d.abbreviation === abbreviation);
    }

    public static parseUtcDate(date: Date, zone: TimeZone): Date {
        var utcDate = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            )
        );
        utcDate.setHours(utcDate.getHours() - zone.offset);
        return utcDate;
    }

    public static parseZoneDate(date: Date, zone: TimeZone): Date {
        let parsedDate = new Date(date);
        parsedDate.setHours(
            parsedDate.getHours() + parsedDate.getTimezoneOffset() / 60
        );
        if (zone) {
            parsedDate.setHours(parsedDate.getHours() + zone.offset);
        }
        return parsedDate;
    }

    public static hasTimeZone(date: Date, zone: TimeZone): boolean {
        return this.getDateZones(date).some(
            (d) => d.abbreviation === zone.abbreviation
        );
    }

    public static isDateValid(dateToValidate: Date): boolean {
        return (
            dateToValidate instanceof Date && !isNaN(dateToValidate.getTime())
        );
    }
}
