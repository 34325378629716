import React from 'react';
const ButtonLoader = ({
    buttonText,
    onClick,
    isLoading,
    loaderButtonText,
    disabled,
    className,
    type = undefined,
    divClassName = '',
}) => {
    const renderLoader = () => {
        return (
            <div
                className={'loader-inner ball-clip-rotate'}
                style={{ display: 'contents' }}>
                <div
                    style={{
                        border: '3px dotted  #ffffff ',
                        height: '11px',
                        width: '11px',
                        marginRight: '10px',
                    }}></div>
            </div>
        );
    };

    const buttonContent = () =>
        isLoading && loaderButtonText ? loaderButtonText : buttonText;

    return (
        <div className={'ButtonLoader-Div ' + divClassName}>
            {type ? (
                <button
                    type={type}
                    className={className}
                    onClick={() => onClick()}
                    disabled={disabled || isLoading}>
                    {isLoading && renderLoader()}
                    {buttonContent()}
                </button>
            ) : (
                <button
                    className={className}
                    onClick={() => onClick()}
                    disabled={disabled || isLoading}>
                    {isLoading && renderLoader()}
                    {buttonContent()}
                </button>
            )}
        </div>
    );
};
export default ButtonLoader;
