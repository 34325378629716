import { createSlice } from '@reduxjs/toolkit';
import { DirectRoom } from '../../models/direct-room';
import { fetchRooms } from './fetch-rooms.thunk';
import { createRoom } from './create-room.thunk';
import { DirectRoomsState } from './direct-rooms.state';

const initialState: DirectRoomsState = {
    rooms: [],
    selectedRoomId: undefined,
    isOpened: false,
    isInitiated: false,
    isLoading: false,
    isAvailable: false,
    refreshDirect: false,
    isCreateRoomModalVisible: false,
    status: '',
    error: '',
    isSelectedDirectRoom: true,
    isConnectedToHub: false,
};

const directRoomsSlice = createSlice({
    name: 'directRooms',
    initialState,
    reducers: {
        addUpdateRoom(state, action) {
            const room = action.payload as DirectRoom;
            if (state.rooms.some((r) => r.id === room.id)) {
                const index = state.rooms.findIndex((r) => r.id === room.id);
                state.rooms[index] = { ...room };
            } else {
                state.rooms.push(room);
                if (state.rooms.length === 1) {
                    state.selectedRoomId = room.id;
                }
            }
        },
        setSelectedRoomId(state, action) {
            state.isSelectedDirectRoom = true;
            const roomId = action.payload;
            if (state.rooms.some((r) => r.id === roomId)) {
                state.selectedRoomId = roomId;
            } else {
                state.selectedRoomId =
                    state.rooms.length > 0 ? state.rooms[0].id : undefined;
            }
        },
        setIsSelectedDirectRoom(state, action) {
            state.isSelectedDirectRoom = action.payload;
        },
        setIsDirectAvailable(state, action) {
            state.isAvailable = action.payload;
        },
        setIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        refreshDirect(state) {
            if (!state.isAvailable) {
                state.refreshDirect = !state.refreshDirect;
            }
        },
        setIsCreateRoomModalVisible(state, action) {
            state.isCreateRoomModalVisible = action.payload;
        },
        setIsConnectedToHub(state, action) {
            state.isConnectedToHub = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchRooms.pending, (state: DirectRoomsState) => {
                setPendingState(state);
            })
            .addCase(
                fetchRooms.fulfilled,
                (state: DirectRoomsState, action: any) => {
                    if (action.payload.status == 200) {
                        state.rooms = action.payload.data;
                        if (
                            !state.selectedRoomId ||
                            action.payload.data.every(
                                (r: DirectRoom) => r.id !== state.selectedRoomId
                            )
                        ) {
                            state.selectedRoomId =
                                action.payload.data.length > 0
                                    ? action.payload.data[0].id
                                    : undefined;
                        }
                        setSucceededState(state);
                        state.isInitiated = true;
                    } else {
                        setRejectedState(state);
                    }
                }
            )
            .addCase(fetchRooms.rejected, (state: any) => {
                setRejectedState(state);
            })
            .addCase(
                createRoom.fulfilled,
                (state: DirectRoomsState, action: any) => {
                    if (action.payload.status == 200) {
                        state.selectedRoomId = action.payload.data;
                    }
                }
            );
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

export const {
    addUpdateRoom,
    setSelectedRoomId,
    setIsSelectedDirectRoom,
    setIsOpened,
    setIsDirectAvailable,
    refreshDirect,
    setIsCreateRoomModalVisible,
    setIsConnectedToHub
} = directRoomsSlice.actions;
export default directRoomsSlice.reducer;
