import { createAsyncThunk } from '@reduxjs/toolkit';

export interface LoadRoomMessagesArg {
    roomId: number;
    isAscending: boolean;
    pageSize?: number;
}

export const loadRoomMessages = createAsyncThunk(
    'directMessages/loadRoomMessages',
    async (arg: LoadRoomMessagesArg, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const roomId = dispatch.getState().directRooms.selectedRoomId;
        const messages = dispatch.getState().directMessages.rooms;

        const roomMessages = messages[roomId]?.messages;
        const message = arg.isAscending
            ? roomMessages?.at(-1)
            : roomMessages?.at(0);

        const response = await axios.get(`api/direct/${arg.roomId}/messages`, {
            params: {
                messageId: message?.id,
                isAscending: arg.isAscending,
                pageSize: arg.pageSize,
            },
        });

        return {
            data: response.data.messages,
            roomId: arg.roomId,
            isAscending: arg.isAscending,
            lastMessageId: response.data.lastMessageId,
        };
    }
);
