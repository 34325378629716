import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Modal, ModalBody } from 'reactstrap';
import editInactive from '../../assets/images/editInactive.jpg';
import './RichTextInput.scss';
import Helpers from '../../utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    convertFromHTML,
    DraftHandleValue,
    Editor,
    EditorCommand,
    EditorState,
    Modifier,
    RichUtils,
    SelectionState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import sanitizeHtml from 'sanitize-html';
import {
    faBold,
    faItalic,
    faUnderline,
    faLink,
    faListUl,
    faListOl,
} from '@fortawesome/fontawesome-free-solid';

interface RichTextInputProps {
    setMessageText(text: string): void
    toggleToolbar(): void;
    focus?: boolean;
}
const RichTextInput = (props: RichTextInputProps) => {
    const [messageText, setMessageText] = useState('');
    const [isToolbarActive, setToolbarActive] = useState(false);
    const [isToolSelected, setIsToolSelected] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setShowLinkModal(false);
        };
        window.addEventListener('resize', handleResize);
    });

    const findLinkEntities = (
        contentBlock: ContentBlock,
        callback: any,
        contentState: ContentState
    ) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        }, callback);
    };

    useEffect(() => {
        if (props.focus === null) {
            return
        }
        focusEditor()
    }, [props.focus])

    const Link = (props: any) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return <a href={url}>{props.children}</a>;
    };
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty(decorator)
    );
    const [showLinkModal, setShowLinkModal] = useState(false);

    const editor = React.useRef(null);
    const editorToolbarRef = useRef(null);

    const allowedOptions = {
        allowedTags: [
            'b',
            'i',
            'u',
            'strong',
            'br',
            'ul',
            'li',
            'p',
            'ol',
            'a',
            'span',
        ],
        allowedAttributes: {
            a: ['href', 'target'],
        },
    };
    const [textStyles, setTextStyles] = useState<
        Array<{ label: string; style: string; icon: any }>
    >([]);

    useEffect(() => {
        setTextStyles([
            { label: 'B', style: 'BOLD', icon: faBold },
            { label: 'I', style: 'ITALIC', icon: faItalic },
            { label: 'U', style: 'UNDERLINE', icon: faUnderline },
            { label: 'L', style: 'LINK', icon: faLink },
            { label: 'UL', style: 'unordered-list-item', icon: faListUl },
            { label: 'OL', style: 'ordered-list-item', icon: faListOl },
        ]);
    }, []);

    const focusEditor = () => {
        editor.current.focus();
    };

    const StyleButton = (props: any) => {
        const blockType = editorState
            .getCurrentContent()
            .getLastBlock()
            .getType();
        let activeState =
            editorState.getCurrentInlineStyle().has(props.style) ||
            blockType === props.style ||
            (showLinkModal && props.style === 'LINK');

        let onClickButton = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setIsToolSelected(!activeState);
            props.onToggle(props.style);
        };

        return (
            <div
                className="inline-rich-text-tool-control-bg"
                style={{ backgroundColor: activeState ? '#e2e2e2' : '#f4f4f4' }}
                onMouseDown={onClickButton}>
                <FontAwesomeIcon
                    style={{
                        backgroundColor: activeState ? '#e2e2e2' : '#f4f4f4',
                    }}
                    icon={props.icon}
                    className="inline-rich-text-tool-control"
                />
            </div>
        );
    };

    const StyleControls = (props: any) => {
        return (
            <>
                {textStyles.map((type) => {
                    return (
                        <StyleButton
                            key={type.label}
                            label={type.label}
                            onToggle={props.onToggle}
                            style={type.style}
                            icon={type.icon}
                        />
                    );
                })}
            </>
        );
    };

    const onChange = (editorState: EditorState) => {
        setEditorState(editorState);
        let msg = stateToHTML(editorState.getCurrentContent());
        if (editorState.getCurrentContent().getPlainText().trim().length) {
            setMessageText(msg);
        } else {
            setMessageText('');
        }
        props.setMessageText(messageText)
    };

    const onControlClick = (e: any) => {
        let nextState = editorState;

        if (e === 'BOLD' || e === 'ITALIC' || e === 'UNDERLINE')
            nextState = RichUtils.toggleInlineStyle(editorState, e);
        if (e === 'unordered-list-item' || e === 'ordered-list-item')
            nextState = RichUtils.toggleBlockType(editorState, e);
        if (e === 'LINK') {
            setShowLinkModal(!showLinkModal);
            return;
        }

        setEditorState(nextState);
    };

    const toggleToolbar = () => {
        setToolbarActive(!isToolbarActive);
    };

    const handlePastedText = (
        text: string,
        html: string,
        changedEditorState: EditorState
    ): DraftHandleValue => {
        let newEditorState = changedEditorState;

        text = text.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
        text = sanitizeHtml(text, allowedOptions);

        const blocks = convertFromHTML(text);
        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(
                blocks.contentBlocks,
                blocks.entityMap
            ).getBlockMap()
        );
        newEditorState = EditorState.push(
            newEditorState,
            contentState,
            'insert-characters'
        );
        if (newEditorState !== changedEditorState) {
            onChange(newEditorState);
        }

        return 'handled';
    };

    const handleKeyCommand = (
        command: EditorCommand,
        editorState: EditorState,
        eventTimeStamp: number
    ): DraftHandleValue => {
        const newEditorState = RichUtils.handleKeyCommand(editorState, command);

        if (newEditorState) {
            setEditorState(newEditorState);
            return 'handled';
        }

        return 'not-handled';
    };

    const AddLinkPopup = (props: any) => {
        const [modalStyle, setModalStyle] = useState({
            top: '0px',
            left: '0px',
        });

        const inputTextRef = useRef(null);
        const inputUrlRef = useRef(null);
        const [errorMessage, setErrorMessage] = useState('');
        const hasMessageError = () => {
            return errorMessage !== '';
        };
        const [canInsertLink, setCanInsertLink] = useState(false);
        const handleOpen = () => {
            let defaultPosition = {
                top: '0px',
                left: '0px',
                position: 'absolute',
            };

            if (editorToolbarRef) {
                const position =
                    editorToolbarRef.current.getBoundingClientRect();
                defaultPosition.left = position.x - 5 + 'px';
                defaultPosition.top = position.y - 235 + 'px';
            }

            setModalStyle(defaultPosition);

            inputTextRef.current.value = getSelectedText(editorState);
            inputUrlRef.current.value = getLinkFromSelection(editorState);

            if (inputTextRef.current.value) {
                inputUrlRef.current.focus();
            } else {
                inputTextRef.current.focus();
            }

            if (Helpers.isValidUrl(inputUrlRef.current.value)) {
                setCanInsertLink(true);
            }
        };
        const handleOnChange = (event: any) => {
            setCanInsertLink(false);
            const urlString = event.target.value;
            if (urlString === '') {
                setErrorMessage('Website could not be empty');
            } else if (!Helpers.isValidUrl(urlString)) {
                setErrorMessage('Invalid URL');
            } else {
                setErrorMessage('');
                setCanInsertLink(true);
            }
        };

        return (
            <Modal
                style={modalStyle}
                isOpen={props.isLinkModalOpen}
                onOpened={handleOpen}
                toggle={() => {
                    setShowLinkModal(!props.isLinkModalOpen);
                }}
                className="add-link-popup-box"
                backdropClassName="backdrop-link">
                <ModalBody>
                    <button
                        onClick={() => {
                            setShowLinkModal(false);
                        }}
                        type="button"
                        className="btn btn-close"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="add-link-box-content">
                        <label className="add-link-label">
                            Text to display
                        </label>
                        <div
                            style={{ display: 'flex' }}
                            className="add-link-flex-controls">
                            <input
                                maxLength={100}
                                ref={inputTextRef}
                                placeholder={'Text to display'}
                                className="insert-link-textarea"
                                type="text"
                            />
                        </div>
                        <label
                            style={{ marginTop: '10px' }}
                            className="add-link-label">
                            Website
                        </label>
                        <div className="add-link-flex-controls">
                            <input
                                maxLength={255}
                                ref={inputUrlRef}
                                placeholder={'eg. www.google.com'}
                                className={
                                    hasMessageError()
                                        ? 'insert-link-textarea invalid-input-textarea'
                                        : 'insert-link-textarea'
                                }
                                type="text"
                                onChange={handleOnChange}
                            />
                            <button
                                disabled={canInsertLink !== true}
                                type="button"
                                className="btn btn-primary btn btn-primary"
                                onClick={() => {
                                    if (canInsertLink) {
                                        insertLink(
                                            inputTextRef.current.value,
                                            inputUrlRef.current.value
                                        );
                                    }
                                }}>
                                Insert
                            </button>
                        </div>
                        {hasMessageError() && (
                            <div className="add-link-flex-controls error-message">
                                <span>
                                    <label>{errorMessage}</label>
                                </span>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const getLinkFromSelection = (editorState: EditorState) => {
        let url = '';
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const startOffset = selection.getStartOffset();

            const blockWithLinkAtBeginning =
                contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
        }

        return url;
    };

    const getSelectedText = (editorState: EditorState): string => {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startOffset = selection.getFocusOffset();
            const endOffset = selection.getAnchorOffset();

            const currentBlock = contentState.getBlockForKey(
                selection.getAnchorKey()
            );
            const text = currentBlock.getText();

            if (startOffset > endOffset) {
                return text.substring(startOffset, endOffset);
            } else {
                return text.substring(endOffset, startOffset);
            }
        }

        return '';
    };

    const insertLink = (text: string, url: string) => {
        if (!text) {
            text = url;
        }

        var httpsPrefix = 'https://';
        var httpPrefix = 'http://';
        if (
            url.substring(0, httpsPrefix.length) !== httpsPrefix &&
            url.substring(0, httpPrefix.length) !== httpPrefix
        ) {
            url = httpsPrefix + url;
        }

        const newEditorState = editorState;
        const contentState = newEditorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                url: url,
                target: '_blank',
            }
        );
        let nextEditorState = EditorState.set(newEditorState, {
            currentContent: contentStateWithEntity,
        });

        const newSelection = nextEditorState.getSelection();

        let entityKey = nextEditorState
            .getCurrentContent()
            .getLastCreatedEntityKey();
        const modifierContent = Modifier.replaceText(
            nextEditorState.getCurrentContent(),
            nextEditorState.getSelection(),
            text,
            null,
            entityKey
        );
        nextEditorState = EditorState.push(
            editorState,
            modifierContent,
            'insert-characters'
        );
        nextEditorState = EditorState.createWithContent(
            modifierContent,
            decorator
        );

        const focusPosition =
            newSelection.getFocusOffset() < newSelection.getAnchorOffset()
                ? newSelection.getFocusOffset()
                : newSelection.getAnchorOffset();
        const curetPosition = focusPosition + text.length;
        const updateSelection = new SelectionState({
            anchorKey: newSelection.getAnchorKey(),
            anchorOffset: curetPosition,
            focusKey: newSelection.getFocusKey(),
            focusOffset: curetPosition,
            isBackward: false,
        });
        nextEditorState = EditorState.forceSelection(
            nextEditorState,
            updateSelection
        );

        setShowLinkModal(false);
        onChange(nextEditorState);
    };

    const customStyleMap: any = {
        STRIKETHROUGH: {
            backgroundColor: '#888f96',
            color: '#888f96',
            fontSize: '16px',
            userSelect: 'none',
        },
    };

    return (
        <div className="app-inner-layout__bottom-pane d-block text-center rich-text-input">
            <FormGroup className="mb-0" row>
                <Col sm={12}>
                    <div
                        className={
                            "search-btn-wrapper edit-search-wrapper"
                        }>
                        <div
                            className="search-box edit-search"
                            onClick={focusEditor}>
                            {isToolbarActive && (
                                <div className="inline-text-editor-area">
                                    <div className="inline-text-editor-controls">
                                        <StyleControls
                                            onToggle={onControlClick}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="message-input-textarea">
                                <img
                                    ref={editorToolbarRef}
                                    className="edit-image"
                                    src={editInactive}
                                    onClick={toggleToolbar}
                                    style={{
                                        opacity: isToolbarActive
                                            ? '100%'
                                            : '75%',
                                        top: isToolbarActive ? '40px' : '5px',
                                    }}
                                />
                                <div className="message-input-editor">
                                    <Editor
                                        onCut={(_, e) => e.preventDefault()}
                                        ref={editor}
                                        editorState={editorState}
                                        placeholder={
                                            !(
                                                messageText.length ||
                                                isToolSelected
                                            ) && 'Enter commentary'
                                        }
                                        onChange={onChange}
                                        handlePastedText={handlePastedText}
                                        handleReturn={() =>
                                            "not-handled"
                                        }
                                        handleKeyCommand={handleKeyCommand}
                                        handleBeforeInput={(
                                            chars,
                                            _editorState
                                        ) =>
                                            "not-handled"
                                        }
                                        customStyleMap={customStyleMap}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </FormGroup>
            <AddLinkPopup isLinkModalOpen={showLinkModal} />
        </div>
    );
};

export default RichTextInput;
