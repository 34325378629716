import React, { useEffect, useState } from 'react';
import './DesignModeSwitcher.scss';
import ReactSwitch from 'react-switch';

interface DesignModeSwitcherProps {
    onEnable?: (formId: number) => void;
    onDisable?: (formId: number) => void;
    defaultValue: boolean;
    formId: number;
}

export const DesignModeSwitcher = (props: DesignModeSwitcherProps) => {
    const [isDesignMode, setIsDesignMode] = useState<boolean>(
        props.defaultValue
    );

    const [isModeChanged, setIsModeChanged] = useState<boolean>(false);

    useEffect(() => {
        if (isDesignMode && props.onEnable && isModeChanged) {
            props.onEnable(props.formId);
        } else if (props.onDisable && isModeChanged) {
            props.onDisable(props.formId);
        }
        setIsModeChanged(false);
    }, [isDesignMode]);
    return (
        <div className="design-mode-switcher">
            <strong>Design Mode</strong>
            <ReactSwitch
                offColor="#d92550"
                checked={isDesignMode}
                onChange={(event) => {
                    setIsModeChanged(true);
                    setIsDesignMode(event);
                }}
                height={22}></ReactSwitch>
        </div>
    );
};
