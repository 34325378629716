import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Table,
    Label,
    Button,
} from 'reactstrap';
import './top-liked-posts-modal.scss';
import {
    Box,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { GetTopLikedMessages } from '../slices/messages/get-top-liked-messages.thunk';
import { useDispatch } from 'react-redux';
import { Message } from '../models/message';
import { Discussion } from '../models/discussion';
import { MessageReference } from '../models/message-reference';
import { useStateSelector } from '../../store/selectors';

interface TopLikedPostsModalProps {
    isVisible: boolean;
    setVisibility(isVisible: boolean): void;
    openMessage(reference: MessageReference): void;
}

interface TopLikedMessage {
    numberOfLikes: number;
    messageId: number;
    chatId: number;
    chat: string;
    messageText: string;
}

const TopLikedPostsModal = (props: TopLikedPostsModalProps) => {
    const dispatch: any = useDispatch();
    const [topMessages, setTopMessages] = useState([]);
    const clubhouse = useStateSelector((state) => state.clubhouse.clubhouse);
    const discussions = useStateSelector(
        (state) => state.clubhouse.clubhouse.discussions
    );
    const toggleModalVisibility = () => {
        props.setVisibility(!props.isVisible);
    };

    const headCells = [
        {
            id: 'numberOfLikes',
            label: 'Likes',
        },
        {
            id: 'chat',
            label: 'Discussion',
        },
        {
            id: 'messageText',
            label: 'Message',
        },
    ];

    useEffect(() => {
        if (
            topMessages.some((m) =>
                discussions.every((d: Discussion) => d.id !== m.chatId)
            )
        ) {
            console.log('inside discussion');
            loadMessages();
        }
    }, [discussions]);

    useEffect(() => {
        loadMessages();
    }, []);

    const loadMessages = () => {
        dispatch(GetTopLikedMessages(clubhouse.id)).then((response: any) => {
            const messages: Message[] = response.payload.data;
            const mappedMessages = messages.map((m) => ({
                messageId: m.id,
                chatId: m.chatId,
                chat: clubhouse.discussions.find(
                    (d: Discussion) => d.id === m.chatId
                )?.name,
                messageText: convertMessage(m),
                numberOfLikes: m.likerIds.length,
            }));
            setTopMessages(mappedMessages);
        });
    };

    const convertMessage = (message: Message) => {
        const tempDoc = document.createElement('div');
        tempDoc.innerHTML = message.htmlContent;
        let text = tempDoc.textContent || tempDoc.innerText || '';
        const hasAttachments = message.attachments.length > 0;
        const hasSharedContent = message.sharedContents.length > 0;
        if (
            hasAttachments ||
            (hasSharedContent && text.split(' ').length < 14)
        ) {
            text += ' Attachments: ';
            if (hasAttachments) {
                text += message.attachments.map((m) => m.name).join(', ');
                if (hasSharedContent) {
                    text += ', ';
                }
            }
            text += message.sharedContents.map((m) => m.title).join(', ');
            text += '.';
        }
        if (text.length > 100) {
            text = text.slice(0, 100);
            return text + '...';
        }

        if (text.split(' ').length >= 14) {
            return text.split(' ').slice(0, 15).join(' ') + '...';
        }
        return text;
    };

    return (
        <div className="top-liked-posts">
            <Modal
                className="top-liked-posts-modal"
                isOpen={props.isVisible}
                toggle={toggleModalVisibility}>
                <div className="create-new-discussion-popup-header">
                    <ModalHeader toggle={toggleModalVisibility}>
                        Most Liked Posts
                    </ModalHeader>
                </div>
                <ModalBody className="editTitleBody deleteModalBody profileViewPopUpBody">
                    <Box sx={{ width: '100%' }}>
                        <Paper>
                            <TableContainer>
                                <Table
                                    className="top-liked-table"
                                    sx={{ minWidth: 450 }}
                                    aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align="left">
                                                    <Label>
                                                        {headCell.label}
                                                    </Label>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!topMessages ||
                                        topMessages.length === 0 ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={12}
                                                    align="center">
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            topMessages.map(
                                                (
                                                    message: TopLikedMessage,
                                                    index
                                                ) => {
                                                    return (
                                                        <TableRow
                                                            onClick={() =>
                                                                props.openMessage(
                                                                    new MessageReference(
                                                                        message.chatId,
                                                                        message.messageId
                                                                    )
                                                                )
                                                            }
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={
                                                                'message' +
                                                                index
                                                            }>
                                                            <TableCell align="right">
                                                                {
                                                                    message.numberOfLikes
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {message.chat}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    message.messageText
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                    <div className="buttons-wrapper">
                        <Button
                            className="cancel-btn"
                            onClick={() => props.setVisibility(false)}>
                            Close
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default TopLikedPostsModal;
