import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Rodal from '../Rodal/Rodal';
import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import './DonorInformationModal.scss';
import { DirectButton } from '../../direct/direct-button/direct-button';
import createNotification from '../../utils/createNotification';
import Loader from 'react-loaders';
import sponsor from '../../assets/images/sponsor.svg';
import { Tooltip } from 'react-tooltip';
import { SponsorIcon } from '../SponsorIcon/SponsorIcon';

interface DonorInfo {
    fullName: string;
    practices: string[];
    isProposer: boolean;
}

interface DonorInformationModalProps {
    isVisible: boolean;
    close: () => void;
    onDirectOpened: () => void;
    donorUserId: number;
    charityId: number;
}

const DonorInformationModal = (props: DonorInformationModalProps) => {
    const [donorData, setDonorData] = useState<DonorInfo>();
    const [isLoading, setIsLoading] = useState(true);
    const axios = useSelector((state: any) => state.core.axios);

    useEffect(() => {
        getDonorData();
    }, [props.isVisible]);

    const getDonorData = () => {
        setIsLoading(true);
        axios
            .get(`api/charities/${props.charityId}/donors/${props.donorUserId}`)
            .then((response: any) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while fetching donor information',
                        'error'
                    );
                    props.close();
                } else {
                    setDonorData(response.data);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                createNotification(
                    'An error occured while fetching donor information',
                    'error'
                );
                props.close();
            });
    };

    const getLoaderHtml = () => {
        return (
            <>
                <div
                    className="loader-container"
                    style={{ height: '100%', width: '100%' }}>
                    <div className="loader-container-inner">
                        <br />
                        <div className="text-center">
                            <Loader
                                active={isLoading}
                                type="ball-scale-multiple"
                            />
                        </div>
                        <h6 className="mt-5">{'Loading...'}</h6>
                    </div>
                </div>
            </>
        );
    };

    const getFields = () => {
        return (
            <>
                <Row>
                    <Col md={12}>
                        <h6>Donor name </h6>
                    </Col>
                    <Col className="donor-name-direct" md={12}>
                        <p>{donorData.fullName}</p>
                        {donorData.isProposer ? (
                            <SponsorIcon id="sponsor-icon"></SponsorIcon>
                        ) : (
                            []
                        )}
                        <DirectButton
                            clickCallback={() => {
                                if (props.onDirectOpened) {
                                    props.onDirectOpened();
                                }
                            }}
                            memberId={props.donorUserId}
                            size="small"></DirectButton>
                    </Col>
                </Row>
                {donorData.practices.length > 0 ? (
                    <Row>
                        <Col md={12}>
                            <h6>
                                Practice
                                {donorData.practices.length > 1 ? 's' : ''}
                            </h6>
                        </Col>
                        <Col md={12}>
                            {donorData.practices.map((p) => (
                                <p>{p}</p>
                            ))}
                        </Col>
                    </Row>
                ) : (
                    []
                )}
            </>
        );
    };

    return (
        <Rodal
            visible={props.isVisible}
            onClose={() => {
                document.body.style.overflow = 'unset';
                props.close();
            }}
            className="donor-information-modal"
            width={550}
            animation={'fade'}
            showMask={false}>
            <ModalHeader>Donor Information</ModalHeader>
            <ModalBody>{isLoading ? getLoaderHtml() : getFields()}</ModalBody>
        </Rodal>
    );
};
export default DonorInformationModal;
