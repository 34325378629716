import { createAsyncThunk } from '@reduxjs/toolkit';

export interface LoadDiscussionMessagesArg {
    discussionId: number;
    clubhouseId: number;
    isAscending: boolean;
    pageSize?: number;
    messageId?: number;
}

export const loadDiscussionMessages = createAsyncThunk(
    'clubhouseMessages/loadDiscussionMessages',
    async (arg: LoadDiscussionMessagesArg, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        const clubhouse = dispatch.getState().clubhouse.clubhouse;
        const messages = dispatch.getState().clubhouseMessages.discussions;

        const discussionMessages =
            messages[clubhouse.selectedDiscussionId]?.messages;
        const message = arg.isAscending
            ? discussionMessages?.at(-1)
            : discussionMessages?.at(0);

        const response = await axios.get(
            `api/clubhouses/${arg.clubhouseId}/discussions/${arg.discussionId}/messages`,
            {
                params: {
                    messageId: arg.messageId ?? message?.id,
                    isAscending: arg.isAscending,
                    pageSize: arg.pageSize,
                    loadAroundMessage: !!arg.messageId,
                },
            }
        );

        return {
            data: response.data.messages,
            discussionId: arg.discussionId,
            isAscending: arg.isAscending,
            lastMessageId: response.data.lastMessageId,
            replaceList: !!arg.messageId,
        };
    }
);
