import React from 'react';
import PropTypes from 'prop-types';

import PageButton from './page-button';

const PaginationList = (props) => {
    const {
        pages = [],
        onPageChange = () => {},
        pageButtonRenderer = null,
        tooltipTargetId = null,
    } = props;

    return (
        <ul className="pagination react-bootstrap-table-page-btns-ul">
            {pages.map((pageProps) => {
                if (pageButtonRenderer) {
                    return pageButtonRenderer({
                        ...pageProps,
                        onPageChange,
                    });
                }
                return (
                    <PageButton
                        key={pageProps.page}
                        {...pageProps}
                        onPageChange={onPageChange}
                        tooltipTargetId={tooltipTargetId}
                    />
                );
            })}
        </ul>
    );
};

PaginationList.propTypes = {
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            page: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.number,
                PropTypes.string,
            ]),
            active: PropTypes.bool,
            disable: PropTypes.bool,
            title: PropTypes.string,
        })
    ).isRequired,
    onPageChange: PropTypes.func.isRequired,
    pageButtonRenderer: PropTypes.func,
    tooltipTargetId: PropTypes.string,
};

export default PaginationList;
