import cs from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../Tooltip/Tooltip';

class PageButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
        this.state = { isOpen: false };
    }

    handleClick(e) {
        e.preventDefault();
        this.props.onPageChange(this.props.page);
    }

    showTooltip() {
        this.setState({ isOpen: true });
    }

    hideTooltip() {
        this.setState({ isOpen: false });
    }

    render() {
        const { page, title, active, disabled, className, tooltipTargetId } =
            this.props;
        const classes = cs(
            {
                active,
                disabled,
                'page-item': true,
            },
            className
        );

        return (
            <>
                <li
                    id={`${tooltipTargetId}-current-page-${title.replaceAll(
                        ' ',
                        '-'
                    )}`}
                    className={classes}
                    onMouseEnter={this.showTooltip}
                    onMouseLeave={this.hideTooltip}>
                    <a
                        href="#"
                        onClick={this.handleClick}
                        className="page-link">
                        {page}
                    </a>
                </li>
                {active && tooltipTargetId ? (
                    <Tooltip
                        className="custom-tooptip"
                        isOpen={this.state.isOpen}
                        placement="top"
                        target={`${tooltipTargetId}-current-page-${title.replaceAll(
                            ' ',
                            '-'
                        )}`}>
                        Current Page
                    </Tooltip>
                ) : (
                    []
                )}
            </>
        );
    }
}

PageButton.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
};

export default PageButton;
