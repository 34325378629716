import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, ModalBody, Row } from 'reactstrap';
import SavedPaymentCard from '../SavedPaymentCard/SavedPaymentCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { SavedCard } from '../SavedPaymentCard/SavedCard';
import './CardPaymentForm.scss';

export interface CardPaymentFormProps {
    doesCardExist: boolean;
    setIsUseDifferentMethodChecked: (isChecked: boolean) => void;
    setIsCardSelected: (isChecked: boolean) => void;
    isUseDifferentMethodChecked: boolean;
    isCardSelected: boolean;
    savedCard: SavedCard;
    shouldSavePaymentMethod: boolean;
    setShouldSavePaymentMethod: (isChecked: boolean) => void;
    setIsFormValid: (isValid: boolean) => void;
}

const CardPaymentForm = (props: CardPaymentFormProps) => {
    const [isNameOnCardValid, setIsNameOnCardValid] = useState<boolean>(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState<boolean>(false);
    const [isExpiryValid, setIsExpiryValid] = useState<boolean>(false);
    const [isCvcNumberValid, setIsCvcNumberValid] = useState<boolean>(false);

    useEffect(() => {
        props.setIsFormValid(
            (isNameOnCardValid &&
                isCardNumberValid &&
                isExpiryValid &&
                isCvcNumberValid) ||
                (!props.isUseDifferentMethodChecked && !!props.savedCard)
        );
    }, [
        isNameOnCardValid,
        isCardNumberValid,
        isExpiryValid,
        isCvcNumberValid,
        props.isUseDifferentMethodChecked,
        props.savedCard,
    ]);

    return (
        <div className="card-payment-form">
            <ModalBody>
                {props.doesCardExist ? (
                    <Row style={{ marginBottom: 6 }}>
                        <FormGroup
                            check
                            style={{ width: '100%', paddingRight: 10 }}>
                            <label className="checkbox-label">
                                <Input
                                    checked={!props.isUseDifferentMethodChecked}
                                    type="checkbox"
                                    onChange={() => {
                                        props.setIsUseDifferentMethodChecked(
                                            !props.isUseDifferentMethodChecked
                                        );
                                        props.setIsCardSelected(
                                            !props.isCardSelected
                                        );
                                    }}
                                    className="checkbox-input"
                                />
                                <span className="wallet-text-ref">
                                    Use saved payment method
                                </span>
                            </label>
                            {props.savedCard ? (
                                <div
                                    className={`saved-payment-info ${
                                        props.isCardSelected ? 'selected' : ''
                                    }`}>
                                    <SavedPaymentCard
                                        savedCard={
                                            props.savedCard
                                        }></SavedPaymentCard>
                                    {props.isCardSelected && (
                                        <FontAwesomeIcon
                                            style={{
                                                color: 'green',
                                            }}
                                            icon={faCheckCircle}
                                        />
                                    )}
                                </div>
                            ) : (
                                []
                            )}
                            <label className="checkbox-label">
                                <Input
                                    checked={props.isUseDifferentMethodChecked}
                                    type="checkbox"
                                    onChange={() => {
                                        props.setIsUseDifferentMethodChecked(
                                            !props.isUseDifferentMethodChecked
                                        );
                                        props.setIsCardSelected(
                                            !props.isCardSelected
                                        );
                                    }}
                                    className="checkbox-input"
                                />
                                <span className="wallet-text-ref">
                                    Use different payment method
                                </span>
                            </label>
                        </FormGroup>
                    </Row>
                ) : (
                    <></>
                )}
                {props.isUseDifferentMethodChecked ? (
                    <>
                        {' '}
                        <Row>
                            <Col>
                                <div className="label">Name on card</div>
                                <Input
                                    name="name"
                                    className="name-input"
                                    placeholder="John Doe"
                                    onChange={(e) =>
                                        setIsNameOnCardValid(!!e.target.value)
                                    }></Input>
                            </Col>
                            <Col>
                                <div className="label">Card Number</div>
                                <CardNumberElement
                                    onChange={(e) =>
                                        setIsCardNumberValid(
                                            e.complete && !e.error
                                        )
                                    }></CardNumberElement>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="label">Expiry</div>
                                <CardExpiryElement
                                    onChange={(e) =>
                                        setIsExpiryValid(e.complete && !e.error)
                                    }></CardExpiryElement>
                            </Col>
                            <Col>
                                <div className="label">CVC Number</div>
                                <CardCvcElement
                                    onChange={(e) =>
                                        setIsCvcNumberValid(
                                            e.complete && !e.error
                                        )
                                    }></CardCvcElement>
                            </Col>
                        </Row>
                        <Row
                            className={
                                props.doesCardExist ? 'right-align' : ''
                            }>
                            <FormGroup check>
                                <label className="checkbox-label">
                                    <Input
                                        checked={props.shouldSavePaymentMethod}
                                        type="checkbox"
                                        onChange={() => {
                                            props.setShouldSavePaymentMethod(
                                                !props.shouldSavePaymentMethod
                                            );
                                        }}
                                        className="checkbox-input"
                                    />
                                    {props.doesCardExist ? (
                                        <span className="wallet-text-ref">
                                            Update saved payment method
                                        </span>
                                    ) : (
                                        <span className="wallet-text-ref">
                                            Save card for future payments
                                        </span>
                                    )}
                                </label>
                            </FormGroup>
                        </Row>
                    </>
                ) : (
                    <></>
                )}
            </ModalBody>
        </div>
    );
};
export default CardPaymentForm;
