export enum DiscountCodeType {
    Standard = 1,
    StudyGroupPartner = 2,
    ExistingStudyGroup = 3,
}

export const DiscountCodeTypes = [
    { id: DiscountCodeType.Standard, name: 'Referral Discount' },
    { id: DiscountCodeType.StudyGroupPartner, name: 'Study Group Partner' },
    { id: DiscountCodeType.ExistingStudyGroup, name: 'Existing Study Group' },
];

export interface DiscountCodeTypeToolTip {
    type: DiscountCodeType;
    tooltip: string;
}
