import { FileType, FILE_EXTENSIONS } from '../../utils/filesConstants';

export class Attachment {
    public type: FileType;
    constructor(public id: number, public name: string, public size: number) {
        const extension = FILE_EXTENSIONS.find(e =>
            e.extensions?.includes('.' + name.split('.').pop())
        );
        this.type = extension
            ? extension
            : FILE_EXTENSIONS[FILE_EXTENSIONS.length - 1];
    }
}
