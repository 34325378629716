export enum StandardizationTypeEnum {
    InitialStandardization12,
    InitialStandardization24,
    InitialStandardization36,
    InitialStandardizationYtd,
    MaintenanceMonthly,
    MaintenanceQuarterly,
    MaintenanceSemiAnnually,
    MaintenanceAnnually,
}

export const StandardizationPeriodList = [
    { id: 0, name: 'L12' },
    { id: 1, name: 'L24' },
    { id: 2, name: 'L36' },
    { id: 3, name: 'YTD + L3CY' },
    { id: 4, name: 'Monthly' },
    { id: 5, name: 'Quarterly' },
    { id: 6, name: 'Semi-Annually' },
    { id: 7, name: 'Annually' },
];
