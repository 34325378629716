import { ComparationType } from './load-discussion-messages-by-likes.thunk';

export interface RetrieveMessageArg {
    clubhouseId: number;
    discussionId: number;
}

export interface RetrieveMessageByLikesArg {
    clubhouseId: number;
    discussionId: number;
    numberOfLikes: number;
    comparationType: ComparationType;
}

export const retrieveMessages = async (
    arg: RetrieveMessageArg,
    dispatch: any
) => {
    const axios = dispatch.getState().core.axios;
    const response = await axios.get(
        `api/clubhouses/${arg.clubhouseId}/discussions/${arg.discussionId}/messages`
    );

    return {
        data: response.data.messages,
        discussionId: arg.discussionId,
        lastMessageId: response.data.lastMessageId,
    };
};

export const retrieveMessagesByLikes = async (
    arg: RetrieveMessageByLikesArg,
    dispatch: any
) => {
    const axios = dispatch.getState().core.axios;
    const response = await axios.get(
        `api/clubhouses/${arg.clubhouseId}/discussions/${arg.discussionId}/messages/byLikes`,
        {
            params: {
                numberOfLikes: arg.numberOfLikes,
                comparationType: arg.comparationType,
            },
        }
    );

    return {
        data: response.data.messages,
        discussionId: arg.discussionId,
        lastMessageId: response.data.lastMessageId,
    };
};
