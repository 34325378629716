import React, { useEffect, useState } from 'react';
import './DataAndDocumentTemplatesDashboard.scss';
import { Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import { HomeButton } from '../../../../components/HomeButton/home-button';
import { useSearchParams } from 'react-router-dom';
import { DataTemplateTable } from './DataTemplateTable/DataTemplateTable';
import { DataFormType } from '../../../../models/DataRequestHub/DataFormTypeEnum';
import { ProjectVariableHost } from '../DataAndDocumentRequestsDashboard/ProjectVariableHost/ProjectVariableHost';
import { DataTablesTemplateTable } from './DataTablesTemplateTable/DataTablesTemplateTable';

export const DataAndDocumentTemplatesDashboard = () => {
    const [activeTab, setActiveTab] = useState<number>();
    const [searchParams, setSearchParams] = useSearchParams({});
    const templateProjectId = 0;
    const [tabData] = useState([
        'Documents',
        'Financial Requests',
        'Questionnaire',
        'Data Tables',
        'Variables',
    ]);

    const changeTab = (index: number) => {
        const tabNumber = index + 1;
        setSearchParams({ tab: tabNumber.toString() });
        setActiveTab(index);
    };

    const getTabClasses = (index: number) => {
        return activeTab == index
            ? 'RRT__tab RRT__tab--first RRT__tab--selected'
            : 'RRT__tab';
    };

    const tabContent = () => {
        switch (activeTab) {
            case 0:
                return (
                    <DataTemplateTable
                        templateType={DataFormType.Documents}
                        tabNumber={1}></DataTemplateTable>
                );
            case 1:
                return (
                    <DataTemplateTable
                        templateType={DataFormType.FinancialRequest}
                        tabNumber={2}></DataTemplateTable>
                );
            case 2:
                return (
                    <DataTemplateTable
                        templateType={DataFormType.Questionnaire}
                        tabNumber={3}></DataTemplateTable>
                );
            case 3:
                return (
                    <DataTablesTemplateTable
                        tabNumber={4}></DataTablesTemplateTable>
                );
            case 4:
                return (
                    <ProjectVariableHost
                        onUpdate={() => {}}
                        projectId={templateProjectId}></ProjectVariableHost>
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
        const value = searchParams.get('tab');
        const numberValue = Number(value);
        if (value && numberValue && numberValue >= 1 && numberValue <= 4) {
            const index = numberValue - 1;
            setActiveTab(index);
        } else {
            setActiveTab(0);
        }
    }, []);

    return (
        <div className="data-and-documents-templates-dashboard">
            <div className="title-content">
                <div className="page-title">
                    <h3>Data & Document Templates Dashboard</h3>
                </div>

                <Breadcrumb>
                    <BreadcrumbItem>
                        <HomeButton></HomeButton>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        Data & Document Templates Dashboard
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="main-card">
                <div className="body_white_box practice-page-tabs m-0">
                    <div className="body_inner_content">
                        <div className="RRT__container">
                            <div className="RRT__tabs body-tabs">
                                {tabData?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={getTabClasses(index)}
                                            onClick={() => {
                                                changeTab(index);
                                            }}
                                            id={'tab-info-' + index}>
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {tabContent()}
                    </div>
                </div>
            </Card>
        </div>
    );
};
