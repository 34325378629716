import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentUser } from './current-user';
export const LoadCurrentUserIfNotSet = createAsyncThunk(
    'currentUser/loadCurrentUserIfNotSet',
    async (forceUpdate: boolean = false, dispatch: any) => {
        const currentUser = dispatch.getState().currentUser.user as CurrentUser;
        if (currentUser.id === 0 || forceUpdate) {
            const axios = dispatch.getState().core.axios;
            const response = await axios.get(`api/who-am-i`);
            return response;
        }
    }
);
