import React, { useState, useEffect } from 'react';
import './SubscriptionsPaymentHistoryPaginatedTable.scss';
import BootstrapTable from '../react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../react-bootstrap-table-next/react-bootstrap-table2-paginator';
import moment from 'moment';
import Helpers from '../../utils/helper';
import UserDetailsModal from '../../containers/Admin/Permissions/Users/Modal/UserDetailsModal';
import createNotification from '../../utils/createNotification';
import { FormGroup } from 'reactstrap';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../arrow-down/arrow-down';
import { TableFilter } from '../Table/models/table-filter';
import { objectToQueryString } from '../../utils/queryHelper';
import { PaymentSourceTypeEnum } from '../../wallet/models/PaymentSourceTypeEnum';
import Loader from 'react-loaders';
import { useStateSelector } from '../../store/selectors';

enum PaymentStatus {
    Pending = 1,
    Confirmed = 2,
    Declined = 3,
}

interface PaymentHistoryPaginatedTableProps {
    typeOptions: Array<any>;
    memberNameOptions: Array<any>;
    practiceNameOptions: Array<any>;
    isUserDetailsAvailable?: boolean;
    subscriptionsUpdatingTracker?: any;
}

interface PaymentHistoryRecord {
    id: number;
    userId: number;
    status: PaymentStatus;
    serviceType: string;
    memberName: string;
    pmgName: string;
    practiceName: string;
    payments: Array<Payments>;
    paymentDate: Date;
    dueDate: Date;
}

interface Payments {
    amount: number;
    lastDigits: string;
    type: PaymentSourceTypeEnum;
}

const PaymentHistoryPaginatedTable = (
    props: PaymentHistoryPaginatedTableProps
) => {
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [userId, setUserID] = useState(0);
    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const axios = useStateSelector((state) => state.core.axios);
    const defaultSortDirection: { dataField: any; order: any } = {
        dataField: 'paymentDate',
        order: 'desc',
    };
    const tableTooltipTargetId = 'subscriptions-payment-history';
    const [sortingState, setSortingState] = useState<any>({
        data: [],
        filters: {},
        page: page,
        searchText: '',
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });

    const [tableFilters, setTalbeFilters] = useState<
        { field: string; value: string; operator: string }[]
    >([]);

    useEffect(() => {
        if (
            props.memberNameOptions.length === 1 &&
            props.memberNameOptions[0]?.id
        ) {
            handleMemberNameChange(props.memberNameOptions[0].name);
        }
    }, [props]);

    useEffect(() => {
        if (sortingState) {
            fetchSubscriptionsData(sortingState);
        }
    }, [sortingState, tableFilters]);

    useEffect(() => {
        if (sortingState) {
            fetchSubscriptionsData(sortingState);
        }
    }, [props.subscriptionsUpdatingTracker]);

    const handleTableChange = (_type: any, newState: any) => {
        setSortingState(newState);
    };

    const showUserDetailPopup = (userId: number) => {
        setIsUserDetailsModalVisible(true);
        setUserID(userId);
    };

    const mapPaymentStatusToLabel = (paymentStatus: number) => {
        switch (paymentStatus) {
            case PaymentStatus.Confirmed:
                return 'Success';
            case PaymentStatus.Pending:
                return 'Pending';
            case PaymentStatus.Declined:
                return 'Failed';
            default:
                break;
        }
    };

    const fetchSubscriptionsData = (sortingState: any) => {
        let tableFilter = new TableFilter(
            { filters: tableFilters, logic: '' },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder,
                field: sortingState.sortField,
            }
        );

        axios
            .get(
                `/api/ServiceSubscriptions/PurchaseHistory?${objectToQueryString(
                    tableFilter
                )}`
            )
            .then((response: any) => {
                if (response && response.data) {
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'There was some error while getting subscriptions. Please come back later',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'There was some error while getting subscriptions. Please come back later',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const renderPaymentMethod = (row: PaymentHistoryRecord) => {
        if (row.status !== PaymentStatus.Pending && row.payments) {
            const vetValueCredits = row.payments.find(
                (x) => x.type != PaymentSourceTypeEnum.Card
            );
            const creditCard = row.payments.find(
                (x) => x.type == PaymentSourceTypeEnum.Card
            );
            return (
                <div>
                    {vetValueCredits ? <div>{`VV Credits`}</div> : []}
                    {creditCard ? (
                        <div>{`CC #${creditCard?.lastDigits}`}</div>
                    ) : (
                        <></>
                    )}
                </div>
            );
        } else {
            return '-';
        }
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'pmgName',
            text: 'Type',
            sort: true,
        },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            sort: true,
        },
        {
            dataField: 'memberName',
            text: 'Member Name',
            sort: true,
            formatter: (cellContent: string, row: PaymentHistoryRecord) => (
                <div
                    className={
                        props.isUserDetailsAvailable ? 'referenced-text' : ''
                    }
                    onClick={() =>
                        props.isUserDetailsAvailable
                            ? showUserDetailPopup(row.userId)
                            : []
                    }>
                    {cellContent}
                </div>
            ),
        },
        {
            dataField: 'serviceType',
            text: 'Service / Subscription',
            sort: true,
        },
        {
            dataField: 'dueDate',
            text: 'Due Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'paymentDate',
            text: 'Payment Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
            sort: true,
        },
        {
            dataField: 'amount',
            text: 'Amount',
            formatter: (_cellContent: any, row: PaymentHistoryRecord) => {
                const vvCredits = row.payments
                    .filter((x) => x.type != PaymentSourceTypeEnum.Card)
                    .map((x) => {
                        x.amount *= -1;
                        return x;
                    });
                const creditCard = row.payments.filter(
                    (x: any) => x.type == PaymentSourceTypeEnum.Card
                );
                return (
                    <div
                        className={
                            vvCredits.length > 0 && creditCard.length > 0
                                ? 'total-credits'
                                : ''
                        }>
                        {vvCredits.length > 0 && creditCard.length > 0 ? (
                            <div style={{ marginLeft: 0 }}>
                                {Helpers.currencyFormatWithBrackets(
                                    vvCredits.reduce(
                                        (sum: any, current: any) =>
                                            sum + current.amount,
                                        0
                                    ) +
                                        creditCard.reduce(
                                            (sum: any, current: any) =>
                                                sum + current.amount,
                                            0
                                        )
                                )}
                            </div>
                        ) : (
                            []
                        )}
                        {vvCredits.length > 0 ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    vvCredits
                                        .filter((x: any) => x.type != 3)
                                        .reduce(
                                            (sum: any, current: any) =>
                                                sum + current.amount,
                                            0
                                        )
                                )}
                            </div>
                        ) : (
                            []
                        )}
                        {creditCard.length > 0 ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    creditCard[0].amount
                                )}
                            </div>
                        ) : (
                            []
                        )}
                    </div>
                );
            },
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cellContent: any) => {
                return <div>{mapPaymentStatusToLabel(cellContent)}</div>;
            },
            sort: true,
        },
        {
            dataField: 'paymentMethod',
            text: 'Payment Method',
            formatter: (_cellContent: any, row: PaymentHistoryRecord) =>
                renderPaymentMethod(row),
            sort: true,
        },
    ];

    const handleTypeNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'pmgName');
        if (changedProperty && changedProperty !== 'None') {
            newFilters.push({
                field: 'pmgName',
                value: changedProperty,
                operator: 'eq',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handlePracticeNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'practiceName');
        if (changedProperty && changedProperty !== 'None') {
            newFilters.push({
                field: 'practiceName',
                value: changedProperty,
                operator: 'contains',
            });
        }
        setTalbeFilters(newFilters);
    };

    const handleMemberNameChange = (changedProperty: string) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'memberName');
        if (changedProperty && changedProperty !== 'None') {
            newFilters.push({
                field: 'memberName',
                value: changedProperty,
                operator: 'contains',
            });
        }
        setTalbeFilters(newFilters);
    };

    return (
        <div className="payment-history-paginated-table">
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '200px',
                    }}>
                    <Loader active={isLoading} type="ball-scale-multiple" />
                </div>
            ) : (
                <>
                    <div className="filters-bar">
                        <FormGroup className="filter-scroller">
                            <div className="filter-item member-input">
                                <label>Type</label>
                                <DropdownList
                                    className="credit-type-select"
                                    selectIcon={<ArrowDown />}
                                    data={props.typeOptions}
                                    onChange={(value) => {
                                        handleTypeNameChange(value.name);
                                    }}
                                    dataKey="TypeId"
                                    filter="contains"
                                    textField="name"
                                />
                            </div>
                            <div className="line-separator"></div>
                            <div className="filter-item member-input">
                                <label>Practice Name</label>
                                <DropdownList
                                    dir=""
                                    className="credit-type-select"
                                    selectIcon={<ArrowDown />}
                                    data={props.practiceNameOptions}
                                    onChange={(value) => {
                                        handlePracticeNameChange(value.name);
                                    }}
                                    dataKey="PracticeNameId"
                                    filter="contains"
                                    textField="name"
                                />
                            </div>
                            <div className="line-separator"></div>
                            <div className="filter-item member-input">
                                <label>Member Name</label>
                                <DropdownList
                                    className="credit-type-select"
                                    selectIcon={<ArrowDown />}
                                    data={props.memberNameOptions}
                                    value={
                                        props.memberNameOptions.length === 1 &&
                                        props.memberNameOptions[0]?.id
                                            ? {
                                                  ...props.memberNameOptions[0],
                                              }
                                            : undefined
                                    }
                                    disabled={
                                        props.memberNameOptions.length === 1
                                    }
                                    onChange={(value) => {
                                        handleMemberNameChange(value.name);
                                    }}
                                    dataKey="MemberNameId"
                                    filter="contains"
                                    textField="name"
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <BootstrapTable
                        remote
                        bootstrap4
                        keyField="PaymentHistoryInfoId"
                        data={tableData}
                        columns={getColumns()}
                        filter={filterFactory({
                            page,
                            sizePerPage,
                            totalSize,
                        })}
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            tooltipTargetId: tableTooltipTargetId,
                        })}
                        defaultSorted={[defaultSortDirection]}
                        onTableChange={handleTableChange}
                        noDataIndication={'No Data available'}
                        rowClasses={(_row) => ''}></BootstrapTable>
                </>
            )}

            {isUserDetailsModalVisible && (
                <UserDetailsModal
                    onClose={() => {
                        setIsUserDetailsModalVisible(false);
                    }}
                    isVisisble={isUserDetailsModalVisible}
                    userID={userId}></UserDetailsModal>
            )}
        </div>
    );
};

export default PaymentHistoryPaginatedTable;
