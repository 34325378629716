import React, { useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-edit';
import { Button } from 'reactstrap';
import './avatar-cropping-tool.scss';

export interface AvatarCroppingToolProps {
    src: File;
    setSrc: React.Dispatch<React.SetStateAction<File>>;
    setIsCroppingModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setNewerFile: React.Dispatch<React.SetStateAction<File>>;
    newerFile: any;
}

const AvatarCroppingTool = (props: AvatarCroppingToolProps) => {
    const [src, setSrc] = useState(null);
    const [preview, setPreview] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [height, setHeight] = useState(0);
    const [minCropRadius, setMinCropRadius] = useState(40);

    useEffect(() => {
        setFileName(props.src.name);
        setFileType(props.src.type);
        let file = URL.createObjectURL(props.src);
        setSrc(file);
        const img = new Image();
        img.src = file;
        img.onload = () => {
            const coefficient = 250 / img.width;
            if (coefficient > 1) {
                setMinCropRadius((70 * coefficient) / 2 + 1);
            }
            const windowHeight = img.height / (img.width / 250) + 1;
            setHeight(windowHeight);
        };
    }, []);

    const saveFile = () => {
        let result = fetch(preview)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], fileName, { type: fileType });
                return file;
            });
        result.then((res) => {
            props.setSrc(res);
            props.setNewerFile(res);
        });
        props.setIsCroppingModalVisible(false);
    };

    return src && height ? (
        <div className="cropping-tool">
            <div className="main-content">
                <AvatarEditor
                    width={250}
                    height={height}
                    onCrop={(view) => {
                        setPreview(view);
                    }}
                    minCropRadius={minCropRadius}
                    exportAsSquare={true}
                    src={src}></AvatarEditor>
                <div>
                    {preview && (
                        <img width={70} height={70} src={preview}></img>
                    )}
                </div>
            </div>
            <div className="btn-wrapper btn-margin">
                <Button
                    className="cancel-btn"
                    onClick={() => {
                        props.setSrc(props.newerFile);
                        props.setIsCroppingModalVisible(false);
                    }}>
                    Cancel
                </Button>
                <Button
                    className="btn save-btn"
                    onClick={() => {
                        saveFile();
                    }}>
                    Apply
                </Button>
            </div>
        </div>
    ) : (
        <div></div>
    );
};

export default AvatarCroppingTool;
