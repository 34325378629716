import React, { useState, useEffect } from 'react';
import Rodal from '../../../../components/Rodal/Rodal';
import { useStateSelector } from '../../../../store/selectors';
import createNotification from '../../../../utils/createNotification';
import './GiftRecipientSearchResultsModal.scss';
import {
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Row,
    Col,
    Button,
    Alert,
    Input,
} from 'reactstrap';
import Loader from 'react-loaders';
import { RecipientInfo } from '../CreateGiftCardModal/GiftCardModels';

interface GiftRecipientSearchResultsModalProps {
    recipientSearchQuery: RecipientInfo;
    isVisible: boolean;
    onSubmitSelected: (selectedRecipientId: RecipientInfo) => void;
    onClose: () => void;
}

export const GiftRecipientSearchResultsModal = (
    props: GiftRecipientSearchResultsModalProps
) => {
    const [isLoading, setIsLoading] = useState(false);

    const [selectedRecipient, setSelectedRecipient] =
        useState<RecipientInfo>(undefined);
    const [recipientsList, setRecipientsList] =
        useState<RecipientInfo[]>(undefined);

    const axios = useStateSelector((state) => {
        return state.core.axios;
    });

    useEffect(() => {
        setIsLoading(true);
        axios
            .post('api/giftCards/repicients', props.recipientSearchQuery)
            .then((response: any) => {
                if (response && response.status == 200) {
                    setRecipientsList(response.data);
                    if (response.data.length > 0) {
                        createNotification(
                            `Found ${
                                response?.data?.length ?? 0
                            } potential recipient${
                                response?.data?.length ?? 0 > 0 ? 's' : ''
                            }`,
                            'success'
                        );
                    }
                } else {
                    createNotification(
                        `Something went wrong during recipients search.`,
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    `Something went wrong during creating Gift Card.`,
                    'error'
                );
            })
            .finally(() => setIsLoading(false));
    }, []);

    const searchResultsNotEmpty =
        !!recipientsList && recipientsList?.length > 0;

    const renderRecipientChoise = (recipient: RecipientInfo) => {
        return (
            <div style={{ width: '100%' }} className='modal_body_radio'>
                <Input
                    type="radio"
                    id={recipient.id.toString()}
                    name="customRadio"
                    disabled={false}
                    checked={
                        selectedRecipient &&
                        selectedRecipient.id == recipient.id
                    }
                    onChange={() => {
                        setSelectedRecipient(recipient);
                    }}
                />
                <label
                    htmlFor={recipient.id.toString()}
                    style={{ width: '100%' }}>
                    <Row className='choice-row'>
                        <Col md={4}>
                            {`${recipient.firstName} ${recipient.lastName}`}
                        </Col>
                        <Col>
                            {`${recipient.practiceName}, ${recipient.practiceLocationCity}, ${recipient.practiceLocationState}`}
                        </Col>
                    </Row>
                    
                </label>
            </div>
        );
    };

    return (
        <div className="gift-card-search-modal">
            <Rodal
                visible={props.isVisible}
                animation={'slideTop'}
                onClose={props.onClose}
                showMask={false}
                width={680}
                center>
                <ModalHeader className="popup-header">
                    Search Results
                </ModalHeader>
                <ModalBody>
                    {isLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '200px',
                            }}>
                            <Loader
                                active={isLoading}
                                type="ball-scale-multiple"
                            />
                        </div>
                    ) : searchResultsNotEmpty ? (
                        <>
                            <Row className='header-row'>
                                <Col md={4} className="bold section name-header-offset">
                                    Name
                                </Col>
                                <Col className="bold section practice-header-offset">Practice</Col>
                            </Row>
                            <div className="recipients-choices-wrapper">
                                <Row form>
                                    <Col>
                                        <FormGroup className="no-bottom-margin">
                                            {recipientsList.map((r) =>
                                                renderRecipientChoise(r)
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ) : (
                        <Row className="no-results-row">
                            <Alert color="dark">
                                Nothing found. Please, try changing the query.
                            </Alert>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={props.onClose}
                        className="btn close-button">
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary confirm-button"
                        disabled={!selectedRecipient}
                        onClick={() =>
                            props.onSubmitSelected(selectedRecipient)
                        }>
                        Select Recipient
                    </Button>
                </ModalFooter>
            </Rodal>
        </div>
    );
};
