export interface PostDataToWebView {
    messageType: PostToWebViewMessageType;
    data: string;
}

export class ToWebViewMessage {
    constructor(
        public messageType: PostToWebViewMessageType,
        public data: string
    ) {}

    public stringify(): string {
        return JSON.stringify(this);
    }
}

export enum PostToWebViewMessageType {
    onRedirectToPoc = 'onRedirectToPoc',
    onRedirectToPmg = 'onRedirectToPmg',
    onRedirectToDirect = 'onRedirectToDirect',
    onRedirectTo = 'onRedirectTo',
}

export const postDataToWebView = (postMessage: PostDataToWebView) => {
    const message = new ToWebViewMessage(
        postMessage.messageType,
        postMessage.data
    );
    const str = JSON.stringify(message);
    if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(str);
    } else {
        console.warn(`ReactNativeWebView is not defined.\n\n${str}`);
    }
};

export const onPocSelected = () => {
    postDataToWebView({
        messageType: PostToWebViewMessageType.onRedirectToPoc,
        data: 'poc',
    });
};
export const onPmgSelected = (id: string) => {
    postDataToWebView({
        messageType: PostToWebViewMessageType.onRedirectToPmg,
        data: id,
    });
};

export const onDirectSelected = () => {
    postDataToWebView({
        messageType: PostToWebViewMessageType.onRedirectToDirect,
        data: "",
    });
};
