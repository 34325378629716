import React from 'react';
import './SavedPaymentCard.scss';
import masterCardLogo from '../../assets/payment-brands/mastercard_logo.svg';
import visaLogo from '../../assets/payment-brands/visa_logo_2021.svg';
import discoverLogo from '../../assets/payment-brands/discover_logo.svg';
import amexLogo from '../../assets/payment-brands/amex_logo.svg';
import dinerLogo from '../../assets/payment-brands/diner_logo.svg';
import jcbLogo from '../../assets/payment-brands/jcb_logo.svg';
import unionPayLogo from '../../assets/payment-brands/unionpay_logo.svg';
import { SavedCard } from './SavedCard';

interface SavedPaymentCardProps {
    savedCard: SavedCard;
}

export const SavedPaymentCard = (props: SavedPaymentCardProps) => {
    const getPaymentSystemImage = () => {
        switch (props.savedCard.brand) {
            case 'mastercard':
                return masterCardLogo;
            case 'visa':
                return visaLogo;
            case 'discover':
                return discoverLogo;
            case 'amex':
                return amexLogo;
            case 'diners':
                return dinerLogo;
            case 'jcb':
                return jcbLogo;
            case 'unionpay':
                return unionPayLogo;
            case 'unknown':
            default:
                break;
        }
    };

    return (
        <div className="saved-card">
            <span className="card-system-image">
                <img src={getPaymentSystemImage()}></img>
            </span>
            <span className="card-info">
                <div>
                    <span className="payment-system-name">
                        {props.savedCard.brand.toUpperCase()}
                    </span>
                    <span className="last-digits">
                        *{props.savedCard.lastDigits}
                    </span>
                </div>
                <div className="expires">
                    <span>{'Expires '}</span>
                    <span>{props.savedCard.expireMonth}</span>
                    <span>/</span>
                    <span>{props.savedCard.expireYear}</span>
                </div>
            </span>
        </div>
    );
};
export default SavedPaymentCard;
