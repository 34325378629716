import { SupportThread } from '../models/SupportThread';
import { addUpdateThread } from '../slices/threads/support-threads-slice';
import { setIsNewSupportMessageReceived } from '../slices/messages/support-threads-messages-slice';
import { Notification } from '../../clubhouse/models/notification';
import { addToMessageList } from '../slices/messages/add-to-messages-list.thunk';
import { addLikeToMessage } from '../slices/messages/add-like-to-message.thunk';
import { fetchSupportMembers } from '../slices/members/fetch-members.thunk';
import { DirectMemberOnlineStatus } from '../models/MemberOnlineStatus';
import { updateOnlineStatus } from '../slices/members/support-portal-members-slice';

const registerSupportThreadsHubHandlers = (
    dispatch: any,
    connection: signalR.HubConnection
): void => {
    if (connection) {
        connection.on('CreateUpdateThread', (notification: SupportThread) => {
            dispatch(addUpdateThread(notification));
        });

        connection.on('CreateMember', () => {
            dispatch(fetchSupportMembers());
        });

        connection.on('Disconnect', () => {
            connection.stop();
        });

        connection.on(
            'UpdateOnlineStatus',
            (notification: DirectMemberOnlineStatus) => {
                dispatch(updateOnlineStatus(notification));
            }
        );

        connection.on('MessageReceived', (notification: Notification) => {
            dispatch(setIsNewSupportMessageReceived(true));
            dispatch(
                addToMessageList({
                    message: notification.message,
                    chatId: notification.chatId,
                })
            );
        });

        connection.on('LikeMessage', (notification: Notification) => {
            dispatch(
                addLikeToMessage({
                    message: notification.message,
                    chatId: notification.chatId,
                })
            );
        });
    } else {
        console.error(
            'Support Threads Connection Handlers do not receive connection'
        );
    }
};

export default registerSupportThreadsHubHandlers;
